import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

import { ValidationErrorComponentModel } from '../form-error-handler.model';

const errorMessageAnimation = trigger('errorMessageAnimation', [
  transition('void => *', [style({ opacity: 0, height: '0px' }), animate(150, style({ opacity: 1, height: '*' }))]),
]);

@Component({
  selector: 'app-form-validation-error-message',
  templateUrl: './form-validation-error-message.component.html',
  styleUrls: ['./form-validation-error-message.component.scss'],
  animations: [errorMessageAnimation],
})
export class FormValidationErrorMessageComponent implements ValidationErrorComponentModel {
  @Input() translationKey: string | null = null;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Input() translationParams?: any;
}
