import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { NumberFormatNone, SettingsService } from '@tokengear-common/modules/settings';

@Pipe({ name: 'customNumber' })
export class CustomNumberPipe implements PipeTransform {
  private readonly defaultFormat = '1.2-2';

  constructor(private readonly settingsService: SettingsService, private readonly decimalPipe: DecimalPipe) {}

  public transform(value: string | number, defaultValue: string | number = 0, format?: string): string {
    const settingDecimalFormat = this.settingsService.settingsLookAndFeel.settings.numberFormat.afterDot;
    const numberFormat =
      settingDecimalFormat !== NumberFormatNone.NONE ? `1.${settingDecimalFormat}` : this.defaultFormat;
    if (value) {
      return this.decimalPipe.transform(value, format ? format : numberFormat);
    }

    return CustomNumberPipe.canTransformToNumber(defaultValue)
      ? this.decimalPipe.transform(defaultValue, numberFormat)
      : defaultValue;
  }

  /**
   * Check to transform a string into a number
   */
  private static canTransformToNumber(value: number | string): value is number {
    return typeof value === 'number' || (typeof value === 'string' && !isNaN(Number(value) - parseFloat(value)));
  }
}
