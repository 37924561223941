import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { action } from 'mobx-angular';
import { Observable } from 'rxjs';

import { HttpHelperService } from '../../../services/http-helper.service';
import { NetworkService } from '../../settings';

@Injectable()
export class APIInterceptor {
  constructor(private networkService: NetworkService) {}

  @action intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.startsWith('/api/')) {
      return next.handle(request);
    }
    request = request.clone({
      url: HttpHelperService.getUrl(request.url),
      params: HttpHelperService.getFilteredParams(request.params),
      headers: request.headers
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache')
        .set(
          'Network-Id',
          this.networkService.currentNetwork ? this.networkService?.currentNetwork?.networkId.toString() : '',
        ),
    });

    return next.handle(request);
  }
}
