<ng-container *mobxAutorun>
  <div class="modal__head">
    <h3 class="modal__headline">
      {{ data.title }}
    </h3>
    <button class="modal__close" [mat-dialog-close]="false">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal">
    <div class="block-line"></div>
    <div class="modal-form">
      <div class="modal-form__wrapper">
        <div class="modal__text">
          {{ data.message }}
        </div>
        <div class="modal-form__btn-row">
          <button
            class="modal-form__btn-row__btn modal-form__btn-row__btn__cancel transparent-btn"
            type="button"
            mat-button
            [mat-dialog-close]="false"
          >
            {{ data.btnCancelText }}
          </button>
          <button class="modal-form__btn-row__btn theme-background" type="button" mat-button [mat-dialog-close]="true">
            {{ data.btnOkText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
