import { observable } from 'mobx-angular';

import { CountryModel } from './country.model';

export class CountryCheckListModel {
  @observable isChecked = false;
  @observable disabled: boolean = null;
  @observable country: CountryModel = null;

  constructor(model: CountryCheckListModel) {
    Object.assign(this, model);

    if (model.country) {
      this.country = new CountryModel(model.country);
    }
  }
}
