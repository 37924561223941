<div appThemeClass *mobxAutorun>
  <div *ngIf="startDate" class="time_card_container">
    <div class="time_card" *ngIf="toDate.days > 0">
      <span class="numbers">{{ toDate.days }}</span>
      <span class="symbol">{{ 'TIMER.DAYS' | translate }}</span>
    </div>
    <div class="time_card" *ngIf="toDate.hours > 0">
      <span class="numbers">{{ toDate.hours }}</span>
      <span class="symbol">{{ 'TIMER.HOURS' | translate }}</span>
    </div>

    <div class="time_card" *ngIf="toDate.min > 0">
      <span class="numbers">{{ toDate.min }}</span>
      <span class="symbol">{{ 'TIMER.MINUTES' | translate }}</span>
    </div>

    <ng-container *ngIf="showSeconds">
      <div class="time_card" *ngIf="toDate.sec > 0">
        <span class="numbers">{{ toDate.sec }}</span>
        <span class="symbol">{{ 'TIMER.SEC' | translate }}</span>
      </div>
    </ng-container>
  </div>
</div>
