import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiUrlsDictionary } from '@tokengear-common/modules/api';

import { CurrencyModel, ExchangeRate } from '../';

@Injectable()
export class CurrenciesService {
  constructor(private http: HttpClient) {}

  public getCurrenciesData(): Observable<CurrencyModel[]> {
    return this.http
      .get<CurrencyModel[]>(ApiUrlsDictionary.currenciesData())
      .pipe(map((response) => response.map((el) => new CurrencyModel(el))));
  }

  public exchangeRate(tokensBase: string, tokensTo: string): Observable<number> {
    return this.http
      .get<ExchangeRate>(ApiUrlsDictionary.exchangeRate(tokensBase, tokensTo))
      .pipe(map(({ rate }) => rate));
  }
}
