export function getStatsOverallMockData() {
  return {
    registeredUsers: 300,
    contributionsCount: 200,
    KYCStats: {
      BLOCKED: 50,
      CONFIRMED: 50,
      PENDING: 50,
      NOT_VERIFIED: 50,
      FAILED: 50,
      MANUAL_CHECKING: 50,
    },
  };
}
