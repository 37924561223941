import { observable } from 'mobx-angular';

export class SettingsContributionsCancelModel {
  @observable enabled: boolean = null;
  @observable durationInDays: number = null;

  constructor(params: SettingsContributionsCancelModel) {
    Object.assign(this, params);
  }
}
