import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { observable } from 'mobx-angular';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
})
export class CountdownTimerComponent implements OnDestroy, OnInit {
  @Input() showSeconds: boolean = true;
  @Input() startDate: Date;
  @Output() public readonly timeIsEnd = new EventEmitter<boolean>();
  @observable toDate = {
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
  };
  public intervalSub: Subscription;

  public ngOnInit(): void {
    this.intervalSub = interval(1000)
      .pipe()
      .subscribe(() => {
        if (this.startDate.getTime() > Date.now()) {
          let delta = Math.abs(this.startDate.getTime() - Date.now()) / 1000;
          this.toDate.days = Math.floor(delta / 86400);
          delta -= this.toDate.days * 86400;
          this.toDate.hours = Math.floor(delta / 3600) % 24;
          delta -= this.toDate.hours * 3600;
          this.toDate.min = Math.floor(delta / 60) % 60;
          delta -= this.toDate.min * 60;
          this.toDate.sec = Math.floor(delta % 60);
        } else {
          this.timeIsEnd.next(true);
          this.intervalSub.unsubscribe();
        }
      });
  }

  public ngOnDestroy(): void {
    if (this.intervalSub) {
    }
  }
}
