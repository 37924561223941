export function getSettingsOptionsMockData() {
  return {
    serviceId: 'ico.options',
    type: 'options',
    settings: {
      currencies: ['USD', 'EUR'],
      timezones: ['Africa/Abidjan', 'Africa/Accra', 'Africa/Addis_Ababa'],
      locales: ['en', 'uk'],
    },
  };
}
