import { InjectionToken, Type } from '@angular/core';
import { NgControl } from '@angular/forms';

import { FormValidationErrorMessageComponent } from './form-validation-error-message/form-validation-error-message.component';

export type ValidationErrorConfig<ErrorKey extends string = string> = Record<ErrorKey, string>;

export interface ValidationErrorComponentModel {
  translationKey: string | null;
  translationParams?: any;
}

export type BaseValidationErrorKeys = 'invalid' | 'required' | 'spaceAtEdges' | 'email';
export type ShouldShowErrorFn = (control: NgControl) => boolean;

export const shouldShowErrorDefaultFn: ShouldShowErrorFn = (control: NgControl) => control.invalid && control.touched;
export const globalErrorConfig: ValidationErrorConfig<BaseValidationErrorKeys> = {
  invalid: 'ERRORS__FIELDS_IS_INVALID',
  required: 'GENERAL__INPUT__ERROR__REQUIRED',
  email: 'GENERAL__INPUT__ERROR__EMAIL',
  spaceAtEdges: 'GENERAL__INPUT__ERROR__NO_SPACE_AT_EDGES',
};

export const VALIDATION_ERRORS = new InjectionToken<ValidationErrorConfig | undefined>('VALIDATION_ERRORS');
export const DYNAMIC_VALIDATION_ERROR_TMPL = new InjectionToken<Type<ValidationErrorComponentModel>>(
  'DYNAMIC_VALIDATION_ERROR_TMPL',
  {
    providedIn: 'root',
    factory: (): Type<ValidationErrorComponentModel> => FormValidationErrorMessageComponent,
  },
);
