import { observable } from 'mobx-angular';

export class UniswapTokenModel {
  @observable address: string;
  @observable symbol0: string;
  @observable symbol1: string;
  @observable token0: string;
  @observable token1: string;
  @observable icon0: string;
  @observable icon1: string;

  constructor(params: UniswapTokenModel) {
    Object.assign(this, params);
  }
}
