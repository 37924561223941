<mat-card [class.card-box-border]="showBorder">
  <mat-card-header class="card-block__header">
    {{ 'USER_STATISTICS__REFERRAL_LINK__HEADER' | translate }}
  </mat-card-header>

  <div class="block-line"></div>
  <mat-card-content *ngIf="referralLink">
    <div class="row">
      <div class="card-block__content-wrapper">
        <qrcode class="card-block__qr-code qr-code" id="qr" [width]="widthQrCode" [qrdata]="referralLink"></qrcode>

        <div>
          <input
            class="card-block__input filter__input"
            type="text"
            disabled
            readonly
            [value]="referralLink"
            #referral
          />

          <button class="card-block__copy-btn theme-foreground" mat-flat-button [ngxClipboard]="referral">
            {{ 'USER_STATISTICS__REFERRAL_LINK__BTN' | translate }}
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
