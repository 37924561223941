import { observable } from 'mobx-angular';

export class SettingsReferral12LevelsModel {
  @observable id: string = null;
  @observable groupId: string = null;
  @observable title: string = null;
  // commission on partner invest
  @observable commissionPercentage: number = null;
  // payout distribution
  @observable payout: {
    tokensPercentage: number;
    fiatPercentage: number;
  } = null;

  // group turnover with own investment
  @observable minGroupInvestment: number = null;
  @observable strongLineMax: number = null;
  // min lines
  @observable minReferrers: number = null;
  @observable minTurnoverEachLineAmount: number = null;
  // min self investment
  @observable minSelfInvestmentAmount: number = null;

  constructor(levels: SettingsReferral12LevelsModel) {
    Object.assign(this, levels);
  }
}
