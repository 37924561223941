import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-slide-pagination',
  templateUrl: './slide-pagination.component.html',
  styleUrls: ['./slide-pagination.component.scss'],
})
export class SlidePaginationComponent {
  @Input() public total: number;
  @Input() public current: number;
  @Input() public isLoading: boolean;
  @Output() public readonly changed = new EventEmitter<number>();

  public goLeft(): void {
    if (this.ableToGoLeft) {
      this.changed.emit(this.current - 1);
    }
  }

  public goRight(): void {
    if (this.ableToGoRight) {
      this.changed.emit(this.current + 1);
    }
  }

  public get ableToGoLeft(): boolean {
    return this.current !== 1;
  }

  public get ableToGoRight(): boolean {
    return this.current !== this.total && this.total !== 0;
  }
}
