export enum DialogResult {
  Ok = 'OK',
  Registered = 'REGISTERED',
  ForgotPassword = 'FORGOT_PASSWORD',
  SingInClosed = 'SING_IN_CLOSED',
  Closed = 'CLOSED',
  Cancel = 'CANCEL',
  Approve = 'APPROVE',
  Error = 'ERROR',
  ForgotPasswordClosed = 'FORGOT_PASSWORD_CLOSED',
  ConfirmEmailClosed = 'CONFIRM_EMAIL_CLOSED',
  OpenSingIn = 'OPEN_SING_IN',
  OpenDashboard = 'OPEN_DASHBOARD',
  ChangeEmailClosed = 'CHANGE_EMAIL_CLOSED',
  ChangePasswordClosed = 'CHANGE_PASSWORD_CLOSED',
}
