import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { NavigationPaths } from '@tokengear-common/modules/navigation';

import { UserRolesDictionary } from '@domain-modules/users/dictionaries/user-roles.dictionary';
import { UsersService } from '@users/services/users/users.service';

import { SettingsService } from '../../settings';

@Injectable()
export class NavigationService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService,
    private settingsService: SettingsService,
  ) {}

  public async navigateToDefaultTenantRoute(): Promise<boolean> {
    if (this.usersService.user?.tenant.role === UserRolesDictionary.Manager) {
      return this.router.navigate([this.settingsService.adminSideBarConfig.settings.defaultNavigationRoute]);
    }

    return this.router.navigate([this.settingsService.sideBarConfig.settings.defaultNavigationRoute]);
  }

  public async navigateToUserDashboard(queryParams?: Params): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.dashboard()], { queryParams });
  }

  public async navigateToUserKyc(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.kyc()]);
  }

  public async navigateToUserContact(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.contact()]);
  }

  public async navigateToUserAffiliateTree(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.affiliateTree()]);
  }

  public async navigateToAdminDashboard(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.admin.dashboard()]);
  }

  public async navigateToAdminEmailsList(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.admin.emails.list()]);
  }

  public async navigateToAdminEmailsCreate(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.admin.emails.create()]);
  }

  public async navigateToAdminEmailsEdit(id?: string): Promise<boolean> {
    return this.router.navigate([NavigationPaths.admin.emails.edit(id)]);
  }

  public async navigateToError404(): Promise<boolean> {
    if (this.usersService.user?.tenant.role === UserRolesDictionary.Manager) {
      return this.navigateToAdminError404();
    }

    return this.navigateToUserError404();
  }

  public async navigateToUserError404(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.error404()]);
  }

  public async navigateToAdminError404(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.admin.error404()]);
  }

  public async navigateToError500(): Promise<boolean> {
    if (this.usersService.user?.tenant.role === UserRolesDictionary.Manager) {
      return this.navigateToAdminError500();
    }

    return this.navigateToUserError500();
  }

  public async navigateToUserLogin(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.login()]);
  }

  public async navigateToUserReg(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.reg()]);
  }

  public async navigateToForgotPassword(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.forgotPassword()]);
  }

  public async navigateToForgotPasswordInfo(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.forgotInfo()]);
  }

  public async navigateToConfirmNewPass(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.confirmNewPass()]);
  }

  public async navigateToConfirmEmail(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.confirmEmail()]);
  }

  public async navigateToUserError500(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.error500()]);
  }

  public async navigateToAdminError500(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.admin.error500()]);
  }

  public async navigateToAdminUsersDetails(id: string): Promise<boolean> {
    return this.router.navigate([NavigationPaths.admin.users.details(id)]);
  }

  public async navigateToConfirmLogin(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.login2FA()]);
  }

  public isUserConfirmEmail(): boolean {
    return this.url.includes(NavigationPaths.user.confirmEmail());
  }

  public isUserRestorePassword(): boolean {
    return this.url.includes(NavigationPaths.user.restorePassword());
  }

  public isUserKycPage(): boolean {
    return this.url.includes(NavigationPaths.user.kyc());
  }

  public async navigateToSalesPoolsDetails(id: string): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.salesPoolDetail(id)]);
  }

  public async navigateToSalesPoolsDetailsWhiteList(id: string): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.salesPoolDetailWhiteList(id)]);
  }

  public async navigateToSalesPools(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.salesPools()]);
  }

  public async navigateToMembership(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.membership()]);
  }

  public async confirmEmailSuccess(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.confirmEmailSuccess()]);
  }
  public async changeEmailSuccess(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.changeEmailSuccess()]);
  }

  public async navigateToPaymentApps(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.paymentApps()]);
  }
  public async navigateToPaymentAppDetails(id: string): Promise<boolean> {
    return this.router.navigate([NavigationPaths.user.paymentAppDetails(id)]);
  }

  public async navigateToAdminPaymentApps(): Promise<boolean> {
    return this.router.navigate([NavigationPaths.admin.paymentApps.list()]);
  }

  public async navigateToAdminPaymentAppUserDetails(id: string, queryParams?: { activeTab: string }): Promise<boolean> {
    return this.router.navigate([NavigationPaths.admin.paymentApps.userDetails(id)], { queryParams });
  }

  public async navigateToAdminUserAppDetails(id: string): Promise<boolean> {
    return this.router.navigate([NavigationPaths.admin.paymentApps.appDetails(id)]);
  }

  public async navigateToPaymentAppList(id?: string): Promise<boolean> {
    if (this.usersService.user?.tenant.role === UserRolesDictionary.Manager && id) {
      return this.navigateToAdminPaymentAppUserDetails(id);
    }

    return this.navigateToPaymentApps();
  }

  public getQueryParam(key: string): string {
    return this.activatedRoute.snapshot.queryParams[key];
  }

  public async setQueryParam(queryParams: Params): Promise<boolean> {
    return this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
    });
  }

  public async navigateTo(routeUrl: string, queryParams?: Params): Promise<boolean> {
    return this.router.navigate([routeUrl], { queryParams });
  }

  public goToLink(url: string): void {
    window.open(url, '_blank');
  }

  public get isAdminEmailEdit(): boolean {
    return this.url.includes(NavigationPaths.admin.emails.edit());
  }

  public get url(): string {
    return this.router.url;
  }

  public async reloadComponent(): Promise<boolean> {
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    return this.router.navigate([currentUrl]);
  }
}
