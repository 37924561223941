import { observable } from 'mobx-angular';

export enum RPC_TYPE {
  RPC = 'RPC',
  INFURA = 'INFURA',
}

export class RpcProviderModel {
  @observable type: RPC_TYPE;
  @observable url: string;
  constructor(data: RpcProviderModel) {
    Object.assign(this, data);
  }
}
