import { observable } from 'mobx-angular';

import { FormattedValueModel } from './formatted-value.model';

export class SeriesModel {
  @observable name: Date = null;
  @observable value: string = null;
  @observable formattedValue: FormattedValueModel = null;

  constructor(params: SeriesModel) {
    Object.assign(this, params);

    if (this.name != null) {
      this.name = new Date(this.name);
    }

    if (this.formattedValue) {
      this.formattedValue = new FormattedValueModel(this.formattedValue);
    }
  }
}
