import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MainContainerWrapperComponent } from './main-container-wrapper.component';

@NgModule({
  declarations: [MainContainerWrapperComponent],
  exports: [MainContainerWrapperComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
})
export class MainContainerWrapperModule {}
