import { observable } from 'mobx-angular';

import { SettingsContributionsCancelModel } from '../settings-contributions-cancel/settings-contributions-cancel.model';
import { PlanType } from '../settings-contributions-plan/settings-contributions-plan.model';

export class SettingsContributionsBtcModel {
  @observable enabled: boolean = null;
  @observable address: PlanType[] | string = null;
  @observable cancel: SettingsContributionsCancelModel = null;

  constructor(params: SettingsContributionsBtcModel) {
    Object.assign(this, params);
    if (this.cancel) {
      this.cancel = new SettingsContributionsCancelModel(this.cancel);
    }
  }
}
