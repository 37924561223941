import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ConnectWalletProvider } from '../models';

/**
 * Set of available events
 *
 * @public
 */
export enum NotifyEvent {
  OpenJiraIssueCollector,
  ClearForm,
  ConnectWalletSuccess,
  ConnectWalletError,
  ToggleSidebarMenu,
  LogOut,
  LogIn,
}

interface EventData {
  name: NotifyEvent;
  value?: NotifyEvenData[NotifyEvent];
}

interface NotifyEvenData {
  [NotifyEvent.OpenJiraIssueCollector]: undefined;
  [NotifyEvent.ClearForm]: undefined;
  [NotifyEvent.ConnectWalletSuccess]: ConnectWalletProvider;
  [NotifyEvent.ConnectWalletError]: string;
  [NotifyEvent.ToggleSidebarMenu]: undefined;
  [NotifyEvent.LogOut]: undefined;
  [NotifyEvent.LogIn]: undefined;
}

export class NotifyHelper {
  private static readonly eventStream$ = new Subject<EventData>();

  public static on<T extends keyof NotifyEvenData, K extends NotifyEvenData[T]>(
    event: T,
    action: (data?: K) => void,
  ): Subscription {
    return this.eventStream$
      .pipe(
        filter(({ name }) => name === event),
        map(({ value }) => value),
      )
      .subscribe(action);
  }

  public static emit<T extends keyof NotifyEvenData, K extends NotifyEvenData[T]>(event: T, value?: K): void {
    this.eventStream$.next({ value, name: event });
  }
}
