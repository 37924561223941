import { TimeModel } from '../models';

export class CountdownHelper {
  public static countdownTime(endDate: number, isFormat: boolean, startDate: number = new Date().getTime()): TimeModel {
    const dayMs = 86400000;
    const hourMs = 3600000;
    const minuteMs = 60000;
    const leftInMs = endDate - startDate;
    if (endDate == null || endDate === 0 || leftInMs <= 0) {
      return null;
    }
    const format = (number) => {
      if (number < 10) {
        return `0${number}`;
      }

      return number.toString();
    };

    const days = Math.floor(leftInMs / dayMs);
    const dayInMs = days * dayMs;

    const hours = Math.floor((leftInMs - dayInMs) / hourMs);
    const hoursInMs = hours * hourMs;

    const minutes = Math.floor((leftInMs - dayInMs - hoursInMs) / minuteMs);
    const minutesInMs = minutes * minuteMs;

    const seconds = Math.floor((leftInMs - dayInMs - hoursInMs - minutesInMs) / 1000);

    return {
      days: isFormat ? format(days) : days,
      hours: isFormat ? format(hours) : hours,
      minutes: isFormat ? format(minutes) : minutes,
      seconds: isFormat ? format(seconds) : seconds,
    };
  }
  public static countDownDate(endDate: number, isFormat: boolean, startDate: Date): TimeModel {
    const time = this.countdownTime(endDate, isFormat, startDate.getTime());
    const year = this.yearDiff(startDate, new Date(endDate * 1000));
    const month = this.monthDiff(startDate, new Date(endDate * 1000)) + 1 - year * 12;

    return {
      year,
      month,
      ...time,
    };
  }

  public static monthDiff(startDate, endDate) {
    let months;
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    const endDateMonth = endDate.getMonth();
    const startDateMonth = startDate.getMonth();
    months += endDateMonth;
    months -= startDateMonth;

    return months <= 0 ? 0 : months;
  }

  public static yearDiff(startDate, endDate) {
    return endDate.getFullYear() - startDate.getFullYear();
  }
}
