import { StatsFilterModel } from '@stats/models/stats-filter/stats-filter.model';

export function getStatsTokensTotalMockData(filter?: StatsFilterModel) {
  if (filter.startDate == null && filter.endDate == null) {
    return [
      {
        currencyName: 'ETH',
        count: '10000000',
        amount: '10000000',
      },
      {
        currencyName: 'LTC',
        count: '10000000',
        amount: '10000000',
      },
    ];
  }

  return [
    {
      currencyName: 'ETH',
      count: '10000000',
      amount: '10000000',
    },
    {
      currencyName: 'LTC',
      count: '30000000',
      amount: '10000000',
    },
  ];
}
