import { observable } from 'mobx-angular';

export class ReferralsConfigModel {
  @observable enabled: boolean = null;
  @observable inviterBonusByLevel: any[] = null;
  @observable referralBonus: number = null;

  constructor(data: ReferralsConfigModel) {
    Object.assign(this, data);
  }
}
