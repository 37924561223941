import { observable } from 'mobx-angular';

import { SettingsVotingItemModel } from './settings-voting-item.model';

export class SettingsVotingModel {
  @observable enabled: boolean;
  @observable activeVote?: SettingsVotingItemModel[];
  constructor(data: SettingsVotingModel) {
    Object.assign(this, data);
  }
}
