import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable } from 'mobx';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiUrlsDictionary } from '@tokengear-common/modules/api';

import { CountriesDataModel, CountryModel, RegionModel } from '../';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  @observable public countries: CountriesDataModel;
  @observable public states: RegionModel[];

  constructor(private http: HttpClient) {}

  public getCountries(useCache = false): Observable<CountriesDataModel> {
    if (useCache && this.countries) {
      return of(this.countries);
    }

    return this.http.get(ApiUrlsDictionary.countriesData()).pipe(
      map((response: CountryModel[]) => {
        response.forEach((el) => (el.code = el.code.toLowerCase()));

        return new CountriesDataModel(response);
      }),
      tap((countries) => {
        this.countries = countries;
      }),
    );
  }

  public getStates(countryId): Observable<RegionModel[]> {
    return this.http.get(ApiUrlsDictionary.regionList(countryId)).pipe(
      map((response: RegionModel[]) => response.map((el) => new RegionModel(el))),
      tap((states) => {
        this.setStates(states);
      }),
    );
  }

  public getCountriesWithRegions(): Observable<string[]> {
    return this.http
      .get(ApiUrlsDictionary.countriesWithRegions())
      .pipe(map((response: string[]) => response.map((el) => el)));
  }

  public setStates(states): void {
    this.states = states.sort((a, b) => (a.regionName > b.regionName ? 1 : -1));
  }
}
