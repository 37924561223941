import { observable } from 'mobx-angular';

export class SettingsFaviconModel {
  @observable bucket: string = null;
  @observable etag: string = null;
  @observable key: string = null;
  @observable location: string = null;
  @observable provider: string = null;

  constructor(params: SettingsFaviconModel) {
    Object.assign(this, params);
  }
}
