import { Injectable } from '@angular/core';

import { UsersService } from '@users/services/users/users.service';

import { PermissionNavigateBaseGuard } from './permission-navigate.base.guard';

import { SettingsResponseModel, SettingsService, SettingsSideBarModel, SideBarItemType } from '../../../settings';
import { NavigationService } from '../../services/navigation.service';

@Injectable()
export class PermissionNavigateUserGuard extends PermissionNavigateBaseGuard<SettingsSideBarModel> {
  constructor(
    settingsService: SettingsService,
    navigationService: NavigationService,
    private readonly usersService: UsersService,
  ) {
    super(settingsService, navigationService);
  }

  protected get settingConfig(): SettingsResponseModel<SettingsSideBarModel> {
    return this.settingsService.sideBarConfig;
  }

  protected hasPagePermission({ enabled, navigation }: SettingsSideBarModel, key: string): boolean {
    return (
      enabled &&
      navigation.some(
        (item) =>
          item.type === SideBarItemType.ROUTE &&
          item.path === key &&
          !item.isDisabled &&
          item.permissions &&
          (item.permissions.length === 0 ||
            (this.usersService.user && item.permissions.includes(this.usersService.user.tenant.role))),
      )
    );
  }
}
