import { observable } from 'mobx-angular';

import {
  LookAndFeelColorStyle,
  LookAndFeelLabelsColorTon,
  LookAndFeelLabelsMileStoneColor,
  LookAndFeelMenuLayout,
} from '../../dictionaries/settings-look-and-feel.dictionary';

export class SettingsLookAndFeelNoImagesModel {
  @observable menuLayout: LookAndFeelMenuLayout = null;
  @observable colorStyle: LookAndFeelColorStyle = null;
  @observable labelColorTon: LookAndFeelLabelsColorTon = null;
  @observable buttonsColor: string = null;
  @observable mileStoneGradientColor: LookAndFeelLabelsMileStoneColor = null;

  constructor(params: SettingsLookAndFeelNoImagesModel) {
    Object.assign(this, params);
  }
}
