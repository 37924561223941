import { observable } from 'mobx-angular';

export class SettingsMilestoneCurrencyModel {
  @observable type: string = null;
  @observable code: string = null;

  constructor(params: SettingsMilestoneCurrencyModel) {
    Object.assign(this, params);
  }
}
