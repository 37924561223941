<label class="file-input">
  <input
    (change)="handleInputChange($event)"
    placeholder="Enter Apartment or Suite"
    class="form-control form-control-lg"
    [accept]="accept"
    #inputFile
    type="file"
  />
</label>
