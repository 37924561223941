import { observable, action } from 'mobx-angular';

import { FaqStatus } from '@domain-modules/faq/dictionaries/faq-status.dictionaty';

export class SettingsFaqModel {
  @observable id?: string = null;
  @observable answer: string = null;
  @observable author?: string = null;
  @observable position?: number = null;
  @observable question: string = null;
  @observable status?: FaqStatus = null;
  @observable title?: string = null;

  constructor(model: SettingsFaqModel) {
    Object.assign(this, model);
  }

  @action setAnswer(value: string) {
    this.answer = value;
  }

  @action setQuestion(value: string) {
    this.question = value;
  }
}
