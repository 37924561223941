import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { action, computed } from 'mobx-angular';

import { NavigationService } from '@tokengear-common/modules/navigation';
import { SettingsService } from '@tokengear-common/modules/settings';

import { ConnectWalletService } from '@pages/user/services/connect-wallet/connect-wallet.service';

@Component({
  selector: 'app-auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthHeaderComponent {
  @Input() titleText: string;
  constructor(
    private connectWalletService: ConnectWalletService,
    private navigationService: NavigationService,
    private settingsService: SettingsService,
  ) {}

  @action async connectToWallet() {
    return this.connectWalletService.connectWallet();
  }
  @action async navigateToSignIn() {
    return this.navigationService.navigateToUserLogin();
  }

  @computed get isAuth1FA(): boolean | number {
    return this.settingsService.isAuth1FA;
  }

  @computed get isAuthCryptoWallet(): boolean {
    return this.settingsService.isAuthCryptoWallet;
  }
}
