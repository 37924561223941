import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserRolesDictionary } from '@domain-modules/users/dictionaries/user-roles.dictionary';
import { UserModel } from '@users/models/user/user.model';
import { UsersService } from '@users/services/users/users.service';

import { NavigationService } from '../../navigation';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanLoad, CanActivate {
  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private usersService: UsersService,
  ) {}

  public canLoad(route: Route): Observable<boolean> {
    return this.canActivate(route);
  }

  public canActivate(route): Observable<boolean> {
    const permission = this.getPermission(route);
    const user = this.usersService.user;
    if (this.authService.isTokenEmpty && permission.includes(UserRolesDictionary.Guest)) {
      return of(true);
    }

    if (this.authService.isTokenEmpty) {
      this.navigationService.navigateToDefaultTenantRoute();

      return of(false);
    }

    if (this.authService.isTokenExpired) {
      this.authService.removeToken();
      this.navigationService.navigateToDefaultTenantRoute();

      return of(false);
    }

    if (user != null) {
      return of(this.checkRole(permission, user));
    }

    return this.usersService.getMe().pipe(map((me) => this.checkRole(permission, me)));
  }

  private getPermission(route): string[] {
    const data = route.data;

    return data != null && data.permission != null ? data.permission : [];
  }

  private checkRole(permission: string[], user: UserModel): boolean {
    if (permission.length === 0) {
      return true;
    }

    if (permission.includes(user.tenant.role)) {
      return true;
    }
    this.navigationService.navigateToDefaultTenantRoute();

    return false;
  }
}
