export function getStatsMockData() {
  return {
    maxTokenSupply: '59500000000000000000000000',
    totalTokenSupply: '341935425369999999999990',
    maxSaleSupply: '44625000000000000000000000',
    totalSaleSupply: '341935425369999999999990',
    currentState: '3',
    activeTier: '0',
    unsoldTokens: '15294277298040000000000004',
    minEthInvest: '436681222707423580',
    tiers: [
      {
        tokenInUsd: '1333333333333333333',
        tokenInWei: '0',
        maxTokensCollected: '15300000000000000000000000',
        soldTierTokens: '5722701959999999999996',
        discountPercents: '75',
        bonusPercents: '0',
        minInvestInUsd: '100',
        minInvestInWei: '0',
        maxInvestInUsd: '0',
        maxInvestInWei: '0',
        startTime: '2018-10-01T09:27:48+00:00',
        endTime: '2018-12-01T09:27:48+00:00',
        type: '1',
      },
      {
        tokenInUsd: '1000000000000000000',
        tokenInWei: '0',
        maxTokensCollected: '11475000000000000000000000',
        soldTierTokens: '150000000000000000000',
        discountPercents: '67',
        bonusPercents: '0',
        minInvestInUsd: '100',
        minInvestInWei: '0',
        maxInvestInUsd: '0',
        maxInvestInWei: '0',
        startTime: '2018-10-01T09:27:48+00:00',
        endTime: '2018-12-02T09:27:48+00:00',
        type: '1',
      },
      {
        tokenInUsd: '1000000000000000000',
        tokenInWei: '0',
        maxTokensCollected: '11475000000000000000000000',
        soldTierTokens: '150000000000000000000',
        discountPercents: '67',
        bonusPercents: '0',
        minInvestInUsd: '100',
        minInvestInWei: '0',
        maxInvestInUsd: '0',
        maxInvestInWei: '0',
        startTime: '2018-10-01T09:27:48+00:00',
        endTime: '2018-12-03T09:27:48+00:00',
        type: '1',
      },
      {
        tokenInUsd: '333333333333333333',
        tokenInWei: '0',
        maxTokensCollected: '17850000000000000000000000',
        soldTierTokens: '336062723409999999999994',
        discountPercents: '0',
        bonusPercents: '0',
        minInvestInUsd: '100',
        minInvestInWei: '0',
        maxInvestInUsd: '0',
        maxInvestInWei: '0',
        startTime: '2018-10-01T09:27:48+00:00',
        endTime: '2018-12-04T09:27:48+00:00',
        type: '2',
      },
    ],
    contr: {
      ETH: {
        tokens: '305333333333333333333',
        formattedTokens: '305.333333333333333333',
        tokensexcludingbonus: '305333333333333333333',
        formattedTokensexcludingbonus: '305.333333333333333333',
        bonus: '0',
        formattedBonus: '0',
      },
      BTC: {
        tokens: '8686769133333333333333',
        formattedTokens: '8686.769133333333333333',
        tokensexcludingbonus: '8686769133333333333333',
        formattedTokensexcludingbonus: '8686.769133333333333333',
        bonus: '0',
        formattedBonus: '0',
      },
      USD: {
        tokens: '1333320000000000000',
        formattedTokens: '1.33332',
        tokensexcludingbonus: '1333320000000000000',
        formattedTokensexcludingbonus: '1.33332',
        bonus: '0',
        formattedBonus: '0',
      },
    },
    startTime: '1533081600',
    endTime: '1539993600',
    tokensSold: '341935.42536999999999999',
    tokensForSale: '59158064.57463000000000001',
    unsoldTokensPriceEth: '50090.42783200873362450884',
    soldPercentage: '0.00574681387176470588',
    collected: {
      ETH: 0,
      BTC: 0,
      USD: 0,
    },
  };
}
