import { observable } from 'mobx-angular';

import { DashboardPageConfigModel } from './dashboard-page-config.model';

export class SettingsDashboardPageModel {
  @observable enabled: boolean;
  @observable items: DashboardPageConfigModel;

  constructor(data: SettingsDashboardPageModel) {
    Object.assign(this, data);
  }
}
