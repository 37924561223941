export function getMilestonesMockData() {
  return {
    serviceId: 'milestone.service',
    type: 'milestones',
    settings: {
      enabled: true,
      maxSupply: '100000',
      alreadyCollected: '20650',
      currency: {
        type: 'fiat',
        code: 'USD',
      },
      milestones: [
        {
          name: 'Mobile app with blockchain ID',
          amount: '5000',
        },
        {
          name: 'Approve app with blockchain ID',
          amount: '10000',
        },
        {
          name: 'Approve app with blockchain ID',
          amount: '20000',
        },
        {
          name: 'Approve app with blockchain ID',
          amount: '100000',
        },
      ],
    },
  };
}
