import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { action, computed, observable } from 'mobx-angular';
import { Observable, Subject } from 'rxjs';

import { fromWei, shortEthAddress } from '@tokengear-common/helpers';
import { AuthService } from '@tokengear-common/modules/auth';
import { NavigationService } from '@tokengear-common/modules/navigation';
import {
  SettingsService,
  SettingsGeneralModel,
  SettingsResponseModel,
  NetworkService,
} from '@tokengear-common/modules/settings';
import { ToastService } from '@tokengear-common/services';

import { AppService } from '@app/app.service';
import { AbiStorageDictionary, ContractStorageDictionary } from '@app/integrations/contracts/contract-storage';
import { ContractStorageService } from '@app/integrations/contracts/contract-storage.service';
import { ContractService } from '@app/integrations/contracts/contract.service';
import { CollectorOptions } from '@app/integrations/modules/jira-issue-collector';
import { Web3Service } from '@app/integrations/services/web3/web3.service';
import { DollarTokenConfigModel } from '@domain-modules/dollar-tokens/models/dollar-token-config/dollar-token-config.model';
import { SalePoolsService } from '@domain-modules/pools/services/sale-pools.service';
import { StakingPoolsService } from '@domain-modules/pools/services/staking-pools.service';
import { userStatusDictionary } from '@domain-modules/users/dictionaries/user-status.dictionary';
import { UserNewPasswordModel } from '@domain-modules/users/models/user-new-password/user-new-password.model';
import { UserSingUpModel } from '@domain-modules/users/models/user-sing-up/user-sing-up.model';
import { UsersService } from '@domain-modules/users/services/users/users.service';
import { environment } from '@environments/environment';
import { ConnectWalletService } from '@pages/user/services/connect-wallet/connect-wallet.service';
import { MenuService } from '@pages/user/services/menu/menu.service';
import { UserDollarTokenBalanceModel } from '@users/models/user-dollar-token-balance/user-dollar-token-balance.model';
import { UserWalletBalanceModel } from '@users/models/user-wallet-balance/user-wallet-balance.model';
import { UserModel } from '@users/models/user/user.model';

@Injectable()
export class UserService {
  @observable public userWalletBalance: UserWalletBalanceModel = new UserWalletBalanceModel({
    balance: null,
    symbol: null,
  });
  public userWalletBalanceChange$ = new Subject<UserWalletBalanceModel>();

  @observable public userDollarTokenBalance: UserDollarTokenBalanceModel = new UserDollarTokenBalanceModel({
    balance: null,
    symbol: null,
  });
  public userDollarTokenBalanceChange$ = new Subject<UserDollarTokenBalanceModel>();

  @observable public userStakedBalance: string | number;
  public userStakedBalanceChange$ = new Subject<string | number>();

  constructor(
    private appService: AppService,
    private translate: TranslateService,
    private authService: AuthService,
    private connectWalletService: ConnectWalletService,
    private usersService: UsersService,
    private settingsService: SettingsService,
    private networkService: NetworkService,
    private menuService: MenuService,
    private web3Service: Web3Service,
    private contractService: ContractService,
    private contractStorageService: ContractStorageService,
    private navigationService: NavigationService,
    private salePoolsService: SalePoolsService,
    private stakingPoolsService: StakingPoolsService,
    private toastService: ToastService,
  ) {}

  @action changeDirection() {
    this.appService.changeDirection();
    window.document.body.setAttribute('dir', this.appService.direction ? 'ltr' : 'rtl');
  }

  @action registration(user: UserSingUpModel) {
    return this.usersService.registration(user);
  }

  login(credentials: UserSingUpModel): Observable<unknown> {
    return this.authService.login(credentials);
  }

  @action verify2FA(data: { secret: string }) {
    return this.authService.verify2FA(data);
  }

  @action resetPasswordRequest(email: string) {
    return this.usersService.resetPasswordRequest(email);
  }

  @action getResetToken(token: string) {
    return this.usersService.getResetToken(token);
  }

  @action createNewPassword(newPassword: UserNewPasswordModel) {
    return this.usersService.createNewPassword(newPassword);
  }

  @action confirmEmail(token: string) {
    return this.usersService.confirmEmail(token);
  }

  @action updateUserImage(userId: string, data: { img: string }) {
    return this.usersService.updateUserImage(userId, data);
  }

  @action updateCurrentUser(user: UserModel) {
    return this.usersService.updateCurrentUser(user);
  }

  @action removeMeUser() {
    return this.usersService.removeMeUser();
  }

  @action updateUserSlug(userId: string, data: { slug: string }) {
    return this.usersService.updateUserSlug(userId, data);
  }

  @action changeLanguage(lang: string) {
    this.translate.use(lang);
  }

  @action getMySalesPoolsList(params: { limit: number; offset: number }) {
    return this.salePoolsService.getUserSalesPoolsList(this.user.id, params);
  }

  @action getMyStakingPoolsList(params: { limit: number; offset: number }) {
    return this.stakingPoolsService.getUserStakingPoolsList(this.user.id, params);
  }

  @action getMyPoolsWithDeposit(networkId: number) {
    return this.salePoolsService.getUserDepositedPools(this.user.id, networkId);
  }
  @computed get isClient(): boolean {
    return this.usersService.isClient;
  }

  @computed get user(): UserModel {
    return this.usersService.user;
  }

  @computed get userKycStatusConfirmed(): boolean {
    if (this.user == null) {
      return null;
    }

    return this.user.tenant.status === userStatusDictionary.Confirmed;
  }

  @computed get checkUserKycStatusConfirmed(): boolean {
    if (!this.isClient) {
      this.connectWalletService.connectWallet();

      return null;
    } else if (this.isClient && !this.userKycStatusConfirmed) {
      this.navigationService.navigateToUserKyc();

      return null;
    }

    return this.isClient && this.userKycStatusConfirmed;
  }

  @computed get checkUserLogin(): boolean {
    if (!this.isClient) {
      this.connectWalletService.connectWallet();

      return null;
    }

    return this.isClient;
  }

  @computed get isKycFeePaid(): boolean {
    if (!this.user || !this.settingsService.kycConfig.settings.isKycFeeEnabled) {
      return false;
    }

    return this.user.tenant.isKycFeePaid;
  }

  @computed get settingsGeneral(): SettingsResponseModel<SettingsGeneralModel> {
    return this.settingsService.settingsGeneral;
  }

  @computed get direction() {
    return this.appService.direction;
  }

  @computed get jiraReportConfig(): CollectorOptions {
    return {
      baseUrl: environment.jiraBaseUrl,
      collectorId: environment.jiraCollectorId,
      recordWebInfo: true,
      fieldValues: {
        fullname: this.user ? `${this.user.params.firstName} ${this.user.params.lastName}` : '',
        email: this.user ? this.user.email : '',
        recordWebInfo: '1',
        recordWebInfoConsent: ['1'],
      },
    };
  }

  @computed get hasFundAccount(): boolean {
    return (
      this.settingsService.fundAccount.settings.enabled &&
      this.user &&
      this.user?.tenant.status === userStatusDictionary.Confirmed
    );
  }

  @computed get dollarTokenSettings(): SettingsResponseModel<DollarTokenConfigModel> {
    return this.settingsService.dollarToken;
  }

  @action confirmChangeEmail(token: string) {
    return this.usersService.changeEmailConfirm(token);
  }

  public shortWalletAddress(firstSymbolNumb?: number) {
    if (this.user && this.user.tenant.address) {
      return shortEthAddress(firstSymbolNumb, this.user.tenant.address);
    }

    return null;
  }

  public getUserWalletBalance(): void {
    if (this.isClient && this.userWalletBalance) {
      this.userWalletBalanceChange$.next(this.userWalletBalance);
    } else {
      this.fetchUserWalletBalance();
    }
  }

  public fetchUserWalletBalance(): void {
    const contractToken = this.contractStorageService.contractStorage[ContractStorageDictionary.contractToken];
    if (this.settingsService.isAuthCryptoWallet && this.user?.tenant?.address && contractToken.address) {
      this.contractService
        .getContractBalanceOf(contractToken.abiJson, contractToken.address, this.user.tenant.address)
        .then(async (balanceResult) => {
          const tokenSymbol = await this.contractService.getContractTokenSymbol(
            this.contractStorageService.abiStorage[AbiStorageDictionary.erc20Abi],
            contractToken.address,
          );
          if (balanceResult && tokenSymbol) {
            this.userWalletBalance.balance = fromWei(balanceResult);
            this.userWalletBalance.symbol = this.networkService.isTokenSymbolByISO
              ? this.settingsService.settingsGeneral.settings.tokenSymbol
              : tokenSymbol;
          } else {
            this.userWalletBalance = new UserWalletBalanceModel({ balance: null, symbol: null });
          }
          this.userWalletBalanceChange$.next(this.userWalletBalance);
        });
    } else {
      this.userWalletBalance = new UserWalletBalanceModel({ balance: null, symbol: null });
      this.userWalletBalanceChange$.next(this.userWalletBalance);
    }
  }

  public getUserStakedBalance(): void {
    if (this.isClient && this.userStakedBalance) {
      this.userStakedBalanceChange$.next(this.userStakedBalance);
    } else {
      this.fetchUserStakedBalance();
    }
  }

  public fetchUserStakedBalance(): void {
    if (this.settingsService.isAuthCryptoWallet && this.user.tenant.address) {
      this.stakingPoolsService
        .getUserStaked(this.contractStorageService.contractStorage[ContractStorageDictionary.contractToken].address)
        .subscribe((result) => {
          this.userStakedBalance = fromWei(result);
          this.userStakedBalanceChange$.next(this.userStakedBalance);
        });
    } else {
      this.userStakedBalance = 0;
      this.userStakedBalanceChange$.next(this.userStakedBalance);
    }
  }

  public getUserDollarTokenBalance(): void {
    if (this.isClient && this.userDollarTokenBalance) {
      this.userDollarTokenBalanceChange$.next(this.userDollarTokenBalance);
    } else {
      this.fetchUserDollarTokenBalance();
    }
  }

  public fetchUserDollarTokenBalance(): void {
    if (this.isClient && this.hasFundAccount) {
      this.settingsService.getDollarTokenSettings().subscribe(() => {
        if (
          this.dollarTokenSettings.settings.enabled &&
          this.dollarTokenSettings.settings.address &&
          this.user.tenant.address
        ) {
          this.contractService
            .getContractBalanceOf(
              this.contractStorageService.abiStorage[AbiStorageDictionary.erc20Abi],
              this.dollarTokenSettings.settings.address,
              this.user.tenant.address,
            )
            .then(async (balance) => {
              const decimals = await this.contractService.getContractTokenDecimals(
                this.contractStorageService.abiStorage[AbiStorageDictionary.erc20Abi],
                this.dollarTokenSettings.settings.address,
              );
              const symbol = await this.contractService.getContractTokenSymbol(
                this.contractStorageService.abiStorage[AbiStorageDictionary.erc20Abi],
                this.dollarTokenSettings.settings.address,
              );
              if (balance && symbol) {
                this.userDollarTokenBalance.balance = fromWei(balance, decimals);
                this.userDollarTokenBalance.symbol = symbol;
              } else {
                this.userDollarTokenBalance = null;
              }
              this.userDollarTokenBalanceChange$.next(this.userDollarTokenBalance);
            });
        } else {
          this.userDollarTokenBalance = null;
          this.userDollarTokenBalanceChange$.next(this.userDollarTokenBalance);
        }
      });
    }
  }

  public accountAddressIsEqualToUserAddress(accountAddress: string): boolean {
    const isEqual = accountAddress.toLowerCase() === this.user.tenant.address.toLowerCase();
    if (!isEqual) {
      this.toastService.open('GENERAL__OTHERS__CHANGE_ACCOUNT_MESSAGE');
    }

    return isEqual;
  }
}
