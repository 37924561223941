export enum ServicesIdDictionary {
  bitcoinContributions = 'configuration.bitcoin-contributions',
  invoiceContributions = 'configuration.invoice-contributions',
  milestones = 'configuration.milestone.service',
  faq = 'configuration.faq.service',
  options = 'configuration.platform.localization.settable.options',
  lookAndFeel = 'configuration.look-and-feel-service',
  general = 'configuration.ico',
  blacklistCountries = 'configuration.blacklist-countries',
  aboutProject = 'configuration.about.project.service',
  whitelistEmails = 'configuration.whitelisted-emails',
  userRegistrationConfig = 'configuration.user.registration.config.service',
  referral12 = 'configuration.referral12.service',
  referral = 'configuration.referral.service',
  contracts = 'configuration.contracts',
  customId = 'configuration.customId-service',
  userKycConfig = 'configuration.user.kyc.config.service',
  kycConfig = 'configuration.kyc.config.service',
  referralTree = 'configuration.referral-tree.service',
  bugsReport = 'configuration.bugs-report.service',
  chainLinks = 'configuration.chain-links.service',
  fundAccount = 'configuration.fund.account.config.service',
  dollarToken = 'configuration.dollar.token.config.service',
  dashboardPage = 'configuration.dashboard-page.service',
  auth = 'configuration.auth.service',
  cryptoIconsConfig = 'configuration.crypto.icons.config.service',
  footer = 'configuration.user.footer.config.service',
  vestingWhitelist = 'configuration.vesting.whitelist.service',
  vestingPoolsConfig = 'configuration.vesting.pools',
  contractsConfig = 'configuration.contracts.interface.service',
  networksConfig = 'configuration.networks.service',
  bridgeConfig = 'configuration.bridge',
  walletsConfig = 'configuration.wallet.service',
  sideBarConfig = 'configuration.side-bar.service',
  leaderboardConfig = 'configuration.leaderboard',
  headerConfig = 'configuration.header.config.service',
  anotherAddressConfig = 'configuration.another-address.service',
  idoConfig = 'configuration.single-ido.service',
  votingConfig = 'configuration.single-vote.service',
  transactionsExplorerLink = 'configuration.transactions-explorer-link.service',
  adminSideBarConfig = 'configuration.admin-side-bar.service',
  mainPageConfig = 'configuration.main-page.service',
  buyTokensConfig = 'configuration.buy-section.config.service',
  membershipConfig = 'configuration.membership',
}
