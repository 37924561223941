import { observable } from 'mobx-angular';

import { SettingsPlanModel } from '@tokengear-common/modules/settings';

export class SettingsContractsModel {
  @observable crowdsale: { address: string };
  @observable stats: { address: string };
  public params: SettingsPlanModel[];
  constructor(params: SettingsContractsModel) {
    Object.assign(this, params);
  }
}
