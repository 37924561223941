import { observable } from 'mobx-angular';

export class KycSignedUrlModel {
  @observable bucket: string;
  @observable key: string;
  @observable url: string;

  constructor(data: KycSignedUrlModel) {
    Object.assign(this, data);
  }
}
