import { observable } from 'mobx-angular';

import { SettingsMilestoneCurrencyModel } from '../settings-milestone-currency/settings-milestone-currency.model';
import { SettingsMilestoneModel } from '../settings-milestone/settings-milestone.model';

export class SettingsMilestonesModel {
  @observable maxSupply: string = null;
  @observable alreadyCollected: string = null;
  @observable enabled: boolean = null;
  @observable currency: any = null;
  @observable milestones: SettingsMilestoneModel[] = null;
  @observable statisticForInvestorIsDisplayed: boolean = null;

  constructor(params: SettingsMilestonesModel) {
    Object.assign(this, params);
    this.currency = new SettingsMilestoneCurrencyModel(this.currency);
    this.milestones = this.milestones.map((item) => new SettingsMilestoneModel(item));
  }
}
