<mat-card [class.card-box-border]="showBorder">
  <app-coming-soon></app-coming-soon>

  <mat-card-header class="card-block__header">
    {{ 'USER_STATISTICS__TOKEN_BALANCE__HEADER' | translate }}
    <button *ngIf="showBtn" class="card-block__small-btn theme-background" mat-flat-button>
      {{ 'USER_STATISTICS__TOKEN_BALANCE__BTN' | translate }}
    </button>
  </mat-card-header>

  <div class="block-line"></div>
  <mat-card-content>
    <div class="row">
      <div class="col-xs-6">
        <div class="card-block__header-text">
          {{ 'USER_STATISTICS__TOKEN_BALANCE__TOKEN_PURCHASES__ALL' | translate }}
        </div>
        <div class="card-block__data-text">85,942</div>
      </div>
      <div class="col-xs-6">
        <div class="card-block__header-text">
          {{ 'USER_STATISTICS__TOKEN_BALANCE__AVAILABLE_LIQUIDATION' | translate }}
        </div>
        <div class="card-block__data-text">1,353</div>
      </div>
      <div class="col-xs-6">
        <div class="card-block__header-text">{{ 'USER_STATISTICS__TOKEN_BALANCE__LOCKED_TOKENS' | translate }}</div>
        <div class="card-block__data-text">5,431</div>
      </div>
      <div class="col-xs-6">
        <div class="card-block__header-text">{{ 'USER_STATISTICS__TOKEN_BALANCE__LIQUIDATED_LAST' | translate }}</div>
        <div class="card-block__data-text">124,543</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
