import { Injectable } from '@angular/core';
import { computed } from 'mobx-angular';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import {
  LookAndFeelStepItem,
  SettingsImageModel,
  SettingsLookAndFeelModel,
  SettingsResponseModel,
  SettingsService,
} from '@tokengear-common/modules/settings';

import { AdminLookAndFeelStore } from '../stores/admin-look-and-feel.store';

@Injectable()
export class AdminLookAndFeelService {
  private emptySettings = {
    mileStoneGradientColor: {},
  } as SettingsLookAndFeelModel;

  constructor(private adminLookAndFeelStore: AdminLookAndFeelStore, private settingsService: SettingsService) {}

  public setLookAndFeelSettings(
    settings: SettingsResponseModel<SettingsLookAndFeelModel>,
  ): Observable<SettingsResponseModel<SettingsLookAndFeelModel>> {
    return this.settingsService.setSettings<SettingsLookAndFeelModel>(settings).pipe(
      tap(() => {
        Object.assign(this.settingsService.settingsLookAndFeel.settings, settings);
        const newSettings = {
          ...this.settingsService.settingsLookAndFeel,
        } as SettingsResponseModel<SettingsLookAndFeelModel>;
        this.settingsService.updateSettingsLookAndFeel(newSettings);
      }),
    );
  }

  public setImage(type: string, image: Blob): Observable<SettingsImageModel> {
    const formData: FormData = new FormData();
    formData.append('image', image);

    return this.settingsService.setImage(type, formData).pipe(
      tap((value) => {
        this.settingsService.settingsLookAndFeel.settings[type] = value;
        const newSettings = {
          ...this.settingsService.settingsLookAndFeel,
        } as SettingsResponseModel<SettingsLookAndFeelModel>;
        this.settingsService.updateSettingsLookAndFeel(newSettings);
        if (type === 'logo') {
          this.settingsService.updateLogo(`${value.location}?${Math.random()}`);
        } else if (type === 'favicon') {
          this.settingsService.updateFavicon(`${value.location}?${Math.random()}`);
        } else if (type === 'emailLogo') {
          this.settingsService.updateEmailLogo(`${value.location}?${Math.random()}`);
        }
      }),
      catchError((error) => {
        if (type === 'logo') {
          this.updateLogoServerError((error.error && error.error.message) || error.error);
        } else {
          this.updateFaviconServerError((error.error && error.error.message) || error.error);
        }

        return throwError(error);
      }),
    );
  }

  public updateLogoServerError(value: string): void {
    this.adminLookAndFeelStore.updateLogoServerError(value);
  }

  public updateFaviconServerError(value: string): void {
    this.adminLookAndFeelStore.updateFaviconServerError(value);
  }

  public updateEmailLogoServerError(value: string): void {
    this.adminLookAndFeelStore.updateEmailLogoServerError(value);
  }

  public updatePreSettings(value: SettingsLookAndFeelModel): void {
    this.adminLookAndFeelStore.updatePreSettings(value);
  }

  @computed public get lookAndFeelSettings(): SettingsResponseModel<SettingsLookAndFeelModel> {
    return this.settingsService.settingsLookAndFeel;
  }

  @computed public get logo(): string {
    return this.settingsService.logo;
  }

  @computed public get emailLogo(): string {
    return this.settingsService.emailLogo;
  }

  @computed public get favicon(): string {
    return this.settingsService.favicon;
  }

  @computed public get logoServerError(): string {
    return this.adminLookAndFeelStore.logoServerError;
  }

  @computed public get faviconServerError(): string {
    return this.adminLookAndFeelStore.faviconServerError;
  }

  @computed public get emailLogoServerError(): string {
    return this.adminLookAndFeelStore.emailServerError;
  }

  @computed public get stepsBlock(): LookAndFeelStepItem[] {
    return this.settingsService.settingsLookAndFeel.settings.stepsBlock;
  }

  @computed public get preSettings(): SettingsLookAndFeelModel {
    return (
      this.adminLookAndFeelStore.preSettings ||
      (this.settingsService.settingsLookAndFeel && this.settingsService.settingsLookAndFeel.settings) ||
      this.emptySettings
    );
  }
}
