import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { action, computed, observable } from 'mobx-angular';
import { lastValueFrom } from 'rxjs';

import {
  LookAndFeelColorStyle,
  LookAndFeelLabelsColorTon,
  LookAndFeelLabelsMileStoneColor,
  LookAndFeelMenuLayout,
  LookAndFeelTextColor,
  SettingsLookAndFeelModel,
} from '@tokengear-common/modules/settings';
import { ToastService } from '@tokengear-common/services';

import { AdminLookAndFeelService } from '../../services/admin-look-and-feel.service';

// enums for color checkboxes
enum ButtonColorEnum {
  one = 'ONE',
  two = 'TWO',
  custom = 'CUSTOM',
}

enum GradientColorEnum {
  one = 'ONE',
  two = 'TWO',
  custom = 'CUSTOM',
}

interface SelectedColors {
  buttonsColor: string;
  mileStoneGradientColor: {
    colorTop: string;
    colorBottom: string;
  };
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent {
  public readonly lookAndFeelColorStyle = LookAndFeelColorStyle;
  public readonly lookAndFeelLabelsColorTon = LookAndFeelLabelsColorTon;
  public readonly lookAndFeelMenuLayout = LookAndFeelMenuLayout;
  public readonly lookAndFeelTextColor = LookAndFeelTextColor;
  public readonly buttonColorEnum = ButtonColorEnum;
  public readonly gradientColorEnum = GradientColorEnum;

  // list of base colors
  public basicColors = {
    purple: '#7460EE',
    blue: '#0072FF',
    gradient11: '#00abff',
    gradient12: '#0072ff',
    gradient21: '#F76B1C',
    gradient22: '#FF5B5B',
  };
  // custom colors values
  @observable public colors = {
    buttons: '',
    gradient1: '',
    gradient2: '',
  };
  public isHex = /(^#[\dA-F]{6}$)|(^#[\dA-F]{3}$)/i;

  public logoImage = '';
  public faviconImage = '';
  public emailLogoImage = '';
  public logoFile: Blob;
  public faviconFile: Blob;
  public emailLogoFile: Blob;

  // object with selected color checkboxes
  @observable public selectedColor = {
    buttons: '',
    gradient: '',
  };
  public fileLoader: boolean;
  public favFileLoader: boolean;
  public emailLogoFileLoader: boolean;

  constructor(
    private adminLookAndFeelService: AdminLookAndFeelService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  @action public onSaveSettings(): void {
    const colors = this.getColors();
    this.adminLookAndFeelService.lookAndFeelSettings.settings = {
      menuLayout: this.preSettings.menuLayout,
      colorStyle: this.preSettings.colorStyle,
      labelColorTon: this.preSettings.labelColorTon,
      textColor: this.preSettings.textColor,
      buttonsColor: colors.buttonsColor,
      numberFormat: this.preSettings.numberFormat,
      mileStoneGradientColor: colors.mileStoneGradientColor as LookAndFeelLabelsMileStoneColor,
    };
    this.adminLookAndFeelService
      .setLookAndFeelSettings(this.adminLookAndFeelService.lookAndFeelSettings)
      .subscribe(() => {
        this.toastService.open('ADMIN_DASHBOARD__SETTINGS__SAVE');
      });
  }

  // change custom color input handler
  @action public changeColor(type, color): void {
    this.colors[type] = color;
    this.colors = { ...this.colors, ...{ [type]: color } };
  }

  // color text field input focus out event handler
  public focusOut(type, prop): void {
    let testColor = '';

    // eslint-disable-next-line default-case
    switch (type) {
      case 'buttons':
        testColor = this.customButtonsColor;
        break;
      case 'gradient1':
        testColor = this.customGradientColor1;
        break;
      case 'gradient2':
        testColor = this.customGradientColor2;
        break;
    }
    if (!this.isHex.test(testColor)) {
      this.colors[type] = this.basicColors[prop];
    }
  }

  public openFileLoader(type): void {
    if (type === 'fav') {
      this.favFileLoader = !this.favFileLoader;
    } else if (type === 'logo') {
      this.fileLoader = !this.fileLoader;
    } else if (type === 'emailLogo') {
      this.emailLogoFileLoader = !this.emailLogoFileLoader;
    }
  }

  // toggle for most checkboxes, immediately updates preSettings
  public onToggle(event, type, enumMap?, prop?, alternative?): void {
    const newSettings = this.getSettingsCopy();
    if (enumMap) {
      if (event.checked) {
        newSettings[type] = this[enumMap][prop];
      } else {
        newSettings[type] = this[enumMap][alternative];
      }
    } else {
      newSettings[type] = event.checked;
    }
    this.adminLookAndFeelService.updatePreSettings(newSettings);
  }

  // toggle for buttons and gradiesnts colors
  @action public onToggleColors(event, type, enumMap, prop, alternative): void {
    if (event.checked) {
      this.selectedColor[type] = this[enumMap][prop];
    } else {
      this.selectedColor[type] = this[enumMap][alternative];
    }
    this.selectedColor = { ...this.selectedColor };
  }

  // return object with actual selected colors and specific structure
  public getColors(): SelectedColors {
    return {
      buttonsColor: this.buttonsColorOne
        ? this.basicColors.purple
        : this.buttonsColorTwo
        ? this.basicColors.blue
        : this.colors.buttons || this.preSettings.buttonsColor,
      mileStoneGradientColor: {
        colorTop: this.gradientColorOne
          ? this.basicColors.gradient11
          : this.gradientColorTwo
          ? this.basicColors.gradient21
          : this.colors.gradient1 || this.preSettings.mileStoneGradientColor.colorTop,
        colorBottom: this.gradientColorOne
          ? this.basicColors.gradient12
          : this.gradientColorTwo
          ? this.basicColors.gradient22
          : this.colors.gradient2 || this.preSettings.mileStoneGradientColor.colorBottom,
      },
    };
  }

  // when open new favicon file
  public async onUpdateFavicon(data): Promise<void> {
    if (data.error) {
      const error = await lastValueFrom(this.translateService.get('ADMIN_DASHBOARD__LOOK_AND_FEEL__IMG_INFO_3'));

      return this.adminLookAndFeelService.updateFaviconServerError(error);
    }
    this.faviconFile = data.file;
    this.faviconImage = data.fileString;
    this.adminLookAndFeelService.updateFaviconServerError('');
  }

  // when open new favicon file
  public async onUpdateEmailLogo(data): Promise<void> {
    if (data.error) {
      const error = await lastValueFrom(this.translateService.get('ADMIN_DASHBOARD__LOOK_AND_FEEL__IMG_INFO_3'));

      return this.adminLookAndFeelService.updateEmailLogoServerError(error);
    }
    this.emailLogoFile = data.file;
    this.emailLogoImage = data.fileString;
    this.adminLookAndFeelService.updateEmailLogoServerError('');
  }

  // when open new logo file
  public async onUpdateLogo(data): Promise<void> {
    if (data.error) {
      const error = await lastValueFrom(this.translateService.get('ADMIN_DASHBOARD__LOOK_AND_FEEL__IMG_INFO_1'));

      return this.adminLookAndFeelService.updateLogoServerError(error);
    }
    this.logoFile = data.file;
    this.logoImage = data.fileString;
    this.adminLookAndFeelService.updateLogoServerError('');
  }

  public onSaveFavicon(): void {
    this.adminLookAndFeelService.setImage('favicon', this.faviconFile).subscribe();
  }

  public onSaveLogo(): void {
    this.adminLookAndFeelService.setImage('logo', this.logoFile).subscribe();
  }

  public onSaveEmailLogo(): void {
    this.adminLookAndFeelService.setImage('emailLogo', this.emailLogoFile).subscribe();
  }

  public onRemoveImage(type): void {
    if (type === 'logo') {
      this.logoFile = null;
      this.logoImage = '';
    } else if (type === 'favicon') {
      this.faviconFile = null;
      this.faviconImage = '';
    } else if (type === 'emailLogo') {
      this.emailLogoFile = null;
      this.emailLogoImage = '';
    }
  }

  // creates copy of settings object
  private getSettingsCopy(set: any = this.preSettings || {}, noImages = false): SettingsLookAndFeelModel {
    return {
      logo: noImages ? '' : set.logo,
      favicon: noImages ? '' : set.favicon,
      menuLayout: set.menuLayout,
      colorStyle: set.colorStyle,
      labelColorTon: set.labelColorTon,
      buttonsColor: set.buttonsColor,
      textColor: set.textColor,
      numberFormat: set.numberFormat,
      mileStoneGradientColor: {
        colorTop: set.mileStoneGradientColor && set.mileStoneGradientColor.colorTop,
        colorBottom: set.mileStoneGradientColor && set.mileStoneGradientColor.colorBottom,
      },
    };
  }

  @computed public get preSettings(): SettingsLookAndFeelModel {
    return this.adminLookAndFeelService.preSettings;
  }

  @computed public get layoutPositionLeft(): boolean {
    return this.preSettings.menuLayout === LookAndFeelMenuLayout.left;
  }

  @computed public get layoutPositionTop(): boolean {
    return this.preSettings.menuLayout === LookAndFeelMenuLayout.top;
  }

  @computed public get layoutPositionRight(): boolean {
    return this.preSettings.menuLayout === LookAndFeelMenuLayout.right;
  }

  @computed public get buttonsColorOne(): boolean {
    return this.selectedColor.buttons
      ? this.selectedColor.buttons === ButtonColorEnum.one
      : this.preSettings.buttonsColor === this.basicColors.purple;
  }

  @computed public get buttonsColorTwo(): boolean {
    return this.selectedColor.buttons
      ? this.selectedColor.buttons === ButtonColorEnum.two
      : this.preSettings.buttonsColor === this.basicColors.blue;
  }

  @computed public get buttonsColorCustom(): boolean {
    return !this.buttonsColorOne && !this.buttonsColorTwo;
  }

  @computed public get gradientColorOne(): boolean {
    const obj: any = this.preSettings.mileStoneGradientColor || {};

    return this.selectedColor.gradient
      ? this.selectedColor.gradient === GradientColorEnum.one
      : obj.colorTop === this.basicColors.gradient11 && obj.colorBottom === this.basicColors.gradient12;
  }

  @computed public get gradientColorTwo(): boolean {
    const obj = (this.preSettings.mileStoneGradientColor || {}) as LookAndFeelLabelsMileStoneColor;

    return this.selectedColor.gradient
      ? this.selectedColor.gradient === GradientColorEnum.two
      : obj.colorTop === this.basicColors.gradient21 && obj.colorBottom === this.basicColors.gradient22;
  }

  @computed public get gradientColorCustom(): boolean {
    return !this.gradientColorOne && !this.gradientColorTwo;
  }

  @computed public get layoutThemeLight(): boolean {
    return this.preSettings.colorStyle === LookAndFeelColorStyle.light;
  }

  @computed public get layoutThemeDark(): boolean {
    return this.preSettings.colorStyle === LookAndFeelColorStyle.dark;
  }

  @computed public get tonColorNormal(): boolean {
    return this.preSettings.labelColorTon === LookAndFeelLabelsColorTon.normal;
  }

  @computed public get tonColorLight(): boolean {
    return this.preSettings.labelColorTon === LookAndFeelLabelsColorTon.light;
  }

  @computed public get tonColorDark(): boolean {
    return this.preSettings.labelColorTon === LookAndFeelLabelsColorTon.dark;
  }

  @computed public get textColorLight(): boolean {
    return this.preSettings.textColor === LookAndFeelTextColor.light;
  }

  @computed public get textColorDark(): boolean {
    return this.preSettings.textColor === LookAndFeelTextColor.dark;
  }

  @computed public get customButtonsColor(): string {
    return this.colors.buttons || this.preSettings.buttonsColor;
  }

  @computed public get customGradientColor1(): string {
    return this.colors.gradient1 || this.preSettings.mileStoneGradientColor.colorTop;
  }

  @computed public get customGradientColor2(): string {
    return this.colors.gradient2 || this.preSettings.mileStoneGradientColor.colorBottom;
  }

  @computed public get showSaveButton(): boolean {
    if (this.adminLookAndFeelService.lookAndFeelSettings) {
      const set1 = this.adminLookAndFeelService.lookAndFeelSettings.settings;
      const set2 = { ...this.preSettings, ...this.getColors() };

      return JSON.stringify(this.getSettingsCopy(set1, true)) !== JSON.stringify(this.getSettingsCopy(set2, true));
    }

    return false;
  }

  @computed public get currentLogoImage(): string {
    this.logoImage = '';

    return this.adminLookAndFeelService.logo;
  }

  @computed public get currentFaviconImage(): string {
    this.faviconImage = '';

    return this.adminLookAndFeelService.favicon;
  }

  @computed public get currentEmailLogoImage(): string {
    this.emailLogoImage = '';

    return this.adminLookAndFeelService.emailLogo;
  }

  @computed public get logoServerError(): string {
    return this.adminLookAndFeelService.logoServerError;
  }

  @computed public get faviconServerError(): string {
    return this.adminLookAndFeelService.faviconServerError;
  }

  @computed public get emailLogoServerError(): string {
    return this.adminLookAndFeelService.emailLogoServerError;
  }
}
