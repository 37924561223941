import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { computed, observable } from 'mobx-angular';
import { Subject } from 'rxjs';

import { ContractStorageService } from '@app/integrations/contracts/contract-storage.service';
import { WEB3 } from '@app/integrations/dictionaries/web3.dictionary';
import { MetaMaskService } from '@app/integrations/services/meta-mask/meta-mask.service';
import { Web3Service } from '@app/integrations/services/web3/web3.service';

import { SettingsService } from './settings.service';

import { isProviderExists } from '../../../helpers';
import { NavigationService, SidebarNavigationRoutesPath } from '../../navigation';
import { NetworkModel } from '../models';

declare let Web3: any;

@Injectable()
export class NetworkService {
  private currentRouteUrl: string;
  @observable currentProvider;
  @observable currentNetwork: NetworkModel;
  public currentNetworkChange = new Subject<NetworkModel>();

  constructor(
    private web3Service: Web3Service,
    private settingsService: SettingsService,
    private metaMaskService: MetaMaskService,
    private navigationService: NavigationService,
    private router: Router,
    private zone: NgZone,
    private dialog: MatDialog,
    private contractStorageService: ContractStorageService,
  ) {
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.currentRouteUrl = route.url;
      }
    });

    this.currentNetworkChange.subscribe((network: NetworkModel) => {
      this.currentNetwork = network;
      if (this.currentNetwork) {
        this.checkEnableRouteUrlByNetwork();
        this.contractStorageService.updatedContractData(this.currentNetwork.networkId);
      }
    });
  }

  public async init(): Promise<void> {
    if (this.isEnabledNetworks) {
      await this.initProvider();
      await this.initNetwork();
      this.checkNetworksChanges();
      if (!isProviderExists()) {
        this.setDefaultRPCProvider();
      }
    }
  }

  public async initProvider(): Promise<void> {
    return this.web3Service
      .initProvider(this.settingsService.walletsConfig.settings.infuraProjectId)
      .then((provider) => this.setProvider(provider));
  }

  public async initNetwork(): Promise<void> {
    return this.web3Service
      .getNetworkId()
      .then((networkId) => {
        const networkFromProvider = this.settingsService.allowedNetworks.find(
          (network) => network.networkId === networkId,
        );
        if (networkFromProvider) {
          this.currentNetworkChange.next(networkFromProvider);
        } else {
          this.currentNetworkChange.next(this.defaultNetwork);
        }
      })
      .catch(() => {
        this.currentNetworkChange.next(this.defaultNetwork);
      });
  }

  public setProvider(provider): void {
    if (provider) {
      this.currentProvider = provider;
      window[WEB3] = new Web3(this.currentProvider);
    } else if (this.web3Service.isWalletConnectObject) {
      this.currentProvider = this.web3Service.isWalletConnectObject;
      window[WEB3] = new Web3(this.currentProvider);
    }
  }

  public setDefaultRPCProvider(): void {
    this.currentProvider = new Web3.providers.HttpProvider(this.currentNetwork.provider.url);
    window[WEB3] = new Web3(this.currentProvider);
  }

  public switchNetworkByParams(network: NetworkModel): void {
    if (network === this.currentNetwork) {
      return;
    }
    if (this.isMetaMastCurrentProvider) {
      this.metaMaskService.switchNetworkByParams(network.networkId);
    } else if (this.isWalletConnectCurrentProvider) {
      this.currentNetworkChange.next(network);
    } else {
      this.currentNetworkChange.next(network);
      this.setProvider(network.provider.url);
    }
  }

  public checkEnableRouteUrlByNetwork(): void {
    this.settingsService.getSideBarConfig().subscribe((res) => {
      const enabledUrl =
        res.settings.enabled &&
        res.settings?.navigation.filter((item) => item.path === this.currentRouteUrl).length > 0;
      if (this.currentRouteUrl && SidebarNavigationRoutesPath.includes(this.currentRouteUrl) && !enabledUrl) {
        this.navigationService.navigateToDefaultTenantRoute();
      }
    });
  }

  public checkNetworksChanges(): void {
    if (this.isMetaMastCurrentProvider || this.isWalletConnectCurrentProvider) {
      // Subscribe to chainId change
      this.currentProvider.on('chainChanged', async () => {
        await this.zone.run(async () => {
          this.dialog.closeAll();
          await this.initNetwork();
        });
      });
    }
  }

  public get isTokenSymbolByISO(): boolean {
    const networkArray = [17171, 1414, 5438];

    return networkArray.includes(this.currentNetwork.networkId);
  }

  @computed public get defaultNetwork(): NetworkModel {
    return this.settingsService.allowedNetworks.find((network) => network.isDefault);
  }

  @computed public get isMetaMastCurrentProvider(): boolean {
    return this.currentProvider && this.currentProvider['isMetaMask'];
  }

  @computed public get isWalletConnectCurrentProvider(): boolean {
    return this.currentProvider && this.currentProvider['infuraId'];
  }

  @computed public get isEnabledNetworks(): boolean {
    return this.settingsService.networksConfig?.settings.enabled;
  }
}
