import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getUserMockData } from '@mocks/user/user.mock-data';
import { Observable, of } from 'rxjs';

import { ApiUrlsDictionary } from '@tokengear-common/modules/api';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      environment.mock &&
      environment.interceptors.UserInterceptor &&
      request.url.endsWith(ApiUrlsDictionary.users('121')) &&
      request.method === 'GET' &&
      request.params.get('id') != null
    ) {
      const response = new HttpResponse({
        status: 200,
        body: getUserMockData(),
      });

      return of(response);
    }

    return next.handle(request);
  }
}
