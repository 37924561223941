import { observable } from 'mobx-angular';

export class SettingsAnotherAddressModel {
  @observable enabled: boolean;
  @observable anotherAddresses: object;

  constructor(data: SettingsAnotherAddressModel) {
    Object.assign(this, data);
  }
}
