import { observable } from 'mobx-angular';

export class StakingPoolsStatsModel {
  @observable totalStaked: string;
  @observable weightedAPY: string;
  userStakedIn: string[];

  constructor(stats: StakingPoolsStatsModel) {
    Object.assign(this, stats);
  }
}
