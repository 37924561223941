import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NavigationService } from '../../navigation';
import { errorMessages } from '../dictionaries/error-messages.dictionary';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
  constructor(private navigationService: NavigationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const status = error.status;
        if (status === 500 && ![errorMessages.USER_NOT_FOUND, errorMessages.AUTH_FAILED].includes(error.message)) {
          this.navigationService.navigateToError500().then();
        }
        if (status === 404) {
          this.navigationService.navigateToError404().then();
        }

        return throwError(error);
      }),
    );
  }
}
