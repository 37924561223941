export function getEmailsMockData() {
  return {
    data: [
      {
        _id: '5b7eb00462d06b6d2ed6fa56',
        tenantId: '9a7e7b7f-fd2f-45bd-b671-67095779197d',
        id: 'registerTenant',
        defaultLocale: 'en',
        subject: {
          en: 'RegisterTenant2',
        },
        htmlBody: {
          en: '{{email}},{{token}} 2',
        },
        textBody: {
          en: '{{email}},{{token}}',
        },
      },
      {
        _id: '5b7eb00462d06b1731d6fa57',
        tenantId: '9a7e7b7f-fd2f-45bd-b671-67095779197d',
        id: '2fa-request',
        defaultLocale: 'en',
        subject: {
          en: '2fa email request',
        },
        htmlBody: {
          en: '{{payload.secret}}',
        },
        textBody: {
          en: '{{payload.secret}}',
        },
      },
    ],
    total: 2,
  };
}
