import { observable } from 'mobx-angular';

export class TwoFactoryAuthModel {
  @observable secret: string;
  @observable otpauth: string;

  constructor(value: TwoFactoryAuthModel) {
    Object.assign(this, value);
  }
}
