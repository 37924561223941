import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmationDialogComponent } from '@tokengear-common/components';

@Injectable({
  providedIn: 'any',
})
export class ConfirmationDialogService {
  constructor(private modalService: MatDialog) {}

  public async confirm(
    title: string = 'Confirm',
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: string = '580px',
  ): Promise<boolean> {
    const dialogRef = this.modalService.open(ConfirmationDialogComponent, {
      width: dialogSize,
      panelClass: 'modal-user-block',
      autoFocus: false,
      data: {
        title,
        message,
        btnOkText,
        btnCancelText,
      },
    });

    return new Promise((resolve) => {
      dialogRef.afterClosed().subscribe((result: boolean) => {
        resolve(result);
      });
    });
  }
}
