import { observable } from 'mobx-angular';

export class CountryModel {
  @observable code: string;
  @observable name: string;

  constructor(model: CountryModel) {
    Object.assign(this, model);
  }
}
