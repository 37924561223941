<div appThemeClass>
  <div class="connect-wallet-header">
    <div class="statuses card_over_size">
      <div class="gradient_card card_over_size">
        <div class="no-wallet">
          <h2>
            {{
              'USER_CONNECT_WALLET__HEAD_TITLE'
                | translate: { text: isAuthCryptoWallet ? 'Connect your wallet' : 'Sign in', pageName: titleText }
            }}
          </h2>
          <div class="button_wrapper">
            <button class="wallet-connect-btn" *ngIf="isAuthCryptoWallet" (click)="connectToWallet()" mat-button>
              <img
                src="../../../../assets/img/icons/green_wallet-icon.svg"
                alt="wallet"
                class="wallet-connect-btn__icon"
              />
              <span class="wallet-connect-btn__text">
                {{ 'USER_HEADER__CONNECT_TO_WALLET' | translate }}
              </span>
            </button>
            <button class="sign-in-btn" *ngIf="isAuth1FA" (click)="navigateToSignIn()" mat-button>
              <span class="sign-in-btn__text">
                {{ 'USER_HEADER__SIGN_IN' | translate }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
