import { observable } from 'mobx-angular';

import { KycStatusModel } from '@domain-modules/kyc/models/kyc-status/kyc-status.model';

export enum EKycAdditinalPageType {
  QUIZ = 'quiz',
  SOCIAL = 'social',
}

export interface IKycadditionalPage {
  order: number;
  type: EKycAdditinalPageType;
  details: string;
  items: any[];
}

export class KycSettingsModel {
  @observable enabled: boolean;
  @observable isKycFeeEnabled: boolean;
  @observable enabledPageAuthOnly: boolean;
  @observable kycFeeCurrency: string;
  @observable kycFeeValue: number;
  @observable showRedirectBtn: boolean;
  @observable hasAdditionalPages: boolean;
  @observable verifyUserAddress: boolean;
  @observable additionalPages: IKycadditionalPage[];
  @observable titles: {
    blocked: KycStatusModel;
    failed: KycStatusModel;
    in_progress: KycStatusModel;
    manual_checking: KycStatusModel;
    not_verified: KycStatusModel;
    pending: KycStatusModel;
    verified: KycStatusModel;
  };

  constructor(data: KycSettingsModel) {
    Object.assign(this, data);
  }
}
