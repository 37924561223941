<mat-card>
  <mat-card-header>
    {{ 'USER_DASHBOARD__PROFILE__HEADER' | translate }}
  </mat-card-header>
  <div class="block-line"></div>
  <mat-card-content>
    <div class="card-content">
      <div class="card-content__wrapper">
        <div class="card-content__icon-wrapper">
          <i class="icon-user"></i>
        </div>
        <div class="card-content__user-info">
          <p class="user-info__name">
            {{
              user?.params?.fullName ||
                (user?.params?.firstName == null ? '' : user?.params?.firstName) +
                  ' ' +
                  (user?.params?.lastName == null ? '' : user?.params?.lastName)
            }}
          </p>
          <p class="user-info__id">{{ user?.customUserId || '--' }}</p>
          <button disabled mat-flat-button class="user-info__upload profile-button theme-reverse btn_xs">
            {{ 'USER_DASHBOARD__PROFILE__UPLOAD_PROFILE_PICTURE' | translate }}
          </button>
        </div>
      </div>
      <div class="block-line"></div>
      <div class="card-content__footer">
        <button disabled class="user-info__edit-location profile-button theme-reverse btn_xs" mat-flat-button>
          {{ 'USER_DASHBOARD__PROFILE__EDIT_LOCATION' | translate }}
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
