<ng-container *mobxAutorun>
  <div appThemeClass>
    <div
      class="kyc-status-box"
      (click)="navigateToUserKyc()"
      [matTooltip]="kycStatusDescription"
      matTooltipClass="custom-theme-tooltip"
      [ngClass]="{
        'kyc-status-box_verify': !kycFailed,
        'kyc-status-box_failed': kycFailed
      }"
    >
      <img src="assets/img/icons/people-icon.svg" />
      <div *ngIf="kycNotVerified">Verify</div>
      <div *ngIf="kycInProgress">Verifying...</div>
      <div *ngIf="kycVerified">Verified</div>
      <div *ngIf="kycFailed">Failed</div>
    </div>
  </div>
</ng-container>
