import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ThemeClassModule } from '@shared/directives/theme/theme-class.module';

import { FormErrorHandlerDirective } from './form-error-handler.directive';
import { FormValidationErrorMessageComponent } from './form-validation-error-message/form-validation-error-message.component';

@NgModule({
  declarations: [FormErrorHandlerDirective, FormValidationErrorMessageComponent],
  imports: [CommonModule, ThemeClassModule, TranslateModule],
  exports: [FormErrorHandlerDirective, FormValidationErrorMessageComponent],
})
export class FormErrorHandlerModule {}
