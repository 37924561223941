import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { computed } from 'mobx-angular';

import { SettingsService } from '@tokengear-common/modules/settings';

import { DialogResult } from '@pages/user/dictionaries/dialog-result';

@Component({
  selector: 'app-modal-connect-wallet',
  templateUrl: './modal-connect-wallet.component.html',
  styleUrls: ['./modal-connect-wallet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConnectWalletComponent {
  public DialogResult = DialogResult;

  constructor(private settingsService: SettingsService, public dialogRef: MatDialogRef<ModalConnectWalletComponent>) {}

  @computed get isMultiNetwork(): boolean {
    return this.settingsService.isMultiNetwork;
  }
}
