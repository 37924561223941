import { InvoicesFilterModel } from '@domain-modules/invoices/models/invoices-filter/invoices-filter.model';

export function getInvoicesMockData(filter: InvoicesFilterModel) {
  const mock = {
    total: 100,
    data: [
      {
        tokens: '1109999.9',
        id: '591d7bd1-a120-11e8-b8f7-a731e9ac72b3',
        key: '591d7bd0-a120-11e8-b8f7-a731e9ac72b3',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'Tanya',
          lastName: 'Chapaliuk',
          middleName: 'frfrgtr',
          birthday: '2000-10-10',
          phone: '4444444444',
          gender: 'Male',
          country: 'aw',
          zipCode: '444545',
          city: 'fvtrgtg',
          state: 'gtrgtyhgtybh',
          street: 'tgtyhtyhy, 88, 55',
          apartment: '55',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '111',
        currency: 'USD',
        receivedAmount: '100',
        receivedTokens: '200',
        status: 'PENDING',
        date: '2018-08-16T06:48:14.093Z',
      },
      {
        tokens: '11109999.9',
        id: '841ed881-a097-11e8-b8f7-a731e9ac72b3',
        key: '841ed880-a097-11e8-b8f7-a731e9ac72b3',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'Tanya',
          lastName: 'Chapaliuk',
          middleName: 'frfrgtr',
          birthday: '2000-10-10',
          phone: '4444444444',
          gender: 'Male',
          country: 'aw',
          zipCode: '444545',
          city: 'fvtrgtg',
          state: 'gtrgtyhgtybh',
          street: 'tgtyhtyhy, 88, 55',
          apartment: '55',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '1111',
        currency: 'USD',
        receivedAmount: '',
        receivedTokens: '',
        status: 'PENDING',
        date: '2018-08-15T14:28:45.192Z',
      },
      {
        tokens: '11109999.9',
        id: '6dc56901-a097-11e8-b8f7-a731e9ac72b3',
        key: '6dc56900-a097-11e8-b8f7-a731e9ac72b3',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'Tanya',
          lastName: 'Chapaliuk',
          middleName: 'frfrgtr',
          birthday: '2000-10-10',
          phone: '4444444444',
          gender: 'Male',
          country: 'aw',
          zipCode: '444545',
          city: 'fvtrgtg',
          state: 'gtrgtyhgtybh',
          street: 'tgtyhtyhy, 88, 55',
          apartment: '55',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '1111',
        currency: 'USD',
        receivedAmount: '',
        receivedTokens: '',
        status: 'PENDING',
        date: '2018-08-15T14:28:07.696Z',
      },
      {
        tokens: '1000000',
        id: 'bb2a72b1-a071-11e8-b8f7-a731e9ac72b3',
        key: 'bb2a72b0-a071-11e8-b8f7-a731e9ac72b3',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'Tanya',
          lastName: 'Chapaliuk',
          middleName: 'frfrgtr',
          birthday: '2000-10-10',
          phone: '4444444444',
          gender: 'Male',
          country: 'aw',
          zipCode: '444545',
          city: 'fvtrgtg',
          state: 'gtrgtyhgtybh',
          street: 'tgtyhtyhy, 88, 55',
          apartment: '55',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '100',
        currency: 'USD',
        receivedAmount: '',
        receivedTokens: '',
        status: 'PENDING',
        date: '2018-08-15T09:58:16.667Z',
      },
      {
        tokens: '1000000',
        id: '0b217011-9ba5-11e8-b8f7-a731e9ac72b3',
        key: '0b217010-9ba5-11e8-b8f7-a731e9ac72b3',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'Tanya',
          lastName: 'Chapaliuk',
          middleName: 'frfrgtr',
          birthday: '2000-10-10',
          phone: '4444444444',
          gender: 'Male',
          country: 'aw',
          zipCode: '444545',
          city: 'fvtrgtg',
          state: 'gtrgtyhgtybh',
          street: 'tgtyhtyhy, 88, 55',
          apartment: '55',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '100',
        currency: 'USD',
        receivedAmount: '',
        receivedTokens: '',
        status: 'PENDING',
        date: '2018-08-09T07:22:59.345Z',
      },
      {
        tokens: '1119999.9',
        id: 'a58bbb20-9ba4-11e8-b8f7-a731e9ac72b3',
        key: 'a58b9410-9ba4-11e8-b8f7-a731e9ac72b3',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'Tanya',
          lastName: 'Chapaliuk',
          middleName: 'frfrgtr',
          birthday: '2000-10-10',
          phone: '4444444444',
          gender: 'Male',
          country: 'aw',
          zipCode: '444545',
          city: 'fvtrgtg',
          state: 'gtrgtyhgtybh',
          street: 'tgtyhtyhy, 88, 55',
          apartment: '55',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '112',
        currency: 'USD',
        receivedAmount: '',
        receivedTokens: '',
        status: 'PENDING',
        date: '2018-08-09T07:20:08.913Z',
      },
      {
        tokens: '1109999.9',
        id: '2d740481-9ba4-11e8-b8f7-a731e9ac72b3',
        key: '2d740480-9ba4-11e8-b8f7-a731e9ac72b3',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'some',
          lastName: 'Podkoritov',
          middleName: 'test',
          birthday: '1993-01-22',
          phone: '+14155552671',
          gender: 'Male',
          country: 'aw',
          zipCode: '4455',
          city: 'City',
          state: 'Kabul',
          street: 'Fddd.,2',
          apartment: '222ffffd',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '111',
        currency: 'USD',
        receivedAmount: '',
        receivedTokens: '',
        status: 'PENDING',
        date: '2018-08-09T07:16:47.432Z',
      },
      {
        tokens: '1000000',
        id: 'd22f4350-94be-11e8-9636-e1a2cc899186',
        key: 'd22f1c40-94be-11e8-9636-e1a2cc899186',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'Tanya',
          lastName: 'Chapaliuk',
          middleName: 'frfrgtr',
          birthday: '2000-10-10',
          phone: '4444444444',
          gender: 'Male',
          country: 'aw',
          zipCode: '444545',
          city: 'fvtrgtg',
          state: 'gtrgtyhgtybh',
          street: 'tgtyhtyhy, 88, 55',
          apartment: '55',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '100',
        currency: 'USD',
        receivedAmount: '111',
        receivedTokens: '1109999.9',
        status: 'ACCEPTED',
        date: '2018-07-31T12:39:52.580Z',
      },
      {
        tokens: '1000000',
        id: '5123e0d0-9415-11e8-89e9-fb90d1e4a719',
        key: '5123b9c0-9415-11e8-89e9-fb90d1e4a719',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'Tanya',
          lastName: 'Chapaliuk',
          middleName: 'frfrgtr',
          birthday: '2000-10-10',
          phone: '4444444444',
          gender: 'Male',
          country: 'aw',
          zipCode: '444545',
          city: 'fvtrgtg',
          state: 'gtrgtyhgtybh',
          street: 'tgtyhtyhy, 88, 55',
          apartment: '55',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '100',
        currency: 'USD',
        receivedAmount: '',
        receivedTokens: '',
        status: 'ACCEPTED',
        date: '2018-07-30T16:26:31.132Z',
      },
      {
        tokens: '1000000',
        id: 'e8763d81-9414-11e8-89e9-fb90d1e4a719',
        key: 'e8763d80-9414-11e8-89e9-fb90d1e4a719',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'Tanya',
          lastName: 'Chapaliuk',
          middleName: 'frfrgtr',
          birthday: '2000-10-10',
          phone: '4444444444',
          gender: 'Male',
          country: 'aw',
          zipCode: '444545',
          city: 'fvtrgtg',
          state: 'gtrgtyhgtybh',
          street: 'tgtyhtyhy, 88, 55',
          apartment: '55',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '100',
        currency: 'USD',
        receivedAmount: '',
        receivedTokens: '',
        status: 'ACCEPTED',
        date: '2018-07-30T16:23:35.512Z',
      },
      {
        tokens: '1009999.9',
        id: '0097d191-940f-11e8-89e9-fb90d1e4a719',
        key: '0097d190-940f-11e8-89e9-fb90d1e4a719',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'Tanya',
          lastName: 'Chapaliuk',
          middleName: 'frfrgtr',
          birthday: '2000-10-10',
          phone: '4444444444',
          gender: 'Male',
          country: 'aw',
          zipCode: '444545',
          city: 'fvtrgtg',
          state: 'gtrgtyhgtybh',
          street: 'tgtyhtyhy, 88, 55',
          apartment: '55',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '101',
        currency: 'USD',
        receivedAmount: '',
        receivedTokens: '',
        status: 'ACCEPTED',
        date: '2018-07-30T15:41:19.017Z',
      },
      {
        tokens: '1000000',
        id: 'fd39fd61-940a-11e8-99c7-13bd02d2c601',
        key: 'fd39fd60-940a-11e8-99c7-13bd02d2c601',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'Tanya',
          lastName: 'Chapaliuk',
          middleName: 'frfrgtr',
          birthday: '2000-10-10',
          phone: '4444444444',
          gender: 'Male',
          country: 'aw',
          zipCode: '444545',
          city: 'fvtrgtg',
          state: 'gtrgtyhgtybh',
          street: 'tgtyhtyhy, 88, 55',
          apartment: '55',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '100',
        currency: 'USD',
        receivedAmount: '',
        receivedTokens: '',
        status: 'ACCEPTED',
        date: '2018-07-30T15:12:35.382Z',
      },
      {
        tokens: '1000000',
        id: '430f4c61-940a-11e8-99c7-13bd02d2c601',
        key: '430f4c60-940a-11e8-99c7-13bd02d2c601',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'Tanya',
          lastName: 'Chapaliuk',
          middleName: 'frfrgtr',
          birthday: '2000-10-10',
          phone: '4444444444',
          gender: 'Male',
          country: 'aw',
          zipCode: '444545',
          city: 'fvtrgtg',
          state: 'gtrgtyhgtybh',
          street: 'tgtyhtyhy, 88, 55',
          apartment: '55',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '100',
        currency: 'USD',
        receivedAmount: '',
        receivedTokens: '',
        status: 'ACCEPTED',
        date: '2018-07-30T15:07:23.046Z',
      },
      {
        tokens: '1000000',
        id: '88cb0041-9406-11e8-99c7-13bd02d2c601',
        key: '88cb0040-9406-11e8-99c7-13bd02d2c601',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'Tanya',
          lastName: 'Chapaliuk',
          middleName: 'frfrgtr',
          birthday: '2000-10-10',
          phone: '4444444444',
          gender: 'Male',
          country: 'aw',
          zipCode: '444545',
          city: 'fvtrgtg',
          state: 'gtrgtyhgtybh',
          street: 'tgtyhtyhy, 88, 55',
          apartment: '55',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '100',
        currency: 'USD',
        receivedAmount: '',
        receivedTokens: '',
        status: 'ACCEPTED',
        date: '2018-07-30T14:40:42.052Z',
      },
      {
        tokens: '1000000',
        id: '09621e61-9406-11e8-99c7-13bd02d2c601',
        key: '09621e60-9406-11e8-99c7-13bd02d2c601',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'Tanya',
          lastName: 'Chapaliuk',
          middleName: 'frfrgtr',
          birthday: '2000-10-10',
          phone: '4444444444',
          gender: 'Male',
          country: 'aw',
          zipCode: '444545',
          city: 'fvtrgtg',
          state: 'gtrgtyhgtybh',
          street: 'tgtyhtyhy, 88, 55',
          apartment: '55',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '100',
        currency: 'USD',
        receivedAmount: '',
        receivedTokens: '',
        status: 'ACCEPTED',
        date: '2018-07-30T14:37:08.294Z',
      },
      {
        tokens: '1019999.9',
        id: 'd0904721-93ff-11e8-99c7-13bd02d2c601',
        key: 'd0904720-93ff-11e8-99c7-13bd02d2c601',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'Tanya',
          lastName: 'Chapaliuk',
          middleName: 'frfrgtr',
          birthday: '2000-10-10',
          phone: '4444444444',
          gender: 'Male',
          country: 'aw',
          zipCode: '444545',
          city: 'fvtrgtg',
          state: 'gtrgtyhgtybh',
          street: 'tgtyhtyhy, 88, 55',
          apartment: '55',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '100',
        currency: 'USD',
        receivedAmount: '',
        receivedTokens: '',
        status: 'ACCEPTED',
        date: '2018-07-30T13:52:35.986Z',
      },
      {
        tokens: '1009999.9',
        id: 'e0353d61-93fb-11e8-99c7-13bd02d2c601',
        key: 'e0353d60-93fb-11e8-99c7-13bd02d2c601',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'Tanya',
          lastName: 'Chapaliuk',
          middleName: 'frfrgtr',
          birthday: '2000-10-10',
          phone: '4444444444',
          gender: 'Male',
          country: 'aw',
          zipCode: '444545',
          city: 'fvtrgtg',
          state: 'gtrgtyhgtybh',
          street: 'tgtyhtyhy, 88, 55',
          apartment: '55',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '100',
        currency: 'USD',
        receivedAmount: '',
        receivedTokens: '',
        status: 'ACCEPTED',
        date: '2018-07-30T13:24:24.246Z',
      },
      {
        tokens: '1000000',
        id: '2c63f181-93f8-11e8-99c7-13bd02d2c601',
        key: '2c63f180-93f8-11e8-99c7-13bd02d2c601',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        userParams: {
          firstName: 'Tanya',
          lastName: 'Chapaliuk',
          middleName: 'frfrgtr',
          birthday: '2000-10-10',
          phone: '4444444444',
          gender: 'Male',
          country: 'aw',
          zipCode: '444545',
          city: 'fvtrgtg',
          state: 'gtrgtyhgtybh',
          street: 'tgtyhtyhy, 88, 55',
          apartment: '55',
          agreeField: true,
          email: 'john.doe@gmail.com',
          cryptoAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
        },
        comment: '',
        txHash: '3478765421',
        amount: '100',
        currency: 'USD',
        receivedAmount: '',
        receivedTokens: '',
        status: 'DECLINED',
        date: '2018-07-30T12:57:54.072Z',
      },
    ],
  };

  if (filter.statusFilter != null) {
    const data = mock.data.filter((item) => {
      const result = filter.statusFilter.includes(item.status);

      return result;
    });
    return {
      total: data.length,
      data: data.length ? data : null,
    };
  }

  if (filter.search != null && filter.search !== '') {
    const data = mock.data.filter((item) => {
      const userParams = item.userParams;
      const fullName = `${userParams.firstName} ${userParams.lastName} ${userParams.middleName}`;
      const result = fullName.toLowerCase().includes(filter.search.toLowerCase());

      return result;
    });
    return {
      total: data.length,
      data: data.length ? data : null,
    };
  }

  return mock;
}
