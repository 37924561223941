import { LayoutModule } from '@angular/cdk/layout';
import { APP_BASE_HREF, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, Provider } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { MocksModule } from '@mocks/mocks.module';
import { GalleryModule } from '@ngx-gallery/core';
import { GallerizeModule } from '@ngx-gallery/gallerize';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { MobxAngularModule } from 'mobx-angular';
import { from, switchMap } from 'rxjs';

import { APIModule } from '@tokengear-common/modules/api';
import { AuthModule } from '@tokengear-common/modules/auth';
import { CurrenciesService } from '@tokengear-common/modules/domain/feature/currencies';
import { NavigationModule } from '@tokengear-common/modules/navigation';
import { NetworkService, SettingsModule, SettingsService } from '@tokengear-common/modules/settings';
import { AdminLookAndFeelStore, AdminLookAndFeelService } from '@tokengear-common/pages/admin-look-and-feel';
import { CoreServicesModule } from '@tokengear-common/services';

import { CustomNumberPipe } from '@shared/pipes/customNumber';
import { SharedModule } from '@shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function settingsServiceFactory(settingsService: SettingsService, networkService: NetworkService) {
  return () => settingsService.getAllSettings().pipe(switchMap(() => from(networkService.init())));
}
const APP_INIT_PROVIDES: Provider[] = [
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [SettingsService, NetworkService],
    useFactory: settingsServiceFactory,
  },
];

const providers: Provider[] = [
  ...APP_INIT_PROVIDES,
  {
    provide: APP_BASE_HREF,
    useValue: '',
  },
  AdminLookAndFeelService,
  AdminLookAndFeelStore,
  DatePipe,
  DecimalPipe,
  CustomNumberPipe,
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  CurrenciesService,
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    MobxAngularModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),

    // Aggregated modules might be configured with static functions later
    CoreServicesModule,
    NavigationModule,
    SettingsModule,
    AppRoutingModule,
    APIModule,
    AuthModule,

    MocksModule,
    LayoutModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    SharedModule,
    GalleryModule,
    LightboxModule,
    GallerizeModule,
  ],
  providers,
  bootstrap: [AppComponent],
})
export class AppModule {}
