export enum ContributionStatus {
  Processing = 'PROCESSING',
  Processed = 'PROCESSED',
  CancelProcessing = 'CANCEL_PROCESSING',
  CancelProcessed = 'CANCEL_PROCESSED',

  // custom status
  Rescind = 'RESCIND',
  Failed = 'FAILED',
  CanBeRescinded = 'CAN_BE_RESCINDED',
}
