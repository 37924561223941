import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable } from 'mobx-angular';
import { lastValueFrom } from 'rxjs';

import { SettingsService } from '@tokengear-common/modules/settings';

import { AbiStorage, ContractStorage } from '@app/integrations/contracts/contract-storage';
import { LIST_ABI, LIST_CONTRACTS } from '@app/integrations/contracts/contracts-list';

@Injectable({
  providedIn: 'root',
})
export class ContractStorageService {
  private contracts = LIST_CONTRACTS;
  private abiList = LIST_ABI;

  @observable isLoadedContractData: boolean;

  constructor(private settingsService: SettingsService, private http: HttpClient) {}

  private getAbi(abiUrl: string) {
    return this.http.get(abiUrl);
  }

  public updatedContractData(networkId: number) {
    if (this.settingsService.contractsConfig.settings.enabled) {
      this.updateContractStorage(networkId).then(() => {
        this.updateAbiStorage().then(() => {
          this.isLoadedContractData = true;
        });
      });
    }
  }

  public async updateContractStorage(networkId: number) {
    const contracts = this.settingsService.contractsConfig.settings.contracts;
    for (const [key, value] of Object.entries(contracts)) {
      const contractByNetworkId = (value as any).find((item) => item.networkId === networkId);
      if (this.contracts[key]) {
        this.contracts[key].address = contractByNetworkId ? contractByNetworkId['address'] : null;
        if (contractByNetworkId && contractByNetworkId['url']) {
          const abi$ = this.getAbi(
            `${this.settingsService.contractsConfig.settings.baseAbiUrl}${contractByNetworkId['url']}`,
          );
          this.contracts[key].abiJson = await lastValueFrom(abi$);
        }
      }
    }
  }

  public async updateAbiStorage() {
    const abiList = this.settingsService.contractsConfig.settings.sharedAbi;
    for (const [key, value] of Object.entries(abiList)) {
      const abi$ = this.getAbi(`${this.settingsService.contractsConfig.settings.baseAbiUrl}${value}`);
      this.abiList[key] = <any>await lastValueFrom(abi$);
    }
  }

  public get contractStorage(): ContractStorage {
    return this.contracts;
  }
  public get abiStorage(): AbiStorage {
    return this.abiList;
  }
}
