export function putUserStatusMockData(status: string) {
  return {
    email: 'test@gmail.com',
    id: '121',
    isEmailConfirmed: true,
    params: {
      apartment: 'test',
      birthday: '01.01.1989',
      city: 'test',
      country: 'test',
      cryptoAddress: '3454354354545',
      firstName: 'test',
      gender: 'test',
      lastName: 'test',
      middleName: 'test',
      phone: '+6456546546',
      state: 'test',
      street: 'test',
      zipCode: '4534545',
    },
    tenant: {
      address: 'test',
      balance: {
        USD: '1000',
        ETH: '100000',
        LTC: '43443',
      },
      bonusReceivedStatus: true,
      kycTxId: '4535435',
      privateSaleApproved: true,
      proofOfIncomeStatus: status,
      referralClicksCount: 0,
      referralId: '4545',
      referralToken: '5453',
      role: 'Admin',
      status,
      statusDescription: 'test',
      tenantId: 'test',
    },
  };
}
