import { observable } from 'mobx-angular';

import { BuyTokensMethodModel } from '@tokengear-common/modules/settings';

export class SettingsBuyTokensModel {
  @observable enabled: boolean;
  @observable allowedMethods: BuyTokensMethodModel[];
  constructor(model: SettingsBuyTokensModel) {
    Object.assign(this, model);
  }
}
