import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-loader',
  templateUrl: './card-loader.component.html',
  styleUrls: ['./card-loader.component.scss'],
})
export class CardLoaderComponent {
  constructor() {}
  @Input() showLoader: boolean;
}
