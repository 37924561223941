import { observable } from 'mobx-angular';

import { SettingsFooterFieldsModel } from '../settings-footer-fields/settings-footer-fields.model';

export class SettingsFooterModel {
  @observable enabled?: boolean = null;
  @observable fields: SettingsFooterFieldsModel = null;
  constructor(data: SettingsFooterModel) {
    Object.assign(this, data);
  }
}
