<div class="currency-inner" *mobxAutorun>
  <div class="filter">
    <input
      #filter
      id="filter-counties"
      class="filter__input"
      type="search"
      [placeholder]="'SHARED__CURRENCY_LIST__PLACEHOLDER' | translate"
      [(ngModel)]="currencyNameFilter"
      (ngModelChange)="changeSearchName(filter.value)"
    />
    <label for="filter-counties" class="filter__label">
      {{ 'SHARED__CURRENCY_LIST__lABEL' | translate }}
    </label>
  </div>
  <div class="check-list">
    <ng-container *ngFor="let item of visibleCurrencies">
      <mat-checkbox
        (change)="valueChanged($event.source.name, $event.checked)"
        [name]="item.code"
        [checked]="item.isChecked"
        color="basic"
        class="check-list__item col-md-4 col-sm-6 col-xs-12"
      >
        <span class="check-list__icon">{{ item.symbol }}</span>
        <span class="check-list__name">{{ item.code }} - {{ item.currency }}</span>
      </mat-checkbox>
    </ng-container>
  </div>
  <button
    *ngIf="!isFullList && currencyNameFilter.length === 0"
    type="button"
    class="load-more"
    mat-button
    (click)="loadMore()"
  >
    Load more
  </button>
</div>
