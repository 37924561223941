import { Component } from '@angular/core';

import { NavigationService } from '@tokengear-common/modules/navigation';

@Component({
  selector: 'app-dummy-route',
  template: '<div class="dummy-page"></div>',
  styleUrls: ['./dummy-route.component.scss'],
})
export class DummyRouteComponent {
  constructor(private navigationService: NavigationService) {
    this.navigationService.navigateToDefaultTenantRoute();
  }
}
