import { observable } from 'mobx-angular';

export class FormattedValueModel {
  @observable formattedNumber: string;
  @observable formattedWithSymbol: string;

  constructor(params: FormattedValueModel) {
    Object.assign(this, params);
  }
}
