import { observable } from 'mobx-angular';

export enum SubHeaderType {
  EXPANDABLE = 'expandable',
  PROGRESS = 'progress',
}

export class SettingsSubHeaderModel {
  @observable enabled: boolean;
  @observable type: SubHeaderType;

  constructor(data: SettingsSubHeaderModel) {
    Object.assign(this, data);
  }
}
