export const RoutingPaths = {
  root: '/',
  user: 'user',
  users: 'users',
  transactions: 'transactions',
  invoices: 'invoices',
  contributions: 'contributions',
  general: 'general',
  blacklist: 'blacklist',
  admin: 'admin',
  dashboard: 'dashboard',
  faq: 'faq',
  milestones: 'milestones',
  contact: 'contact',
  kyc: 'kyc',
  balances: 'balances',
  affiliate: 'affiliate',
  statistics: 'statistics',
  affiliateTree: 'affiliate-tree',
  about: 'about',
  settings: 'settings',
  aboutUs: 'about-us',
  features: 'features',
  lookAndFeel: 'look-and-feel',
  emails: 'emails',
  error: 'error',
  login: 'sign-in',
  login2fa: 'confirm-sign-in',
  reg: 'sign-up',
  forgotPassword: 'forgot-password',
  sso: 'sso',
  forgotInfo: 'forgot-password-info',
  restorePassword: 'restore-password',
  confirmNewPass: 'confirm-new-pass',
  confirmEmail: 'confirm-email',
  confirmEmailSuccess: 'confirm-email-success',
  changeEmailSuccess: 'change-email-success',
  membership: 'membership',
  stakingFarming: 'staking-farming',
  staking: 'staking',
  farming: 'farming',
  salesPools: 'sales-pools',
  applyIdo: 'apply-ido',
  bridge: 'bridge',
  leaderboards: 'leaderboards',
  stakingLeaderboards: 'staking-leaderboards',
  voting: 'voting',
  main: 'main',
  payment: 'payment',
  paymentApps: 'payment-apps',
  order: 'order',
  ido: 'ido',
  '500': '500',
  '404': '404',
} as const;

export const NavigationPaths = {
  root: () => `/`,
  userPath: () => `/${RoutingPaths.user}`,
  user: {
    restorePassword: () => `/${RoutingPaths.user}/${RoutingPaths.restorePassword}`,
    confirmEmail: () => `/${RoutingPaths.user}/${RoutingPaths.confirmEmail}`,
    confirmEmailSuccess: () => `/${RoutingPaths.user}/${RoutingPaths.confirmEmailSuccess}`,
    changeEmailSuccess: () => `/${RoutingPaths.user}/${RoutingPaths.changeEmailSuccess}`,
    dashboard: () => `/${RoutingPaths.user}/${RoutingPaths.dashboard}`,
    bridge: () => `/${RoutingPaths.user}/${RoutingPaths.bridge}`,
    faq: () => `/${RoutingPaths.user}/${RoutingPaths.faq}`,
    contact: () => `/${RoutingPaths.user}/${RoutingPaths.contact}`,
    kyc: () => `/${RoutingPaths.user}/${RoutingPaths.kyc}`,
    balances: () => `/${RoutingPaths.user}/${RoutingPaths.balances}`,
    affiliate: () => `/${RoutingPaths.user}/${RoutingPaths.affiliate}`,
    affiliateTree: () => `/${RoutingPaths.user}/${RoutingPaths.affiliateTree}`,
    statistics: () => `/${RoutingPaths.user}/${RoutingPaths.statistics}`,
    about: () => `/${RoutingPaths.user}/${RoutingPaths.about}`,
    settings: () => `/${RoutingPaths.user}/${RoutingPaths.settings}`,
    error500: () => `/${RoutingPaths.user}/${RoutingPaths.error}/${RoutingPaths[500]}`,
    error404: () => `/${RoutingPaths.user}/${RoutingPaths.error}/${RoutingPaths[404]}`,
    login: () => `/${RoutingPaths.user}/${RoutingPaths.login}`,
    login2FA: () => `/${RoutingPaths.user}/${RoutingPaths.login2fa}`,
    reg: (referralToken?: string) =>
      `/${RoutingPaths.user}/${RoutingPaths.reg}${referralToken ? `?referralToken=${referralToken}` : ''}`,
    forgotPassword: () => `/${RoutingPaths.user}/${RoutingPaths.forgotPassword}`,
    forgotInfo: () => `/${RoutingPaths.user}/${RoutingPaths.forgotInfo}`,
    confirmNewPass: () => `/${RoutingPaths.user}/${RoutingPaths.confirmNewPass}`,
    membership: () => `/${RoutingPaths.user}/${RoutingPaths.membership}`,
    stakingFarming: () => `/${RoutingPaths.user}/${RoutingPaths.stakingFarming}`,
    staking: () => `/${RoutingPaths.user}/${RoutingPaths.staking}`,
    farming: () => `/${RoutingPaths.user}/${RoutingPaths.farming}`,
    salesPools: () => `/${RoutingPaths.user}/${RoutingPaths.salesPools}`,
    salesPoolDetail: (id?: string) => `/${RoutingPaths.user}/${RoutingPaths.salesPools}/detail/${id != null ? id : ''}`,
    salesPoolDetailWhiteList: (id?: string) =>
      `/${RoutingPaths.user}/${RoutingPaths.salesPools}/detail-white-list/${id != null ? id : ''}`,
    applyIdo: () => `/${RoutingPaths.user}/${RoutingPaths.applyIdo}`,
    leaderboards: () => `/${RoutingPaths.user}/${RoutingPaths.leaderboards}`,
    stakingLeaderboards: () => `/${RoutingPaths.user}/${RoutingPaths.stakingLeaderboards}`,
    voting: () => `/${RoutingPaths.user}/${RoutingPaths.voting}`,
    main: () => `/${RoutingPaths.user}/${RoutingPaths.main}`,
    ido: () => `/${RoutingPaths.user}/${RoutingPaths.ido}`,
    paymentApps: () => `/${RoutingPaths.user}/${RoutingPaths.paymentApps}`,
    paymentAppDetails: (id?: string) =>
      `/${RoutingPaths.user}/${RoutingPaths.paymentApps}/detail/${id != null ? id : ''}`,
  },
  admin: {
    dashboard: () => `/${RoutingPaths.admin}/${RoutingPaths.dashboard}`,
    transactions: () => `/${RoutingPaths.admin}/${RoutingPaths.transactions}`,
    invoices: () => `/${RoutingPaths.admin}/${RoutingPaths.invoices}`,
    contributions: () => `/${RoutingPaths.admin}/${RoutingPaths.contributions}`,
    general: () => `/${RoutingPaths.admin}/${RoutingPaths.general}`,
    blacklist: () => `/${RoutingPaths.admin}/${RoutingPaths.blacklist}`,
    faq: () => `/${RoutingPaths.admin}/${RoutingPaths.faq}`,
    milestones: () => `/${RoutingPaths.admin}/${RoutingPaths.milestones}`,
    aboutUs: () => `/${RoutingPaths.admin}/${RoutingPaths.aboutUs}`,
    features: () => `/${RoutingPaths.admin}/${RoutingPaths.features}`,
    lookAndFeel: () => `/${RoutingPaths.admin}/${RoutingPaths.lookAndFeel}`,

    paymentApps: {
      list: () => `/${RoutingPaths.admin}/${RoutingPaths.paymentApps}`,
      userDetails: (id?: string) => `/${RoutingPaths.admin}/${RoutingPaths.paymentApps}/user/${id != null ? id : ''}`,
      appDetails: (id?: string) => `/${RoutingPaths.admin}/${RoutingPaths.paymentApps}/app/${id}`,
    },
    emails: {
      list: () => `/${RoutingPaths.admin}/${RoutingPaths.emails}`,
      create: () => `/${RoutingPaths.admin}/${RoutingPaths.emails}/create`,
      edit: (id?: string) => `/${RoutingPaths.admin}/${RoutingPaths.emails}/edit/${id != null ? id : ''}`,
    },
    users: {
      list: () => `/${RoutingPaths.admin}/${RoutingPaths.users}`,
      details: (id?: string) => `/${RoutingPaths.admin}/${RoutingPaths.users}/detail/${id != null ? id : ''}`,
    },
    error500: () => `/${RoutingPaths.admin}/${RoutingPaths.error}/${RoutingPaths[500]}`,
    error404: () => `/${RoutingPaths.admin}/${RoutingPaths.error}/${RoutingPaths[404]}`,
  },
  payment: {
    payment: () => `/${RoutingPaths.payment}`,
    order: (id?: string) => `/${RoutingPaths.payment}/${RoutingPaths.order}/${id != null ? id : ''}`,
  },
};
