import { observable } from 'mobx-angular';

export class SettingsPlanModel {
  @observable discount: string;
  @observable formattedUnlockAfterSold: string;
  @observable plan: string;
  @observable timeLockUp: string;
  @observable unlockAfterSold: string;

  constructor(params: SettingsPlanModel) {
    Object.assign(this, params);
  }
}
