import { observable } from 'mobx-angular';

export class SettingsMainPageModel {
  @observable enabled: boolean;
  @observable title: string;
  @observable description: string;
  @observable itemTitle: string;
  @observable itemDescription: string;
  @observable itemImgSrc: string;
  @observable navigateToText: string;
  @observable navigateToRoute: string;
  constructor(model: SettingsMainPageModel) {
    Object.assign(this, model);
  }
}
