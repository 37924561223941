import { observable } from 'mobx-angular';

export class SalePoolsWhitelistCountersModel {
  @observable available: number;
  @observable applied: number;
  @observable whiteListed: number;
  @observable total: number;

  constructor(model: SalePoolsWhitelistCountersModel) {
    Object.assign(this, model);
  }
}
