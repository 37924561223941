import { observable } from 'mobx-angular';

import { SettingsAboutProjectVersionModel } from '../settings-about-project-version/settings-about-project-version.model';

export class SettingsAboutProjectModel {
  @observable body: string = null;
  @observable updatedAt: Date = null;
  @observable isPublic: boolean = null;
  @observable versions: SettingsAboutProjectVersionModel[] = null;

  constructor(params: SettingsAboutProjectModel) {
    Object.assign(this, params);

    if (this.versions != null) {
      this.versions = this.versions.map((version) => new SettingsAboutProjectVersionModel(version));
    }
  }
}
