import { HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';

import { ArrayHelper } from '../helpers';
import { AuthService } from '../modules/auth';

class CustomHttpUrlEncodingCodec extends HttpUrlEncodingCodec {
  encodeKey(k: string): string {
    return super.encodeKey(k).replace(new RegExp('%5B', 'g'), '[').replace(new RegExp('%5D', 'g'), ']');
  }
}

@Injectable()
export class HttpHelperService {
  constructor(private readonly authService: AuthService) {}

  public static getFilteredParams(params: HttpParams): HttpParams {
    let result = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    params.keys().forEach((fieldName) => {
      let values = params.getAll(fieldName);

      if (fieldName === 'offset') {
        const noAutoOffset = params.getAll('noAutoOffset');
        if (!noAutoOffset) {
          const limit = params.getAll('limit');

          values[0] = <any>(Number(values[0]) * Number(limit[0]));
        }
      }

      if (fieldName === 'sortField') {
        const sortOrder = params.getAll('sortOrder');

        if (sortOrder == null || sortOrder[0] == null || sortOrder[0] === '') {
          return;
        }
      }

      if (values == null || values[0] == null || values[0] === '') {
        return;
      }

      if (<any>values[0] instanceof Date) {
        values[0] = (<any>values[0]).toISOString();
      }

      if (<any>values[0] instanceof Array) {
        values = ArrayHelper.removeItem(<any>values[0], 'ALL');
        result = result.set(fieldName, <any>values);

        return result;
      }

      result = result.set(fieldName, <any>values);
    });

    return result;
  }

  public getUrlWithToken(params: { url: string; httpParams?: any; authToken?: string }) {
    if (this.authService.token) {
      const paramsAll = {};
      Object.assign(paramsAll, params.httpParams, { access_token: params.authToken || this.authService.token });

      let httpParams = new HttpParams();

      Object.keys(paramsAll).forEach((name) => {
        const value = paramsAll[name];

        httpParams = httpParams.set(name, value);
      });

      const result = HttpHelperService.getFilteredParams(httpParams).toString();

      return HttpHelperService.getUrl(`${params.url}/?${result}`);
    }
  }

  public static getUrl(requestUrl: string): string {
    return `${environment.apiUrl}${environment.apiNamespace}${requestUrl}`;
  }
}
