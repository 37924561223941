export function getSettingsCountriesBlacklistMockData() {
  return {
    serviceId: 'blacklist-countries',
    type: 'blacklist-countries',
    settings: {
      enabled: true,
      countries: ['AF', 'AX', 'AL', 'AW', 'MH', 'SD', 'SC', 'SX', 'CH', 'AD'],
    },
  };
}
