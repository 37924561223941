import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-statistic-referral-link',
  templateUrl: './statistic-referral-link.component.html',
  styleUrls: ['./statistic-referral-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticReferralLinkComponent {
  @Input() referralLink: string;
  @Input() showBorder: boolean;

  public readonly widthQrCode = 130;
}
