import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CountryCheckListModel } from '@tokengear-common/modules/domain/feature/countries';

interface CountryChange {
  item: CountryCheckListModel;
  isChecked: boolean;
}

@Component({
  selector: 'app-country-check-list',
  templateUrl: './country-check-list.component.html',
  styleUrls: ['./country-check-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryCheckListComponent {
  @Input() public label: string;
  @Input() public checkedCount: number = null;
  @Input() public nameFilter: string = null;
  @Input() public countries: CountryCheckListModel[] = null;
  @Input() public isChangedList: boolean = null;

  @Output() public readonly changeNameFilter = new EventEmitter<string>();
  @Output() public readonly selectAll = new EventEmitter<boolean>();
  @Output() public readonly valueChange = new EventEmitter<CountryChange>();
  @Output() public readonly saveChange = new EventEmitter<void>();

  public onChangeSearch(value: string): void {
    this.changeNameFilter.emit(value);
  }

  public onSelectAll(value: boolean): void {
    this.selectAll.emit(value);
  }

  public onChangeCheck(item: CountryCheckListModel, isChecked: boolean): void {
    this.valueChange.emit({
      item,
      isChecked,
    });
  }
}
