import { observable } from 'mobx-angular';

import { TypeModel } from './type.model';

export class TypesModel {
  @observable EIP712Domain: TypeModel;
  @observable Person: TypeModel;
  @observable Mail: TypeModel;

  constructor(value: TypesModel) {
    Object.assign(this, value);
  }
}
