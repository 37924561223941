import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-main-container-wrapper',
  templateUrl: './main-container-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainContainerWrapperComponent {
  @Input() public isLoading = false;
}
