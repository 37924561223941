<div class="main" [ngClass]="{ 'is-admin': isAdmin }">
  <div class="main-wrap">
    <div class="header">
      <div class="header__inner">
        <!--First part of header Logo + menu-btn -->
        <div class="header__left-part">
          <ng-content select="app-toolbar"></ng-content>
        </div>
        <!--First part of header Logo + menu-btn end-->

        <!--Second part of header navigation-->

        <div class="display-flex">
          <ng-content select="app-top-navigation"></ng-content>
          <ng-content select="app-header"></ng-content>
          <button
            class="header__btn"
            aria-label="Toggle sidenav"
            mat-icon-button
            (click)="updateMenuStatus(!menuStatus)"
          >
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
          </button>
        </div>

        <!--Seheader-bigcond part of header navigation end-->
      </div>
    </div>
    <mat-sidenav-container
      [class.sub-header-box]="isEnabledSubHeader"
      class="main-wrap__sidenav"
      [ngClass]="{ 'change-z-index': menuStatus }"
    >
      <mat-sidenav
        #drawer
        class="main-wrap__aside"
        fixedInViewport="false"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="menuStatus || (!isAdmin && !(isHandset$ | async))"
      >
        <div class="main-wrap__aside-box">
          <ng-content select="app-aside"></ng-content>
        </div>
      </mat-sidenav>
      <!--      <mat-sidenav-->
      <!--        #drawer-->
      <!--        class="main-wrap__aside"-->
      <!--        fixedInViewport="false"-->
      <!--        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"-->
      <!--        [mode]="(isHandset$ | async) ? 'over' : 'side'"-->
      <!--        [opened]="menuStatus || (!isAdmin && !(isHandset$ | async))"-->
      <!--      >-->
      <!--        <div class="main-wrap__aside-box">-->
      <!--          <ng-content select="app-aside"></ng-content>-->
      <!--        </div>-->
      <!--      </mat-sidenav>-->
      <mat-sidenav-content class="main-wrap__content" appScrollToTop>
        <ng-content select="router-outlet"></ng-content>
      </mat-sidenav-content>
    </mat-sidenav-container>
    <ng-content select="app-kyc-notice"></ng-content>
  </div>
</div>
