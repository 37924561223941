import { Component, OnInit } from '@angular/core';
import { action, computed } from 'mobx-angular';

import { NavigationService } from '@tokengear-common/modules/navigation';
// TODO(Max): we should not import the feature services in the shared module
import { KycService } from '@tokengear-common/pages/user-kyc/services/kyc.service';

@Component({
  selector: 'app-user-kyc-status',
  templateUrl: './user-kyc-status.component.html',
  styleUrls: ['./user-kyc-status.component.scss'],
})
export class UserKycStatusComponent implements OnInit {
  constructor(private kycService: KycService, private navigationService: NavigationService) {}

  @action ngOnInit() {}

  public navigateToUserKyc() {
    if (this.kycNotVerified || this.kycFailed) {
      this.navigationService.navigateToUserKyc();
    }
  }

  @computed get kycNotVerified() {
    return this.kycService.kycNotVerified;
  }

  @computed get kycInProgress() {
    return this.kycService.kycInProgress;
  }

  @computed get kycVerified() {
    return this.kycService.kycVerified;
  }

  @computed get kycFailed() {
    return this.kycService.kycFailed;
  }

  @computed get kycStatusDescription() {
    return this.kycService.kycStatusDescription;
  }
}
