import { observable } from 'mobx-angular';

import { FundAccountModel } from '@domain-modules/fund-account/models/fund-account-config/fund-account.model';

export class FundAccountConfigModel {
  @observable enabled = false;
  @observable availableMethods: FundAccountModel = null;

  constructor(data: FundAccountConfigModel) {
    Object.assign(this, data);
  }
}
