import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { MdePopoverModule } from '@material-extended/mde';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { AngularResizedEventModule } from 'angular-resize-event';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { QRCodeModule } from 'angularx-qrcode';
import { MobxAngularModule } from 'mobx-angular';
import { ClipboardModule } from 'ngx-clipboard';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { ConfirmationDialogModule } from '@tokengear-common/components';

import { JiraIssueCollectorModule } from '@app/integrations/modules/jira-issue-collector';
import { UserKycStatusComponent } from '@pages/user/components/user-kyc-status/user-kyc-status.component';
import { AnimatedCounterComponent } from '@shared/components/annimated-counter/animated-counter.component';
import { AuthHeaderComponent } from '@shared/components/auth-header/auth-header.component';
import { CardLoaderComponent } from '@shared/components/card-loader/card-loader.component';
import { ComingSoonComponent } from '@shared/components/coming-soon/coming-soon.component';
import { CountdownTimerComponent } from '@shared/components/countdown-timer/countdown-timer.component';
import { DummyRouteComponent } from '@shared/components/dummy-route/dummy-route.component';
import { LoadingInfiniteComponent } from '@shared/components/infinite-loader/infinite-loader.component';
import { ModalInvoiceGeneratedComponent } from '@shared/components/modal-invoice-generated/modal-invoice-generated.component';
import { ModalTransactionStatusComponent } from '@shared/components/modal-transaction-status/modal-transaction-status.component';
import { NetworkErrorComponent } from '@shared/components/network-error/network-error.component';
import { NotConnectWalletComponent } from '@shared/components/not-connect-wallet/not-connect-wallet.component';
import { ProgressBarComponent } from '@shared/components/progress-bar/progress-bar.component';
import { ScrollToTopDirective } from '@shared/components/sidenav/scroll-to-top/scroll-to-top.directive';
import { StartKycVerificationComponent } from '@shared/components/start-kyc-verification/start-kyc-verification.component';
import { StatisticAffiliateStatisticsComponent } from '@shared/components/statistic-affiliate-statistics/statistic-affiliate-statistics.component';
import { StatisticReferralLinkComponent } from '@shared/components/statistic-referral-link/statistic-referral-link.component';
import { StatisticTokenBalanceComponent } from '@shared/components/statistic-token-balance/statistic-token-balance.component';
import { StatisticTokenSaleBalanceComponent } from '@shared/components/statistic-token-sale-balance/statistic-token-sale-balance.component';
import { SwitchBtnComponent } from '@shared/components/switch-btn/switch-btn.component';
import { TabComponent } from '@shared/components/tabs/tab.component';
import { TabsComponent } from '@shared/components/tabs/tabs.component';
import { TransactionLoaderComponent } from '@shared/components/transaction-loader/transaction-loader.component';
import { TransactionStatusComponent } from '@shared/components/transaction-status/transaction-status.component';
import { InvalidControlScrollDirective } from '@shared/directives/invalid-control-scroll/invalid-control-scroll.directive';
import { NumberOnlyDirective } from '@shared/directives/number-only/number-only.directive';
import { ThemeClassDirective } from '@shared/directives/theme/theme-class.directive';
import { ThemeClassModule } from '@shared/directives/theme/theme-class.module';
import { FormErrorHandlerModule } from '@shared/form-error-handler/form-error-handler.module';
import { ImagePipe } from '@shared/pipes/image.pipe';
import { RateNumberPipe } from '@shared/pipes/rateNumber';
import { SortPipe } from '@shared/pipes/sort.pipe';
import { TsToDaysPipe } from '@shared/pipes/ts-to-days.pipe';

import { AccordionItemComponent } from './components/accordion-item/accordion-item.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { BadgeComponent } from './components/badge/badge.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckListComponent } from './components/check-list/check-list.component';
import { CountryCheckListComponent } from './components/country-check-list/country-check-list.component';
import { CryptoCurrencyListComponent } from './components/crypto-currency-list/crypto-currency-list.component';
import { CurrencyListComponent } from './components/currency-list/currency-list.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { InputErrorMessageComponent } from './components/input-error-message/input-error-message.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SaveSettingsComponent } from './components/save-settings/save-settings.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SlidePaginationComponent } from './components/slide-pagination/slide-pagination.component';
import { TxHashComponent } from './components/tx-hash/tx-hash.component';
import { CustomNumberPipe } from './pipes/customNumber';
import { SymbolNumberPipe } from './pipes/symbolNumber';

@NgModule({
  imports: [
    CommonModule,
    MobxAngularModule,
    AngularResizedEventModule,
    TranslateModule,
    NgxDnDModule,
    PortalModule,
    RouterModule,
    JiraIssueCollectorModule,
    InfiniteScrollModule,
    AngularSvgIconModule.forRoot(),
    /*Angular Material modules*/
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTabsModule,
    QRCodeModule,
    ClipboardModule,
    MatRippleModule,
    FormErrorHandlerModule,
    ThemeClassModule,
    NgxJsonViewerModule,
    MdePopoverModule,
    // Use by exact module instead
    ConfirmationDialogModule,
  ],
  declarations: [
    /*Pipes*/
    TsToDaysPipe,
    RateNumberPipe,
    CustomNumberPipe,
    SortPipe,
    SymbolNumberPipe,
    ImagePipe,
    /*Directives*/
    NumberOnlyDirective,
    InvalidControlScrollDirective,
    ScrollToTopDirective,
    /*Components*/
    SlidePaginationComponent,
    DummyRouteComponent,
    SidenavComponent,
    CheckListComponent,
    CountryCheckListComponent,
    BadgeComponent,
    AccordionComponent,
    AccordionItemComponent,
    NotFoundComponent,
    ComingSoonComponent,
    CurrencyListComponent,
    StartKycVerificationComponent,
    FileInputComponent,
    InputErrorMessageComponent,
    CryptoCurrencyListComponent,
    SaveSettingsComponent,
    ProfileComponent,
    ProgressBarComponent,
    StatisticTokenSaleBalanceComponent,
    StatisticAffiliateStatisticsComponent,
    StatisticTokenBalanceComponent,
    StatisticReferralLinkComponent,
    TabsComponent,
    TabComponent,
    ModalInvoiceGeneratedComponent,
    SwitchBtnComponent,
    CardLoaderComponent,
    AuthHeaderComponent,
    NotConnectWalletComponent,
    NetworkErrorComponent,
    LoadingInfiniteComponent,
    TransactionStatusComponent,
    ModalTransactionStatusComponent,
    ButtonComponent,
    UserKycStatusComponent,
    AnimatedCounterComponent,
    TxHashComponent,
    TransactionLoaderComponent,
    CountdownTimerComponent,
  ],
  exports: [
    CommonModule,
    /*Pipes*/
    TsToDaysPipe,
    RateNumberPipe,
    CustomNumberPipe,
    SortPipe,
    SymbolNumberPipe,
    ImagePipe,
    /*Modules*/
    TranslateModule,
    MobxAngularModule,
    AngularResizedEventModule,
    NgxDnDModule,
    PortalModule,
    JiraIssueCollectorModule,
    InfiniteScrollModule,
    AngularSvgIconModule,
    FormErrorHandlerModule,
    ThemeClassModule,
    NgxJsonViewerModule,
    /*Angular Material modules*/
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule,
    MatRippleModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTabsModule,
    QRCodeModule,
    ClipboardModule,
    MdePopoverModule,
    /*Directives*/
    NumberOnlyDirective,
    InvalidControlScrollDirective,
    ScrollToTopDirective,
    ThemeClassDirective,
    /*Components*/
    SlidePaginationComponent,
    DummyRouteComponent,
    SidenavComponent,
    CheckListComponent,
    CountryCheckListComponent,
    BadgeComponent,
    AccordionComponent,
    AccordionItemComponent,
    NotFoundComponent,
    ComingSoonComponent,
    CurrencyListComponent,
    CryptoCurrencyListComponent,
    StartKycVerificationComponent,
    FileInputComponent,
    InputErrorMessageComponent,
    SaveSettingsComponent,
    ProfileComponent,
    StatisticTokenSaleBalanceComponent,
    StatisticAffiliateStatisticsComponent,
    StatisticReferralLinkComponent,
    StatisticTokenBalanceComponent,
    TabsComponent,
    TabComponent,
    ProgressBarComponent,
    SwitchBtnComponent,
    ModalInvoiceGeneratedComponent,
    CardLoaderComponent,
    AuthHeaderComponent,
    NotConnectWalletComponent,
    NetworkErrorComponent,
    LoadingInfiniteComponent,
    TransactionStatusComponent,
    ModalTransactionStatusComponent,
    ButtonComponent,
    UserKycStatusComponent,
    AnimatedCounterComponent,
    TxHashComponent,
    TransactionLoaderComponent,
    CountdownTimerComponent,
  ],
})
export class SharedModule {}
