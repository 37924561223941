<div class="save-changes-block">
  <div class="save-changes-block__inner">
    <p class="save-changes-block__title">
      {{ 'SAVE_SETTINGS_TITLE' | translate }}
    </p>
    <button
      class="btn btn_xl btn__primary-flat theme-background"
      mat-flat-button
      [disabled]="!isValid"
      (click)="onSaveSettings()"
    >
      {{ 'SAVE_SETTINGS_BTN' | translate }}
    </button>
  </div>
</div>
<div class="save-changes-block-padding"></div>
