import { observable } from 'mobx-angular';

import { SalePoolsItemModel } from '@domain-modules/pools/models/sale-pools-item.model';

export class SalePoolsModel {
  data: SalePoolsItemModel[];
  @observable total: number;

  constructor(pools: SalePoolsModel) {
    Object.assign(this, pools);
    if (pools.data != null) {
      this.data = pools.data.map((item) => new SalePoolsItemModel(item));
    }
  }
}
