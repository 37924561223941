<div appThemeClass>
  <button
    class="btn-tenant-component"
    (click)="onClick()"
    [disabled]="disabled"
    [class.theme-background]="themeBackground"
    mat-flat-button
  >
    <mat-icon *ngIf="svgIcon" svgIcon="{{ svgIcon }}"></mat-icon>
    {{ text }}
    <mat-spinner *ngIf="showSpinner"></mat-spinner>
  </button>
</div>
