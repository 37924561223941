<div appThemeClass>
  <div class="loader-wrapper">
    <svg-icon
      class="loader-wrapper__icon rotating-animation"
      [src]="'./assets/img/icons/loader-gradient-icon.svg'"
      [stretch]="false"
    ></svg-icon>
    <h1 class="loader-wrapper__title">{{ titleText }}</h1>
    <p class="loader-wrapper__description">{{ descriptionText }}</p>
  </div>
</div>
