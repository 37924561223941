/**
 * The main component that renders single TabComponent
 * instances.
 */
import { AfterContentInit, Component, ContentChildren, EventEmitter, NgZone, Output, QueryList } from '@angular/core';

import { NavigationService } from '@tokengear-common/modules/navigation';

import { TabComponent } from './tab.component';

@Component({
  selector: 'app-my-tabs',
  template: `
    <ul class="tabs-wrapper">
      <li
        *ngFor="let tab of tabs; let i = index"
        (click)="selectTab(tab)"
        class="tabs-item"
        [class.tabs-item-center]="tab.positionCenter"
        [ngClass]="{ 'tab-active theme-border-color': tab.active }"
        [class.theme-border-color]="tab.active"
        [class.disable-div]="tab.disabled"
      >
        <span>{{ tab.tabTitle }}</span>
      </li>
    </ul>
    <ng-content></ng-content>
  `,
  styles: [
    `
      .tabs-wrapper {
        display: flex;
        font-weight: normal;
        font-size: 14px;
      }
      .tabs-item {
        cursor: pointer;
        padding: 10px 0 10px 0;
        margin: 0 8px 0 8px;
      }

      .tabs-item-center {
        width: 33%;
        text-align: center;
      }
      .tab-active {
        border-bottom: solid 2px #735fed;
      }
    `,
  ],
})
export class TabsComponent implements AfterContentInit {
  @Output() readonly tabChange: EventEmitter<string> = new EventEmitter();

  constructor(private navigationService: NavigationService, private zone: NgZone) {}
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  // contentChildren are set
  public ngAfterContentInit(): void {
    const activeTabByParam = this.navigationService.getQueryParam('activeTab');
    const activeTab = this.tabs.find((tab) => (activeTabByParam ? tab.tabTitle === activeTabByParam : tab.active));
    this.selectTab(activeTab);
  }

  public selectTab(tab: TabComponent = this.tabs.first): void {
    // deactivate all tabs
    // eslint-disable-next-line @typescript-eslint/no-shadow
    if (tab) {
      this.tabChange.emit(tab.tabTitle);
      this.zone.run(() => {
        this.navigationService.setQueryParam({ activeTab: tab.tabTitle }).then(() => {
          this.tabs.toArray().forEach((tabItem) => (tabItem.active = false));
          // activate the tab the user has clicked on.
          tab.active = true;
        });
      });
    }
  }
}
