import { observable } from 'mobx-angular';

export class KycConfigModel {
  @observable apartment: KycConfig;
  @observable address: KycConfig;
  @observable birthday: KycConfig;
  @observable city: KycConfig;
  @observable country: KycConfig;
  @observable docCountry: KycConfig;
  @observable docType: KycConfig;
  @observable documentScanBack: KycConfig;
  @observable documentScanBackFile: KycConfig;
  @observable documentScanFace: KycConfig;
  @observable documentScanFaceFile: KycConfig;
  @observable documentScanFront: KycConfig;
  @observable documentScanFrontFile: KycConfig;
  @observable firstName: KycConfig;
  @observable fullName: KycConfig;
  @observable email: KycConfig;
  @observable emailVerificationCode: KycConfig;
  @observable gender: KycConfig;
  @observable isTermsOfUseAccepted: KycConfig;
  @observable lastName: KycConfig;
  @observable middleName: KycConfig;
  @observable phone: KycConfig;
  @observable referralToken: KycConfig;
  @observable state: KycConfig;
  @observable street: KycConfig;
  @observable zipCode: KycConfig;

  constructor(data: KycConfigModel) {
    Object.assign(this, data);
  }
}

export class KycConfig {
  @observable enabled: boolean;
  @observable required: boolean;

  constructor(data: KycConfigModel) {
    Object.assign(this, data);
  }
}
