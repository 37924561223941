export function getSettingsGeneralMockData() {
  return {
    serviceId: 'ico',
    type: 'ico',
    settings: {
      projectName: 'TokenGear',
      defaultLocale: 'en',
      policyUrl: '',
      kycType: null,
      timeZone: '',
      displayCurrency: 'USD',
      tokenSymbol: 'ETH',
      contactUsEmail: 'support@tokengear.io',
    },
  };
}
