import { observable } from 'mobx-angular';

import { SettingsAuthDictionary } from '../../dictionaries/settings-auth.dictionary';

export class SettingsAuthModel {
  @observable method: SettingsAuthDictionary;
  @observable emailOnly: boolean;

  constructor(data: SettingsAuthModel) {
    Object.assign(this, data);
  }
}
