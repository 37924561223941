import { observable } from 'mobx-angular';

import { SaleModeTypeDictionary } from '@domain-modules/ico/dectionaries/sale-mode-type.dictionary';
import { KycFormTypeDictionary } from '@domain-modules/kyc/dectionaries/kyc-form-type.dictionary';

interface KYCField {
  isRequired: boolean;
}

export class SettingsGeneralModel {
  @observable startDate: string;
  // @observable endDate: string;
  @observable projectName: string;
  @observable purchaseAgreementLink: string;
  @observable purchaseAgreementTitle: string;
  @observable policyUrl: string;
  @observable termsUrl: string;
  @observable displayCurrency: string;
  @observable tokenSymbol: string;
  @observable contactUsEmail: string;
  @observable kycFields: { [key: string]: KYCField };
  @observable defaultLocale: string;
  @observable kycType: KycFormTypeDictionary;
  @observable saleStage: SaleModeTypeDictionary;
  @observable companyMode: boolean;
  @observable timeZone: string; // Ukraine/Kiev
  @observable timeZoneOffset: string; // -0500, UTC
  @observable videoUrl: string;
  @observable statisticForInvestorIsDisplayed: boolean;
  @observable decimals: number;
  @observable getCoinsPancakeSwapExchangeURL: string;
  @observable getCoinsBridgeURL: string;

  constructor(params: SettingsGeneralModel) {
    Object.assign(this, params);
  }
}
