import { observable } from 'mobx-angular';

export class SettingsBlacklistCountriesModel {
  @observable enabled: boolean = null;
  @observable countries: string[] = null;

  constructor(params: SettingsBlacklistCountriesModel) {
    Object.assign(this, params);
  }
}
