import { observable } from 'mobx-angular';

export class UserIdHistoryModel {
  @observable createAt: Date = null;
  @observable customUserId: string = null;
  @observable userId: string = null;

  constructor(user: UserIdHistoryModel) {
    Object.assign(this, user);
    this.createAt = new Date(this.createAt);
  }
}
