export const environment = {
  production: false,
  name: 'development',
  apiUrl: 'https://dev-api-eu.tokengear.io',
  apiNamespace: '',
  jiraBaseUrl: 'https://applicature.atlassian.net',
  uniswap: 'https://app.uniswap.org/#/add/v2/',
  uniswapOutputCurrency: 'https://app.uniswap.org/#/swap?outputCurrency',
  jiraCollectorId: '05c33489',
  mock: true,
  isSupportVisible: false,
  isDirVisible: true,
  isSentryActive: false,
  interceptors: {
    AuthInterceptor: false,
    MeInterceptor: false,
    BalanceInterceptor: true, // ICO
    CountriesInterceptor: false,
    FaqsInterceptor: false,
    InvoicesApproveInterceptor: false,
    InvoicesDeclineInterceptor: false,
    InvoicesInterceptor: false,
    SettingsAboutProjectInterceptor: true,
    SettingsContributionsBtcInterceptor: false,
    SettingsContributionsEtcInterceptor: false,
    SettingsContributionsInvoiceInterceptor: false,
    SettingsContributionsLtcInterceptor: false,
    SettingsCountriesBlacklistInterceptor: false,
    SettingsGeneralInterceptor: false,
    SettingsMilestonesInterceptor: false,
    SettingsOptionsInterceptor: false,
    StatsCountiesInterceptor: false,
    StatsInterceptor: false, // ICO
    StatsInvestorsInterceptor: false,
    StatsOverallInterceptor: false,
    StatsTokensDailyInterceptor: false,
    StatsTokensInterceptor: false,
    StatsTokensTotalInterceptor: false,
    TransactionsInterceptor: false,
    UserContactInterceptor: false,
    UserInterceptor: false,
    UsersInterceptor: false,
    UserStatusInterceptor: false,
    EmailsInterceptor: {
      getAll: false,
      getItem: false,
      putItem: false,
    },
  },
};
