import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[appNumberOnly]',
})
export class NumberOnlyDirective {
  @Input() public decimals = 6;
  @Input() public maxLength = 18;
  private readonly specialKeys: string[] = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ];
  private readonly disabledFirstValues = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09'];
  constructor(private el: ElementRef<HTMLInputElement>) {}

  private checkZero(): void {
    setTimeout(() => {
      const currentValue = this.el.nativeElement.value;
      const currentValueFirstTwoSymbol = this.el.nativeElement.value.substring(0, 2);

      if (this.disabledFirstValues.includes(currentValueFirstTwoSymbol)) {
        this.el.nativeElement.value = parseFloat(currentValue).toString();
      }
    });
  }

  private check(value: string, event: KeyboardEvent): RegExpMatchArray | null {
    if (event.which === 32) {
      this.el.nativeElement.value = value.replace(/\s/g, '');

      return null;
    }
    if (this.decimals === 0) {
      return String(value).match(new RegExp(/^\d+$/));
    }
    const regExpString = `^\\s*((\\d+(\\.\\d{0,${this.decimals}})?)|((\\d*(\\.\\d{1,${this.decimals}}))))\\s*$`;

    return String(value).match(new RegExp(regExpString));
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current = this.el.nativeElement.value.trim();
    const position = this.el.nativeElement.selectionStart;
    const next: string = [
      current.slice(0, position),
      event.key === 'Decimal' ? '.' : event.key,
      current.slice(position),
    ].join('');
    if (next && (!this.check(next, event) || this.maxLength < next.length)) {
      event.preventDefault();
    }
    if (event.key !== '.') {
      this.checkZero();
    }
  }

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    event.preventDefault();
  }
}
