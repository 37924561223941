import { observable } from 'mobx-angular';

export class LoginModel {
  @observable email: string;
  @observable password: string;

  constructor(value: LoginModel) {
    Object.assign(this, value);
  }
}
