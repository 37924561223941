import { observable } from 'mobx-angular';

export class SettingsContractsConfigModel {
  @observable enabled: boolean;
  @observable baseAbiUrl: string;
  public contracts: any;
  public sharedAbi: any;
  constructor(data: SettingsContractsConfigModel) {
    Object.assign(this, data);
  }
}
