import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { errorMessages, ApiUrlsDictionary } from '../../api';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          if (
            [401, 500].includes(error.status) &&
            [errorMessages.USER_NOT_FOUND, errorMessages.AUTH_FAILED].includes(error.error?.message)
          ) {
            // in order to prevent navigation if there are some errors with the auth requests
            const isAuthRequest = request.url.endsWith(ApiUrlsDictionary.auth());

            this.authService.handleAuthError(!isAuthRequest);
          }
        },
      }),
    );
  }
}
