import { TriggerPosition } from './trigger-position.enum';

/**
 * Options for the Issue Collector
 */
export interface CollectorOptions {
  baseUrl: string;
  collectorId: string;
  enabled?: boolean;
  recordWebInfo?: boolean;
  environment?: { [key: string]: string };
  environmentFn?: () => { [key: string]: string };
  fieldValues?: { [key: string]: string | any[] };
  fieldValuesFn?: () => { [key: string]: string };
  triggerPosition?: TriggerPosition;
  triggerText?: string;
  customHeight?: string;
}
