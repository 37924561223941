import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiUrlsDictionary } from '@tokengear-common/modules/api';
import { SignTypeDataModel } from '@tokengear-common/modules/auth';

import { EthSignatureTypeDictionary } from '@domain-modules/eth-address-signature/dectionaries/eth-signature-type.dictionary';

@Injectable({
  providedIn: 'root',
})
export class EthAddressSignatureService {
  constructor(private http: HttpClient) {}

  public getKycEthAddressSignature(signType: EthSignatureTypeDictionary): Observable<SignTypeDataModel> {
    return this.http.get<SignTypeDataModel>(ApiUrlsDictionary.ethAddressSignature(signType));
  }
}
