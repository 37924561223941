import { Component } from '@angular/core';
import { action } from 'mobx-angular';

import { ConnectWalletService } from '@pages/user/services/connect-wallet/connect-wallet.service';

@Component({
  selector: 'app-not-connect-wallet',
  templateUrl: './not-connect-wallet.component.html',
  styleUrls: ['./not-connect-wallet.component.scss'],
})
export class NotConnectWalletComponent {
  constructor(private connectWalletService: ConnectWalletService) {}

  @action async connectToWallet() {
    return this.connectWalletService.connectWallet();
  }
}
