import { observable } from 'mobx-angular';

import { SettingsSubHeaderModel } from './settings-sub-header.model';

export class SettingsHeaderModel {
  @observable enabled: boolean;
  @observable subHeader: SettingsSubHeaderModel;

  constructor(data: SettingsHeaderModel) {
    Object.assign(this, data);
  }
}
