<div
  class="progress-bar-container"
  [style.borderRadius.px]="borderRadius"
  [style.height.px]="height"
  [style.width.px]="width"
  [style.background]="background"
  [ngStyle]="{ 'box-shadow': boxShadow }"
>
  <div
    *ngIf="progressFirst && total"
    class="progress-bar"
    [style.z-index]="2"
    [class.progress-bar-animation]="showAnimation"
    [style.borderRadius.px]="borderRadius"
    [style.background]="colorFirst"
    [style.width.%]="percentage && percentage > progressFirst ? percentage : (progressFirst / total) * 100"
    [style.height.px]="height"
  ></div>
  <div
    *ngIf="progressSecond && total"
    class="progress-bar"
    [style.z-index]="1"
    [class.progress-bar-animation]="showAnimation"
    [style.borderRadius.px]="borderRadius"
    [style.background]="colorSecond"
    [style.width.%]="(progressSecond / total) * 100"
    [style.height.px]="height"
  ></div>
</div>
