import { Directive, ElementRef, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { Destroyable } from '@tokengear-common/base';

@Directive({
  selector: '[appScrollToTop]',
})
export class ScrollToTopDirective extends Destroyable implements OnInit {
  constructor(private router: Router, private el: ElementRef) {
    super();
  }

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((val: RouterEvent) => {
      if (val instanceof NavigationEnd) {
        this.el.nativeElement.scrollTo({ top: 0 });
      }
    });
  }
}
