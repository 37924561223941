import { observable } from 'mobx-angular';

import { SideBarItemModel } from './side-bar-item.model';

export class SettingsSideBarModel {
  @observable enabled: boolean;
  @observable assetsBaseUrl: string;
  @observable defaultNavigationRoute: string;
  @observable navigation: SideBarItemModel[];

  constructor(data: SettingsSideBarModel) {
    Object.assign(this, data);
  }
}
