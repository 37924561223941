<ng-container *mobxAutorun>
  <div appThemeClass>
    <div class="transaction-status" *ngIf="status" [ngSwitch]="status">
      <div class="transaction-status__submitted" *ngSwitchCase="transactionStatus.SUCCESS">
        <img
          src="/assets/img/icons/back-btn.svg"
          *ngIf="showBack"
          class="transaction-status__submitted__back"
          (click)="backEmitter.emit()"
          alt="back-btn"
        />

        <img
          src="assets/img/icons/modal-close-icon.svg"
          class="transaction-status__submitted__close"
          (click)="closeEmitter.emit()"
        />
        <div class="transaction-status__submitted__icon"></div>
        <h3 class="transaction-status__submitted__title">
          <span *ngIf="!title">{{ 'TRANSACTION_STATUS.SUBMITTED_TITLE' | translate }}</span>
          <span *ngIf="title">{{ title | translate }}</span>
        </h3>
        <a
          *ngIf="!description && transactionResponse && transactionResponse.transactionHash && link"
          href="{{ link }}"
          target="_blank"
        >
          <p class="transaction-status__submitted__description">
            {{ 'TRANSACTION_STATUS.DESCRIPTION' | translate }}
          </p>
        </a>
        <p *ngIf="description" class="transaction-status__submitted__description">
          <span>{{ description | translate }}</span>
        </p>
      </div>
      <div class="transaction-status__error" *ngSwitchCase="transactionStatus.ERROR">
        <img
          src="/assets/img/icons/back-btn.svg"
          *ngIf="showBack"
          class="transaction-status__error__back"
          (click)="backEmitter.emit()"
          alt="back-btn"
        />
        <img
          src="assets/img/icons/modal-close-icon.svg"
          class="transaction-status__error__close"
          (click)="closeEmitter.emit()"
        />
        <div class="transaction-status__error__icon"></div>
        <h3 class="transaction-status__error__title">
          <span *ngIf="!title">{{ 'TRANSACTION_STATUS.ERROR_TITLE' | translate }}</span>
          <span *ngIf="title">{{ title | translate }}</span>
        </h3>
        <a
          *ngIf="!description && transactionResponse && transactionResponse.transactionHash && link"
          href="{{ link }}"
          target="_blank"
        >
          <p class="transaction-status__error__description">
            {{ 'TRANSACTION_STATUS.DESCRIPTION' | translate }}
          </p>
        </a>
        <p *ngIf="description" class="transaction-status__error__description">
          <span>{{ description | translate }}</span>
        </p>
      </div>
    </div>
  </div>
</ng-container>
