import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'image' })
export class ImagePipe implements PipeTransform {
  public async transform(url: string, defaultImgUrl: string): Promise<string> {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      if (img.complete) {
        resolve(url);
      } else {
        img.onload = () => resolve(url);
        img.onerror = () => resolve(defaultImgUrl);
      }
    });
  }
}
