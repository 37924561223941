import { observable } from 'mobx-angular';

import { VestingInfoCardModel } from './vesting-info-card.model';

export enum VestingFilterType {
  MAIN = 'main',
  FILTERED = 'filtered',
}

export class VestingInfoCardListModel {
  @observable enabled: boolean = null;
  @observable main: VestingInfoCardModel[] = null;
  @observable filtered: VestingInfoCardModel[] = null;

  constructor(data: VestingInfoCardListModel) {
    Object.assign(this, data);
  }
}
