import { StatsFilterCountriesModel } from '@stats/models/stats-filter-countries/stats-filter-countries.model';

export function getStatsCountriesMockData(filter: StatsFilterCountriesModel) {
  if (filter.type === 'ContributionsCount') {
    return [
      {
        id: 'UKR',
        country: 'Ukraine',
        count: '30000',
      },
      {
        id: 'DEU',
        country: 'Germany',
        count: '70000',
      },
    ];
  } else if (filter.type === 'ContributionsAmount') {
    return [
      {
        id: 'UKR',
        country: 'Ukraine',
        count: '50000',
      },
      {
        id: 'DEU',
        country: 'Germany',
        count: '80000',
      },
    ];
  } else if (filter.type === 'AvgContributionAmountPerContributor') {
    return [
      {
        id: 'UKR',
        country: 'Ukraine',
        count: '10000',
      },
      {
        id: 'DEU',
        country: 'Germany',
        count: '10000',
      },
    ];
  } else if (filter.type === 'AvgContributionCurrencyAmountPerContributor') {
    return [
      {
        id: 'UKR',
        country: 'Ukraine',
        count: '40000',
      },
      {
        id: 'DEU',
        country: 'Germany',
        count: '30000',
      },
    ];
  } else if (filter.type === 'ContributorsCount') {
    return [
      {
        id: 'UKR',
        country: 'Ukraine',
        count: '90000',
      },
      {
        id: 'DEU',
        country: 'Germany',
        count: '80000',
      },
    ];
  } else {
    return [
      {
        id: 'UKR',
        country: 'Ukraine',
        count: '20000',
      },
      {
        id: 'DEU',
        country: 'Germany',
        count: '30000',
      },
    ];
  }
}
