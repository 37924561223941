import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiUrlsDictionary } from '@tokengear-common/modules/api';

import { environment } from '@environments/environment';

import { getFaqsMockData, getFaqMockData } from './faqs.mock-data';

@Injectable({
  providedIn: 'root',
})
export class FaqsInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      environment.mock &&
      environment.interceptors.FaqsInterceptor &&
      request.url.endsWith(ApiUrlsDictionary.faq()) &&
      request.method === 'GET'
    ) {
      const response = new HttpResponse({
        status: 200,
        body: getFaqsMockData(),
      });

      return of(response);
    }

    if (
      environment.mock &&
      environment.interceptors.FaqsInterceptor &&
      request.url.includes(ApiUrlsDictionary.faq()) &&
      (request.method === 'PUT' || request.method === 'DELETE')
    ) {
      const response = new HttpResponse({
        status: 204,
      });

      return of(response);
    }

    if (
      environment.mock &&
      environment.interceptors.FaqsInterceptor &&
      request.url.includes(ApiUrlsDictionary.faq()) &&
      request.method === 'POST'
    ) {
      const response = new HttpResponse({
        status: 201,
        body: getFaqMockData(),
      });

      return of(response);
    }

    return next.handle(request);
  }
}
