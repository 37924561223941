import { Component, EventEmitter, Input, Output } from '@angular/core';
import { computed } from 'mobx-angular';

import { TransactionStatusService } from '@tokengear-common/services';

import { TransactionStatus } from '@app/integrations/dictionaries/web3.dictionary';
import { TransactionResponseModel } from '@app/integrations/models/transaction-response.model';

@Component({
  selector: 'app-transaction-status',
  templateUrl: './transaction-status.component.html',
  styleUrls: ['./transaction-status.component.scss'],
})
export class TransactionStatusComponent {
  @Input() status: TransactionStatus;
  @Input() showBack = true;
  @Input() transactionResponse: TransactionResponseModel;
  @Input() title: string;
  @Input() description: string;

  @Output() readonly closeEmitter = new EventEmitter<boolean>();
  @Output() readonly backEmitter = new EventEmitter<boolean>();

  public readonly transactionStatus = TransactionStatus;

  constructor(private transactionStatusService: TransactionStatusService) {}

  @computed get link(): string | null {
    if (this.transactionResponse && this.transactionResponse.transactionHash) {
      return this.transactionStatusService.getTxUrl(this.transactionResponse.transactionHash);
    }

    return null;
  }
}
