import { UsersFilterModel } from '@users/models/users-filter/users-filter.model';

export function getUsersMockData(filter: UsersFilterModel) {
  const mock = {
    total: 100,
    data: [
      {
        email: 'test@gmail.com',
        id: '121',
        isEmailConfirmed: true,
        params: {
          apartment: 'test',
          birthday: '01.01.1990',
          city: 'test',
          country: 'test',
          cryptoAddress: '3454354354545',
          firstName: 'test',
          gender: 'test',
          lastName: 'test',
          middleName: 'test',
          phone: '+6456546546',
          state: 'test',
          street: 'test',
          zipCode: '4534545',
        },
        tenant: {
          address: 'test',
          balance: {
            USD: '1000',
            ETH: '100000',
            LTC: '43443',
          },
          bonusReceivedStatus: true,
          kycTxId: '4535435',
          privateSaleApproved: true,
          proofOfIncomeStatus: 'PENDING',
          referralClicksCount: 0,
          referralId: '4545',
          referralToken: '5453',
          role: 'Admin',
          status: 'CONFIRMED',
          statusDescription: 'test',
          tenantId: 'test',
        },
      },
      {
        email: 'test@gmail.com',
        id: '121',
        isEmailConfirmed: true,
        params: {
          apartment: 'test',
          birthday: '01.01.1989',
          city: 'test',
          country: 'test',
          cryptoAddress: '3454354354545',
          firstName: 'test',
          gender: 'test',
          lastName: 'test',
          middleName: 'test',
          phone: '+6456546546',
          state: 'test',
          street: 'test',
          zipCode: '4534545',
        },
        tenant: {
          address: 'test',
          balance: {
            USD: '1000',
            ETH: '100000',
            LTC: '43443',
          },
          bonusReceivedStatus: true,
          kycTxId: '4535435',
          privateSaleApproved: true,
          proofOfIncomeStatus: 'PENDING',
          referralClicksCount: 0,
          referralId: '4545',
          referralToken: '5453',
          role: 'Admin',
          status: 'CONFIRMED',
          statusDescription: 'test',
          tenantId: 'test',
        },
      },
      {
        email: 'test@gmail.com',
        id: '121',
        isEmailConfirmed: true,
        params: {
          apartment: 'test',
          birthday: '01.01.1989',
          city: 'test',
          country: 'test',
          cryptoAddress: '3454354354545',
          firstName: 'test',
          gender: 'test',
          lastName: 'test',
          middleName: 'test',
          phone: '+6456546546',
          state: 'test',
          street: 'test',
          zipCode: '4534545',
        },
        tenant: {
          address: 'test',
          balance: {
            USD: '1000',
            ETH: '100000',
            LTC: '43443',
          },
          bonusReceivedStatus: true,
          kycTxId: '4535435',
          privateSaleApproved: true,
          proofOfIncomeStatus: 'PENDING',
          referralClicksCount: 0,
          referralId: '4545',
          referralToken: '5453',
          role: 'Admin',
          status: 'CONFIRMED',
          statusDescription: 'test',
          tenantId: 'test',
        },
      },
      {
        email: 'test@gmail.com',
        id: '121',
        isEmailConfirmed: true,
        params: {
          apartment: 'test',
          birthday: '01.01.1989',
          city: 'test',
          country: 'test',
          cryptoAddress: '3454354354545',
          firstName: 'test',
          gender: 'test',
          lastName: 'test',
          middleName: 'test',
          phone: '+6456546546',
          state: 'test',
          street: 'test',
          zipCode: '4534545',
        },
        tenant: {
          address: 'test',
          balance: {
            USD: '1000',
            ETH: '100000',
            LTC: '43443',
          },
          bonusReceivedStatus: true,
          kycTxId: '4535435',
          privateSaleApproved: true,
          proofOfIncomeStatus: 'PENDING',
          referralClicksCount: 0,
          referralId: '4545',
          referralToken: '5453',
          role: 'Admin',
          status: 'CONFIRMED',
          statusDescription: 'test',
          tenantId: 'test',
        },
      },
      {
        email: 'test@gmail.com',
        id: '121',
        isEmailConfirmed: true,
        params: {
          apartment: 'test',
          birthday: '01.01.1989',
          city: 'test',
          country: 'test',
          cryptoAddress: '3454354354545',
          firstName: 'test',
          gender: 'test',
          lastName: 'test',
          middleName: 'test',
          phone: '+6456546546',
          state: 'test',
          street: 'test',
          zipCode: '4534545',
        },
        tenant: {
          address: 'test',
          balance: {
            USD: '1000',
            ETH: '100000',
            LTC: '43443',
          },
          bonusReceivedStatus: true,
          kycTxId: '4535435',
          privateSaleApproved: true,
          proofOfIncomeStatus: 'PENDING',
          referralClicksCount: 0,
          referralId: '4545',
          referralToken: '5453',
          role: 'Admin',
          status: 'CONFIRMED',
          statusDescription: 'test',
          tenantId: 'test',
        },
      },
      {
        email: 'test@gmail.com',
        id: '121',
        isEmailConfirmed: true,
        params: {
          apartment: 'test',
          birthday: '01.01.1989',
          city: 'test',
          country: 'test',
          cryptoAddress: '3454354354545',
          firstName: 'test',
          gender: 'test',
          lastName: 'test',
          middleName: 'test',
          phone: '+6456546546',
          state: 'test',
          street: 'test',
          zipCode: '4534545',
        },
        tenant: {
          address: 'test',
          balance: {
            USD: '1000',
            ETH: '100000',
            LTC: '43443',
          },
          bonusReceivedStatus: true,
          kycTxId: '4535435',
          privateSaleApproved: true,
          proofOfIncomeStatus: 'PENDING',
          referralClicksCount: 0,
          referralId: '4545',
          referralToken: '5453',
          role: 'Admin',
          status: 'CONFIRMED',
          statusDescription: 'test',
          tenantId: 'test',
        },
      },
      {
        email: 'test@gmail.com',
        id: '121',
        isEmailConfirmed: true,
        params: {
          apartment: 'test',
          birthday: '01.01.1989',
          city: 'test',
          country: 'test',
          cryptoAddress: '3454354354545',
          firstName: 'test',
          gender: 'test',
          lastName: 'test',
          middleName: 'test',
          phone: '+6456546546',
          state: 'test',
          street: 'test',
          zipCode: '4534545',
        },
        tenant: {
          address: 'test',
          balance: {
            USD: '1000',
            ETH: '100000',
            LTC: '43443',
          },
          bonusReceivedStatus: true,
          kycTxId: '4535435',
          privateSaleApproved: true,
          proofOfIncomeStatus: 'PENDING',
          referralClicksCount: 0,
          referralId: '4545',
          referralToken: '5453',
          role: 'Admin',
          status: 'CONFIRMED',
          statusDescription: 'test',
          tenantId: 'test',
        },
      },
      {
        email: 'test@gmail.com',
        id: '121',
        isEmailConfirmed: true,
        params: {
          apartment: 'test',
          birthday: '01.01.1989',
          city: 'test',
          country: 'test',
          cryptoAddress: '3454354354545',
          firstName: 'test',
          gender: 'test',
          lastName: 'test',
          middleName: 'test',
          phone: '+6456546546',
          state: 'test',
          street: 'test',
          zipCode: '4534545',
        },
        tenant: {
          address: 'test',
          balance: {
            USD: '1000',
            ETH: '100000',
            LTC: '43443',
          },
          bonusReceivedStatus: true,
          kycTxId: '4535435',
          privateSaleApproved: true,
          proofOfIncomeStatus: 'PENDING',
          referralClicksCount: 0,
          referralId: '4545',
          referralToken: '5453',
          role: 'Admin',
          status: 'CONFIRMED',
          statusDescription: 'test',
          tenantId: 'test',
        },
      },
      {
        email: 'test@gmail.com',
        id: '121',
        isEmailConfirmed: true,
        params: {
          apartment: 'test',
          birthday: '01.01.1989',
          city: 'test',
          country: 'test',
          cryptoAddress: '3454354354545',
          firstName: 'test',
          gender: 'test',
          lastName: 'test',
          middleName: 'test',
          phone: '+6456546546',
          state: 'test',
          street: 'test',
          zipCode: '4534545',
        },
        tenant: {
          address: 'test',
          balance: {
            USD: '1000',
            ETH: '100000',
            LTC: '43443',
          },
          bonusReceivedStatus: true,
          kycTxId: '4535435',
          privateSaleApproved: true,
          proofOfIncomeStatus: 'PENDING',
          referralClicksCount: 0,
          referralId: '4545',
          referralToken: '5453',
          role: 'Admin',
          status: 'CONFIRMED',
          statusDescription: 'test',
          tenantId: 'test',
        },
      },
      {
        email: 'test@gmail.com',
        id: '121',
        isEmailConfirmed: true,
        params: {
          apartment: 'test',
          birthday: '01.01.1989',
          city: 'test',
          country: 'test',
          cryptoAddress: '3454354354545',
          firstName: 'test',
          gender: 'test',
          lastName: 'test',
          middleName: 'test',
          phone: '+6456546546',
          state: 'test',
          street: 'test',
          zipCode: '4534545',
        },
        tenant: {
          address: 'test',
          balance: {
            USD: '1000',
            ETH: '100000',
            LTC: '43443',
          },
          bonusReceivedStatus: true,
          kycTxId: '4535435',
          privateSaleApproved: true,
          proofOfIncomeStatus: 'PENDING',
          referralClicksCount: 0,
          referralId: '4545',
          referralToken: '5453',
          role: 'Admin',
          status: 'CONFIRMED',
          statusDescription: 'test',
          tenantId: 'test',
        },
      },
      {
        email: 'test@gmail.com',
        id: '121',
        isEmailConfirmed: true,
        params: {
          apartment: 'test',
          birthday: '01.01.1989',
          city: 'test',
          country: 'test',
          cryptoAddress: '3454354354545',
          firstName: 'test',
          gender: 'test',
          lastName: 'test',
          middleName: 'test',
          phone: '+6456546546',
          state: 'test',
          street: 'test',
          zipCode: '4534545',
        },
        tenant: {
          address: 'test',
          balance: {
            USD: '1000',
            ETH: '100000',
            LTC: '43443',
          },
          bonusReceivedStatus: true,
          kycTxId: '4535435',
          privateSaleApproved: true,
          proofOfIncomeStatus: 'PENDING',
          referralClicksCount: 0,
          referralId: '4545',
          referralToken: '5453',
          role: 'Admin',
          status: 'CONFIRMED',
          statusDescription: 'test',
          tenantId: 'test',
        },
      },
      {
        email: 'test@gmail.com',
        id: '121',
        isEmailConfirmed: true,
        params: {
          apartment: 'test',
          birthday: '01.01.1989',
          city: 'test',
          country: 'test',
          cryptoAddress: '3454354354545',
          firstName: 'test',
          gender: 'test',
          lastName: 'test',
          middleName: 'test',
          phone: '+6456546546',
          state: 'test',
          street: 'test',
          zipCode: '4534545',
        },
        tenant: {
          address: 'test',
          balance: {
            USD: '1000',
            ETH: '100000',
            LTC: '43443',
          },
          bonusReceivedStatus: true,
          kycTxId: '4535435',
          privateSaleApproved: true,
          proofOfIncomeStatus: 'PENDING',
          referralClicksCount: 0,
          referralId: '4545',
          referralToken: '5453',
          role: 'Admin',
          status: 'CONFIRMED',
          statusDescription: 'test',
          tenantId: 'test',
        },
      },
      {
        email: 'test@gmail.com',
        id: '121',
        isEmailConfirmed: true,
        params: {
          apartment: 'test',
          birthday: '01.01.1989',
          city: 'test',
          country: 'test',
          cryptoAddress: '3454354354545',
          firstName: 'test',
          gender: 'test',
          lastName: 'test',
          middleName: 'test',
          phone: '+6456546546',
          state: 'test',
          street: 'test',
          zipCode: '4534545',
        },
        tenant: {
          address: 'test',
          balance: {
            USD: '1000',
            ETH: '100000',
            LTC: '43443',
          },
          bonusReceivedStatus: true,
          kycTxId: '4535435',
          privateSaleApproved: true,
          proofOfIncomeStatus: 'PENDING',
          referralClicksCount: 0,
          referralId: '4545',
          referralToken: '5453',
          role: 'Admin',
          status: 'CONFIRMED',
          statusDescription: 'test',
          tenantId: 'test',
        },
      },
    ],
  };

  if (filter.search != null && filter.search !== '') {
    const data = mock.data.filter((item) => item.params.firstName.includes(filter.search));
    return {
      total: 50,
      data: data.length ? data : null,
    };
  }

  if (filter.proofOfIncomeFilter != null) {
    const data = mock.data.filter((item) => item.tenant.proofOfIncomeStatus === filter.proofOfIncomeFilter);
    return {
      total: 50,
      data: data.length ? data : null,
    };
  }

  if (filter.statusFilter != null) {
    const data = mock.data.filter((item) => item.tenant.status === filter.statusFilter);
    return {
      total: 50,
      data: data.length ? data : null,
    };
  }

  return mock;
}
