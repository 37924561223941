import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-save-settings',
  templateUrl: './save-settings.component.html',
  styleUrls: ['./save-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveSettingsComponent implements OnInit {
  @Output()
  public readonly saveSettings = new EventEmitter();

  @Input()
  public isValid: boolean;

  constructor() {
    this.isValid = false;
  }

  public ngOnInit() {}

  public onSaveSettings() {
    this.saveSettings.emit();
  }
}
