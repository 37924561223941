import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { action, computed } from 'mobx-angular';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { Destroyable } from '@tokengear-common/base';
import { NotifyEvent, NotifyHelper } from '@tokengear-common/helpers';
import { SettingsService } from '@tokengear-common/modules/settings';

import { AppService } from '@app/app.service';
import { UserRolesDictionary } from '@domain-modules/users/dictionaries/user-roles.dictionary';
import { UsersService } from '@domain-modules/users/services/users/users.service';
import { MenuService } from '@pages/user/services/menu/menu.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent extends Destroyable implements OnInit {
  @Output() readonly headerHeight = new EventEmitter<number>();

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map((result) => result.matches));

  @ViewChild('drawer') public drawer: MatSidenav;
  public items = [
    { value: '0', viewValue: 'Eng' },
    { value: '1', viewValue: 'Ua' },
    { value: '2', viewValue: 'Rus' },
  ];

  @HostListener('window:resize')
  onResize(): void {
    this.settingsService.setInnerWidth(window.innerWidth);
    this.cdr.markForCheck();
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    private settingsService: SettingsService,
    private menuService: MenuService,
    private usersService: UsersService,
    private appService: AppService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.settingsService.setInnerWidth(window.innerWidth);
    this.isHandset$.pipe(takeUntil(this.unsubscribe$)).subscribe((isMobile) => {
      if (isMobile && this.settingsService.menuStatus) {
        this.menuService.disableBodyScroll();
      } else {
        this.menuService.enableBodyScroll();
      }
      if (this.isAdmin) {
        this.settingsService.menuStatus = !isMobile;
      }
    });
    // Event listening for closing mobile menu.
    this.subscriptions.push(
      NotifyHelper.on(NotifyEvent.ToggleSidebarMenu, () => {
        this.menuService.enableBodyScroll();
        this.cdr.detectChanges();
      }),
    );
  }

  @action updateMenuStatus(status: boolean): void {
    const newStatus = status === this.drawer.opened ? !status : status;

    this.settingsService.menuStatus = newStatus && Math.random();
    this.menuService.disableBodyScroll();
  }

  @computed get menuStatus(): boolean | number {
    return this.settingsService.menuStatus;
  }

  @computed get isAdmin(): boolean {
    if (!this.usersService.user) {
      return false;
    }

    return this.usersService.user.tenant.role === UserRolesDictionary.Manager;
  }

  @computed get isEnabledSubHeader(): boolean {
    return (
      this.settingsService.headerConfig &&
      this.settingsService.headerConfig.settings.enabled &&
      this.settingsService.headerConfig.settings.subHeader.enabled
    );
  }
}
