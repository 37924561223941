import { observable } from 'mobx-angular';

export class DollarTokenConfigModel {
  @observable enabled: boolean;
  @observable address: string;
  @observable symbol: string;
  @observable btcAddress: string;

  constructor(data: DollarTokenConfigModel) {
    Object.assign(this, data);
  }
}
