import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable, action, computed } from 'mobx-angular';
import { merge, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiUrlsDictionary } from '@tokengear-common/modules/api';
import { SettingsService } from '@tokengear-common/modules/settings';

import { KycIdentifyUrlModel } from '@domain-modules/kyc/models/kyc-identify-url/kyc-identify-url.model';
import { KycSignedUrlsParamsModel } from '@domain-modules/kyc/models/kyc-signed-urls-params/kyc-signed-urls-params.model';
import { KycSignedUrlsModel } from '@domain-modules/kyc/models/kyc-signed-urls/kyc-signed-urls.model';
import { PaymentAppModel } from '@domain-modules/payment/models/payment-app.model';
import { ProofOfIncomeStatusChangeDictionary } from '@domain-modules/users/dictionaries/proof-of-income-status-change.dictionary';
import { UserRolesDictionary } from '@domain-modules/users/dictionaries/user-roles.dictionary';
import { userStatusChangeDictionary } from '@domain-modules/users/dictionaries/user-status-change.dictionary';
import { userStatusFilterDictionary } from '@domain-modules/users/dictionaries/user-status-filter.dictionary';
import { UserCountModel } from '@domain-modules/users/models/user-count/user-count.model';
import { UserIdHistoryParamsModel } from '@domain-modules/users/models/user-id-history-params/user-id-history-params.model';
import { UserIdHistoryResponseModel } from '@domain-modules/users/models/user-id-history-response/user-id-history-response.model';
import { UserKycParamsModel } from '@domain-modules/users/models/user-kyc-params/user-kyc-params.model';
import { UserNewPasswordModel } from '@domain-modules/users/models/user-new-password/user-new-password.model';
import { UserModel } from '@domain-modules/users/models/user/user.model';
import { UsersFilterModel } from '@domain-modules/users/models/users-filter/users-filter.model';
import { UsersModel } from '@domain-modules/users/models/users/users.model';
import { ContributionAvailableModel } from '@users/models/contributions/contribution-available.model';
import { ContributionSignatureModel } from '@users/models/contributions/contribution-signature.model';
import { TwoFactoryAuthModel } from '@users/models/two-factory-auth/two-factory-auth.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  @observable user: UserModel | null = null;

  constructor(private http: HttpClient, private settingsService: SettingsService) {}
  @action updateCurrentUser(user: UserModel) {
    this.user = user;
  }

  @action getMe(): Observable<UserModel> {
    const url = ApiUrlsDictionary.me();

    return this.http.get<UserModel>(url).pipe(
      map((user: UserModel) => new UserModel(user)),
      tap((user) => {
        this.updateCurrentUser(user);
        this.settingsService.getAllSettings().subscribe();
      }),
    );
  }

  @action getUsers(filter: UsersFilterModel): Observable<UsersModel> {
    return this.http
      .get<UsersModel>(ApiUrlsDictionary.users(), { params: <any>filter })
      .pipe(map((users: UsersModel) => new UsersModel(users)));
  }

  @action getUser(id: string): Observable<UserModel> {
    return this.http
      .get<UserModel>(`${ApiUrlsDictionary.users(id)}`)
      .pipe(map((user: UserModel) => new UserModel(user)));
  }

  @action getUserPaymentApps(id: string): Observable<PaymentAppModel[]> {
    return this.http
      .get<PaymentAppModel[]>(`${ApiUrlsDictionary.paymentApp()}`, { params: { userId: id } })
      .pipe(map((response: PaymentAppModel[]) => response.map((res) => new PaymentAppModel(res))));
  }

  @action uploadFileToS3(url, file): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
    });
    const req = new HttpRequest('PUT', url, file, {
      headers,
    });

    return this.http.request(req);
  }

  @action getKeysForFiles(params: KycSignedUrlsParamsModel): Observable<KycSignedUrlsModel> {
    return this.http
      .get<any>(`${ApiUrlsDictionary.kycSygnedUrls()}`, {
        params,
      } as any)
      .pipe(map((data: any) => new KycSignedUrlsModel(data)));
  }

  @action updateKycManualFormParams(id: string, kycParams: UserKycParamsModel): Observable<UserModel> {
    return this.http.put<UserModel>(`${ApiUrlsDictionary.users(id.toString())}`, kycParams).pipe(
      map((user: UserModel) => new UserModel(user)),
      tap((user: UserModel) => {
        this.user = user;
      }),
    );
  }

  @action updateKycIdentifyDirectFormParams(id: string, kycParams: UserKycParamsModel): Observable<UserModel> {
    return this.http.put<UserModel>(`${ApiUrlsDictionary.users(id.toString())}`, kycParams).pipe(
      map((user: UserModel) => new UserModel(user)),
      tap((user: UserModel) => {
        this.user = user;
      }),
    );
  }
  // redirectUrl
  @action updateKycIdentifyFormParams(id: string, kycParams: UserKycParamsModel): Observable<KycIdentifyUrlModel> {
    return this.http
      .put<KycIdentifyUrlModel>(`${ApiUrlsDictionary.users(id.toString())}`, kycParams)
      .pipe(map((url: KycIdentifyUrlModel) => new KycIdentifyUrlModel(url)));
  }

  @action updateKycStatus(params: { id: string; status: string }): Observable<UserModel> {
    return this.http
      .put<UserModel>(`${ApiUrlsDictionary.usersKycStatus(params.id)}`, { status: params.status })
      .pipe(map((user: UserModel) => new UserModel(user)));
  }

  @action getSalesContributionsAddresses(userId: string, networkId: number) {
    return merge(
      this.http.get(ApiUrlsDictionary.userSalesPoolsList(userId), {
        params: {
          addressOnly: true,
          networkId,
        },
      }),
      this.http.get(ApiUrlsDictionary.allStakedContracts()),
    );
  }

  @action sendKycEmailVerificationCode(email: string): Observable<any> {
    return this.http.post(ApiUrlsDictionary.emailVerfication, { email, type: 'kyc' });
  }

  @action checkKycEmailVerificationCode(email: string, code: string): Observable<any> {
    return this.http.get(ApiUrlsDictionary.checkEmailCode, { params: { email, code } });
  }

  @action updateProofOfIncomeStatus(params: { id: string; status: string }): Observable<UserModel> {
    return this.http
      .put<UserModel>(`${ApiUrlsDictionary.usersProofOfIncomeStatus(params.id)}`, { status: params.status })
      .pipe(map((user: UserModel) => new UserModel(user)));
  }

  @action registration(user): Observable<UserModel> {
    return this.http.post<UserModel>(ApiUrlsDictionary.users(), user).pipe(
      map((value: UserModel) => new UserModel(value)),
      tap((_value: UserModel) => {
        // this.user = value;
      }),
    );
  }

  @action getResetToken(token: string): Observable<any> {
    return this.http.get(ApiUrlsDictionary.resetToken(token));
  }

  @action resetPasswordRequest(email: string): Observable<any> {
    return this.http.post(ApiUrlsDictionary.resetPasswordRequest(), { email });
  }

  @action createNewPassword(newPassword: UserNewPasswordModel): Observable<any> {
    return this.http.post(ApiUrlsDictionary.newPassword(), newPassword);
  }

  @action confirmEmail(token: string): Observable<any> {
    return this.http.post(ApiUrlsDictionary.confirmEmail(), { token });
  }

  @action getUserCount(): Observable<UserCountModel> {
    return this.http
      .get<UserCountModel>(ApiUrlsDictionary.userCount())
      .pipe(map((userCount: UserCountModel) => new UserCountModel(userCount)));
  }

  @action getUserIdHistory(userId, params: UserIdHistoryParamsModel = {}): Observable<UserIdHistoryResponseModel> {
    return this.http
      .get<any>(ApiUrlsDictionary.userIdHistory(userId), {
        params,
      } as any)
      .pipe(map((data: any) => new UserIdHistoryResponseModel(data)));
  }

  @action getFreeUserId(): Observable<string> {
    return this.http.get<string>(`${ApiUrlsDictionary.nextFreeRunningNumber()}`);
  }

  @action updateUserId(params: any): Observable<UserModel> {
    return this.http
      .put<UserModel>(ApiUrlsDictionary.customUserId(), params)
      .pipe(map((users: UserModel) => new UserModel(users)));
  }

  @action updateUserReferralParentByCustomId(userId: string, parentId: string): Observable<UsersModel> {
    return this.http
      .put<UsersModel>(ApiUrlsDictionary.userReferralParentByCustomId(userId), {
        parentCustomId: parentId,
      })
      .pipe(map((users: UsersModel) => new UsersModel(users)));
  }

  @action updateUserReferralLevel(userId: string, rank: string): Observable<UsersModel> {
    return this.http
      .put<UsersModel>(ApiUrlsDictionary.userReferralLevel(userId), {
        levelId: rank,
      })
      .pipe(map((users: UsersModel) => new UsersModel(users)));
  }

  @action removeMeUser() {
    this.user = null;
  }

  @computed get userStatusFilterDictionaryList(): string[] {
    return Object.values(userStatusFilterDictionary as any);
  }

  @computed get userStatusChangeDictionaryList(): string[] {
    return Object.values(userStatusChangeDictionary as any);
  }

  @computed get proofOfIncomeStatusDictionaryList(): string[] {
    return Object.values(ProofOfIncomeStatusChangeDictionary as any);
  }

  @computed get isClient() {
    if (this.user == null) {
      return false;
    }

    return (
      this.user.tenant.role === UserRolesDictionary.Client || this.user.tenant.role === UserRolesDictionary.Developer
    );
  }

  @action updateUserImage(userId: string, data: { img: string }): Observable<UserModel> {
    const url = ApiUrlsDictionary.userImage(userId);

    return this.http.put<UserModel>(url, data).pipe(map((user: UserModel) => new UserModel(user)));
  }

  @action updateUserSlug(userId: string, data: { slug: string }): Observable<UserModel> {
    const url = ApiUrlsDictionary.userSlug(userId);

    return this.http.put<UserModel>(url, data).pipe(map((user: UserModel) => new UserModel(user)));
  }

  @action changeEmailRequest(userId: string, data: { email: string; password: string }): Observable<any> {
    const url = ApiUrlsDictionary.changeEmailRequest(userId);

    return this.http.post(url, data);
  }
  @action changeEmailConfirm(token: string): Observable<any> {
    return this.http.put(ApiUrlsDictionary.changeEmailConfirm(), { token });
  }

  @action updateUserPassword(userId: string, data: { currentPassword: string; password: string }): Observable<any> {
    const url = ApiUrlsDictionary.userPassword(userId);

    return this.http.put(url, data);
  }

  @action enableTwoFactoryAuth(userId: string, data: { password: string }): Observable<TwoFactoryAuthModel> {
    const url = ApiUrlsDictionary.enableTwoFactoryAuth(userId);

    return this.http
      .put<TwoFactoryAuthModel>(url, data)
      .pipe(map((res: TwoFactoryAuthModel) => new TwoFactoryAuthModel(res)));
  }
  @action disableTwoFactoryAuth(userId: string, data: { password: string }): Observable<any> {
    const url = ApiUrlsDictionary.disableTwoFactoryAuth(userId);

    return this.http.put(url, data);
  }
  @action verifyTwoFactoryAuth(userId: string, data: { token: string; secret: string }): Observable<any> {
    const url = ApiUrlsDictionary.verifyTwoFactoryAuth(userId);

    return this.http.put(url, data);
  }

  public getContributionSignature(data: {
    amount: string;
    chainId: number;
    token: string;
  }): Observable<ContributionSignatureModel> {
    return this.http
      .get(ApiUrlsDictionary.contributionsSignature(), { params: <any>data })
      .pipe(map((res: ContributionSignatureModel) => new ContributionSignatureModel(res)));
  }

  public contributionsAvailableAmount(): Observable<ContributionAvailableModel> {
    return this.http
      .get(ApiUrlsDictionary.contributionsAvailableAmount())
      .pipe(map((res: ContributionAvailableModel) => new ContributionAvailableModel(res)));
  }
}
