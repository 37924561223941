import { Component, Input } from '@angular/core';
import { computed } from 'mobx-angular';

import { SettingsService, SettingsTransactionsExplorerLinkModel } from '@tokengear-common/modules/settings';

@Component({
  selector: 'app-tx-hash',
  templateUrl: './tx-hash.component.html',
  styleUrls: ['./tx-hash.component.scss'],
})
export class TxHashComponent {
  @Input() txHash: string;

  constructor(private settingsService: SettingsService) {}

  @computed get scanLink(): SettingsTransactionsExplorerLinkModel {
    const defaultNetwork = this.settingsService.networksConfig?.settings?.allowedNetworks.find(
      (network) => network.isDefault,
    );

    return this.settingsService.transactionsExplorerLinks.settings.links.find(
      (link: SettingsTransactionsExplorerLinkModel) => link.networkId === defaultNetwork?.networkId,
    );
  }

  @computed get isLinkEnable(): boolean {
    return (
      this.settingsService.transactionsExplorerLinks.settings.enabled &&
      this.settingsService.transactionsExplorerLinks.settings.links.length !== 0
    );
  }
}
