import { StatsFilterModel } from '@stats/models/stats-filter/stats-filter.model';

export function getStatsTopInvestorsMockData(filter: StatsFilterModel) {
  if (filter.startDate == null && filter.endDate == null) {
    return [
      {
        userId: '1',
        last: '1200.50',
        total: '7000.50',
        user: {
          params: {
            firstName: 'Jaky',
            lastName: 'Robinson',
          },
        },
      },
      {
        userId: '2',
        last: '2300.30',
        total: '5000.30',
        user: {
          params: {
            firstName: 'Bob',
            lastName: 'Deny',
          },
        },
      },
    ];
  }

  return [
    {
      userId: '1',
      last: '1700.50',
      total: '3000.50',
      user: {
        params: {
          firstName: 'Roy',
          lastName: 'Bill',
        },
      },
    },
    {
      userId: '2',
      last: '1300.50',
      total: '8000.50',
      user: {
        params: {
          firstName: 'Ivan',
          lastName: 'Ivano',
        },
      },
    },
  ];
}
