import { observable } from 'mobx-angular';

import { StakingPoolsItemModel } from '@domain-modules/pools/models/staking-pools-item.model';

export class StakingPoolsModel {
  data: StakingPoolsItemModel[];
  @observable total: number;

  constructor(pools: StakingPoolsModel) {
    Object.assign(this, pools);

    if (pools.data != null) {
      this.data = pools.data.map((item) => new StakingPoolsItemModel(item));
    }
  }
}
