<div class="switch-btn-container">
  <div
    class="switch-btn-container__btn"
    [class.switch-btn-container__btn--active]="active === leftName"
    [class.theme-background]="active === leftName"
    (click)="changeActiveBtn(leftName)"
  >
    {{ leftName }}
  </div>
  <div
    class="switch-btn-container__btn"
    [class.switch-btn-container__btn--active]="active === rightName"
    [class.theme-background]="active === rightName"
    (click)="changeActiveBtn(rightName)"
  >
    {{ rightName }}
  </div>
</div>
