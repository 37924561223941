import { action, observable } from 'mobx-angular';

export class SettingsMilestoneModel {
  @observable public name: string = null;
  @observable public amount: string = null;

  constructor(params: SettingsMilestoneModel) {
    Object.assign(this, params);
  }

  @action public setName(value: string): void {
    this.name = value;
  }

  @action public setAmount(value: string): void {
    this.amount = value;
  }
}
