<div class="filter">
  <input id="filter-counties" class="filter__input" type="search" placeholder="Enter country" />
  <label for="filter-counties" class="filter__label">Search needed country for blocking that country for you ICO</label>
</div>
<div class="check-list">
  <mat-checkbox color="basic" class="check-list__item col-lg-3 col-md-4 col-sm-6 col-xs-12">
    <mat-icon class="check-list__icon" svgIcon="af"></mat-icon>
    <span class="check-list__name">Afghanistan (AF)</span>
  </mat-checkbox>
  <mat-checkbox color="basic" class="check-list__item col-lg-3 col-md-4 col-sm-6 col-xs-12">
    <mat-icon class="check-list__icon" svgIcon="ax"></mat-icon>
    <span class="check-list__name">Aland Islands (AX)</span>
  </mat-checkbox>
  <mat-checkbox color="basic" class="check-list__item col-lg-3 col-md-4 col-sm-6 col-xs-12">
    <mat-icon class="check-list__icon" svgIcon="al"></mat-icon>
    <span class="check-list__name">Albania (AL)</span>
  </mat-checkbox>
  <mat-checkbox color="basic" class="check-list__item col-lg-3 col-md-4 col-sm-6 col-xs-12">
    <mat-icon class="check-list__icon" svgIcon="af"></mat-icon>
    <span class="check-list__name">Afghanistan (AF)</span>
  </mat-checkbox>
  <mat-checkbox color="basic" class="check-list__item col-lg-3 col-md-4 col-sm-6 col-xs-12">
    <mat-icon class="check-list__icon" svgIcon="af"></mat-icon>
    <span class="check-list__name">Afghanistan (AF)</span>
  </mat-checkbox>
  <mat-checkbox color="basic" class="check-list__item col-lg-3 col-md-4 col-sm-6 col-xs-12">
    <mat-icon class="check-list__icon" svgIcon="ax"></mat-icon>
    <span class="check-list__name">Aland Islands (AX)</span>
  </mat-checkbox>
  <mat-checkbox color="basic" class="check-list__item col-lg-3 col-md-4 col-sm-6 col-xs-12">
    <mat-icon class="check-list__icon" svgIcon="al"></mat-icon>
    <span class="check-list__name">Albania (AL)</span>
  </mat-checkbox>
  <mat-checkbox color="basic" class="check-list__item col-lg-3 col-md-4 col-sm-6 col-xs-12">
    <mat-icon class="check-list__icon" svgIcon="af"></mat-icon>
    <span class="check-list__name">Afghanistan (AF)</span>
  </mat-checkbox>
</div>
