import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { computed } from 'mobx-angular';

import { NavigationService } from '@tokengear-common/modules/navigation';

import { userStatusDictionary } from '@domain-modules/users/dictionaries/user-status.dictionary';
import { UserService } from '@pages/user/services/user/user.service';

@Component({
  selector: 'app-start-kyc-verification',
  templateUrl: './start-kyc-verification.component.html',
  styleUrls: ['./start-kyc-verification.component.scss'],
})
export class StartKycVerificationComponent {
  public readonly statuses = userStatusDictionary;

  constructor(
    private navigationService: NavigationService,
    private translateService: TranslateService,
    private userService: UserService,
  ) {}

  public async startKyc(): Promise<boolean> {
    if (this.isClient) {
      return this.navigationService.navigateToUserKyc();
    }

    return this.navigationService.navigateToUserLogin();
  }

  @computed get isClient(): boolean {
    return this.userService.isClient;
  }

  @computed get btnText(): string {
    return this.isClient
      ? this.translateService.instant(`USER_DASHBOARD__KYC_STATUS__${this.userService.user.tenant.status}`)
      : this.translateService.instant('USER_HEADER__SIGN_IN');
  }

  @computed get classes(): string {
    if (this.isClient) {
      return `kyc-verification__btn--${this.userService.user.tenant.status.toLowerCase()} ${
        this.userService.user.tenant.status === userStatusDictionary.NotVerified ? 'theme-background' : ''
      }`;
    }

    return `theme-background`;
  }
}
