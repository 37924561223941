import { StatsFilterModel } from '@stats/models/stats-filter/stats-filter.model';

export function updateSettingsContributionsInvoice() {
  return {
    enabled: false,
    companyName: 'test',
    companyAddress: 'test',
    nameOfBank: 'test',
    bankAddress: 'test',
    nameOfAccount: 'test',
    accountNumber: 'test',
    wireRoutingNumber: 'test',
    swiftCode: 'HELLOO638TO',
  };
}

export function getSettingsContributionsInvoice() {
  return {
    serviceId: 'invoice-contributions',
    type: 'contributions',
    settings: {
      enabled: false,
      companyName: 'test',
      companyAddress: 'test',
      nameOfBank: 'test',
      bankAddress: 'test',
      nameOfAccount: 'test',
      accountNumber: 'test',
      wireRoutingNumber: 'test',
      swiftCode: 'HELLOO638TO',
    },
  };
}
