import { observable } from 'mobx-angular';

import { SettingsTransactionsExplorerLinkModel } from '../settings-transactions-explorer-link/settings-transactions-explorer-link.model';

export class SettingsTransactionsExplorerLinksModel {
  @observable enabled: boolean;
  @observable links: SettingsTransactionsExplorerLinkModel[];

  constructor(params: SettingsTransactionsExplorerLinksModel) {
    Object.assign(this, params);
  }
}
