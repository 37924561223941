<ng-container *mobxAutorun>
  <div appThemeClass *ngIf="showNotice && currentNetwork">
    <div class="backdrop-bg">
      <div class="error-network-card">
        <div class="network-error-header">
          <div class="network-error-header__icon-network">
            <i class="material-icons material-icons-outlined">help_outline</i>
          </div>
          <h1 class="network-error-header__title" *ngIf="showErrorNetworkMessage">You Must Change Network</h1>
          <h1 class="network-error-header__title" *ngIf="showNoProviderMessage">Install MetaMask for your browser</h1>
          <!--          <div class="network-error-header__icon-close" (click)="closeNotice()">-->
          <!--            <i class="material-icons material-icons-outlined">close</i>-->
          <!--          </div>-->
        </div>
        <div class="network-error-notice" *ngIf="showErrorNetworkMessage">
          <p class="network-error-notice__text">
            We've detected that you need to switch your wallet's network from
            <b>({{ networkId || 'Unknown' }})</b>
            to
            <b>{{ currentNetwork?.name }}</b>
            for this Dapp.
          </p>
          <p class="network-error-notice__info">
            *Some wallets may not support changing networks. If you can not change the network in your wallet you may
            consider switching to other wallets.
          </p>
          <button
            class="network-error-notice__btn"
            mat-button
            (click)="switchNetworkByParams(currentNetwork?.networkId)"
          >
            <img src="/assets/img/wallet-connect/wallet/metamask.svg" alt="metamask" />
            Change Network
          </button>
        </div>
        <div class="network-error-notice" *ngIf="showNoProviderMessage">
          <p class="network-error-notice__text">
            {{ projectName }} require Metamask for functioning, please install Metamask to continue.
          </p>
          <a [href]="METAMASK_LINK" target="_blank">
            <button class="network-error-notice__btn" mat-button>
              <img src="/assets/img/wallet-connect/wallet/metamask.svg" alt="metamask" />
              Install
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
