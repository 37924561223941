import { StatsFilterModel } from '@stats/models/stats-filter/stats-filter.model';

export function getStatsTokensMockData(filter: StatsFilterModel) {
  if (filter.startDate == null || filter.endDate == null) {
    return {
      tokensMaxSupply: '0',
      tokensSold: '200000000000000000000',
      tokensRemain: '300000000000000000000',
      tokensSoldToday: '1000000000000000000',
    };
  }

  return {
    tokensMaxSupply: '500000000000000000000',
    tokensSold: '200000000000000000000',
    tokensRemain: '300000000000000000000',
    tokensSoldToday: '1000000000000000000',
  };
}
