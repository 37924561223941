import { observable } from 'mobx-angular';

export class MenuItem {
  @observable routerLink: string;
  @observable hasRedirectLink: boolean;
  @observable iconPath: string;
  @observable name: string;
  @observable enabled: boolean;
  @observable permission: string[];

  constructor(value: MenuItem) {
    Object.assign(this, value);
  }
}
