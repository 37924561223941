import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { putInvoiceDeclineMockData } from '@mocks/invoices-decline/invoices-decline.mock-data';
import { Observable, of } from 'rxjs';

import { ApiUrlsDictionary } from '@tokengear-common/modules/api';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InvoicesDeclineInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      environment.mock &&
      environment.interceptors.InvoicesDeclineInterceptor &&
      request.url.endsWith(ApiUrlsDictionary.invoiceDecline()) &&
      request.method === 'PUT'
    ) {
      const params = request.params;
      const id = params.get('id');
      const response = new HttpResponse({
        status: 200,
        body: putInvoiceDeclineMockData({
          id,
        }),
      });

      return of(response);
    }

    return next.handle(request);
  }
}
