import { Injectable } from '@angular/core';
import { action, observable } from 'mobx-angular';

import { MembershipModel } from '@domain-modules/membership/dectionaries/membership.model';

// TODO(Max): maybe move this store on higher level as used in other modules
@Injectable({ providedIn: 'root' })
export class UserMembershipStore {
  @observable public isLoadingResults;
  @observable public memberships: MembershipModel[];
  @observable public userMembership: MembershipModel;

  @action public setIsLoadingResults(value: boolean): void {
    this.isLoadingResults = value;
  }

  @action public setMemberships(value: MembershipModel[]): void {
    this.memberships = value;
  }

  @action public setUserMembership(value: MembershipModel): void {
    this.userMembership = value;
  }
}
