import { Injectable } from '@angular/core';
import { computed } from 'mobx-angular';
import { forkJoin, Observable } from 'rxjs';

import { FormHelper } from '@tokengear-common/helpers';
import { CountriesDataModel, CountriesService, CountryModel } from '@tokengear-common/modules/domain/feature/countries';
import { SettingsService } from '@tokengear-common/modules/settings';

import { KycConfigModel } from '@domain-modules/kyc/models/kyc-config/kyc-config.model';
import { KycSignedUrlModel } from '@domain-modules/kyc/models/kyc-signed-url/kyc-signed-url.model';
import { userStatusDictionary } from '@domain-modules/users/dictionaries/user-status.dictionary';
import { UserService } from '@pages/user/services/user/user.service';
import { UserModel } from '@users/models/user/user.model';
import { UsersService } from '@users/services/users/users.service';

@Injectable()
export class KycService {
  constructor(
    private countriesService: CountriesService,
    private userService: UserService,
    private usersService: UsersService,
    private settingsService: SettingsService,
  ) {}

  public getCountries(): Observable<CountriesDataModel> {
    return this.countriesService.getCountries();
  }

  @computed public get countries(): CountryModel[] {
    if (
      this.settingsService.blacklistCountries.settings &&
      this.settingsService.blacklistCountries.settings.enabled &&
      this.countriesService.countries
    ) {
      const blacklist = this.settingsService.blacklistCountries.settings.countries || [];

      return this.countriesService.countries.items.filter((el) => !blacklist.find((val) => val === el.code));
    }

    return this.countriesService.countries ? this.countriesService.countries.items : [];
  }

  @computed public get user(): UserModel {
    return this.userService.user;
  }

  @computed public get kycNotVerified(): boolean {
    return this.user && this.user.tenant.status === userStatusDictionary.NotVerified;
  }

  @computed public get kycInProgress(): boolean {
    return (
      this.user &&
      (this.user.tenant.status === userStatusDictionary.InProgress ||
        this.user.tenant.status === userStatusDictionary.ManualChecking ||
        this.user.tenant.status === userStatusDictionary.Pending)
    );
  }

  @computed public get kycVerified(): boolean {
    return this.user && this.user.tenant.status === userStatusDictionary.Confirmed;
  }

  @computed public get kycFailed(): boolean {
    return (
      this.user &&
      (this.user.tenant.status === userStatusDictionary.Failed ||
        this.user.tenant.status === userStatusDictionary.Blocked)
    );
  }

  public get formIsDisabled(): boolean {
    if (this.userService?.user) {
      return !(
        this.userService.user.tenant.status === userStatusDictionary.NotVerified ||
        this.userService.user.tenant.status === userStatusDictionary.Failed
      );
    }

    return false;
  }

  public get kycRequiredConfig(): KycConfigModel {
    return this.settingsService.userKycConfig.settings;
  }

  public getDateLimit(y: number): Date {
    return FormHelper.getDateLimit(y);
  }

  public uploadFilesToS3(files, urlsData: KycSignedUrlModel[]): Observable<unknown> {
    return forkJoin(files.map((file, i) => this.usersService.uploadFileToS3(urlsData[i].url, file.value)));
  }

  public get kycStatusDescription(): string {
    if (this.kycNotVerified) {
      return 'Click to verify your KYC.';
    }
    if (this.kycInProgress) {
      return 'Your KYC information is under review.';
    }
    if (this.kycFailed) {
      return 'Please note: Your KYC verification has failed. Please resubmit your KYC information.';
    }
    if (this.kycVerified) {
      return 'You’re KYC verified!';
    }
  }
}
