import { Injectable } from '@angular/core';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import { AbiItem } from 'web3-utils';

import { isETH } from '@tokengear-common/helpers';

import { DEFAULT_DECIMALS, EMPTY_ADDRESS__SYMBOL, WEB3 } from '@app/integrations/dictionaries/web3.dictionary';
import { Web3Service } from '@app/integrations/services/web3/web3.service';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  constructor(private web3Service: Web3Service) {}

  public async getContract(abi: AbiItem[], address: string): Promise<Contract> {
    if (abi && address) {
      return new Promise((resolve) => {
        const web3: Web3 = window[WEB3];
        if (web3?.eth) {
          const contract = new web3.eth.Contract(abi, address);
          resolve(contract);
        }
      });
    }
  }

  public async getContractBalanceOf(abi: AbiItem[], contractAddress: string, account: string): Promise<string> {
    if (!isETH(contractAddress)) {
      return this.getContract(abi, contractAddress).then((contract: Contract) =>
        contract.methods.balanceOf(account).call(),
      );
    }

    return this.web3Service.getBalance(account);
  }

  public async getContractTokenName(abi: AbiItem[], address: string): Promise<string> {
    return this.getContract(abi, address).then((contract: Contract) => contract.methods.name().call());
  }

  public async getContractTokenSymbol(abi: AbiItem[], address: string): Promise<string> {
    return new Promise((resolve) => {
      if (!isETH(address)) {
        this.getContract(abi, address).then((contract: Contract) => {
          contract.methods
            .symbol()
            .call()
            .then((res) => {
              resolve(res);
            });
        });
      } else {
        resolve(EMPTY_ADDRESS__SYMBOL);
      }
    });
  }

  public async getContractTokenDecimals(abi: AbiItem[], address: string): Promise<number> {
    return new Promise((resolve) => {
      if (!isETH(address)) {
        this.getContract(abi, address).then((contract: Contract) => {
          contract.methods
            .decimals()
            .call()
            .then((res) => {
              resolve(Number(res));
            });
        });
      } else {
        resolve(DEFAULT_DECIMALS);
      }
    });
  }
}
