import { NgModule } from '@angular/core';

import { HttpHelperService } from './http-helper.service';
import { LookAndFeelService } from './look-and-feel.service';
import { SignatureService } from './signature.service';
import { ToastService } from './toast.service';
import { TransactionStatusService } from './transaction-status.service';

@NgModule({
  providers: [SignatureService, HttpHelperService, ToastService, LookAndFeelService, TransactionStatusService],
})
export class CoreServicesModule {}
