import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AdminLookAndFeelService } from '@tokengear-common/pages/admin-look-and-feel';

import {
  LookAndFeelContentType,
  LookAndFeelTextColor,
  SettingsLookAndFeelModel,
  SettingsService,
} from '../modules/settings';

@Injectable()
export class LookAndFeelService {
  constructor(
    private adminLookAndFeelService: AdminLookAndFeelService,
    private settingsService: SettingsService,
    private router: Router,
  ) {
    const settings = this.adminLookAndFeelService.lookAndFeelSettings.settings;
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (!val.urlAfterRedirects.indexOf('/admin')) {
          window.document.body.classList.remove('user-page');
          window.document.body.classList.add('admin-page');
          window.document.body.classList.remove(this.projectThemeClass(settings?.contentType));
        } else if (!val.urlAfterRedirects.indexOf('/user')) {
          window.document.body.classList.remove('admin-page');
          window.document.body.classList.add('user-page');
          window.document.body.classList.add(this.projectThemeClass(settings?.contentType));
        } else {
          window.document.body.classList.remove(
            'user-page',
            'admin-page',
            this.projectThemeClass(settings?.contentType),
          );
        }
      }
    });
  }

  public run(): void {
    this.setUserStyles(this.adminLookAndFeelService.lookAndFeelSettings.settings);
  }

  public setUserStyles(settings: SettingsLookAndFeelModel): void {
    if (settings) {
      this.addStyles(settings);
      window.document.body.classList.add(`menu-position-${settings.menuLayout.toLowerCase()}`);
      window.document.body.classList.add(`label-ton-${settings.labelColorTon.toLowerCase()}`);
      window.document.body.classList.add(`color-style-${settings.colorStyle.toLowerCase()}`);
    }
  }

  public addStyles(settings: SettingsLookAndFeelModel): void {
    // IMPORTANT! Spaces before styles line are not allowed, IE11 requirement!
    const css = `
    body:not(.admin-page) .mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: ${settings.buttonsColor} !important;
    border: 1px solid ${settings.buttonsColor} !important;
    color: ${settings.textColor === LookAndFeelTextColor.dark ? '#000' : '#fff'} !important;
    }
    body:not(.admin-page).mat-checkbox-ripple .mat-ripple-element,.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: ${settings.buttonsColor} !important;
    border: 1px solid ${settings.buttonsColor} !important;
    color: ${settings.textColor === LookAndFeelTextColor.dark ? '#000' : '#fff'} !important;
    }

    body:not(.admin-page) .mat-checkbox-ripple .mat-ripple-element {
    // opacity: 0.3!important;
    background-color: ${settings.buttonsColor} !important;
    }
    body:not(.admin-page) .plan-selected__item__selected {
    background-color: ${settings.buttonsColor} !important;
    border-color: ${settings.buttonsColor} !important;
    }
    body:not(.admin-page) .plan-plan-selected, .plan-selected__item {
    border-color: ${settings.buttonsColor} !important;
    }
    body:not(.admin-page) .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: ${settings.buttonsColor} !important;
    }
    body:not(.admin-page) mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    // opacity: 0.3!important;
    background-color: ${settings.buttonsColor} !important;
    }

    body:not(.admin-page) .theme-background {
    background-color: ${settings.buttonsColor} !important;
    color: ${settings.textColor === LookAndFeelTextColor.dark ? '#000' : '#fff'} !important;
    }
    body:not(.admin-page) .theme-reverse:not([disabled]) {
    background-color: ${settings.textColor === LookAndFeelTextColor.dark ? '#000' : '#fff'} !important;
    border: 1px solid ${settings.buttonsColor} !important;
    color: ${settings.buttonsColor} !important;
    }
    body:not(.admin-page) .theme-reverse:not([disabled]):hover {
    background-color: ${settings.buttonsColor} !important;
    color: ${settings.textColor === LookAndFeelTextColor.dark ? '#000' : '#fff'} !important;
    }
    body:not(.admin-page) .theme-foreground {
    color: ${settings.buttonsColor} !important;
    }
    body:not(.admin-page) .theme-foreground svg path {
    fill: ${settings.buttonsColor} !important;
    }
    body:not(.admin-page) .owl-prev, .owl-next {
    color: ${settings.buttonsColor} !important;
    }

    body:not(.admin-page) .plan-selected {
     border: solid 1px ${settings.buttonsColor} !important;
    }

    body:not(.admin-page) mat-radio-button.mat-accent .mat-radio-checked .mat-radio-outer-circle {
    color: ${settings.buttonsColor} !important;
    }

    body:not(.admin-page) .profile-menu__link:hover {
    color: ${settings.buttonsColor} !important;
    }

    body:not(.admin-page) .mat-menu-item:hover {
    color: ${settings.buttonsColor} !important;
    }
    body:not(.admin-page) .container-wrap-settings-picture__btn__remove:hover {
    border: 1px solid ${settings.buttonsColor}!important;
    }

    body:not(.admin-page) mat-icon svg path {
    fill: ${settings.buttonsColor} !important;
    }

    body:not(.admin-page) .jira-report-icon svg path {
    fill: #000000 !important;
    }
    body:not(.admin-page) .chain-links-icon svg path {
    fill: transparent !important;
    stroke: #fff;
    }

    body:not(.admin-page) .theme-border-color {
    border-color: ${settings.buttonsColor} !important;
    }
    body:not(.admin-page) .theme-reverse:not([disabled]) {
    background-color: transparent !important;
    border: 1px solid ${settings.buttonsColor} !important;
    color: ${settings.buttonsColor} !important;
    }
    body:not(.admin-page) .theme-reverse:not([disabled]):hover {
    background-color: ${settings.buttonsColor} !important;
    color: ${settings.textColor === LookAndFeelTextColor.dark ? '#000' : '#fff'} !important;
    }
    body:not(.admin-page) .filter__input:hover,
    body:not(.admin-page) .filter__input:focus,
    body:not(.admin-page) .modal-form__input:hover,
    body:not(.admin-page) .modal-form__input:focus,
    body:not(.admin-page) .filter__select:hover,
    body:not(.admin-page) .filter__select:focus,
    body:not(.admin-page) .filter__textarea:hover,
    body:not(.admin-page) .filter__textarea:focus {
    border-color: ${settings.buttonsColor} !important;
    }
    .main-gradient {
    background-image: linear-gradient(to bottom, ${settings.mileStoneGradientColor.colorTop} 0, ${
      settings.mileStoneGradientColor.colorBottom
    } 100%) !important;
    }
    body:not(.admin-page)  .nav-list__link__active {
    color: ${settings.buttonsColor} !important;
    }
    body:not(.admin-page):not(.game-station) .nav-list__link__active svg-icon svg path {
        stroke: ${settings.buttonsColor} !important;
    }

    body:not(.admin-page) .mat-calendar-body-selected {
    background-color: ${settings.buttonsColor} !important;
    color: ${settings.textColor === LookAndFeelTextColor.dark ? '#000' : '#fff'} !important;
    }
    body:not(.admin-page) .mat-datepicker-toggle-active {
    color: ${settings.buttonsColor} !important;
    }
    body:not(.admin-page) .mat-radio-inner-circle {
    background-color: ${settings.buttonsColor} !important;
    }
    body:not(.admin-page) .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: ${settings.buttonsColor} !important;
    }
    body:not(.admin-page) .blue-block-level {
    background-color: ${settings.buttonsColor} !important;
    }
    body:not(.admin-page) .tab-active {
    color: ${settings.buttonsColor} !important;
    }
    body:not(.admin-page) .spinner-bg circle,
    body:not(.admin-page) .spinner-bg-transparent circle,
    body:not(.admin-page) .modal__spinner-bg circle {
    stroke: ${settings.buttonsColor} !important
    }

    body:not(.admin-page) .header-wrapper-one input:checked + label,
    body:not(.admin-page) .header-wrapper-two input:checked + label {
    background: ${settings.buttonsColor} !important
    }
      `;
    const head = document.head || document.querySelector('head');
    const style = document.createElement('style');
    style.innerText = css;
    head.appendChild(style);

    if (settings.favicon && settings.favicon.location) {
      const favicon = document.createElement('link');
      favicon.rel = 'icon';
      favicon.type = 'image/png';
      favicon.href = settings.favicon.location;
      head.appendChild(favicon);
    }
  }

  public projectThemeClass(contentType: string): string {
    switch (contentType) {
      case LookAndFeelContentType.one:
        return 'kingaru';
      case LookAndFeelContentType.two:
        return 'kaishi';
      case LookAndFeelContentType.three:
        return 'trial';
      case LookAndFeelContentType.for:
        return 'game-station';
      default:
        return null;
    }
  }
}
