import { observable } from 'mobx-angular';

import { SettingsIdoPoolItemModel } from './settings-ido-pool-item.model';

export class SettingsIdoModel {
  @observable enabled: boolean;
  @observable address: SettingsIdoPoolItemModel[];
  constructor(data: SettingsIdoModel) {
    Object.assign(this, data);
  }
}
