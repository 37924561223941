import { Component } from '@angular/core';

@Component({
  selector: 'app-infinite-loader',
  templateUrl: './infinite-loader.component.html',
  styleUrls: ['./infinite-loader.component.scss'],
})
export class LoadingInfiniteComponent {
  constructor() {}
}
