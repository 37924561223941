export function getFaqsMockData() {
  return [
    {
      id: 'q1',
      answer: 'My answer',
      question: 'My question?',
      position: 0,
    },
    {
      id: 'q2',
      answer: 'My next answer',
      question: 'My next question?',
      position: 1,
    },
    {
      id: 'q3',
      answer:
        'Fusce vehicula dolor arcu, sit amet blandit dolor mollis nec. Donec viverra eleifend lacus, vitae ullamcorper ' +
        'metus. Sed sollicitudin ipsum quis nunc sollicitudin ultrices. Donec euismod scelerisque ligula. Maecenas eu ' +
        'varius risus, eu aliquet arcu.',
      question: '1) Self aware panel',
      position: 2,
    },
  ];
}

export function getFaqMockData() {
  return {
    id: 'q5',
    answer: 'My answer5',
    question: 'My question?5',
    position: 5,
  };
}
