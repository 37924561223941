export function putEmailMockData() {
  return {
    _id: '5b7eb00462d06b6d2ed6fa56',
    tenantId: '9a7e7b7f-fd2f-45bd-b671-67095779197b',
    id: 'registerTenant',
    defaultLocale: 'en',
    subject: {
      en: 'RegisterTenant2',
    },
    htmlBody: {
      en: '{{email}},{{token}} 2',
    },
    textBody: {
      en: '{{email}},{{token}}',
    },
  };
}
