import { NavigationPaths } from './navigation-paths.dictionary';

export const SidebarNavigationRoutesPath = [
  NavigationPaths.user.dashboard(),
  NavigationPaths.user.faq(),
  NavigationPaths.user.contact(),
  NavigationPaths.user.kyc(),
  NavigationPaths.user.affiliate(),
  NavigationPaths.user.affiliateTree(),
  NavigationPaths.user.statistics(),
  NavigationPaths.user.balances(),
  NavigationPaths.user.about(),
  NavigationPaths.user.membership(),
  NavigationPaths.user.staking(),
  NavigationPaths.user.farming(),
  NavigationPaths.user.salesPools(),
  NavigationPaths.user.bridge(),
  NavigationPaths.user.leaderboards(),
  NavigationPaths.user.stakingLeaderboards(),
  NavigationPaths.user.ido(),
  NavigationPaths.user.voting(),
];
