import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-statistic-token-balance',
  templateUrl: './statistic-token-balance.component.html',
  styleUrls: ['./statistic-token-balance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticTokenBalanceComponent {
  @Input() showBtn: boolean;
  @Input() showBorder: boolean;
}
