import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { NetworkService } from './services/network.service';
import { SettingsService } from './services/settings.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [SettingsService, NetworkService],
})
export class SettingsModule {}
