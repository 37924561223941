import { ChangeDetectorRef, ElementRef } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

export class FormHelper {
  public static addFileToArray(
    formArray: AbstractControl,
    changeDetectorRef: ChangeDetectorRef,
    subscriptions: Subscription[],
    maxFileSize?: number,
  ) {
    const formControl = new FormControl();

    (<FormArray>formArray).push(formControl);

    subscriptions.push(this.fileValueChangesSubscribe(formControl, changeDetectorRef, maxFileSize));
  }

  public static addFileWithTypesAndSizeToArray(
    formArray: AbstractControl,
    changeDetectorRef: ChangeDetectorRef,
    subscriptions: Subscription[],
    fileTypes?: string[],
    maxFileSize?: number,
  ) {
    const formControl = new FormControl();

    (<FormArray>formArray).push(formControl);

    subscriptions.push(this.fileValueAndTypeChangesSubscribe(formControl, changeDetectorRef, fileTypes, maxFileSize));
  }

  public static fileValueChangesSubscribe(
    formControl: AbstractControl,
    changeDetectorRef: ChangeDetectorRef,
    maxFileSize?: number,
  ) {
    return formControl.valueChanges.subscribe((value) => {
      const reader = new FileReader();
      const file = value ? value[0] : null;
      if (!file) {
        return;
      }
      if (maxFileSize && file.size && file.size > maxFileSize) {
        formControl.setErrors({ fileSizeTooBig: true });
        changeDetectorRef.markForCheck();
      } else {
        reader.readAsDataURL(file);
        reader.onload = () => {
          formControl.setValue(
            {
              name: file.name,
              type: file.type,
              value: reader.result,
            },
            { emitEvent: false },
          );
          changeDetectorRef.markForCheck();
        };
      }
    });
  }

  public static fileValueAndTypeChangesSubscribe(
    formControl: AbstractControl,
    changeDetectorRef: ChangeDetectorRef,
    fileTypes?: string[],
    maxFileSize?: number,
  ) {
    return formControl.valueChanges.subscribe((value) => {
      const reader = new FileReader();
      const file = value ? value[0] : null;
      if (!file) {
        return;
      }

      let matched = false;

      for (const type of fileTypes) {
        if (value[0].type === type) {
          matched = true;
        }
      }

      if (!matched) {
        formControl.setErrors({ notSupportedFileType: true });
        changeDetectorRef.markForCheck();
      } else if (maxFileSize && file.size && file.size > maxFileSize) {
        formControl.setErrors({ fileSizeTooBig: true });
        changeDetectorRef.markForCheck();
      } else {
        reader.readAsDataURL(file);
        reader.onload = () => {
          formControl.setValue(
            {
              name: file.name,
              type: file.type,
              value: reader.result,
            },
            { emitEvent: false },
          );
          changeDetectorRef.markForCheck();
        };
      }
    });
  }

  public static unsubscribeArray(subscriptions: Subscription[]) {
    subscriptions.forEach((item) => {
      item.unsubscribe();
    });
  }

  public static showErrors(form: FormGroup) {
    Object.keys(form.controls).forEach((field) => {
      form.get(field).markAsTouched({ onlySelf: true });
    });
  }

  public static scrollToFirstInvalidControl(el: ElementRef) {
    const firstInvalidControl: HTMLElement = el.nativeElement.querySelector('form .ng-invalid');
    firstInvalidControl.focus();
  }

  public static getDateLimit(y: number) {
    const minDate = new Date(Date.now());

    minDate.setFullYear(minDate.getFullYear() - y);

    return minDate;
  }
}
