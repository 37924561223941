<div class="currency-inner">
  <div class="filter">
    <input
      id="filter-counties"
      class="filter__input"
      type="search"
      [placeholder]="'SHARED__CRYPTO_CURRENCY_LIST__PLACEHOLDER' | translate"
    />
    <label for="filter-counties" class="filter__label">
      {{ 'SHARED__CRYPTO_CURRENCY_LIST__lABEL' | translate }}
    </label>
  </div>
  <div class="check-list">
    <mat-checkbox color="basic" class="check-list__item col-md-4 col-sm-6 col-xs-12">
      <img class="check-list__icon" [src]="cryptoCurrencyUrl + 'ant.svg'" alt="" />
      <span class="check-list__name">Aragon (ANT)</span>
    </mat-checkbox>
    <mat-checkbox color="basic" class="check-list__item col-md-4 col-sm-6 col-xs-12">
      <img class="check-list__icon" [src]="cryptoCurrencyUrl + 'ara.svg'" alt="" />
      <span class="check-list__name">Aragonic (ARA)</span>
    </mat-checkbox>
  </div>
  <a class="load-more">Load more</a>
</div>
