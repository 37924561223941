import { observable } from 'mobx-angular';

export class CurrencyModel {
  @observable code: string;
  @observable countries: string[];
  @observable currency: string;
  @observable digits: number;
  @observable number: string;
  @observable symbol: string;

  constructor(model: CurrencyModel) {
    Object.assign(this, model);
  }
}
