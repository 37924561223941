import { observable } from 'mobx-angular';

export enum VestingInfoCardType {
  Text = 'text',
  Filter = 'filter',
  ResetFilter = 'reset_filter',
}

export class VestingInfoCardModel {
  @observable text: string = null;
  @observable type: VestingInfoCardType = null;
  @observable order: number = null;
  constructor(data: VestingInfoCardModel) {
    Object.assign(this, data);
  }
}
