import { StatsFilterModel } from '@stats/models/stats-filter/stats-filter.model';

export function updateSettingsContributionsBtc() {
  return {
    enabled: true,
    address: 'miJS8eSAuJoBtXfkbSjCqMVqTjTK5Z49Ht',
    cancel: {
      enabled: true,
      durationInDays: 0,
    },
  };
}

export function getSettingsContributionsBtc() {
  return {
    serviceId: 'bitcoin-contributions',
    type: 'contributions',
    settings: {
      enabled: true,
      address: 'miJS8eSAuJoBtXfkbSjCqMVqTjTK5Z49Ht',
      cancel: {
        enabled: true,
        durationInDays: 0,
      },
    },
  };
}
