import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MainContainerWrapperModule } from '@tokengear-common/components';

import { SharedModule } from '@shared/shared.module';

import { AdminLookAndFeelRoutingModule } from './admin-look-and-feel-routing.module';
import { LookAndFeelComponent } from './components/look-and-feel/look-and-feel.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AdminLookAndFeelService } from './services/admin-look-and-feel.service';
import { AdminLookAndFeelStore } from './stores/admin-look-and-feel.store';

@NgModule({
  imports: [CommonModule, SharedModule, AdminLookAndFeelRoutingModule, MainContainerWrapperModule],
  declarations: [LookAndFeelComponent, SettingsComponent],
  providers: [AdminLookAndFeelService, AdminLookAndFeelStore],
})
export class AdminLookAndFeelModule {}
