import { observable } from 'mobx-angular';

import { SettingsContributionsCancelModel } from '../settings-contributions-cancel/settings-contributions-cancel.model';
import { SettingsContributionsInvoiceSettingModel } from '../settings-contributions-invoice-setting/settings-contributions-invoice-setting.model';

export class SettingsContributionsInvoiceModel {
  @observable enabled: boolean = null;
  @observable companyName: string = null;
  @observable companyAddress: string = null;
  @observable bankName: string = null;
  @observable bankAddress: string = null;
  @observable accountName: string = null;
  @observable accountNumber: string = null;
  @observable wireRoutingNumber: string = null;
  @observable swiftCode: string = null;
  @observable federalId?: string = null;
  @observable allowedCurrencies: string[] = null;
  @observable cancelPerCurrencies: { [key: string]: SettingsContributionsCancelModel } = null; // SettingsContributionsCancelMode
  @observable invoiceSettings: SettingsContributionsInvoiceSettingModel[] = null;

  constructor(params: SettingsContributionsInvoiceModel) {
    Object.assign(this, params);
  }

  public get isCancelAvailable(): boolean {
    let available = false;
    if (this.cancelPerCurrencies) {
      Object.keys(this.cancelPerCurrencies).forEach((currency) => {
        if (this.cancelPerCurrencies[currency].enabled) {
          available = true;
        }
      });
    }

    if (this.invoiceSettings) {
      this.invoiceSettings.forEach((invoiceSetting) => {
        if (invoiceSetting.cancel.enabled) {
          available = true;
        }
      });
    }

    return available;
  }
}
