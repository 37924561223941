<div class="modal" appThemeClass>
  <app-transaction-status
    [transactionResponse]="transactionResponse"
    [status]="transactionStatus"
    [showBack]="showBack"
    (closeEmitter)="closeModal()"
    (backEmitter)="transactionStatus = null"
    [title]="title"
    [description]="description"
  ></app-transaction-status>
</div>
