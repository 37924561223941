export class ScheduleHelper {
  private static schedules: { [k: string]: NodeJS.Timer[] } = {};

  public static schedule(scheduleName: string, func: Function, interval: number): NodeJS.Timer {
    const id = setInterval(() => {
      func();
    }, interval);
    if (!ScheduleHelper.schedules[scheduleName]) {
      ScheduleHelper.schedules[scheduleName] = [];
    }
    ScheduleHelper.schedules[scheduleName].push(id);

    return id;
  }

  public static unschedule(id: NodeJS.Timer): void {
    clearInterval(id);
    Object.keys(ScheduleHelper.schedules).forEach((scheduleName: string) => {
      ScheduleHelper.schedules[scheduleName] = ScheduleHelper.schedules[scheduleName].filter((sId) => sId !== id);
    });
  }

  public static unscheduleAll(scheduleName: string): void {
    if (!ScheduleHelper.schedules[scheduleName]) {
      ScheduleHelper.schedules[scheduleName] = [];
    }
    ScheduleHelper.schedules[scheduleName].forEach((id) => clearInterval(id));
    ScheduleHelper.schedules[scheduleName] = [];
  }
}
