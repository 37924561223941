import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { observable } from 'mobx-angular';
import { finalize } from 'rxjs';

import { SettingsService } from '@tokengear-common/modules/settings';

@Component({
  selector: 'app-look-and-feel',
  templateUrl: './look-and-feel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LookAndFeelComponent implements OnInit {
  @observable public isLoading = true;

  constructor(private settingsService: SettingsService) {}

  public ngOnInit(): void {
    this.settingsService
      .getLookAndFeelSettings()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe();
  }
}
