import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { action } from 'mobx-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { cleanObj } from '@tokengear-common/helpers';
import { ApiUrlsDictionary } from '@tokengear-common/modules/api';

import { SalePoolsFilterModel } from '@domain-modules/pools/models/sale-pools-filter.model';
import { SalePoolsItemModel } from '@domain-modules/pools/models/sale-pools-item.model';
import { SalePoolsWhitelistCountersModel } from '@domain-modules/pools/models/sale-pools-whitelist-counters.model';
import { SalePoolsModel } from '@domain-modules/pools/models/sale-pools.model';

@Injectable({
  providedIn: 'root',
})
export class SalePoolsService {
  constructor(private http: HttpClient) {}

  @action getSalePools(filter: SalePoolsFilterModel): Observable<SalePoolsModel> {
    return this.http
      .get(ApiUrlsDictionary.salePoolsList(), { params: <any>filter })
      .pipe(map((response: SalePoolsModel) => new SalePoolsModel(response)));
  }

  @action getSalePoolProjectDetail(id: string): Observable<SalePoolsItemModel> {
    return this.http.get(ApiUrlsDictionary.getSalePoolProjectDetail(id)).pipe(
      map((response: SalePoolsItemModel) => {
        if (response) {
          return new SalePoolsItemModel(response);
        }
      }),
    );
  }

  @action addToWhitelist(data: any, id: string): Observable<any> {
    data = cleanObj(data);

    return this.http.post(ApiUrlsDictionary.addToWhitelist(id), data);
  }

  @action getUserSalesPoolsList(userId: string, params: { offset: number; limit: number }): Observable<any> {
    return this.http.get(ApiUrlsDictionary.userSalesPoolsList(userId), { params });
  }

  @action getUserDepositedPools(userId, networkId: number): Observable<any> {
    return this.http.get(ApiUrlsDictionary.userSalesPoolsList(userId), {
      params: {
        networkId,
        addressOnly: 'true',
      },
    });
  }

  @action getSalePoolWhitelistCounters(id: string, networkId: number): Observable<SalePoolsWhitelistCountersModel> {
    return this.http
      .get(ApiUrlsDictionary.salePoolWhitelistCounters(id), {
        params: {
          networkId,
        },
      })
      .pipe(map((response: SalePoolsWhitelistCountersModel) => new SalePoolsWhitelistCountersModel(response)));
  }
}
