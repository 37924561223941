import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthInterceptor } from '@mocks/auth/auth.interceptor';
import { BalanceInterceptor } from '@mocks/balance/balance.interceptor';
import { CountriesInterceptor } from '@mocks/countries/countries.interceptor';
import { EmailsInterceptor } from '@mocks/emails/emails.interceptor';
import { FaqsInterceptor } from '@mocks/faqs/faqs.interceptor';
import { InvoicesApproveInterceptor } from '@mocks/invoices-approve/invoices-approve.interceptor';
import { InvoicesDeclineInterceptor } from '@mocks/invoices-decline/invoices-decline.interceptor';
import { InvoicesInterceptor } from '@mocks/invoices/invoices.interceptor';
import { MeInterceptor } from '@mocks/me/me.interceptor';
import { SettingsAboutProjectInterceptor } from '@mocks/settings-about-project/settings-about-project.interceptor';
import { SettingsContributionsBtcInterceptor } from '@mocks/settings-contributions-btc/settings-contributions-btc.interceptor';
import { SettingsContributionsInvoiceInterceptor } from '@mocks/settings-contributions-invoice/settings-contributions-invoice.interceptor';
import { SettingsContributionsLtcInterceptor } from '@mocks/settings-contributions-ltc/settings-contributions-ltc.interceptor';
import { SettingsCountriesBlacklistInterceptor } from '@mocks/settings-countries-blacklist/settings-countries-blacklist.interceptor';
import { SettingsGeneralInterceptor } from '@mocks/settings-general/settings-general.interceptor';
import { SettingsMilestonesInterceptor } from '@mocks/settings-milestones/milestones.interceptor';
import { SettingsOptionsInterceptor } from '@mocks/settings-options/settings-options.interceptor';
import { StatsCountiesInterceptor } from '@mocks/stats-countries/stats-countries.interceptor';
import { StatsOverallInterceptor } from '@mocks/stats-overall/stats-overall.interceptor';
import { StatsTokensDailyInterceptor } from '@mocks/stats-tokens-daily/stats-tokens-daily.interceptor';
import { StatsTokensTotalInterceptor } from '@mocks/stats-tokens-total/stats-tokens-total.interceptor';
import { StatsTokensInterceptor } from '@mocks/stats-tokens/stats-tokens.interceptor';
import { StatsInvestorsInterceptor } from '@mocks/stats-top-investors/stats-top-investors.interceptor';
import { StatsInterceptor } from '@mocks/stats/stats.interceptor';
import { TransactionsInterceptor } from '@mocks/transactions/transactions.interceptor';
import { UserContactInterceptor } from '@mocks/user-contact/user-contact.interceptor';
import { UserStatusInterceptor } from '@mocks/user-status/user-status.interceptor';
import { UserInterceptor } from '@mocks/user/user.interceptor';
import { UsersInterceptor } from '@mocks/users/users.interceptor';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StatsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StatsCountiesInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EmailsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StatsOverallInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StatsTokensInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StatsTokensDailyInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StatsTokensTotalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StatsInvestorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TransactionsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FaqsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InvoicesInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InvoicesApproveInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InvoicesDeclineInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SettingsContributionsBtcInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SettingsContributionsLtcInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SettingsContributionsInvoiceInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BalanceInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SettingsAboutProjectInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SettingsMilestonesInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserContactInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UsersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserStatusInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SettingsGeneralInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SettingsOptionsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CountriesInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SettingsCountriesBlacklistInterceptor,
      multi: true,
    },
  ],
})
export class MocksModule {}
