import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiUrlsDictionary } from '@tokengear-common/modules/api';

import { InvoicesFilterModel } from '@domain-modules/invoices/models/invoices-filter/invoices-filter.model';
import { environment } from '@environments/environment';

import { getInvoicesMockData } from './invoices.mock-data';

@Injectable({
  providedIn: 'root',
})
export class InvoicesInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      environment.mock &&
      environment.interceptors.InvoicesInterceptor &&
      request.url.endsWith(ApiUrlsDictionary.invoicesList()) &&
      request.method === 'GET'
    ) {
      const params = request.params;
      const search = params.get('search');
      const statusFilter = params.get('statusFilter');
      const sortField = params.get('sortField');
      const sortOrder = params.get('sortOrder');
      const limit = params.get('limit');
      const offset = params.get('offset');
      const userId = params.get('userId');
      const response = new HttpResponse({
        status: 200,
        body: getInvoicesMockData(<InvoicesFilterModel>{
          search,
          statusFilter: statusFilter != null ? statusFilter : null,
          sortField,
          sortOrder,
          limit: Number(limit),
          offset: Number(offset),
          userId,
        }),
      });

      return of(response);
    }

    return next.handle(request);
  }
}
