import { observable } from 'mobx-angular';

import {
  LookAndFeelColorStyle,
  LookAndFeelLabelsColorTon,
  LookAndFeelLabelsMileStoneColor,
  LookAndFeelTextColor,
  LookAndFeelMenuLayout,
  LookAndFeelStepItem,
  LookAndFeelContentType,
  LookAndFeelNumberFormat,
} from '../../dictionaries/settings-look-and-feel.dictionary';
import { SettingsImageModel } from '../settings-image/settings-image.model';

export class SettingsLookAndFeelModel {
  @observable logo?: SettingsImageModel;
  @observable favicon?: SettingsImageModel;
  @observable emailLogo?: SettingsImageModel;
  @observable menuLayout: LookAndFeelMenuLayout;
  @observable colorStyle: LookAndFeelColorStyle;
  @observable textColor: LookAndFeelTextColor;
  @observable labelColorTon: LookAndFeelLabelsColorTon;
  @observable stepsBlock?: LookAndFeelStepItem[];
  @observable buttonsColor: string;
  @observable text?: { [key: string]: string };
  @observable mileStoneGradientColor: LookAndFeelLabelsMileStoneColor;
  @observable contentType?: LookAndFeelContentType;
  @observable numberFormat: LookAndFeelNumberFormat;

  constructor(params: SettingsLookAndFeelModel) {
    Object.assign(this, params);
  }
}
