import { Injectable } from '@angular/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { Web3Service } from '@app/integrations/services/web3/web3.service';
import { EthSignatureTypeDictionary } from '@domain-modules/eth-address-signature/dectionaries/eth-signature-type.dictionary';
import { EthAddressSignatureDataModel } from '@domain-modules/eth-address-signature/models/eth-address-signature-data.model';
import { EthAddressSignatureService } from '@domain-modules/eth-address-signature/services/eth-address-signature.service';

import { SignTypeDataModel } from '../modules/auth';
import { SettingsService } from '../modules/settings';

@Injectable()
export class SignatureService {
  constructor(
    private ethAddressSignatureService: EthAddressSignatureService,
    private settingsService: SettingsService,
    private web3Service: Web3Service,
  ) {}

  private getEthAddressSignature(signType: EthSignatureTypeDictionary): Observable<SignTypeDataModel> {
    return this.ethAddressSignatureService
      .getKycEthAddressSignature(signType)
      .pipe(map((response: SignTypeDataModel) => new SignTypeDataModel(response)));
  }

  public getEthAddressSignatureData(
    signType: EthSignatureTypeDictionary,
    isEnabled?: boolean,
  ): Observable<EthAddressSignatureDataModel | null> {
    if (isEnabled) {
      return forkJoin([this.getEthAddressSignature(signType), this.web3Service.connectToMetaMask()]).pipe(
        mergeMap(([signatureData, account]) =>
          from(this.web3Service.signDataV4(account, JSON.stringify(signatureData))).pipe(
            map(
              (signature) =>
                new EthAddressSignatureDataModel(<EthAddressSignatureDataModel>{ signatureData, signature, account }),
            ),
          ),
        ),
      );
    }

    return of(null);
  }
}
