import { StatsFilterModel } from '@stats/models/stats-filter/stats-filter.model';

export function getStatsTokensDailyMockData(filter?: StatsFilterModel) {
  if (filter.startDate == null && filter.endDate == null) {
    return [
      {
        name: 'NTVA Sold',
        series: [
          {
            name: 'January',
            value: '0',
          },
          {
            name: 'February',
            value: '4.2',
          },
          {
            name: 'March',
            value: '0.5',
          },
          {
            name: 'April',
            value: '8',
          },
          {
            name: 'May',
            value: '0.1',
          },
          {
            name: 'June',
            value: '4',
          },
        ],
      },
      {
        name: 'Sold for ETH',
        series: [
          {
            name: 'January',
            value: '2',
          },
          {
            name: 'February',
            value: '3.5',
          },
          {
            name: 'March',
            value: '6',
          },
          {
            name: 'April',
            value: '2.1',
          },
          {
            name: 'May',
            value: '5',
          },
          {
            name: 'June',
            value: '8',
          },
        ],
      },
      {
        name: 'Sold for BTC',
        series: [
          {
            name: 'January',
            value: '4',
          },
          {
            name: 'February',
            value: '3',
          },
          {
            name: 'March',
            value: '7',
          },
          {
            name: 'April',
            value: '8',
          },
          {
            name: 'May',
            value: '2',
          },
          {
            name: 'June',
            value: '6',
          },
        ],
      },
    ];
  }

  return [
    {
      name: 'NTVA Sold',
      series: [
        {
          name: 'January',
          value: '10',
        },
        {
          name: 'February',
          value: '4.2',
        },
        {
          name: 'March',
          value: '0.5',
        },
        {
          name: 'April',
          value: '8',
        },
        {
          name: 'May',
          value: '0.1',
        },
        {
          name: 'June',
          value: '4',
        },
      ],
    },
    {
      name: 'Sold for ETH',
      series: [
        {
          name: 'January',
          value: '2',
        },
        {
          name: 'February',
          value: '3.5',
        },
        {
          name: 'March',
          value: '6',
        },
        {
          name: 'April',
          value: '20',
        },
        {
          name: 'May',
          value: '5',
        },
        {
          name: 'June',
          value: '50',
        },
      ],
    },
    {
      name: 'Sold for BTC',
      series: [
        {
          name: 'January',
          value: '4',
        },
        {
          name: 'February',
          value: '3',
        },
        {
          name: 'March',
          value: '7',
        },
        {
          name: 'April',
          value: '8',
        },
        {
          name: 'May',
          value: '5',
        },
        {
          name: 'June',
          value: '6',
        },
      ],
    },
  ];
}
