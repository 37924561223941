import { observable } from 'mobx-angular';

// TODO: separate the imports
import { UserRolesDictionary } from '@domain-modules/users/dictionaries/user-roles.dictionary';

export enum SideBarItemEvents {
  REPORT_BAG = 'REPORT_BAG',
}

export enum SideBarItemType {
  LINK = 'LINK',
  ARRAY_LINKS = 'ARRAY_LINKS',
  ROUTE = 'ROUTE',
  EVENT = 'EVENT',
}

export enum SideBarIconType {
  ABSOLUTE = 'ABSOLUTE',
  RELATIVE = 'RELATIVE',
}

export class SideBarItemModel {
  @observable label: string;
  @observable type: SideBarItemType;
  @observable orderBy: number;
  @observable permissions: UserRolesDictionary[];
  @observable isDisabled: boolean;
  @observable icon: { type: SideBarIconType; path: string };
  @observable arrayLinks?: { label: string; path: string }[];
  @observable path?: string;
  @observable eventName?: SideBarItemEvents;

  constructor(data: SideBarItemModel) {
    Object.assign(this, data);
  }
}
