export class FirebaseConfigModel {
  public apiKey: string;
  public authDomain: string;
  public projectId: string;
  public storageBucket: string;
  public messagingSenderId: string;
  public appId: string;
  public measurementId: string;

  constructor(data: FirebaseConfigModel) {
    Object.assign(this, data);
  }
}
