import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { computed } from 'mobx-angular';

import { SettingsService } from '@tokengear-common/modules/settings';

import { AffiliateStatisticModel } from '@domain-modules/referrals/models/referrals-tree-statistic/referrals-tree-statistic.model';

@Component({
  selector: 'app-statistic-affiliate-statistics',
  templateUrl: './statistic-affiliate-statistics.component.html',
  styleUrls: ['./statistic-affiliate-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticAffiliateStatisticsComponent {
  @Input() affiliateStatistic: AffiliateStatisticModel;
  @Input() showBorder: boolean;

  constructor(private settingsService: SettingsService) {}

  @computed get isRankReferralTree(): boolean {
    return this.settingsService.isRankReferralTree;
  }
}
