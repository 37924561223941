import { observable } from 'mobx-angular';

import { SettingsAdminSideBarGroupModel } from '@tokengear-common/modules/settings';

export class SettingsAdminSideBarModel {
  @observable enabled: boolean;
  @observable defaultNavigationRoute: string;
  public navigationGroup: SettingsAdminSideBarGroupModel[];

  constructor(data: SettingsAdminSideBarModel) {
    Object.assign(this, data);
  }
}
