export class ArrayHelper {
  public static removeObj<T>(array: T[], item: T, property: string): T[] {
    const index: number = array.findIndex((obj) => obj[property] === item[property]);

    if (index >= 0) {
      array.splice(index, 1);
    }

    return array;
  }

  public static replaceObj<T>(array: T[], item: T, property: string): T[] {
    const index: number = array.findIndex((obj) => obj[property] === item[property]);

    if (index >= 0) {
      array[index] = item;
    }

    return array;
  }

  public static prepareMultiplaySelectList(oldValue: string[], newValue: string[], dictionary: string[]): string[] {
    if (newValue.includes('ALL') && (oldValue == null || !oldValue.includes('ALL'))) {
      return dictionary;
    }

    if (!newValue.includes('ALL') && oldValue != null && oldValue.includes('ALL')) {
      return null;
    }

    if (newValue.includes('ALL') && dictionary.length !== newValue.length) {
      const result = this.removeItem(newValue, 'ALL');

      return result;
    }

    if (newValue.length === 0) {
      return null;
    }

    return newValue;
  }

  public static removeItem(value: string[], name: string) {
    const index = value.findIndex((item) => item.toUpperCase() === name);

    if (index === -1) {
      return value;
    }

    const result = value.slice();

    result.splice(index, 1);

    return result;
  }
}
