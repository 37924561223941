import { EthSignatureTypeDictionary } from '@domain-modules/eth-address-signature/dectionaries/eth-signature-type.dictionary';
import { ConvertCurrencyParams } from '@domain-modules/payment/services/http/convert-currency-http.service';

export const ApiUrlsDictionary = {
  auth: () => '/api/auth',
  verify2FA: () => '/api/verify',
  logout: () => '/api/logout',
  me: () => '/api/me',
  resetToken: (token) => `/api/user/reset-token/${token}`,
  resetPasswordRequest: () => '/api/user/restore-request',
  newPassword: () => '/api/user/restore',
  confirmEmail: () => '/api/user/activate',
  linkAnotherAddress: () => '/api/user/link-another-address',
  ethAddressSignature: (signType: EthSignatureTypeDictionary) => `/api/utils/${signType}/signature`,
  // SSO API
  ssoAuth: (token: string) => `/api/auth/sso?token=${token}`,

  // ICO API
  stats: () => '/api/ico/stats',
  balance: () => '/api/ico/balance',

  // API
  statsTokens: () => '/api/stats/tokens',
  statsTokensTotal: () => '/api/stats/tokens/total',
  statsTokensDaily: () => '/api/stats/tokens/daily',
  statsCountries: () => '/api/stats/countries',
  statsTopInvestors: () => '/api/stats/top-investors',
  statsOverall: () => '/api/stats/overall',

  contributions: {
    paymentMethods: () => '/api/contributions/paymentMethods',
  },
  contributionsSignature: () => '/api/contributions/sign',
  contributionsAvailableAmount: () => '/api/contributions/available-amount',

  transactions: () => '/api/contributions',
  contributionsLocked: () => '/api/contributions/locked',
  sendAmount: () => '/api/contributions/complete',
  transactionExportCSV: () => '/api/export/transactions',
  requestTransactionRescind: (id) => `/api/contributions/${id}/cancel`,
  approveTransactionRescind: (id) => `/api/contributions/${id}/cancel-approve`,

  invoicesList: () => '/api/invoice',
  invoicesExportCSV: () => '/api/export/invoice',
  invoicesExportPDF: () => '/api/invoice/pdf',
  invoiceRescind: () => '/api/invoice/cancel',

  invoiceCreate: () => '/api/invoice',
  invoiceApprove: () => '/api/invoice/accept',
  invoiceDecline: () => '/api/invoice/decline',

  faq: (id?: string) => `/api/faq/${id != null ? id : ''}`,
  settingsAboutProject: () => '/api/platform/settings/about.project.service',
  settingsContributionsBtc: () => '/api/platform/settings/bitcoin-contributions',
  settingsContributionsLtc: () => '/api/platform/settings/litecoin-contributions',
  settingsContributionsEtc: () => '/api/platform/settings/ethereum-contributions',
  settingsContributionsInvoice: () => '/api/platform/settings/invoice-contributions',
  settingsUpdateService: (options?: string) => `/api/platform/settings/${options != null ? options : ''}`,

  settingsImage: (type) => `/api/upload/${type}`,

  nextFreeRunningNumber: () => `/api/nextFreeRunningNumber/`,

  userIdHistory: (id?: string) => `/api/userIdHistory/${id}`,

  contactEmail: () => '/api/contact',
  users: (id?: string) => `/api/users/${id != null ? id : ''}`,
  userCount: () => `/api/user/count`,
  usersExportCSV: () => '/api/export/users',
  usersKycStatus: (id?: string) => `/api/users/${id != null ? id : ''}/status`,
  usersProofOfIncomeStatus: (id?: string) => `/api/users/${id != null ? id : ''}/proofOfIncomeStatus`,
  customUserId: () => `/api/users/custom-id`,

  userReferralParentByCustomId: (id) => `/api/users/${id}/referral14/parent-by-customId`,
  userReferralLevel: (id) => `/api/users/${id}/referral14/level`,
  adminUserAffiliate: (userId) => `/api/users/${userId}/referral14`,
  adminUserReferrals14: (userId) => `/api/users/${userId}/referral14/referrers`,

  countriesData: () => '/api/countries/data',
  countriesList: () => '/api/countries/list',
  regionList: (countryId) => `/api/countries/regions?country=${countryId}`,
  countriesWithRegions: () => `/api/countriesWithRegions`,

  kycSygnedUrls: () => '/api/kyc/signed-urls',
  currenciesData: () => '/api/currencies/data',
  exchangeRate: (tokensBase: string, tokensTo: string) => `/api/exchange-rate?base=${tokensBase}&to=${tokensTo}`,

  icoContribute: (crypto) => `/api/ico/contribute/${crypto}`,
  icoSaleMode: (saleMode) => `/api/ico/check-new-stage/${saleMode}`,
  icoCalc: (currency: string, amount: number, plan?: string) =>
    `/api/ico/calc?currency=${currency}&amount=${amount}${plan != null ? `&plan=${plan}` : ''}`,
  icoRate: (tokensFrom: string, tokensTo: string, amount: number) =>
    `/api/ico/rate?from=${tokensFrom}&to=${tokensTo}&amount=${amount}`,

  emails: (id?: string) => `/api/emails${id != null ? `/${id}` : ''}`,
  emailsPublish: (id: string) => `/api/emails/${id}/publish`,
  emailVerfication: `/api/email/verify-request`,
  checkEmailCode: `/api/email/verify-code`,

  userReferrals14: (userId) => `/api/referrals14/referrals?userId=${userId}`,
  referralsStats: () => `/api/referrals14/stats`,
  userGroupStats: (userId) => `/api/referrals14/${userId}/user-groups`,

  userImage: (userId: string) => `/api/users/${userId}/avatar`,
  userSlug: (userId: string) => `/api/users/${userId}/slug`,
  userPassword: (userId: string) => `/api/users/${userId}/password`,
  changeEmailRequest: (userId: string) => `/api/users/${userId}/change-email`,
  changeEmailConfirm: () => `/api/users/change-email-confirm`,
  enableTwoFactoryAuth: (userId: string) => `/api/users/${userId}/enable-2fa`,
  disableTwoFactoryAuth: (userId: string) => `/api/users/${userId}/disable-2fa`,
  verifyTwoFactoryAuth: (userId: string) => `/api/users/${userId}/2fa-verify`,

  userReferralToken: () => `/api/users/referral-tree/code`,
  userReferralParent: () => `/api/users/referral-tree/parent`,
  userAffiliateStatistics: () => `/api/users/referral-tree/statistic`,
  referralTreeNetworkInfo: () => `/api/users/referral-tree/network-info`,
  checkReferralToken: (tokenReferral) => `/api/referral/${tokenReferral}`,
  userReferrals: () => `/api/referral-tree/referrals`,
  userReferralInfo: (userId) => `/api/users/${userId}/referral-tree/referral-info`,

  userBalances: () => `/api/users/balances`,
  userPlanBalances: () => `/api/users/plans/balances`,

  // WALLET CONNECT
  signature: () => '/api/auth/signature',
  authWallet: () => '/api/auth/wallet',

  // Pools
  salePoolsList: () => '/api/projects',
  vestingPoolsList: () => `/api/pools/vesting`,
  getSalePoolProjectDetail: (id: string) => `/api/projects/${id}`,
  stakingFarmingPoolsList: () => '/api/pools',
  allStakedContracts: () => '/api/user/all-stake-pools',
  userStaked: () => '/api/pools/user-staked',
  stakedAll: () => '/api/pools/staked-all',
  membership: () => '/api/membership',
  poolSignature: () => '/api/pools/signature',
  stakingFarmingPoolStats: (type: string) => `/api/pools/stats?type=${type}`,
  addToWhitelist: (id) => `/api/projects/${id}/white-list`,
  userSalesPoolsList: (userId: string) => `/api/users/${userId}/projects`,
  userStakingPoolsList: (userId: string) => `/api/users/${userId}/pools`,
  salePoolWhitelistCounters: (id: string) => `/api/projects/${id}/whitelist-counters`,
  // bridge
  bridgeAssets: () => `/api/bridge/assets`,
  bridgeReceives: () => `/api/bridge/signatures`,
  bridgeReceiveSignature: (id: string) => `/api/bridge/signatures/${id}`,
  bridgeReceiveSignatureSigned: (id: string) => `/api/bridge/signatures/${id}/received`,
  networkLiquidity: (networkId: number, tokenAddress: string) =>
    `/api/bridge/liquidity?networkId=${networkId}&tokenAddress=${tokenAddress}`,

  // tokens
  tokens: (token: string) => `/api/utils/uniswap/tokens?token=${token}`,

  // leaderboards
  stakingLeaderboards: () => `/api/staking-leaderboard`,

  // voting
  voting: (id: string) => `/api/votes/${id}`,
  sendVote: (id: string) => `/api/votes/${id}`,
  getVotesResultList: (id: string) => `/api/votes/${id}/voters`,

  // payment
  paymentOrder: (id: string) => `/api/payment/orders/${id}`,
  paymentOrderSignature: (id: string) => `/api/payment/orders/${id}/sign`,
  convertCurrency: ({ to, from, amount }: ConvertCurrencyParams) =>
    `/api/currencies/internal-rate?from=${from}&to=${to}${amount ? `&amount=${amount}` : ''}`,
  paymentApp: () => `/api/payment/app`,
  paymentAppDetails: (id: string) => `/api/payment/app/${id}`,
  paymentAppLiveMode: (id: string) => `/api/payment/app/${id}/live-mode`,
  paymentAppApiKeys: (id: string) => `/api/payment/app/${id}/api-keys`,
  paymentAppOrders: (id: string) => `/api/payment/orders/?appId=${id}`,
};
