<div>
  <div class="modal__headline">
    {{ 'USER_DASHBOARD__MODAL_INVOICE_GENERATED__TITLE' | translate }}
  </div>
  <div class="content">
    <div class="block-line"></div>
    <div class="text-wrap">
      <p>{{ 'USER_DASHBOARD__MODAL_INVOICE_GENERATED__TEXT1' | translate }}</p>
      <p>{{ 'USER_DASHBOARD__MODAL_INVOICE_GENERATED__TEXT2' | translate }}</p>
    </div>
    <div class="block-line"></div>
    <div class="actions">
      <button type="button" mat-flat-button class="cancel" [mat-dialog-close]="DialogResult.Closed">
        {{ 'USER_DASHBOARD__MODAL_INVOICE_GENERATED__CANCEL' | translate }}
      </button>
      <a download mat-flat-button class="download theme-background" [href]="urlPDF(data.invoiceId)">
        {{ 'USER_DASHBOARD__MODAL_INVOICE_GENERATED__DOWNLOAD' | translate }}
      </a>
    </div>
  </div>
</div>
