<mat-card *mobxAutorun [class.card-box-border]="showBorder">
  <mat-card-header class="card-block__header">
    {{ 'USER_STATISTICS__TOKEN_SALE_BALANCE__HEADER' | translate }}
    <button *ngIf="showBtn" class="card-block__small-btn theme-background" mat-flat-button (click)="buyTokens()">
      {{ 'USER_STATISTICS__TOKEN_SALE_BALANCE__BTN' | translate }}
    </button>
  </mat-card-header>

  <div class="block-line"></div>
  <mat-card-content>
    <div class="row">
      <div class="col-xs-6">
        <div class="card-block__header-text">
          {{ 'USER_STATISTICS__TOKEN_SALE_BALANCE__OWN_PURCHASES' | translate }}
        </div>
        <div class="card-block__data-text">{{ userAffiliate?.personalPurchaseFormatted || '--' }}</div>
      </div>
      <!--      <div class="col-xs-6">-->
      <!--        <div class="card-block__header-text">{{"USER_STATISTICS__TOKEN_SALE_BALANCE__NEXT_LEVEL" | translate}}</div>-->
      <!--        <div class="card-block__data-text">$ 300,000</div>-->
      <!--      </div>-->
      <div class="col-xs-6">
        <div class="card-block__header-text">
          {{ 'USER_STATISTICS__TOKEN_SALE_BALANCE__NETWORK_PURCHASES' | translate }}
        </div>
        <div class="card-block__data-text">{{ userAffiliate?.networkSalesFormatted || '--' }}</div>
      </div>
      <div class="col-xs-6">
        <div class="card-block__header-text">
          {{ 'USER_STATISTICS__TOKEN_SALE_BALANCE__TOTAL_PURCHASES' | translate }}
        </div>
        <div class="card-block__data-text">{{ userAffiliate?.ownAndNetworkPurchasesFormatted || '--' }}</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
