import { observable } from 'mobx-angular';

import { SettingsReferral12GroupsModel } from '../settings-referral12-groups/settings-referral12-groups.model';
import { SettingsReferral12LevelsModel } from '../settings-referral12-levels/settings-referral12-levels.model';

export class SettingsReferral12Model {
  @observable enabled: boolean = null;
  @observable id: string = null;
  @observable currency: string = null;
  @observable groups: SettingsReferral12GroupsModel[] = null;
  @observable levels: SettingsReferral12LevelsModel[] = null;
  @observable referralSecureToken: string = null;
  @observable levelId: string = null;
  @observable status: string; // KYC_REQUIRED; PAUSED; ACTIV = null;E
  @observable referredUserIds: string[] = null;
  @observable contributions: string[] = null;
  @observable spendings: string[] = null;

  // group investments
  @observable totalReferredFiatAmount: number = null;
  @observable totalEarnedFiatAmount: number = null;
  @observable totalEarnedTokensAmount: number = null;
  @observable totalReferredPendingFiatAmount: number = null;
  @observable totalReferredPendingTokensAmount: number = null;
  @observable totalSpentAmount: number = null;
  @observable referralsCount: number = null;

  // custom
  @observable selfInvestedFiat?: number = null;
  @observable groupInvestedFiat?: number = null;
  @observable fullName?: string = null;
  @observable hasReferrals?: boolean = null;
  @observable active?: boolean = null;
  @observable relevantForNextLevelAmount?: number = null;

  constructor(affiliate: SettingsReferral12Model) {
    Object.assign(this, affiliate);
    if (this.groups) {
      this.groups = this.groups.map((gr) => new SettingsReferral12GroupsModel(gr));
    }
    if (this.levels) {
      this.levels = this.levels.map((lvl) => new SettingsReferral12LevelsModel(lvl));
    }
  }
}
