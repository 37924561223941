import { Injectable } from '@angular/core';
import { action, computed, observable } from 'mobx-angular';

import { ExtendedSalePoolsItemModel } from '@domain-modules/pools/models/extended-sale-pools-item.model';
import { HeaderPoolsFilterModel } from '@domain-modules/pools/models/header-pools-filter.model';
import { StakingPoolsItemModel } from '@domain-modules/pools/models/staking-pools-item.model';

export enum HeaderContentType {
  StakingFarming = 'staking-farming',
  Pools = 'pools',
}

@Injectable({
  providedIn: 'root',
})
export class PoolsStore {
  @observable isLoading: boolean;
  @observable filter: HeaderPoolsFilterModel;
  @observable pools: ExtendedSalePoolsItemModel[];
  @observable stackingPools: StakingPoolsItemModel[];
  @observable contentType: HeaderContentType;
  @observable totalPages = 1;

  @action createFilter(params: HeaderPoolsFilterModel) {
    this.filter = new HeaderPoolsFilterModel(params);
  }

  @action setStackingPoolsList(value: StakingPoolsItemModel[]) {
    this.stackingPools = value;
  }

  @computed get stackingPoolsUnclaimedRewards(): number {
    return this.stackingPools?.reduce((accum, value) => accum + value.unclaimedReward, 0) || 0;
  }

  @computed get poolsUnclaimedRewards(): number {
    return this.pools?.reduce((accum, value) => accum + value.unclaimedReward, 0) || 0;
  }

  @computed get queryParams() {
    return this.filter.queryParams;
  }

  @computed get currentPage() {
    return this.filter.currentPage;
  }

  @action setPoolsList(value: ExtendedSalePoolsItemModel[]) {
    this.pools = value;
  }

  @action setTotalPages(num: number) {
    this.totalPages = num;
  }

  @action setLimit(value: number) {
    this.filter.setLimit(value);
  }

  @action setContentType(type: HeaderContentType) {
    this.contentType = type;
  }

  @action setOffset(value: number) {
    this.filter.setOffset(value);
  }

  @action setLoading(value: boolean) {
    this.isLoading = value;
  }
}
