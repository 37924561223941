export const DEFAULT_GAS_LIMIT = 500000;
export const DEFAULT_DECIMALS = 18;
export const DEFAULT_DIGITS = 2;
export const EMPTY_ADDRESS = '0x0000000000000000000000000000000000000000';
export const EMPTY_ADDRESS__SYMBOL = 'ETH';
export const MAX_FOR_ALLOWANCE = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
export const METAMASK_LINK = 'https://metamask.io/';
export const ETHEREUM = 'ethereum';
export const WEB3 = 'web3';

export const METAMASK_ERRORS = {
  notInstalled: 'ERROR.METAMASK__NOT__INSTALLED',
  needUserPermission: 'ERROR.NEED__USER__PERMISSION',
  failedToRetrieveGasPrice: 'ERROR.FAILED__RETRIEVING__GAS__PRICE',
};

export enum TransactionStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum EthUnits {
  wei = 'wei', // 1
  kwei = 'kwei', // 3
  mwei = 'mwei', // 6
  gwei = 'gwei', // 9
  ether = 'ether', // 18
}
