/**
 * A single tab page. It renders the passed template
 * via the @Input properties by using the ngTemplateOutlet
 * and ngTemplateOutletContext directives.
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-my-tab',
  styles: [
    `
      .pane {
        padding-top: 30px;
      }
    `,
  ],
  template: `
    <div [hidden]="!active" [class.pane]="marginTop">
      <ng-content></ng-content>
    </div>
  `,
})
export class TabComponent {
  @Input() tabTitle: string;
  @Input() marginTop = false;
  @Input() disabled = false;
  @Input() active = false;
  @Input() positionCenter = false;
  constructor() {}
}
