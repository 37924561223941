import { observable } from 'mobx-angular';

export enum TreeType {
  SIMPLE = 'SIMPLE',
  WITH_RANKS = 'WITH_RANKS',
}

export class ReferralsTreeConfigModel {
  @observable enabled: boolean = null;
  @observable treeType: TreeType = null;

  constructor(data: ReferralsTreeConfigModel) {
    Object.assign(this, data);
  }
}
