import { AbstractControl, FormArray, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export class ValidationHelper {
  public static readonly specSymbols = '\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff';
  public static readonly validFileTypes = ['image/png', 'image/jpeg'];

  public static addressValidators = [Validators.maxLength(200)];
  public static emailValidators = [Validators.email];
  public static zipCodeValidators = [
    Validators.maxLength(50),
    Validators.pattern(
      `^[A-Za-z0-9][A-Za-z${ValidationHelper.specSymbols}0-9]*(?:[ 0-9-][A-Za-z${ValidationHelper.specSymbols}0-9]+)*$`,
    ),
  ];
  public static firstNameValidators = [
    Validators.maxLength(50),
    Validators.pattern(`^[A-Za-z0-9][A-Za-z${ValidationHelper.specSymbols} -]*$`),
  ];
  public static lastNameValidators = [
    Validators.maxLength(50),
    Validators.pattern(`^[A-Za-z0-9][A-Za-z${ValidationHelper.specSymbols} -]*$`),
  ];
  public static middleNameValidators = [Validators.pattern(`^[A-Za-z0-9][A-Za-z${ValidationHelper.specSymbols} -]*$`)];
  public static cityValidators = [
    Validators.pattern(
      `^[A-Za-z0-9][a-zA-Z${ValidationHelper.specSymbols} ]*(?:[ -][a-zA-Z${ValidationHelper.specSymbols}]+)*$`,
    ),
  ];
  public static cryptoAddressValidators = [Validators.maxLength(50), Validators.pattern('^0x[a-fA-F0-9]{40}$')];
  public static streetValidators = [
    Validators.pattern(
      `^[A-Za-z0-9][a-zA-Z${ValidationHelper.specSymbols}0-9., -]*(?:[ 0-9.,-][a-zA-Z${ValidationHelper.specSymbols}0-9.,-]+)*$`,
    ),
  ];
  public static apartmentValidators = [
    Validators.pattern(`^[A-Za-z0-9][A-Za-z${ValidationHelper.specSymbols}0-9 -]*$`),
  ];
  public static phoneValidators = [Validators.pattern('\\+?\\d{10,14}')];
  public static urlValidators = [
    Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/),
  ];
  public static documentScanFrontValidators = [ValidationHelper.fileTypesValidator(ValidationHelper.validFileTypes)];
  public static documentScanBackValidators = [ValidationHelper.fileTypesValidator(ValidationHelper.validFileTypes)];
  public static documentScanFaceValidators = [ValidationHelper.fileTypesValidator(ValidationHelper.validFileTypes)];
  public static fundsProofsValidators = [ValidationHelper.fileTypesValidator(ValidationHelper.validFileTypes)];

  public static uppercaseValidator(reg: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.value;
      const hasUppercaseLetters = reg.test(password);

      return hasUppercaseLetters ? null : { uppercaseLetters: { password } };
    };
  }

  public static numbersValidator(reg: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.value;
      const hasNumbers = reg.test(password);

      return hasNumbers ? null : { numbers: { password } };
    };
  }

  public static oneItemRequiredValidator(control: FormArray): ValidationErrors | null {
    const controls = control.controls;
    const error = {
      oneItemRequired: true,
    };

    if (controls == null || controls.length === 0) {
      return error;
    }

    const isValid = control.controls.reduce((result, next) => {
      if (result) {
        return true;
      }

      return Validators.required(next) == null;
    }, false);

    return isValid ? null : error;
  }

  public static fileTypesValidator(types: string[]): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value) {
        if (control.value[0]) {
          let matched = false;

          for (const type of types) {
            if (control.value[0].type === type) {
              matched = true;
            }
          }

          return !matched ? { notSupportedFileType: true } : null;
        }
      }
    };
  }

  public static noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;

    return isValid ? null : { whitespace: true };
  }

  public static notPositiveValue(control: FormControl) {
    if (Number(control.value) <= 0) {
      return { nonZero: true };
    }

    return null;
  }
}
