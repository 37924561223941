<mat-accordion>
  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
      <mat-panel-title>
        {{ index }})
        {{ faq.question }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <p class="answer">{{ faq.answer }}</p>
  </mat-expansion-panel>
</mat-accordion>
