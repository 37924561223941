import { observable } from 'mobx-angular';

import { KycSignedUrlModel } from '../kyc-signed-url/kyc-signed-url.model';

export class KycSignedUrlsModel {
  @observable signedUrls: KycSignedUrlModel[];

  constructor(data: KycSignedUrlsModel) {
    Object.assign(this, data);

    if (this.signedUrls) {
      this.signedUrls = this.signedUrls.map((su) => new KycSignedUrlModel(su));
    }
  }
}
