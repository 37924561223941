import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { action, computed, observable } from 'mobx-angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { SettingsMembershipModel } from '@tokengear-common/modules/settings/models/settings-membership/settings-membership.model';

import { AppSettings } from '@app/app.setting';
import { NetworkParamsModel } from '@app/integrations/models/network-params.model';
import { DollarTokenConfigModel } from '@domain-modules/dollar-tokens/models/dollar-token-config/dollar-token-config.model';
import { FundAccountConfigModel } from '@domain-modules/fund-account/models/fund-account-config/fund-account-config.model';
import { SaleModeTypeDictionary } from '@domain-modules/ico/dectionaries/sale-mode-type.dictionary';
import { KycConfigModel } from '@domain-modules/kyc/models/kyc-config/kyc-config.model';
import { KycSettingsModel } from '@domain-modules/kyc/models/kyc-settings/kyc-settings.model';
import { ReferralsConfigModel } from '@domain-modules/referrals/models/referrals-config/referrals-config.model';
import {
  ReferralsTreeConfigModel,
  TreeType,
} from '@domain-modules/referrals/models/referrals-tree-config/referrals-tree-config.model';
import { ContributionStatus } from '@domain-modules/transactions/dictionaries/contribution-status.dictionary';
import { TransactionModel } from '@transactions/models/transaction/transaction.model';

import { ApiUrlsDictionary } from '../../api';
import { SettingsAuthDictionary, ServicesIdDictionary } from '../dictionaries';
import {
  FirebaseConfigModel,
  NetworkModel,
  ProviderModel,
  SettingsAboutProjectModel,
  SettingsAdminSideBarModel,
  SettingsAnotherAddressModel,
  SettingsAuthModel,
  SettingsBlacklistCountriesModel,
  SettingsBridgeModel,
  SettingsBuyTokensModel,
  SettingsContractsConfigModel,
  SettingsContractsModel,
  SettingsContributionsBtcModel,
  SettingsContributionsInvoiceModel,
  SettingsCryptoIconsModel,
  SettingsCustomIdModel,
  SettingsDashboardPageModel,
  SettingsFaqsModel,
  SettingsFooterModel,
  SettingsGeneralModel,
  SettingsHeaderModel,
  SettingsIdoModel,
  SettingsLeaderboardModel,
  SettingsLookAndFeelModel,
  SettingsMainPageModel,
  SettingsMilestonesModel,
  SettingsNetworksModel,
  SettingsOptionsModel,
  SettingsReferral12Model,
  SettingsResponseModel,
  SettingsSideBarModel,
  SettingsTransactionsExplorerLinksModel,
  SettingsUserRegistrationsConfigModel,
  SettingsVestingPoolsModel,
  SettingsVestingWhitelistModel,
  SettingsVotingModel,
  SettingsWalletModel,
} from '../models';

@Injectable()
export class SettingsService {
  @observable settingsGeneral: SettingsResponseModel<SettingsGeneralModel>;
  @observable settingsLookAndFeel: SettingsResponseModel<SettingsLookAndFeelModel>;
  @observable faqsSettings: SettingsResponseModel<SettingsFaqsModel>;
  @observable settingsOptions: SettingsResponseModel<SettingsOptionsModel>;
  @observable settingsAbout: SettingsResponseModel<SettingsAboutProjectModel>;
  @observable btcSettings: SettingsResponseModel<SettingsContributionsBtcModel>;
  @observable invoiceSettings: SettingsResponseModel<SettingsContributionsInvoiceModel>;
  @observable userRegistrationConfig: SettingsResponseModel<SettingsUserRegistrationsConfigModel>;
  @observable milestones: SettingsResponseModel<SettingsMilestonesModel>;
  @observable blacklistCountries: SettingsResponseModel<SettingsBlacklistCountriesModel>;
  @observable transactionsExplorerLinks: SettingsResponseModel<SettingsTransactionsExplorerLinksModel>;
  @observable settingsReferral12: SettingsResponseModel<SettingsReferral12Model>;
  @observable settingsReferral: SettingsResponseModel<ReferralsConfigModel>;
  @observable contracts: SettingsResponseModel<SettingsContractsModel>;
  @observable customId: SettingsResponseModel<SettingsCustomIdModel>;
  @observable userKycConfig: SettingsResponseModel<KycConfigModel>;
  @observable kycConfig: SettingsResponseModel<KycSettingsModel>;
  @observable footerConfig: SettingsResponseModel<SettingsFooterModel>;
  @observable referralTreeConfig: SettingsResponseModel<ReferralsTreeConfigModel>;
  @observable fundAccount: SettingsResponseModel<FundAccountConfigModel>;
  @observable dollarToken: SettingsResponseModel<DollarTokenConfigModel>;
  @observable dashboardPage: SettingsResponseModel<SettingsDashboardPageModel>;
  @observable auth: SettingsResponseModel<SettingsAuthModel>;
  @observable cryptoIconsConfig: SettingsResponseModel<SettingsCryptoIconsModel>;
  @observable vestingWhitelistConfig: SettingsResponseModel<SettingsVestingWhitelistModel>;
  @observable contractsConfig: SettingsResponseModel<SettingsContractsConfigModel>;
  @observable vestingPoolsConfig: SettingsResponseModel<SettingsVestingPoolsModel>;
  @observable networksConfig: SettingsResponseModel<SettingsNetworksModel>;
  @observable bridgeConfig: SettingsResponseModel<SettingsBridgeModel>;
  @observable walletsConfig: SettingsResponseModel<SettingsWalletModel>;
  @observable sideBarConfig: SettingsResponseModel<SettingsSideBarModel>;
  @observable leaderboardConfig: SettingsResponseModel<SettingsLeaderboardModel>;
  @observable headerConfig: SettingsResponseModel<SettingsHeaderModel>;
  @observable anotherAddressConfig: SettingsResponseModel<SettingsAnotherAddressModel>;
  @observable idoConfig: SettingsResponseModel<SettingsIdoModel>;
  @observable votingConfig: SettingsResponseModel<SettingsVotingModel>;
  @observable adminSideBarConfig: SettingsResponseModel<SettingsAdminSideBarModel>;
  @observable mainPageConfig: SettingsResponseModel<SettingsMainPageModel>;
  @observable buyTokensConfig: SettingsResponseModel<SettingsBuyTokensModel>;
  @observable membershipConfig: SettingsResponseModel<SettingsMembershipModel>;

  @observable logo: string | undefined;
  @observable favicon: string | undefined;
  @observable emailLogo: string | undefined;
  @observable menuStatus: number | boolean = false;
  @observable innerWidth: number;
  @observable isLoadingGlobalData: boolean;

  public currencyMap = {
    EUR: '€',
  };

  constructor(private http: HttpClient) {}

  @action public setIsLoadingGlobalData(value: boolean): void {
    this.isLoadingGlobalData = value;
  }

  @action getAllSettings(): Observable<any> {
    const url = ApiUrlsDictionary.settingsUpdateService();

    return this.http.get(url).pipe(
      tap((response: any) => {
        response.forEach((setting: any) => {
          switch (setting.serviceId) {
            case ServicesIdDictionary.aboutProject:
              this.settingsAbout = new SettingsResponseModel<SettingsAboutProjectModel>(
                setting,
                SettingsAboutProjectModel,
              );
              break;
            case ServicesIdDictionary.bitcoinContributions:
              this.btcSettings = new SettingsResponseModel<SettingsContributionsBtcModel>(
                setting,
                SettingsContributionsBtcModel,
              );
              break;
            case ServicesIdDictionary.blacklistCountries:
              this.blacklistCountries = new SettingsResponseModel<SettingsBlacklistCountriesModel>(
                setting,
                SettingsBlacklistCountriesModel,
              );
              break;
            case ServicesIdDictionary.transactionsExplorerLink:
              this.transactionsExplorerLinks = new SettingsResponseModel<SettingsTransactionsExplorerLinksModel>(
                setting,
                SettingsTransactionsExplorerLinksModel,
              );
              break;
            case ServicesIdDictionary.contracts:
              this.contracts = new SettingsResponseModel<SettingsContractsModel>(setting, SettingsContractsModel);
              break;
            case ServicesIdDictionary.customId:
              this.customId = new SettingsResponseModel<SettingsCustomIdModel>(setting, SettingsCustomIdModel);
              break;
            case ServicesIdDictionary.userKycConfig:
              this.userKycConfig = new SettingsResponseModel<KycConfigModel>(setting, KycConfigModel);
              break;
            case ServicesIdDictionary.kycConfig:
              this.kycConfig = new SettingsResponseModel<KycSettingsModel>(setting, KycSettingsModel);
              break;
            case ServicesIdDictionary.footer:
              this.footerConfig = new SettingsResponseModel<SettingsFooterModel>(setting, SettingsFooterModel);
              break;
            case ServicesIdDictionary.faq:
              this.faqsSettings = new SettingsResponseModel<SettingsFaqsModel>(setting, SettingsFaqsModel);
              break;
            case ServicesIdDictionary.general:
              this.settingsGeneral = new SettingsResponseModel<SettingsGeneralModel>(setting, SettingsGeneralModel);
              break;
            case ServicesIdDictionary.invoiceContributions:
              this.invoiceSettings = new SettingsResponseModel<SettingsContributionsInvoiceModel>(
                setting,
                SettingsContributionsInvoiceModel,
              );
              break;
            case ServicesIdDictionary.lookAndFeel:
              this.settingsLookAndFeel = new SettingsResponseModel<SettingsLookAndFeelModel>(
                setting,
                SettingsLookAndFeelModel,
              );
              this.logo = this.settingsLookAndFeel.settings.logo && this.settingsLookAndFeel.settings.logo.location;
              this.favicon =
                this.settingsLookAndFeel.settings.favicon && this.settingsLookAndFeel.settings.favicon.location;
              this.emailLogo =
                this.settingsLookAndFeel.settings.emailLogo && this.settingsLookAndFeel.settings.emailLogo.location;
              break;
            case ServicesIdDictionary.milestones:
              this.milestones = new SettingsResponseModel<SettingsMilestonesModel>(setting, SettingsMilestonesModel);
              break;
            case ServicesIdDictionary.options:
              this.settingsOptions = new SettingsResponseModel<SettingsOptionsModel>(setting, SettingsOptionsModel);
              break;
            case ServicesIdDictionary.userRegistrationConfig:
              this.userRegistrationConfig = new SettingsResponseModel<SettingsUserRegistrationsConfigModel>(
                setting,
                SettingsUserRegistrationsConfigModel,
              );
              break;
            case ServicesIdDictionary.referral:
              this.settingsReferral = new SettingsResponseModel<ReferralsConfigModel>(setting, ReferralsConfigModel);
              break;
            case ServicesIdDictionary.referral12:
              this.settingsReferral12 = new SettingsResponseModel<SettingsReferral12Model>(
                setting,
                SettingsReferral12Model,
              );
              break;
            case ServicesIdDictionary.referralTree:
              this.referralTreeConfig = new SettingsResponseModel<ReferralsTreeConfigModel>(
                setting,
                ReferralsTreeConfigModel,
              );
              break;
            case ServicesIdDictionary.fundAccount:
              this.fundAccount = new SettingsResponseModel<FundAccountConfigModel>(setting, FundAccountConfigModel);
              break;
            case ServicesIdDictionary.dollarToken:
              this.dollarToken = new SettingsResponseModel<DollarTokenConfigModel>(setting, DollarTokenConfigModel);
              break;
            case ServicesIdDictionary.dashboardPage:
              this.dashboardPage = new SettingsResponseModel<SettingsDashboardPageModel>(
                setting,
                SettingsDashboardPageModel,
              );
              break;
            case ServicesIdDictionary.auth:
              this.auth = new SettingsResponseModel<SettingsAuthModel>(setting, SettingsAuthModel);
              break;
            case ServicesIdDictionary.cryptoIconsConfig:
              this.cryptoIconsConfig = new SettingsResponseModel<SettingsCryptoIconsModel>(
                setting,
                SettingsCryptoIconsModel,
              );
              break;
            case ServicesIdDictionary.vestingWhitelist:
              this.vestingWhitelistConfig = new SettingsResponseModel<SettingsVestingWhitelistModel>(
                setting,
                SettingsVestingWhitelistModel,
              );
              break;
            case ServicesIdDictionary.contractsConfig:
              this.contractsConfig = new SettingsResponseModel<SettingsContractsConfigModel>(
                setting,
                SettingsContractsConfigModel,
              );
              break;
            case ServicesIdDictionary.vestingPoolsConfig:
              this.vestingPoolsConfig = new SettingsResponseModel<SettingsVestingPoolsModel>(
                setting,
                SettingsVestingPoolsModel,
              );
              break;
            case ServicesIdDictionary.networksConfig:
              this.networksConfig = new SettingsResponseModel<SettingsNetworksModel>(setting, SettingsNetworksModel);
              break;
            case ServicesIdDictionary.bridgeConfig:
              this.bridgeConfig = new SettingsResponseModel<SettingsBridgeModel>(setting, SettingsBridgeModel);
              break;
            case ServicesIdDictionary.walletsConfig:
              this.walletsConfig = new SettingsResponseModel<SettingsWalletModel>(setting, SettingsWalletModel);
              break;
            case ServicesIdDictionary.sideBarConfig:
              this.sideBarConfig = new SettingsResponseModel<SettingsSideBarModel>(setting, SettingsSideBarModel);
              break;
            case ServicesIdDictionary.leaderboardConfig:
              this.leaderboardConfig = new SettingsResponseModel<SettingsLeaderboardModel>(
                setting,
                SettingsLeaderboardModel,
              );
              break;
            case ServicesIdDictionary.headerConfig:
              this.headerConfig = new SettingsResponseModel<SettingsHeaderModel>(setting, SettingsHeaderModel);
              break;
            case ServicesIdDictionary.anotherAddressConfig:
              this.anotherAddressConfig = new SettingsResponseModel<SettingsAnotherAddressModel>(
                setting,
                SettingsAnotherAddressModel,
              );
              break;
            case ServicesIdDictionary.idoConfig:
              this.idoConfig = new SettingsResponseModel<SettingsIdoModel>(setting, SettingsIdoModel);
              break;
            case ServicesIdDictionary.votingConfig:
              this.votingConfig = new SettingsResponseModel<SettingsVotingModel>(setting, SettingsVotingModel);
              break;
            case ServicesIdDictionary.adminSideBarConfig:
              this.adminSideBarConfig = new SettingsResponseModel<SettingsAdminSideBarModel>(
                setting,
                SettingsAdminSideBarModel,
              );
              break;
            case ServicesIdDictionary.mainPageConfig:
              this.mainPageConfig = new SettingsResponseModel<SettingsMainPageModel>(setting, SettingsMainPageModel);
              break;
            case ServicesIdDictionary.buyTokensConfig:
              this.buyTokensConfig = new SettingsResponseModel<SettingsBuyTokensModel>(setting, SettingsBuyTokensModel);
              break;
            case ServicesIdDictionary.membershipConfig:
              this.membershipConfig = new SettingsResponseModel<SettingsMembershipModel>(
                setting,
                SettingsMembershipModel,
              );
              break;
          }
        });
      }),
    );
  }

  @action getContractSettings(): Observable<SettingsResponseModel<SettingsContractsModel>> {
    return this.getSettings<SettingsContractsModel>(ServicesIdDictionary.contracts, SettingsContractsModel).pipe(
      tap((contractsSettings) => {
        this.contracts = contractsSettings;
      }),
    );
  }

  @action getBuyTokensSettings(): Observable<SettingsResponseModel<SettingsBuyTokensModel>> {
    return this.getSettings<SettingsBuyTokensModel>(ServicesIdDictionary.buyTokensConfig, SettingsBuyTokensModel).pipe(
      tap((buyTokensSettings) => {
        this.buyTokensConfig = buyTokensSettings;
      }),
    );
  }

  @action getContractsConfigSettings(): Observable<SettingsResponseModel<SettingsContractsConfigModel>> {
    return this.getSettings<SettingsContractsConfigModel>(
      ServicesIdDictionary.contractsConfig,
      SettingsContractsConfigModel,
    ).pipe(
      tap((contractsConfigSettings) => {
        this.contractsConfig = contractsConfigSettings;
      }),
    );
  }

  @action getCustomIdSettings(): Observable<SettingsResponseModel<SettingsCustomIdModel>> {
    return this.getSettings<SettingsCustomIdModel>(ServicesIdDictionary.customId, SettingsCustomIdModel).pipe(
      tap((customIdSettings) => {
        this.customId = customIdSettings;
      }),
    );
  }

  @action getGeneralSettings(): Observable<SettingsResponseModel<SettingsGeneralModel>> {
    return this.getSettings<SettingsGeneralModel>(ServicesIdDictionary.general, SettingsGeneralModel).pipe(
      tap((generalSettings) => {
        this.settingsGeneral = generalSettings;
      }),
    );
  }

  @action getOptionsSettings(): Observable<SettingsResponseModel<SettingsOptionsModel>> {
    return this.getSettings<SettingsOptionsModel>(ServicesIdDictionary.options, SettingsOptionsModel).pipe(
      tap((options) => {
        this.settingsOptions = options;
      }),
    );
  }

  @action getLookAndFeelSettings(): Observable<SettingsResponseModel<SettingsLookAndFeelModel>> {
    return this.getSettings<SettingsLookAndFeelModel>(ServicesIdDictionary.lookAndFeel, SettingsLookAndFeelModel).pipe(
      tap((settings) => {
        this.settingsLookAndFeel = settings;
        this.logo = settings.settings.logo && settings.settings.logo.location;
        this.favicon = settings.settings.favicon && settings.settings.favicon.location;
      }),
    );
  }

  @action getDollarTokenSettings(): Observable<SettingsResponseModel<DollarTokenConfigModel>> {
    return this.getSettings<DollarTokenConfigModel>(ServicesIdDictionary.dollarToken, DollarTokenConfigModel).pipe(
      tap((settings) => {
        this.dollarToken = settings;
      }),
    );
  }

  @action getMilestones(): Observable<SettingsResponseModel<SettingsMilestonesModel>> {
    return this.getSettings<SettingsMilestonesModel>(ServicesIdDictionary.milestones, SettingsMilestonesModel);
  }

  @action getFaqsSettings(): Observable<SettingsResponseModel<SettingsFaqsModel>> {
    return this.getSettings<SettingsFaqsModel>(ServicesIdDictionary.faq, SettingsFaqsModel).pipe(
      tap((settings) => {
        this.faqsSettings = settings;
      }),
    );
  }

  @action getAboutProject(): Observable<SettingsResponseModel<SettingsAboutProjectModel>> {
    return this.getSettings<SettingsAboutProjectModel>(
      ServicesIdDictionary.aboutProject,
      SettingsAboutProjectModel,
    ).pipe(
      tap((settingsAbout) => {
        this.settingsAbout = settingsAbout;
      }),
    );
  }

  @action getNetworkSettings(): Observable<SettingsResponseModel<SettingsNetworksModel>> {
    return this.getSettings<SettingsNetworksModel>(ServicesIdDictionary.networksConfig, SettingsNetworksModel).pipe(
      tap((networksConfig) => {
        this.networksConfig = networksConfig;
      }),
    );
  }
  @action getWalletsSettings(): Observable<SettingsResponseModel<SettingsWalletModel>> {
    return this.getSettings<SettingsWalletModel>(ServicesIdDictionary.walletsConfig, SettingsWalletModel).pipe(
      tap((walletsConfig) => {
        this.walletsConfig = walletsConfig;
      }),
    );
  }

  @action getSideBarConfig(): Observable<SettingsResponseModel<SettingsSideBarModel>> {
    return this.getSettings<SettingsSideBarModel>(ServicesIdDictionary.sideBarConfig, SettingsSideBarModel).pipe(
      tap((sideBarConfig) => {
        this.sideBarConfig = sideBarConfig;
      }),
    );
  }

  @action setAboutProjectSettings(moduleSettings: SettingsResponseModel<SettingsAboutProjectModel>): Observable<any> {
    const url = ApiUrlsDictionary.settingsUpdateService(moduleSettings.serviceId);

    return this.http.put(url, {
      body: moduleSettings.settings.body,
      isPublic: moduleSettings.settings.isPublic,
    });
  }

  @action getSettings<T>(
    serviceId: ServicesIdDictionary,
    modelClass: new (...args: any[]) => T,
  ): Observable<SettingsResponseModel<T>> {
    const url = ApiUrlsDictionary.settingsUpdateService(serviceId);

    return this.http.get(url).pipe(map((response: any) => new SettingsResponseModel<T>(response, modelClass)));
  }

  @action setGeneralSettings(moduleSettings: SettingsResponseModel<SettingsGeneralModel>): Observable<unknown> {
    const url = ApiUrlsDictionary.settingsUpdateService(moduleSettings.serviceId);
    const setting = moduleSettings.settings;
    const request = {
      projectName: setting.projectName,
      termsUrl: setting.termsUrl,
      policyUrl: setting.policyUrl,
      displayCurrency: setting.displayCurrency,
      contactUsEmail: setting.contactUsEmail,
      defaultLocale: setting.defaultLocale,
      timeZone: setting.timeZone,
      videoUrl: setting.videoUrl,
      purchaseAgreementLink: setting.purchaseAgreementLink,
      purchaseAgreementTitle: setting.purchaseAgreementTitle,
      kycType: setting.kycType,
      saleStage: setting.saleStage,
    };

    return this.http.put(url, request);
  }

  @action setMilestonesSettings(moduleSettings: SettingsResponseModel<SettingsMilestonesModel>): Observable<unknown> {
    const url = ApiUrlsDictionary.settingsUpdateService(moduleSettings.serviceId);
    const setting = moduleSettings.settings;
    const request = {
      enabled: setting.enabled,
      currency: setting.currency,
      milestones: setting.milestones,
      statisticForInvestorIsDisplayed: setting.statisticForInvestorIsDisplayed,
      maxSupply: setting.maxSupply,
      alreadyCollected: setting.alreadyCollected,
    };

    return this.http.put(url, request);
  }

  @action setSettings<T>(moduleSettings: SettingsResponseModel<T>): Observable<any> {
    const url = ApiUrlsDictionary.settingsUpdateService(moduleSettings.serviceId);

    return this.http.put(url, moduleSettings.settings);
  }

  @action updateSettingsGeneral(value: SettingsGeneralModel) {
    this.settingsGeneral.updateSettings(value);
  }

  @action updateSettingsLookAndFeel(value: SettingsResponseModel<SettingsLookAndFeelModel>) {
    this.settingsLookAndFeel = value;
  }

  @action updateSettingsFaqs(value: SettingsResponseModel<SettingsFaqsModel>) {
    this.faqsSettings = value;
  }

  @action updateLogo(value: string) {
    this.logo = value;
  }

  @action updateFavicon(value: string) {
    this.favicon = value;
  }

  @action updateEmailLogo(value: string) {
    this.emailLogo = value;
  }

  @action setImage(type: string, data: FormData): Observable<any> {
    const url = ApiUrlsDictionary.settingsImage(type);

    return this.http.post(url, data);
  }

  // TODO: move to another module
  @action changeCancelTransactionStatus(transaction: TransactionModel, customStatus?: ContributionStatus): void {
    customStatus = customStatus || ContributionStatus.Rescind;
    const date = new Date().getTime();
    if (transaction.status === ContributionStatus.Processed) {
      const days = this.cancelDurationInDays || 0;
      const trDate = new Date(transaction.date);
      trDate.setDate(trDate.getDate() + days);
      if (date < trDate.getTime()) {
        transaction.status = customStatus;
      }
    }
  }

  @computed get projectName(): string {
    if (this.settingsGeneral && this.settingsGeneral.settings && this.settingsGeneral.settings.projectName) {
      return this.settingsGeneral.settings.projectName;
    }

    return 'null';
  }

  @computed get isShowFaqs() {
    const faqsSettings = this.faqsSettings;

    return faqsSettings != null && faqsSettings.settings.enabled && faqsSettings.settings.questions.length > 0;
  }

  @computed get isShowAboutProject() {
    const settingsAbout = this.settingsAbout;

    return settingsAbout != null && settingsAbout.settings.isPublic && settingsAbout.settings.body != null;
  }

  @computed get isShowReferralTree() {
    const settingsReferralTree = this.referralTreeConfig;

    return settingsReferralTree != null && settingsReferralTree.settings.enabled;
  }
  @computed get enabledKYCPageAuthOnly() {
    const settingsKyc = this.kycConfig;

    return settingsKyc != null && settingsKyc.settings.enabledPageAuthOnly;
  }

  @computed get cancelDurationInDays(): number {
    const cancel: any = {};
    const btcCancel = this.btcSettings && this.btcSettings.settings.cancel;
    cancel['BTC'] = (btcCancel && btcCancel.enabled && btcCancel?.durationInDays) || 0;
    const fiatCancel = this.invoiceSettings && this.invoiceSettings.settings?.cancelPerCurrencies;
    if (fiatCancel) {
      Object.keys(fiatCancel).forEach((key) => {
        cancel[key] = (fiatCancel[key] && fiatCancel[key].enabled && fiatCancel[key]?.durationInDays) || 0;
      });
    }

    return cancel;
  }

  @computed get isAuth1FA(): boolean {
    return this.auth && this.auth.settings && this.auth.settings.method === SettingsAuthDictionary.Auth1FA;
  }
  @computed get isAuthCryptoWallet(): boolean {
    return this.auth && this.auth.settings && this.auth.settings.method === SettingsAuthDictionary.AuthCryptoWallet;
  }

  @computed get isMultiNetwork(): boolean {
    return (
      this.networksConfig &&
      this.networksConfig.settings &&
      this.networksConfig.settings.enabled &&
      this.networksConfig.settings.isMultiNetwork
    );
  }

  @computed get allowedNetworks(): NetworkModel[] {
    return (
      this.networksConfig &&
      this.networksConfig.settings &&
      this.networksConfig.settings.enabled &&
      this.networksConfig.settings.allowedNetworks
    );
  }

  @computed get isEnabledVestingWhitelist(): boolean {
    return (
      this.vestingWhitelistConfig &&
      this.vestingWhitelistConfig.settings &&
      this.vestingWhitelistConfig.settings.enabled
    );
  }

  @computed get isRankReferralTree() {
    const settingsReferralTree = this.referralTreeConfig;

    return settingsReferralTree != null && settingsReferralTree.settings.treeType === TreeType.WITH_RANKS;
  }

  @computed get isSimpleReferralTree() {
    const settingsReferralTree = this.referralTreeConfig;

    return settingsReferralTree != null && settingsReferralTree.settings.treeType === TreeType.SIMPLE;
  }

  @action setInnerWidth(value: number) {
    this.innerWidth = value;
  }
  @computed get isMobile() {
    return this.innerWidth <= AppSettings.MOBILE_WIDTH;
  }

  @computed get isPublicSaleMode() {
    return this.settingsGeneral && this.settingsGeneral.settings.saleStage === SaleModeTypeDictionary.Public;
  }
  @computed get isPrivateSaleMode() {
    return this.settingsGeneral && this.settingsGeneral.settings.saleStage === SaleModeTypeDictionary.Private;
  }

  @computed get allowedProviders(): ProviderModel[] {
    return (
      this.walletsConfig &&
      this.walletsConfig.settings &&
      this.walletsConfig.settings.enabled &&
      this.walletsConfig.settings.allowedProviders
    );
  }
  @computed get infuraProjectId(): string {
    return (
      this.walletsConfig &&
      this.walletsConfig.settings &&
      this.walletsConfig.settings.enabled &&
      this.walletsConfig.settings.infuraProjectId
    );
  }

  @computed get leaderboardConfigFireBaseSettings(): FirebaseConfigModel {
    return (
      this.leaderboardConfig &&
      this.leaderboardConfig.settings &&
      this.leaderboardConfig.settings.enabled &&
      this.leaderboardConfig.settings.fireBaseSettings
    );
  }

  public getNetworkParams(): Observable<NetworkParamsModel[]> {
    return this.http.get<NetworkParamsModel[]>(this.networksConfig.settings.networksParamsUrl);
  }
}
