import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiUrlsDictionary } from '@tokengear-common/modules/api';

import { environment } from '@environments/environment';

import { getSettingsContributionsLtc } from './settings-contributions-ltc.mock-data';

@Injectable({
  providedIn: 'root',
})
export class SettingsContributionsLtcInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      environment.mock &&
      environment.interceptors.SettingsContributionsLtcInterceptor &&
      request.url.endsWith(ApiUrlsDictionary.settingsContributionsLtc())
    ) {
      let response = new HttpResponse({ status: 204 });

      if (request.method === 'PUT') {
        response = new HttpResponse({ status: 204 });
      }

      if (request.method === 'GET') {
        response = new HttpResponse({ status: 200, body: getSettingsContributionsLtc() });
      }

      return of(response);
    }

    return next.handle(request);
  }
}
