import { Directive, ElementRef, HostBinding } from '@angular/core';

import { SettingsService } from '@tokengear-common/modules/settings';
import { LookAndFeelService } from '@tokengear-common/services';

@Directive({
  selector: '[appThemeClass]',
})
export class ThemeClassDirective {
  @HostBinding('class') public elementClass;

  constructor(
    private lookAndFeelService: LookAndFeelService,
    private settingsService: SettingsService,
    private elementRef: ElementRef<HTMLElement>,
  ) {
    this.elementClass = `${this.lookAndFeelService.projectThemeClass(
      this.settingsService?.settingsLookAndFeel?.settings?.contentType,
    )} ${this.elementRef.nativeElement.className}`;
  }
}
