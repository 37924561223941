import BigNumber from 'bignumber.js';
import { computed } from 'mobx';
import { Observable } from 'rxjs';

import { DEFAULT_DECIMALS, EMPTY_ADDRESS, EthUnits, WEB3 } from '@app/integrations/dictionaries/web3.dictionary';

export function isProviderExists(): boolean {
  return Boolean(window[WEB3]);
}

export async function sleep(time: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export async function waitUntil(condition: () => boolean, time: number = 200) {
  if (condition()) {
    return true;
  }
  await sleep(time);

  return waitUntil(condition, time);
}

export function shortEthAddress(firstSymbolNumb: number, address: string): string {
  return `${address.substring(0, firstSymbolNumb)}...${address.slice(address.length - 4)}`;
}

export function normalizeAmount(amount: BigNumber.Value, decimals: number = DEFAULT_DECIMALS) {
  const parts = amount.toString().split('.');
  parts[1] = (parts[1] || '').substr(0, decimals);

  return parts.join('.');
}

export function toBN(value: BigNumber.Value | string | number) {
  return new BigNumber(value);
}

export function fromWei(amount: BigNumber.Value, decimals: number = DEFAULT_DECIMALS): string {
  const web3 = window[WEB3];
  let unit: EthUnits = EthUnits.ether;
  // amount = amount.length > DEFAULT_DECIMALS ? parseFloat(amount).toFixed(DEFAULT_DECIMALS): amount;
  switch (decimals) {
    case 1:
      unit = EthUnits.wei;
      break;
    case 3:
      unit = EthUnits.kwei;
      break;
    case 6:
      unit = EthUnits.mwei;
      break;
    case 8: {
      amount = toWei(amount, DEFAULT_DECIMALS);
      unit = EthUnits.ether;
      break;
    }
    case 9:
      unit = EthUnits.gwei;
      break;
    case 18:
      unit = EthUnits.ether;
      break;
  }

  return web3.utils.fromWei(amount || '0', unit);
}

export function toWei(amount: BigNumber.Value, decimals: number = DEFAULT_DECIMALS): string | any {
  const web3 = window[WEB3];
  let unit: EthUnits = EthUnits.ether;
  switch (decimals) {
    case 1:
      unit = EthUnits.wei;
      break;
    case 3:
      unit = EthUnits.kwei;
      break;
    case 6:
      unit = EthUnits.mwei;
      break;
    case 9:
      unit = EthUnits.gwei;
      break;
    case 18:
      unit = EthUnits.ether;
      break;
  }

  return web3.utils.toWei(normalizeAmount(amount || 0, decimals || DEFAULT_DECIMALS), unit);
}

export function parsePercentDivider(amount: string, pow: number): number {
  const web3 = window[WEB3];
  const divider = web3.utils.toBN(10).pow(web3.utils.toBN(pow));
  let result = new web3.utils.BN(amount).div(divider).toNumber();
  result = result / 100;

  return result;
}

export function lessOrEqual(amount1: string, amount2: string) {
  const web3 = window[WEB3];
  amount1 = web3.utils.toWei(toBN(amount1).toFixed(DEFAULT_DECIMALS));
  amount2 = web3.utils.toWei(toBN(amount2).toFixed(DEFAULT_DECIMALS));

  return new web3.utils.BN(amount1).lte(new web3.utils.BN(amount2));
}

export function greaterThen(amount1: string, amount2: string) {
  const web3 = window[WEB3];
  amount1 = web3.utils.toWei(toBN(amount1).toFixed(DEFAULT_DECIMALS));
  amount2 = web3.utils.toWei(toBN(amount2).toFixed(DEFAULT_DECIMALS));

  return new web3.utils.BN(amount1).gt(new web3.utils.BN(amount2));
}

export function greaterOrEqual(amount1: string, amount2: string) {
  const web3 = window[WEB3];
  amount1 = web3.utils.toWei(toBN(amount1).toFixed(DEFAULT_DECIMALS));
  amount2 = web3.utils.toWei(toBN(amount2).toFixed(DEFAULT_DECIMALS));

  return new web3.utils.BN(amount1).gte(new web3.utils.BN(amount2));
}

export function lessThen(amount1: string, amount2: string) {
  const web3 = window[WEB3];
  amount1 = web3.utils.toWei(parseFloat(amount1).toFixed(DEFAULT_DECIMALS));
  amount2 = web3.utils.toWei(parseFloat(amount2).toFixed(DEFAULT_DECIMALS));

  return new web3.utils.BN(amount1).lt(new web3.utils.BN(amount2));
}

export function sumBigNumbers(arrayNumbers: any[]) {
  let total = new BigNumber(0);
  arrayNumbers.forEach((item) => {
    total = new BigNumber(total).plus(new BigNumber(item));
  });

  return total.toFixed();
}

export function plusBigNumbers(value1: string, value2: string) {
  let result = new BigNumber(0);
  result = new BigNumber(value1).plus(new BigNumber(value2));

  return result.toFixed();
}

export function minusBigNumbers(value1: string, value2: string) {
  let result = new BigNumber(0);
  result = new BigNumber(value1).minus(new BigNumber(value2));

  return result.toFixed();
}
export function divBigNumbers(value1: string, value2: string) {
  let result = new BigNumber(0);
  result = new BigNumber(value1).div(new BigNumber(value2));

  return result.toFixed();
}

export function multipliedByBigNumbers(value1: string, value2: string) {
  let result = new BigNumber(0);
  result = new BigNumber(value1).multipliedBy(new BigNumber(value2));

  return result.toFixed();
}

export function cleanObj(obj) {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }

  return obj;
}

export function toPlainString(num): string {
  return `${+num}`.replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, (a, b, c, d, e) =>
    e < 0 ? `${b}0.${Array(1 - e - c.length).join('0')}${c}${d}` : b + c + d + Array(e - d.length + 1).join('0'),
  );
}

export async function fireEvent(eventName: string) {
  let eventClass: string;
  switch (eventName) {
    case 'click': // Dispatching of 'click' appears to not work correctly in Safari. Use 'mousedown' or 'mouseup' instead.
    case 'mousedown':
    case 'mouseup':
      eventClass = 'MouseEvents';
      break;
    case 'focus':
    case 'change':
    case 'blur':
    case 'select':
      eventClass = 'HTMLEvents';
      break;
    default:
      // eslint-disable-next-line @typescript-eslint/quotes
      throw new Error(`fireEvent: Couldn't find an event class for event '${eventName}'.`);
  }
  const event = window.document.createEvent(eventClass);
  event.initEvent(eventName, true, true);
  window.document.body.dispatchEvent(event);
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, 1000);
  });
}

export function fromMobx<T>(expression: () => T): Observable<T> {
  return new Observable((observer) => {
    const computedValue = computed(expression);
    const disposer = computedValue.observe((changes) => {
      observer.next(changes.newValue);
    }, true);

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      disposer && disposer();
    };
  });
}

export function isValidAddress(address: string, chainId?: number): boolean {
  const web3 = window[WEB3];

  return web3.utils.isAddress(address, chainId);
}

export function valueInPercent(item1: string, item2: string): string {
  return `${Number(multipliedByBigNumbers(divBigNumbers(item1, item2), '100')).toFixed(2)}%`;
}

export function indexOf(arr, q): number {
  return arr.findIndex((item) => q.toLowerCase() === item.toLowerCase());
}

export function isValidDate(value: any): value is Date {
  return value instanceof Date && !isNaN(value as any);
}

export function compare(a: number | string | boolean | Date, b: number | string | boolean | Date, isAsc: boolean) {
  if (a && b) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  return isAsc ? 1 : -1;
}
export function compareDate(a: Date, b: Date, isAsc: boolean) {
  if (a && b) {
    return (a.getTime() - b.getTime()) * (isAsc ? 1 : -1);
  }

  return isAsc ? 1 : -1;
}

export function compareNumbers(a: number, b: number, isAsc: boolean) {
  if (!isNaN(a) && !isNaN(b)) {
    return (a - b) * (isAsc ? 1 : -1);
  }

  return isAsc ? 1 : -1;
}

export function calculatePercent(percent: number | string, num: number | string) {
  return (Number(percent) / 100) * Number(num);
}

export function isETH(address: string): boolean {
  return address === EMPTY_ADDRESS;
}

export function groupByKey(array, key): object {
  return array.reduce((acc, item) => {
    if (item[key]) {
      acc[`${item[key]}`] = acc[`${item[key]}`] || [];
      acc[`${item[key]}`].push(item);
    }

    return acc;
  }, {});
}

export function splitToSymbol(value: number | string, symbol: string): string {
  return value.toString().split(symbol)[0];
}
