import { observable } from 'mobx-angular';

import { StakingFarmingPoolsType } from '@domain-modules/pools/dectionaries/staking-farming-pools.dictionary';
import { StakingPoolsPairModel } from '@domain-modules/pools/models/staking-pools-pair.model';

export class StakingPoolsItemModel {
  @observable contractAddress: string;
  @observable id: string;
  @observable pair: StakingPoolsPairModel[];
  @observable stage: string;
  @observable status: string;
  @observable tenantId: string;
  @observable token: string;
  @observable stakedToken: string;
  @observable stakedTokenDecimal: number;
  @observable token0: string;
  @observable token1: string;
  @observable totalLiquidity: number;
  @observable type: StakingFarmingPoolsType;
  @observable apy: string;
  @observable currentReward: string;
  @observable unclaimedReward: number;
  @observable balanceForUnStakedToken: string;
  @observable balanceForStakedToken: string;
  @observable harvestInProgress?: boolean;
  @observable poolShareLiquidity?: string;
  @observable perDay?: string;
  @observable perWeek?: string;
  @observable perMonth?: string;
  @observable perYear?: string;
  @observable depositFee?: string;
  @observable startBlock?: string;
  @observable endBlock?: string;
  @observable progressMax?: number;
  @observable progressCurrent?: number;

  constructor(params: StakingPoolsItemModel) {
    Object.assign(this, params);
  }
}
