import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { action, observable } from 'mobx-angular';

import { AuthService } from '@tokengear-common/modules/auth';
import { LookAndFeelService } from '@tokengear-common/services';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  @observable direction = true;

  constructor(
    private translate: TranslateService,
    private lookAndFeelService: LookAndFeelService,
    private authService: AuthService,
  ) {}

  public init(): void {
    this.authService.init();
    this.lookAndFeelService.run();
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  @action changeDirection(): void {
    this.direction = !this.direction;
  }
}
