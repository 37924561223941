<div *mobxAutorun>
  <div class="filter">
    <div class="filter">
      <div class="input-field">
        <input
          id="filter-counties"
          class="filter__input"
          type="search"
          placeholder="Enter country"
          [value]="nameFilter"
          (input)="onChangeSearch($event.target.value)"
        />

        <a *ngIf="nameFilter !== ''" class="input-field__btn">
          <mat-icon class="input-field__icon" (click)="onChangeSearch('')">close</mat-icon>
        </a>
      </div>

      <label for="filter-counties" class="filter__label">
        {{ label }}
      </label>
    </div>

    <button class="check-list__select" mat-button [matMenuTriggerFor]="appMenu">
      {{ checkedCount }}:
      {{ 'ADMIN_DASHBOARD__BLACKLIST_COUNTRY__SELECTED' | translate }}
      <i class="material-icons">keyboard_arrow_down</i>
    </button>
  </div>

  <mat-menu #appMenu="matMenu">
    <button mat-menu-item (click)="onSelectAll(true)">
      {{ 'ADMIN_DASHBOARD__BLACKLIST_COUNTRY__SELECTED_ALL' | translate }}
    </button>

    <button mat-menu-item (click)="onSelectAll(false)">
      {{ 'ADMIN_DASHBOARD__BLACKLIST_COUNTRY__SELECTED_NONE' | translate }}
    </button>
  </mat-menu>

  <div class="check-list">
    <ng-container *ngFor="let item of countries">
      <mat-checkbox
        color="basic"
        class="check-list__item col-lg-3 col-md-4 col-sm-6 col-xs-12"
        [name]="item.country.code"
        [checked]="item.isChecked"
        [disabled]="item.disabled"
        (change)="onChangeCheck(item, $event.checked)"
      >
        <span class="check-list__icon flag-icon" [ngClass]="'flag-icon-' + item.country.code | lowercase"></span>

        <span class="check-list__name">
          {{ item.country.name }}
          ({{ item.country.code }})
        </span>
      </mat-checkbox>
    </ng-container>
  </div>
</div>

<app-save-settings [isValid]="true" *ngIf="isChangedList" (saveSettings)="saveChange.emit()"></app-save-settings>
