import { observable } from 'mobx-angular';

import { SettingsAdminSideBarItemModel } from './settings-admin-side-bar-item.model';

export class SettingsAdminSideBarGroupModel {
  @observable label: string;
  @observable orderBy: number;
  @observable navigation: SettingsAdminSideBarItemModel[];

  constructor(data: SettingsAdminSideBarGroupModel) {
    Object.assign(this, data);
  }
}
