<mat-card *mobxAutorun [class.card-box-border]="showBorder">
  <mat-card-header class="card-block__header">
    {{ 'USER_STATISTICS__AFFILIATE_STATISTICS__HEADER' | translate }}
  </mat-card-header>

  <div class="block-line"></div>
  <mat-card-content>
    <div class="row">
      <ng-container *ngIf="isRankReferralTree">
        <div class="col-xs-6">
          <div class="card-block__header-text">
            {{ 'USER_STATISTICS__AFFILIATE_STATISTICS__CURRENT_RANK' | translate }}
          </div>
          <div class="card-block__data-text">{{ affiliateStatistic?.currentRank || '--' }}</div>
        </div>
        <div class="col-xs-6">
          <div class="card-block__header-text">
            {{ 'USER_STATISTICS__AFFILIATE_STATISTICS__NEXT_RANK' | translate }}
          </div>
          <div class="card-block__data-text">{{ affiliateStatistic?.nextRank || '--' }}</div>
        </div>
      </ng-container>
      <div class="col-xs-6">
        <div class="card-block__header-text">
          {{ 'USER_STATISTICS__AFFILIATE_STATISTICS__DIRECT_REFERRALS' | translate }}
        </div>
        <div class="card-block__data-text">{{ affiliateStatistic?.directReferrals || '--' }}</div>
      </div>
      <div class="col-xs-6">
        <div class="card-block__header-text">
          {{ 'USER_STATISTICS__AFFILIATE_STATISTICS__TOTAL_MEMBERSHIP' | translate }}
        </div>
        <div class="card-block__data-text">{{ affiliateStatistic?.totalMembership || '--' }}</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
