import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Host,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { FormGroupDirective, NgControl } from '@angular/forms';
import { EMPTY, merge, Subject } from 'rxjs';
import { debounceTime, first, takeUntil } from 'rxjs/operators';

import {
  DYNAMIC_VALIDATION_ERROR_TMPL,
  globalErrorConfig,
  shouldShowErrorDefaultFn,
  ShouldShowErrorFn,
  VALIDATION_ERRORS,
  ValidationErrorComponentModel,
  ValidationErrorConfig,
} from '@shared/form-error-handler/form-error-handler.model';

@Directive({
  selector: '[appFormErrorHandler]',
})
export class FormErrorHandlerDirective implements OnInit, OnDestroy {
  private readonly onBlur$$ = new Subject<void>();
  private readonly destroy$$ = new Subject<void>();
  private readonly submit$ = this.group?.ngSubmit.asObservable() || EMPTY;

  private errorComponentRef: ComponentRef<ValidationErrorComponentModel> | undefined;

  private readonly _errorConfig = { ...globalErrorConfig, ...(this.groupErrorConfig || undefined) };

  @HostBinding('class.modal-form__input-error')
  public isErrorFieldHighlighted = false;

  /**
   * Custom error config object.
   *
   * Use only if you need to set custom error labels for specific control.
   * To specify custom error labels for entire form on the specific DI level use {@link VALIDATION_ERRORS} InjectionToken.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input() set errorConfig(value: ValidationErrorConfig | undefined) {
    if (value) {
      Object.assign(this._errorConfig, value);
    }
  }

  /**
   * Custom function to specify when errors should be shown.
   *
   * @default: {@link shouldShowErrorDefaultFn}
   */
  @Input() shouldShowErrorFn: ShouldShowErrorFn = shouldShowErrorDefaultFn;

  /**
   * Custom component for error message.
   *
   * Use only if you need to set custom component for specific control.
   * To specify custom component for all form errors use {@link DYNAMIC_VALIDATION_ERROR_TMPL} InjectionToken.
   *
   * @default: {@link FormValidationErrorMessageComponent}
   */
  @Input() errorComponent = this.errorTmpl;

  /**
   * Flag to highlight the field if it is invalid
   */
  @Input() highlight = true;

  @HostListener('blur') onElBlur(): void {
    this.onBlur$$.next();
  }

  constructor(
    @Self() private readonly control: NgControl,
    @Host() @Optional() private readonly group: FormGroupDirective,
    @Optional() @Inject(VALIDATION_ERRORS) private readonly groupErrorConfig: ValidationErrorConfig,
    @Inject(DYNAMIC_VALIDATION_ERROR_TMPL) private readonly errorTmpl: Type<ValidationErrorComponentModel>,
    private readonly factoryResolver: ComponentFactoryResolver,
    private readonly viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    merge(
      this.control.valueChanges,
      this.control.statusChanges,
      this.submit$,
      this.onBlur$$.pipe(first()), // to detect first invalid state if no control changes happened.
    )
      .pipe(debounceTime(100), takeUntil(this.destroy$$))
      .subscribe(() => this.checkAndToggleError());
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  private checkAndToggleError(): void {
    const controlErrors = this.control.errors;
    const showError = controlErrors && this.shouldShowErrorFn(this.control);

    this.isErrorFieldHighlighted = showError && this.highlight;

    if (showError) {
      const [errorKey, errorParams] = Object.entries(controlErrors)[0];
      const translationKey = this._errorConfig[errorKey] || this._errorConfig.invalid;

      if (this.errorComponentRef?.instance.translationKey !== translationKey) {
        this.setError(translationKey, errorParams);
      }
    } else if (this.errorComponentRef) {
      this.setError(null);
    }
  }

  private setError(translationKey: string | null, params?: any): void {
    if (!this.errorComponentRef) {
      const factory = this.factoryResolver.resolveComponentFactory<ValidationErrorComponentModel>(this.errorComponent);

      this.errorComponentRef = this.viewContainerRef.createComponent(factory);
    }

    this.errorComponentRef.instance.translationKey = translationKey;
    this.errorComponentRef.instance.translationParams = params;

    this.errorComponentRef.changeDetectorRef.detectChanges();
  }
}
