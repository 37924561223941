import { observable } from 'mobx-angular';

import { SettingsFaqModel } from '../settings-faq/settings-faq.model';

export class SettingsFaqsModel {
  @observable enabled: boolean = null;
  @observable questions: SettingsFaqModel[] = null;
  @observable hasRedirectLink: boolean = null;
  @observable redirectLink: string = null;
  constructor(params: SettingsFaqsModel) {
    Object.assign(this, params);

    this.questions = this.questions.map((item) => new SettingsFaqModel(item));
  }
}
