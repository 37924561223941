import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiUrlsDictionary } from '@tokengear-common/modules/api';

import { environment } from '@environments/environment';

import { getStatsTopInvestorsMockData } from './stats-top-investors.mock-data';

@Injectable({
  providedIn: 'root',
})
export class StatsInvestorsInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      environment.mock &&
      environment.interceptors.StatsInvestorsInterceptor &&
      request.url.endsWith(ApiUrlsDictionary.statsTopInvestors()) &&
      request.method === 'GET'
    ) {
      const startDate = request.params.get('startDate');
      const endDate = request.params.get('endDate');
      const limit = request.params.get('limit');
      const response = new HttpResponse({
        status: 200,
        body: getStatsTopInvestorsMockData({
          startDate: startDate != null ? new Date(startDate) : null,
          endDate: endDate != null ? new Date(endDate) : null,
          limit: limit != null ? Number(limit) : null,
        }),
      });

      return of(response);
    }

    return next.handle(request);
  }
}
