import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ThemeClassDirective } from '@shared/directives/theme/theme-class.directive';

@NgModule({
  declarations: [ThemeClassDirective],
  imports: [CommonModule],
  exports: [ThemeClassDirective],
})
export class ThemeClassModule {}
