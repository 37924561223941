import { Component, Input, OnInit } from '@angular/core';
import { FaqModel } from '@faq/models/faq/faq.model';

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
})
export class AccordionItemComponent implements OnInit {
  panelOpenState = false;

  @Input() faq: FaqModel;
  @Input() index: number;

  constructor() {}

  ngOnInit() {}
}
