import { Injectable } from '@angular/core';
import { observable } from 'mobx-angular';

import { CurrencyModel } from '../';

@Injectable({
  providedIn: 'root',
})
export class CurrenciesStore {
  @observable isLoading: boolean;
  @observable currencies: CurrencyModel[];

  public setIsLoading(value: boolean): void {
    this.isLoading = value;
  }

  public setCurrencies(value: CurrencyModel[]): void {
    this.currencies = value;
  }
}
