import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ToastService {
  constructor(private snackBar: MatSnackBar, private translateService: TranslateService) {}

  public open(text: string, params?: object): void {
    this.translateService.get(text, params).subscribe((res) => {
      this.snackBar.open(res, null, { duration: 10000, panelClass: ['custom-snackbar'] });
    });
  }
}
