import { observable, action } from 'mobx-angular';

export class UsersFilterModel {
  @observable sortField: string;
  @observable sortOrder: string | number;
  @observable limit: number;
  @observable offset: number;
  @observable search?: string;
  @observable statusFilter?: string[] | any;
  @observable proofOfIncomeFilter?: string[] | any;
  @observable referral14LevelId?: string[] | any;

  constructor(params: UsersFilterModel) {
    Object.assign(this, params);
  }

  @action setSearch(value: string) {
    this.search = value;
  }

  @action setStatusFilter(value: string[]) {
    this.statusFilter = value;
  }

  @action setProofOfIncomeFilter(value: string[]) {
    this.proofOfIncomeFilter = value;
  }

  @action setReferral14LevelId(value: string[]) {
    this.referral14LevelId = value;
  }

  @action setSortField(value: string) {
    this.sortField = value;
  }

  @action setSortOrder(value: string) {
    this.sortOrder = value;
  }

  @action setLimit(value: number) {
    this.limit = value;
  }

  @action setOffset(value: number) {
    this.offset = value;
  }
}
