import { observable } from 'mobx-angular';

import { FirebaseConfigModel } from './firebase-config.model';

export class SettingsLeaderboardModel {
  @observable enabled: boolean;
  @observable fireBaseSettings: FirebaseConfigModel;

  constructor(data: SettingsLeaderboardModel) {
    Object.assign(this, data);
  }
}
