import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.isSentryActive ? 'https://ecda5fae28114a78b5f0feab95174498@o138480.ingest.sentry.io/1517904' : null,
  environment: environment.name,
  integrations: [
    new Sentry.Integrations.GlobalHandlers({
      onunhandledrejection: false,
      onerror: false,
    }),
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
