import { computed, observable, action } from 'mobx-angular';

export class HeaderPoolsFilterModel {
  @observable networkId: number;
  @observable limit: number;
  @observable offset: number;

  constructor(params: HeaderPoolsFilterModel) {
    Object.assign(this, params);
  }

  @computed get queryParams() {
    return {
      networkId: this.networkId,
      limit: this.limit,
      offset: this.offset,
    };
  }

  @computed get currentPage() {
    return this.offset + 1;
  }

  @action setLimit(value: number) {
    this.limit = value;
  }

  @action setOffset(value: number) {
    this.offset = value;
  }

  @action setNetworkId(value: number) {
    this.networkId = value;
  }
}
