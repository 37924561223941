import { observable } from 'mobx-angular';

export class BridgeAddressModel {
  @observable networkId: number;
  @observable address: string;
  @observable token: string;
  constructor(data: BridgeAddressModel) {
    Object.assign(this, data);
  }
}
