import { observable } from 'mobx-angular';

import { DomainModel } from './domain.model';
import { MessageModel } from './message.model';
import { TypesModel } from './types.model';

export class SignTypeDataModel {
  @observable types: TypesModel;
  @observable primaryType: string;
  @observable domain: DomainModel;
  @observable message: MessageModel;

  constructor(value: SignTypeDataModel) {
    Object.assign(this, value);
  }
}
