import { observable } from 'mobx-angular';

export class MessageModel {
  @observable contents: string;
  @observable deadline: number;
  @observable amount: string;
  @observable destination: string;
  @observable nonce: number;
  @observable sender: string;
  @observable token: string;
  @observable primaryType: string;

  constructor(value: MessageModel) {
    Object.assign(this, value);
  }
}
