import { observable } from 'mobx-angular';

import { RpcProviderModel } from './rpc-provider.model';

export class NetworkModel {
  @observable icon: string;
  @observable isDefault: boolean;
  @observable name: string;
  @observable networkId: number;
  @observable provider: RpcProviderModel;
  @observable disabled?: boolean;
  @observable url?: string;
  @observable blockPerDay?: number;
  constructor(data: NetworkModel) {
    Object.assign(this, data);
  }
}
