import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-transaction-loader',
  templateUrl: './transaction-loader.component.html',
  styleUrls: ['./transaction-loader.component.scss'],
})
export class TransactionLoaderComponent {
  @Input() titleText: string;
  @Input() descriptionText: string;
}
