import { observable } from 'mobx-angular';

export class TypeModel {
  @observable name: string;
  @observable type: string;

  constructor(value: TypeModel) {
    Object.assign(this, value);
  }
}
