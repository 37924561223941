import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'tsToDays' })
export class TsToDaysPipe implements PipeTransform {
  transform(value: any): any {
    const days = Math.floor(value / 60 / 60 / 24);

    return this.format(days);
  }

  public format(days) {
    const suffix = days === 1 ? '' : 's';

    return `${days} day${suffix}`;
  }
}
