import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { JiraIssueCollectorComponent } from './jira-issue-collector.component';

@NgModule({
  declarations: [JiraIssueCollectorComponent],
  imports: [CommonModule, HttpClientModule, HttpClientJsonpModule, MatProgressSpinnerModule],
  exports: [JiraIssueCollectorComponent],
})
export class JiraIssueCollectorModule {}
