import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

import { UserRolesDictionary } from '@domain-modules/users/dictionaries/user-roles.dictionary';
import { UsersService } from '@users/services/users/users.service';

import { NavigationService } from '../../navigation';
import { AuthService } from '../services/auth.service';

@Injectable()
export class NoAuthGuard implements CanLoad, CanActivate {
  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private usersService: UsersService,
  ) {}

  canLoad(route: Route): Observable<boolean> {
    return this.canActivate(route);
  }

  canActivate(route: ActivatedRouteSnapshot | Route): Observable<boolean> {
    const permission = this.getPermission(route);
    const user = this.usersService.user;
    if ((!user && this.authService.isTokenEmpty) || permission.includes(UserRolesDictionary.Guest)) {
      return of(true);
    }
    this.navigationService.navigateToDefaultTenantRoute();

    return of(false);
  }

  private getPermission(route: ActivatedRouteSnapshot | Route): string[] {
    const data = route.data;

    return data != null && data.permission != null ? data.permission : [];
  }
}
