import { NgModule } from '@angular/core';

import { PermissionNavigateAdminGuard } from './guards/permission-navigate/permission-navigate-admin.guard';
import { PermissionNavigateUserGuard } from './guards/permission-navigate/permission-navigate-user.guard';
import { NavigationService } from './services/navigation.service';

@NgModule({
  providers: [PermissionNavigateUserGuard, PermissionNavigateAdminGuard, NavigationService],
})
export class NavigationModule {}
