import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { observable } from 'mobx-angular';

import { fromMobx } from '@tokengear-common/helpers';

@Component({
  selector: 'app-animated-counter',
  templateUrl: './animated-counter.component.html',
})
export class AnimatedCounterComponent implements AfterViewInit {
  @Input() isPercent: boolean;
  @Input() duration: number;
  @Input() @observable count = 0;
  @Input() steps: number;
  @ViewChild('animatedDigit') animatedDigit: ElementRef;

  public ngAfterViewInit() {
    fromMobx(() => this.count).subscribe((count) => {
      if (count || count === 0) {
        this.animateCount();
      }
    });
  }
  private animateCount() {
    if (!this.duration) {
      this.duration = 1000;
    }
    if (typeof this.count === 'number') {
      this.counterFunc(this.count, this.duration, this.animatedDigit);
    }
  }

  private counterFunc(endValue, durationMs, element) {
    if (!this.steps) {
      this.steps = 12;
    }

    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = (endValue - 0) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = 0;
    let currentSinValue = 0;

    function step(isPercent) {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      if (element) {
        element.nativeElement.textContent = `${Math.abs(Math.floor(currentValue))} ${isPercent ? '%' : ''}`;
      }
      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    }

    step(this.isPercent);
  }
}
