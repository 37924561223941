export enum userStatusDictionary {
  Blocked = 'BLOCKED',
  Confirmed = 'CONFIRMED',
  Pending = 'PENDING',
  NotVerified = 'NOT_VERIFIED',
  Failed = 'FAILED',
  ManualChecking = 'MANUAL_CHECKING',
  InProgress = 'IN_PROGRESS',
  Expired = 'EXPIRED',
}

export enum userStatusAffiliateTreeDictionary {
  Inactive = 'INACTIVE',
  KycPassed = 'KYC_PASSED',
  MadeContribution = 'MADE_CONTRIBUTION',
  NoKyc = 'NO_KYC',
}
