import { observable } from 'mobx-angular';

import { UserRolesDictionary } from '@domain-modules/users/dictionaries/user-roles.dictionary';
import { userStatusDictionary } from '@domain-modules/users/dictionaries/user-status.dictionary';

export class UserTenantModel {
  @observable address: string;
  @observable bonusReceivedStatus: boolean;
  @observable isKycFeePaid: boolean;
  @observable kycTxId: string;
  @observable privateSaleApproved: boolean;
  @observable proofOfIncomeStatus: string;
  @observable referralClicksCount: number;
  @observable referralId: string;
  @observable referralToken: string;
  @observable role: UserRolesDictionary;
  @observable status: userStatusDictionary;
  @observable statusDescription: string;
  @observable tenantId: string;

  constructor(tenant: UserTenantModel) {
    Object.assign(this, tenant);
  }
}
