import { Pipe, PipeTransform } from '@angular/core';

import { toBN } from '@tokengear-common/helpers';

@Pipe({ name: 'symbolNumber' })
export class SymbolNumberPipe implements PipeTransform {
  transform(value: number | string): string {
    let num = toBN(value.toString());
    const map = {
      B: 1_000_000_000,
      M: 1_000_000,
      K: 1_000,
    };
    const resultMap = {
      B: 0,
      M: 0,
      K: 0,
      N: 0,
    };
    if (num.lt(map.K)) {
      return parseFloat(num.toFixed(2)).toString();
    }
    let result = '';
    while (num.gte(map.B)) {
      resultMap.B++;
      num = num.minus(map.B);
    }
    while (num.gte(map.M)) {
      resultMap.M++;
      num = num.minus(map.M);
    }
    while (num.gte(map.K)) {
      resultMap.K++;
      num = num.minus(map.K);
    }
    if (num.gte(99)) {
      resultMap.N = parseInt(num.toString().substr(0, 1), 10);
    } else {
      resultMap.N = parseFloat(num.toFixed(2));
    }
    if (resultMap.B) {
      result = `${resultMap.B}B `;
    }
    if (resultMap.M) {
      result += `${resultMap.M}M `;
    }
    if (resultMap.K || resultMap.N) {
      result += `${resultMap.K}.${resultMap.N}K `;
    }

    return result;
  }
}
