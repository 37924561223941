import { Component, Input } from '@angular/core';

import { SettingsFaqModel } from '@tokengear-common/modules/settings';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  constructor() {}

  @Input() faqs: SettingsFaqModel[];
}
