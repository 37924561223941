import { AbiItem } from 'web3-utils';

export interface ContractData {
  address: string;
  abiJson: AbiItem[] | any;
}

export interface ContractStorage {
  [index: string]: ContractData;
}

export interface AbiStorage {
  [index: string]: any;
}

export enum ContractStorageDictionary {
  uniswapContract = 'uniswapContract',
  uniswapFactoryContract = 'uniswapFactoryContract',
  planForwarderContract = 'planForwarderContract',
  exchangeContract = 'exchangeContract',
  fundForwarderContract = 'fundForwarderContract',
  aprStakeContract = 'aprStakeContract',
  contractMembership = 'contractMembership',
  wrappedEthContract = 'wrappedEthContract',
  contractToken = 'contractToken',
  paymentContract = 'paymentContract',
  shopsManagerContract = 'shopsManagerContract',
  shopsPoolContract = 'shopsPoolContract',
  managementContract = 'managementContract',
  coinExchangeToNative = 'coinExchangeToNative',
  coinDiscountExchange = 'coinDiscountExchange',
}

export enum AbiStorageDictionary {
  nftTokenAbi = 'nftTokenAbi',
  vestingPoolNewAbi = 'vestingPoolNewAbi',
  bridgeAbi = 'bridgeAbi',
  erc20Abi = 'erc20Abi',
  vestingPoolAbi = 'vestingPoolAbi',
  privateVestingPoolAbi = 'privateVestingPoolAbi',
  privatePoolAbi = 'privatePoolAbi',
  publicPoolAbi = 'publicPoolAbi',
  genesisAbi = 'genesisAbi',
  uniswapPairAbi = 'uniswapPairAbi',
  exchangeForwarder = 'exchangeForwarder',
}
