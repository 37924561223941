import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { computed } from 'mobx-angular';

import { NotifyHelper, NotifyEvent } from '@tokengear-common/helpers';
import { SettingsService, SideBarItemEvents, SideBarItemModel } from '@tokengear-common/modules/settings';

import { UsersService } from '@domain-modules/users/services/users/users.service';

@Injectable()
export class MenuService {
  private renderer: Renderer2;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
    private usersService: UsersService,
    private settingsService: SettingsService,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public disableBodyScroll() {
    this.renderer.addClass(this.document.body, 'disable-body-scroll');
  }

  public enableBodyScroll() {
    this.renderer.removeClass(this.document.body, 'disable-body-scroll');
  }

  @computed get sideBarItems(): SideBarItemModel[] {
    const user = this.usersService.user;
    if (this.settingsService.sideBarConfig && this.settingsService.sideBarConfig.settings.enabled) {
      return this.settingsService.sideBarConfig.settings.navigation
        .filter(
          (item) =>
            (item.permissions && item.permissions.length === 0) ||
            (user && item.permissions.includes(user.tenant.role)),
        )
        .sort((a, b) => a.orderBy - b.orderBy);
    }

    return null;
  }

  @computed get assetsBaseUrl(): string {
    return (
      this.settingsService.sideBarConfig &&
      this.settingsService.sideBarConfig.settings.enabled &&
      this.settingsService.sideBarConfig.settings.assetsBaseUrl
    );
  }

  public clickEvent(event: SideBarItemEvents): void {
    switch (event) {
      case SideBarItemEvents.REPORT_BAG: {
        NotifyHelper.emit(NotifyEvent.OpenJiraIssueCollector);
        break;
      }
    }
  }
}
