import { observable } from 'mobx-angular';

export class RegionModel {
  @observable regionName: string;
  @observable regionKey: string;

  constructor(model: RegionModel) {
    Object.assign(this, model);
  }
}
