<mat-card *mobxAutorun>
  <mat-card-content>
    <div class="look-inner">
      <div class="look-block look-inner_margin">
        <h3 class="look-block__headline">
          {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_1' | translate }}
        </h3>
        <p class="look-block__title">
          {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_1' | translate }}
        </p>
        <div class="img-info">
          <div class="img-block">
            <img *ngIf="currentLogoImage || logoImage" alt="logo" [src]="logoImage || currentLogoImage" />
            <app-file-input
              (Update)="onUpdateLogo($event)"
              [accept]="'.png,.jpg'"
              [minResolution]="'250,250'"
            ></app-file-input>
          </div>
          <ul class="img-info-list">
            <li class="img-info-list__item">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__IMG_INFO_1' | translate }}
            </li>
            <li class="img-info-list__item">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__IMG_INFO_2' | translate }}
            </li>
            <li class="img-info-list__button">
              <app-file-input
                (Update)="onUpdateLogo($event)"
                [fileloader]="fileLoader"
                [accept]="'.png,.jpg'"
                [minResolution]="'250,250'"
              ></app-file-input>
              <button
                class="btn btn_lg btn__primary"
                mat-button
                *ngIf="!logoImage || logoServerError"
                (click)="openFileLoader('logo')"
              >
                {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__BTN_LOAD' | translate }}

                <app-input-error-message *ngIf="logoServerError">
                  {{ logoServerError }}
                </app-input-error-message>
              </button>
              <a class="btn btn__link" (click)="onRemoveImage('logo')" *ngIf="logoImage && !logoServerError">
                {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__BTN_REMOVE' | translate }}
              </a>
              <button class="btn btn_md btn__primary" (click)="onSaveLogo()" *ngIf="logoImage && !logoServerError">
                {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__BTN_SAVE' | translate }}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="look-block look-inner_margin">
        <h3 class="look-block__headline">
          {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_2' | translate }}
        </h3>
        <p class="look-block__title">
          {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_2' | translate }}
        </p>
        <div class="img-info">
          <div class="img-block">
            <i *ngIf="!currentFaviconImage && !faviconImage" class="anticon anticon-plus img-block__icon"></i>
            <img
              *ngIf="currentFaviconImage || faviconImage"
              alt="favicon"
              [src]="faviconImage || currentFaviconImage"
            />
            <app-file-input
              (Update)="onUpdateFavicon($event)"
              [accept]="'.png,.jpg'"
              [minResolution]="'40,40'"
            ></app-file-input>
          </div>
          <ul class="img-info-list">
            <li class="img-info-list__item">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__IMG_INFO_3' | translate }}
            </li>
            <li class="img-info-list__item">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__IMG_INFO_4' | translate }}
            </li>
            <li class="img-info-list__button">
              <app-file-input
                (Update)="onUpdateFavicon($event)"
                [accept]="'.png,.jpg'"
                [fileloader]="favFileLoader"
                [minResolution]="'40,40'"
              ></app-file-input>
              <button
                class="btn btn_lg btn__primary"
                mat-button
                *ngIf="!faviconImage || faviconServerError"
                (click)="openFileLoader('fav')"
              >
                {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__BTN_LOAD' | translate }}

                <app-input-error-message *ngIf="faviconServerError">
                  {{ faviconServerError }}
                </app-input-error-message>
              </button>
              <a class="btn btn__link" (click)="onRemoveImage('favicon')" *ngIf="faviconImage && !faviconServerError">
                {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__BTN_REMOVE' | translate }}
              </a>
              <button
                class="btn btn_md btn__primary"
                (click)="onSaveFavicon()"
                *ngIf="faviconImage && !faviconServerError"
              >
                {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__BTN_SAVE' | translate }}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="look-block look-inner_margin">
        <h3 class="look-block__headline">
          {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_EMAIL_LOGO' | translate }}
        </h3>
        <p class="look-block__title">
          {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_EMAIL_LOGO' | translate }}
        </p>
        <div class="img-info">
          <div class="img-block">
            <i *ngIf="!currentEmailLogoImage && !emailLogoImage" class="anticon anticon-plus img-block__icon"></i>
            <img
              *ngIf="currentEmailLogoImage || emailLogoImage"
              alt="emailLogo"
              [src]="emailLogoImage || currentEmailLogoImage"
            />
            <app-file-input
              (Update)="onUpdateEmailLogo($event)"
              [accept]="'.png,.jpg'"
              [minResolution]="'40,40'"
            ></app-file-input>
          </div>
          <ul class="img-info-list">
            <li class="img-info-list__item">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__IMG_INFO_3_EMAIL_LOGO' | translate }}
            </li>
            <li class="img-info-list__item">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__IMG_INFO_4_EMAIL_LOGO' | translate }}
            </li>
            <li class="img-info-list__button">
              <app-file-input
                (Update)="onUpdateEmailLogo($event)"
                [accept]="'.png,.jpg'"
                [fileloader]="emailLogoFileLoader"
                [minResolution]="'40,40'"
              ></app-file-input>
              <button
                class="btn btn_lg btn__primary"
                mat-button
                *ngIf="!emailLogoImage || emailLogoServerError"
                (click)="openFileLoader('emailLogo')"
              >
                {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__BTN_LOAD' | translate }}

                <app-input-error-message *ngIf="emailLogoServerError">
                  {{ emailLogoServerError }}
                </app-input-error-message>
              </button>
              <a
                class="btn btn__link"
                (click)="onRemoveImage('emailLogo')"
                *ngIf="emailLogoImage && !emailLogoServerError"
              >
                {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__BTN_REMOVE' | translate }}
              </a>
              <button
                class="btn btn_md btn__primary"
                (click)="onSaveEmailLogo()"
                *ngIf="emailLogoImage && !emailLogoServerError"
              >
                {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__BTN_SAVE' | translate }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="block-line"></div>
    <div class="look-inner">
      <h3 class="look-block__headline col-xs-12">
        {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_3' | translate }}
      </h3>
      <div class="look-inner">
        <div class="col-lg-8 col-sm-6">
          <div class="look-inner">
            <div class="col-lg-6 col-sm-12">
              <div class="look-inner look-inner_margin">
                <mat-slide-toggle
                  class="setting-item__toggle"
                  [checked]="buttonsColorOne"
                  (change)="onToggleColors($event, 'buttons', 'buttonColorEnum', 'one', 'two')"
                  color="primary"
                ></mat-slide-toggle>
                <div class="look-block_list">
                  <div class="look-inner look-inner_center">
                    <h4 class="look-block__headline_min">
                      {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_MIN_1' | translate }}
                    </h4>
                    <div class="color-block" [style.background-color]="basicColors.purple"></div>
                  </div>
                  <p class="look-block__title">
                    {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_3' | translate }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="look-inner look-inner_margin">
                <mat-slide-toggle
                  class="setting-item__toggle"
                  [checked]="buttonsColorTwo"
                  (change)="onToggleColors($event, 'buttons', 'buttonColorEnum', 'two', 'one')"
                  color="primary"
                ></mat-slide-toggle>
                <div class="look-block_list">
                  <div class="look-inner look-inner_center">
                    <h4 class="look-block__headline_min">
                      {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_MIN_2' | translate }}
                    </h4>
                    <div class="color-block" [style.background-color]="basicColors.blue"></div>
                  </div>
                  <p class="look-block__title">
                    {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_4' | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="look-inner look-inner_margin">
            <mat-slide-toggle
              class="setting-item__toggle"
              [checked]="buttonsColorCustom"
              (change)="onToggleColors($event, 'buttons', 'buttonColorEnum', 'custom', 'one')"
              color="primary"
            ></mat-slide-toggle>
            <div class="look-block_list">
              <h4 class="look-block__headline_min">
                {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_MIN_3' | translate }}
              </h4>
              <p class="look-block__title">
                {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_5' | translate }}
              </p>
              <div class="filter filter_margin">
                <label
                  [style.background-color]="customButtonsColor"
                  class="color-picker__bg"
                  for="button-color-picker"
                ></label>
                <input
                  id="button-color-picker"
                  type="color"
                  [value]="customButtonsColor"
                  class="color-picker__bg_input"
                  (input)="changeColor('buttons', $event.target.value)"
                />
                <input
                  id="color-picker"
                  class="color-picker__input filter__input"
                  [value]="customButtonsColor"
                  (input)="changeColor('buttons', $event.target.value)"
                  (focusout)="focusOut('buttons', 'purple')"
                />
              </div>
              <p class="look-block__title">
                {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_6' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="look-block" *ngIf="false">
        <h3 class="look-block__headline col-xs-12">
          {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_4' | translate }}
        </h3>
        <div class="look-inner look-inner_margin">
          <mat-slide-toggle
            class="setting-item__toggle"
            [checked]="gradientColorOne"
            (change)="onToggleColors($event, 'gradient', 'gradientColorEnum', 'one', 'two')"
            color="primary"
          ></mat-slide-toggle>
          <div class="look-block_list">
            <h4 class="look-block__headline_min">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_MIN_4' | translate }}
            </h4>
            <p class="look-block__title">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_7' | translate }}
            </p>
          </div>
          <div
            class="gradient-block"
            [style.background-image]="
              'linear-gradient(to bottom, ' + basicColors.gradient11 + ' 0%, ' + basicColors.gradient12 + ' 100%)'
            "
          >
            <img class="gradient-block__img" src="../assets/img/admin/look-and-feel/milestones-line.svg" alt="" />
          </div>
        </div>
        <div class="look-inner look-inner_margin">
          <mat-slide-toggle
            class="setting-item__toggle"
            [checked]="gradientColorTwo"
            (change)="onToggleColors($event, 'gradient', 'gradientColorEnum', 'two', 'one')"
            color="primary"
          ></mat-slide-toggle>
          <div class="look-block_list">
            <h4 class="look-block__headline_min">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_MIN_5' | translate }}
            </h4>
            <p class="look-block__title">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_8' | translate }}
            </p>
          </div>
          <div
            class="gradient-block"
            [style.background-image]="
              'linear-gradient(to bottom, ' + basicColors.gradient21 + ' 0%, ' + basicColors.gradient22 + ' 100%)'
            "
          >
            <img class="gradient-block__img" src="../assets/img/admin/look-and-feel/milestones-line.svg" alt="" />
          </div>
        </div>
        <div class="look-inner look-inner_margin">
          <mat-slide-toggle
            class="setting-item__toggle"
            [checked]="gradientColorCustom"
            (change)="onToggleColors($event, 'gradient', 'gradientColorEnum', 'custom', 'one')"
            color="primary"
          ></mat-slide-toggle>
          <div class="look-block_list">
            <h4 class="look-block__headline_min">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_MIN_6' | translate }}
            </h4>
            <p class="look-block__title">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_9' | translate }}
            </p>
          </div>
          <div
            class="gradient-block gradient-block_own"
            [style.background-image]="
              'linear-gradient(to bottom, ' + customGradientColor1 + ' 0%, ' + customGradientColor2 + ' 100%)'
            "
          >
            <img
              class="gradient-block__img"
              src="../assets/img/admin/look-and-feel/milestones-line.svg"
              alt="milestones-line"
            />
          </div>
        </div>
        <div class="look-inner look-inner_margin look-inner_top">
          <div class="look-inner_column">
            <div class="filter">
              <label
                [style.background-color]="customGradientColor1"
                class="color-picker__bg"
                for="gradient1-color-picker"
              ></label>
              <input
                id="gradient1-color-picker"
                type="color"
                [value]="customGradientColor1"
                class="color-picker__bg_input"
                (input)="changeColor('gradient1', $event.target.value)"
              />
              <input
                class="color-picker__input filter__input"
                [value]="customGradientColor1"
                (input)="changeColor('gradient1', $event.target.value)"
                (focusout)="focusOut('gradient1', 'gradient11')"
              />
            </div>
            <div class="filter filter_margin">
              <label
                [style.background-color]="customGradientColor2"
                class="color-picker__bg"
                for="gradient2-color-picker"
              ></label>
              <input
                id="gradient2-color-picker"
                type="color"
                [value]="customGradientColor2"
                class="color-picker__bg_input"
                (input)="changeColor('gradient2', $event.target.value)"
              />
              <input
                class="color-picker__input filter__input"
                [value]="customGradientColor2"
                (input)="changeColor('gradient2', $event.target.value)"
                (focusout)="focusOut('gradient2', 'gradient12')"
              />
            </div>
          </div>
          <div class="look-inner_column look-inner_column_margin">
            <p class="look-block__title look-block__title_no-margin">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_10' | translate }}
            </p>
            <p class="look-block__title look-block__title_no-margin">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_11' | translate }}
            </p>
            <p class="look-block__title look-block__title_no-margin">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_12' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="block-line"></div>
    <div class="look-inner">
      <h3 class="col-xs-12 look-block__headline">
        {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_COLOR_TEXT_BTN' | translate }}
      </h3>
      <div class="col-lg-4 col-sm-6">
        <div class="look-inner look-inner_margin">
          <mat-slide-toggle
            class="setting-item__toggle"
            [checked]="textColorLight"
            (change)="onToggle($event, 'textColor', 'lookAndFeelTextColor', 'light', 'dark')"
            color="primary"
          ></mat-slide-toggle>
          <div class="look-block_list">
            <div class="look-inner look-inner_center">
              <h4 class="look-block__headline_min">
                {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEDLINE_MIN_COLOR_TEXT_1' | translate }}
              </h4>
              <div class="color-block color-block_white">Buy tokens</div>
            </div>
            <p class="look-block__title">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_COLOR_TEXT_1' | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="look-inner look-inner_margin">
          <mat-slide-toggle
            class="setting-item__toggle"
            [checked]="textColorDark"
            (change)="onToggle($event, 'textColor', 'lookAndFeelTextColor', 'dark', 'light')"
            color="primary"
          ></mat-slide-toggle>
          <div class="look-block_list">
            <div class="look-inner look-inner_center">
              <h4 class="look-block__headline_min">
                {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEDLINE_MIN_COLOR_TEXT_2' | translate }}
              </h4>
              <div class="color-block color-block_dark">Buy tokens</div>
            </div>
            <p class="look-block__title">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_COLOR_TEXT_2' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="block-line"></div>
    <div class="look-inner">
      <h3 class="col-xs-12 look-block__headline">
        {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_6' | translate }}
      </h3>
      <div class="col-lg-4 col-sm-6">
        <div class="look-inner">
          <mat-slide-toggle
            class="setting-item__toggle"
            [checked]="layoutPositionLeft"
            (change)="onToggle($event, 'menuLayout', 'lookAndFeelMenuLayout', 'left', 'top')"
            color="primary"
          ></mat-slide-toggle>
          <div class="look-block_list">
            <h4 class="look-block__headline_min">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_MIN_7' | translate }}
            </h4>
            <p class="look-block__title">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_13' | translate }}
            </p>
            <img
              src="../assets/img/admin/look-and-feel/left-side-bar.svg"
              alt="left-side-bar"
              class="look-block__img"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="look-inner">
          <mat-slide-toggle
            class="setting-item__toggle"
            [checked]="layoutPositionTop"
            (change)="onToggle($event, 'menuLayout', 'lookAndFeelMenuLayout', 'top', 'left')"
            color="primary"
          ></mat-slide-toggle>
          <div class="look-block_list">
            <h4 class="look-block__headline_min">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_MIN_8' | translate }}
            </h4>
            <p class="look-block__title">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_14' | translate }}
            </p>
            <img
              src="../assets/img/admin/look-and-feel/horizontal-side-bar.svg"
              alt="horizontal-side-bar"
              class="look-block__img"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="look-inner">
          <mat-slide-toggle
            class="setting-item__toggle"
            [checked]="layoutPositionRight"
            (change)="onToggle($event, 'menuLayout', 'lookAndFeelMenuLayout', 'right', 'left')"
            color="primary"
          ></mat-slide-toggle>
          <div class="look-block_list">
            <h4 class="look-block__headline_min">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_MIN_9' | translate }}
            </h4>
            <p class="look-block__title">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_15' | translate }}
            </p>
            <img
              src="../assets/img/admin/look-and-feel/right-side-bar.svg"
              alt="right-side-bar"
              class="look-block__img"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="block-line"></div>
    <div class="look-inner">
      <h3 class="col-xs-12 look-block__headline">
        {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_7' | translate }}
      </h3>
      <div class="col-lg-4 col-sm-6">
        <div class="look-inner">
          <mat-slide-toggle
            class="setting-item__toggle"
            [checked]="layoutThemeLight"
            (change)="onToggle($event, 'colorStyle', 'lookAndFeelColorStyle', 'light', 'dark')"
            color="primary"
          ></mat-slide-toggle>
          <div class="look-block_list">
            <h4 class="look-block__headline_min">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_MIN_10' | translate }}
            </h4>
            <p class="look-block__title">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_16' | translate }}
            </p>
            <img src="../assets/img/admin/look-and-feel/light-style.svg" alt="light-style" class="look-block__img" />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="look-inner">
          <mat-slide-toggle
            class="setting-item__toggle"
            [checked]="layoutThemeDark"
            (change)="onToggle($event, 'colorStyle', 'lookAndFeelColorStyle', 'dark', 'light')"
            color="primary"
          ></mat-slide-toggle>
          <div class="look-block_list">
            <h4 class="look-block__headline_min">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_MIN_11' | translate }}
            </h4>
            <p class="look-block__title">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_17' | translate }}
            </p>
            <img src="../assets/img/admin/look-and-feel/dark-style.svg" alt="dark-style" class="look-block__img" />
          </div>
        </div>
      </div>
    </div>
    <div class="block-line"></div>
    <div class="look-inner">
      <h3 class="col-xs-12 look-block__headline">
        {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_8' | translate }}
      </h3>
      <div class="col-lg-4 col-sm-6">
        <div class="look-inner">
          <mat-slide-toggle
            class="setting-item__toggle"
            [checked]="tonColorNormal"
            (change)="onToggle($event, 'labelColorTon', 'lookAndFeelLabelsColorTon', 'normal', 'light')"
            color="primary"
          ></mat-slide-toggle>
          <div class="look-block_list">
            <h4 class="look-block__headline_min">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_MIN_12' | translate }}
            </h4>
            <p class="look-block__title">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_17' | translate }}
            </p>
            <div class="badge-row">
              <app-badge [status]="'PENDING'"></app-badge>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="look-inner">
          <mat-slide-toggle
            class="setting-item__toggle"
            [checked]="tonColorLight"
            (change)="onToggle($event, 'labelColorTon', 'lookAndFeelLabelsColorTon', 'light', 'normal')"
            color="primary"
          ></mat-slide-toggle>
          <div class="look-block_list">
            <h4 class="look-block__headline_min">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_MIN_13' | translate }}
            </h4>
            <p class="look-block__title">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_18' | translate }}
            </p>
            <div class="badge-row">
              <app-badge [status]="'PENDING'"></app-badge>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="look-inner">
          <mat-slide-toggle
            class="setting-item__toggle"
            [checked]="tonColorDark"
            (change)="onToggle($event, 'labelColorTon', 'lookAndFeelLabelsColorTon', 'dark', 'normal')"
            color="primary"
          ></mat-slide-toggle>
          <div class="look-block_list">
            <h4 class="look-block__headline_min">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__HEADLINE_MIN_14' | translate }}
            </h4>
            <p class="look-block__title">
              {{ 'ADMIN_DASHBOARD__LOOK_AND_FEEL__TITLE_19' | translate }}
            </p>
            <div class="badge-row">
              <app-badge [status]="'PENDING'"></app-badge>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-save-settings [isValid]="true" *ngIf="showSaveButton" (saveSettings)="onSaveSettings()"></app-save-settings>
  </mat-card-content>
</mat-card>
