import { observable } from 'mobx-angular';

import { UserModel } from '../user/user.model';

export class UsersModel {
  public data: UserModel[];
  @observable public total: number = null;

  constructor(users: UsersModel) {
    Object.assign(this, users);

    if (users != null && users.data != null) {
      this.data = users.data.map((user) => new UserModel(user));
    }
  }
}
