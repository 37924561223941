import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { NotifyEvent, NotifyHelper } from '@tokengear-common/helpers';
import { ConnectWalletProvider } from '@tokengear-common/models';
import { SettingsService } from '@tokengear-common/modules/settings';

import { Web3Service } from '@app/integrations/services/web3/web3.service';

import { ModalConnectWalletComponent } from '../../components/modal-connect-wallet/modal-connect-wallet.component';

@Injectable()
export class ConnectWalletService {
  constructor(public dialog: MatDialog, public web3Service: Web3Service, public settingsService: SettingsService) {}

  public async connectWallet(): Promise<ConnectWalletProvider> {
    this.dialog.open(ModalConnectWalletComponent, {
      width: '568px',
      backdropClass: 'backdrop-bg-dark',
      panelClass: 'modal-dark',
      autoFocus: false,
      disableClose: true,
    });

    return new Promise((resolve, reject) => {
      const subscriptions = [
        NotifyHelper.on(NotifyEvent.ConnectWalletSuccess, (provider) => {
          resolve(provider);
          this.dialog.closeAll();
          this.unsubscribeAll(subscriptions);
        }),
        NotifyHelper.on(NotifyEvent.ConnectWalletError, (error) => {
          reject(error);
          this.unsubscribeAll(subscriptions);
        }),
      ];
    });
  }

  public unsubscribeAll(subscriptions: Subscription[]): void {
    subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
