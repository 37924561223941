import { observable } from 'mobx-angular';

import { ProviderModel } from './provider.model';

export class SettingsWalletModel {
  @observable enabled: boolean;
  @observable infuraProjectId: string;
  @observable allowedProviders: ProviderModel[];

  constructor(params: SettingsWalletModel) {
    Object.assign(this, params);
  }
}
