import { observable, action } from 'mobx-angular';

export class SettingsResponseModel<ConfigModelType> {
  @observable serviceId: string = null;
  @observable type?: string = null;
  @observable settings: ConfigModelType = null;

  constructor(params: Partial<SettingsResponseModel<ConfigModelType>>, ConfigModel: new (...args) => ConfigModelType) {
    Object.assign(this, params);
    if (params.settings != null) {
      this.settings = new ConfigModel(params.settings);
    }
  }

  @action updateSettings(settings: ConfigModelType) {
    Object.assign(this.settings, settings);
  }
}
