import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent implements OnInit {
  @Output() readonly Update: EventEmitter<any> = new EventEmitter();
  @Output() readonly showFileSizeError: EventEmitter<boolean> = new EventEmitter();
  @Input() accept: string;
  @Input() minResolution: string;
  @ViewChild('inputFile') inputFile: any;
  private _data = new BehaviorSubject(null);

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('fileloader')
  set in(val) {
    if (val === true) {
      this.inputFile.nativeElement.click();
    }
  }

  public fileValue = '';
  private file: Blob;

  constructor() {}

  ngOnInit() {}

  handleInputChange(e) {
    this.file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (!this.file) {
      return;
    }

    if (this.file.size > 10485760) {
      this.showFileSizeError.emit();
    }

    if (this.file) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(this.file);
    }
  }

  _handleReaderLoaded(e) {
    const reader = e.target;
    this.fileValue = reader.result;
    if (this.minResolution) {
      const img = new Image();
      img.src = window.URL.createObjectURL(this.file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src);
        if (this.minResolution) {
          const resolutions = this.minResolution.split(',');
          if (width < +resolutions[0] && height < +resolutions[1]) {
            return this.Update.emit({
              error: true,
            });
          }
        }
        this.Update.emit({
          file: this.file,
          fileString: this.fileValue,
        });
      };
    } else {
      this.Update.emit({
        file: this.file,
        fileString: this.fileValue,
      });
    }
  }
}
