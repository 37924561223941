import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { NavigationService } from '../../navigation';
import { AuthService } from '../services/auth.service';

@Injectable()
export class TwoFactorAuthenticationGuard implements CanActivate {
  constructor(private authService: AuthService, public navigationService: NavigationService) {}

  canActivate(): boolean {
    const authorization2FA = this.authService.ifAuthorization2FA;

    if (!authorization2FA) {
      this.navigationService.navigateToUserLogin();

      return false;
    }

    return true;
  }
}
