import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { action, computed, observable } from 'mobx-angular';

@Component({
  selector: 'app-currency-list',
  templateUrl: './currency-list.component.html',
  styleUrls: ['./currency-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyListComponent implements OnInit {
  @observable @Input() allCurrencies = null;
  @observable @Input() allowedCurrencies = null;
  @observable @Input() topCurrencies = null;

  @Output() readonly valueChange = new EventEmitter();

  @observable isFullList = false;
  @observable currencyNameFilter = '';

  constructor() {}

  ngOnInit() {}

  @computed get visibleCurrencies() {
    return this.allCurrencies
      .filter((currency) => {
        if (this.currencyNameFilter.length === 0) {
          return true;
        }

        return (
          currency.code.toLocaleLowerCase().indexOf(this.currencyNameFilter) > -1 ||
          currency.currency.toLocaleLowerCase().indexOf(this.currencyNameFilter) > -1 ||
          currency.code.toLocaleLowerCase().indexOf(this.currencyNameFilter.trim()) > -1 ||
          currency.currency.toLocaleLowerCase().indexOf(this.currencyNameFilter.trim()) > -1
        );
      })
      .filter((currency) =>
        !this.isFullList && this.currencyNameFilter.length === 0
          ? this.topCurrencies.indexOf(currency.code) > -1
          : true,
      )
      .sort((a, b) => this.topCurrencies.reverse().indexOf(b.code) - this.topCurrencies.reverse().indexOf(a.code))
      .map((currency) => {
        if (this.allowedCurrencies.indexOf(currency.code) > -1) {
          currency.isChecked = true;
        }

        return currency;
      });
  }

  @action valueChanged(code: string, isChecked: boolean) {
    this.valueChange.emit({ code, isChecked });
  }

  @action changeSearchName(val: string) {
    this.currencyNameFilter = val.toLocaleLowerCase();
  }

  @action loadMore() {
    this.isFullList = true;
  }
}
