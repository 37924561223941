import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiUrlsDictionary } from '@tokengear-common/modules/api';

import { environment } from '@environments/environment';

import { getBalanceMockData } from './balance.mock-data';

@Injectable({
  providedIn: 'root',
})
export class BalanceInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      environment.mock &&
      environment.interceptors.BalanceInterceptor &&
      request.url.endsWith(ApiUrlsDictionary.balance()) &&
      request.method === 'GET'
    ) {
      const response = new HttpResponse({
        status: 200,
        body: getBalanceMockData(),
      });

      return of(response);
    }

    return next.handle(request);
  }
}
