import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ApiUrlsDictionary } from '@tokengear-common/modules/api';
import { HttpHelperService } from '@tokengear-common/services';

import { DialogResult } from '@pages/user/dictionaries/dialog-result';

@Component({
  selector: 'app-modal-invoice-generated',
  templateUrl: './modal-invoice-generated.component.html',
  styleUrls: ['./modal-invoice-generated.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalInvoiceGeneratedComponent {
  DialogResult = DialogResult;

  constructor(private httpHelperService: HttpHelperService, @Inject(MAT_DIALOG_DATA) public data: any) {}

  public urlPDF(id) {
    const params = {
      id,
    };

    return this.httpHelperService.getUrlWithToken({
      url: ApiUrlsDictionary.invoicesExportPDF(),
      httpParams: params,
    });
  }
}
