import { observable } from 'mobx-angular';

export class DomainModel {
  @observable name: string;
  @observable version: string;
  @observable reqId?: string;
  @observable chainId?: string;
  @observable salt?: string;

  constructor(value: DomainModel) {
    Object.assign(this, value);
  }
}
