import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MobxAngularModule } from 'mobx-angular';

import { ConfirmationDialogComponent } from './confirmation-dialog.component';

@NgModule({
  declarations: [ConfirmationDialogComponent],
  exports: [ConfirmationDialogComponent],
  imports: [MatDialogModule, MatButtonModule, MobxAngularModule],
})
export class ConfirmationDialogModule {}
