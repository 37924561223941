import { Injectable } from '@angular/core';
import { action, observable } from 'mobx-angular';

import { SettingsLookAndFeelModel } from '@tokengear-common/modules/settings';

@Injectable()
export class AdminLookAndFeelStore {
  @observable public logoServerError: string = null;
  @observable public faviconServerError: string = null;
  @observable public emailServerError: string = null;
  @observable public preSettings: SettingsLookAndFeelModel = null;

  @action public updateLogoServerError(value: string): void {
    this.logoServerError = value;
  }

  @action public updateFaviconServerError(value: string): void {
    this.faviconServerError = value;
  }

  @action public updateEmailLogoServerError(value: string): void {
    this.emailServerError = value;
  }

  @action public updatePreSettings(value: SettingsLookAndFeelModel): void {
    this.preSettings = value;
  }
}
