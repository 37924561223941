import { observable } from 'mobx-angular';

import { SignTypeDataModel } from '@tokengear-common/modules/auth';

export class EthAddressSignatureDataModel {
  @observable signatureData: SignTypeDataModel;
  @observable signature: string;
  @observable account: string;

  constructor(data: EthAddressSignatureDataModel) {
    Object.assign(this, data);
  }
}
