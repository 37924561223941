import { Directive, HostListener, ElementRef } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

@Directive({
  selector: '[appInvalidControlScroll]',
})
export class InvalidControlScrollDirective {
  public get containerEl(): Window {
    return window;
  }

  constructor(private el: ElementRef<HTMLElement>, private formGroupDir: FormGroupDirective) {}

  @HostListener('ngSubmit') onSubmit() {
    if (this.formGroupDir.control.invalid) {
      this.scrollToFirstInvalidControl();
    }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector('form .ng-invalid');
    const container: any = this.el.nativeElement.closest('mat-sidenav-content') || document.body;
    const top = this.getTopOffset(firstInvalidControl, container);

    container.scrollTo({
      top,
      left: 0,
      behavior: 'smooth',
    });

    fromEvent(container, 'scroll')
      .pipe(debounceTime(100), take(1))
      .subscribe(() => firstInvalidControl.focus());
  }

  public getTopOffset(controlEl: HTMLElement, container): number {
    const containerYPosition = container.getBoundingClientRect().y;
    const { top, height } = controlEl.getBoundingClientRect();
    const absoluteControlElTop = container.scrollTop + top - height;

    return absoluteControlElTop - containerYPosition;
  }
}
