import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { action, computed } from 'mobx-angular';

import { UsersService } from '@domain-modules/users/services/users/users.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit {
  constructor(private usersService: UsersService) {}

  @action ngOnInit() {}

  @computed get user() {
    return this.usersService.user;
  }
}
