import { observable } from 'mobx-angular';

import { SettingsFaviconModel } from '../settings-favicon/settings-favicon.model';

export class SettingsImageModel {
  @observable key: string = null;
  @observable location: string = null;
  @observable favicons: SettingsFaviconModel[] = null;

  constructor(params: SettingsImageModel) {
    Object.assign(this, params);

    if (this.favicons != null) {
      this.favicons = this.favicons.map((item) => new SettingsFaviconModel(item));
    }
  }
}
