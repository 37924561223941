import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() progressFirst: number;
  @Input() progressSecond: number;
  @Input() total: number;
  @Input() height: number;
  @Input() width?: number;
  @Input() colorFirst?: string;
  @Input() colorSecond?: string;
  @Input() background?: string;
  @Input() borderRadius?: number;
  @Input() showAnimation?: boolean;
  @Input() percentage?: number;
  @Input() boxShadow?: string;
}
