import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { action } from 'mobx-angular';

import { TransactionStatus } from '@app/integrations/dictionaries/web3.dictionary';
import { TransactionResponseModel } from '@app/integrations/models/transaction-response.model';
import { DialogResult } from '@pages/user/dictionaries/dialog-result';

@Component({
  selector: 'app-modal-transaction-status',
  templateUrl: './modal-transaction-status.component.html',
  styleUrls: ['./modal-transaction-status.component.scss'],
})
export class ModalTransactionStatusComponent {
  public DialogResult = DialogResult;
  public transactionStatus: TransactionStatus;
  public showBack = false;
  public transactionResponse: TransactionResponseModel;
  public title: string;
  public description: string;
  constructor(
    private dialogRef: MatDialogRef<ModalTransactionStatusComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      status: TransactionStatus;
      response: TransactionResponseModel;
      title?: string;
      description?: string;
    },
  ) {
    this.title = data.title;
    this.description = data.description;
    this.transactionStatus = data.status;
    this.transactionResponse = data.response;
  }

  @action public closeModal(): void {
    switch (this.transactionStatus) {
      case TransactionStatus.SUCCESS:
        this.dialogRef.close(this.DialogResult.Approve);
        break;
      case TransactionStatus.ERROR:
        this.dialogRef.close(this.DialogResult.Closed);
        break;
      default:
        this.dialogRef.close(this.DialogResult.Closed);
    }
  }
}
