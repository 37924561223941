import { observable } from 'mobx-angular';

export class SettingsCustomIdModel {
  @observable digitsInInvoiceFreeRunningNumber?: number = null;
  @observable digitsInTransactionFreeRunningNumber?: number = null;
  @observable digitsInUserFreeRunningNumber?: number = null;
  @observable digitsInWithdrawalFreeRunningNumber?: number = null;
  @observable enabledInvoiceId?: boolean = null;
  @observable enabledTransactionId?: boolean = null;
  @observable enabledUserId?: boolean = null;
  @observable enabledWithdrawalId?: boolean = null;

  constructor(params: SettingsCustomIdModel) {
    Object.assign(this, params);
  }
}
