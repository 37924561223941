<div class="modal" appThemeClass>
  <div class="modal__head">
    <div *ngIf="!isMultiNetwork">{{ 'USER_MODAL__CONNECT_WALLET__HEAD_TITLE' | translate }}</div>
    <div *ngIf="isMultiNetwork">{{ 'USER_MODAL__CONNECT_WALLET__HEAD_TITLE_1' | translate }}</div>
    <button class="modal__head__close" [mat-dialog-close]="DialogResult.Closed">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="modal__content">
    <app-choose-network *ngIf="isMultiNetwork"></app-choose-network>
    <app-choose-wallet></app-choose-wallet>
  </div>
</div>
