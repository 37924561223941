import { observable } from 'mobx-angular';

import { NetworkModel } from './network.model';

export class SettingsNetworksModel {
  @observable enabled: boolean;
  @observable allowedNetworks: NetworkModel[];
  @observable isMultiNetwork: boolean;
  @observable networksParamsUrl: string;
  @observable showWithoutLogin: boolean;

  constructor(data: SettingsNetworksModel) {
    Object.assign(this, data);
  }
}
