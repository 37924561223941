import { observable } from 'mobx-angular';

export class SettingsAdminSideBarItemModel {
  @observable label: string;
  @observable orderBy: number;
  @observable isDisabled: boolean;
  @observable iconName: string;
  @observable iconText: string;
  @observable path: string;

  constructor(data: SettingsAdminSideBarItemModel) {
    Object.assign(this, data);
  }
}
