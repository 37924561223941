import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { action } from 'mobx-angular';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable()
export class SetTokenInterceptor {
  constructor(private authService: AuthService) {}

  @action intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authService.token;

    if (token == null || request.url.indexOf('amazonaws.com') !== -1) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        Authorization: token,
      },
    });

    return next.handle(request);
  }
}
