import { observable } from 'mobx-angular';

export class SettingsReferral12GroupsModel {
  @observable id: string = null;
  @observable title: string = null;
  @observable minTokensBalance: number = null;

  constructor(groups: SettingsReferral12GroupsModel) {
    Object.assign(this, groups);
  }
}
