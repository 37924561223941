import { observable } from 'mobx-angular';

import {
  SalePoolsStatus,
  SalePoolsType,
  SalePoolsWhitelistStatus,
} from '@domain-modules/pools/dectionaries/sale-pools.dictionary';
import { SalePoolsInfoModel } from '@domain-modules/pools/models/sale-pools-info.model';
import { SalePoolsKeyMetricsModel } from '@domain-modules/pools/models/sale-pools-key-metrics.model';
import { SalePoolsTimePointItemModel } from '@domain-modules/pools/models/sale-pools-time-point-item.model';

export class SalePoolsItemModel {
  @observable id: string;
  @observable name: string;
  @observable address: string;
  @observable depositToken: { name: string; address: string };
  @observable info: SalePoolsInfoModel;
  @observable type: SalePoolsType;
  @observable status: SalePoolsStatus;
  @observable social: { url: string; icon: string }[] | null;
  @observable whitelistStatus: SalePoolsWhitelistStatus;
  @observable keyMetrics: SalePoolsKeyMetricsModel;
  @observable timePoints: SalePoolsTimePointItemModel[];
  @observable rewardToken?: { name: string; address: string };
  @observable exchangeRate?: number;
  @observable totalRaise?: string;
  @observable totalDeposited?: string;
  @observable totalDepositedInRewardToken?: string;
  @observable totalRaiseInRewardToken?: string;
  @observable price?: string;
  @observable availHarvest?: string;
  @observable harvestPaid?: string;
  @observable balance?: string;
  @observable balanceForDepositToken?: string;
  @observable depositTokenDecimal?: number;
  @observable progress?: number;
  @observable allocation?: string;
  @observable lockedBalance?: string;
  @observable totalRewards?: string;
  @observable nextAction?: Date | null;
  @observable blockDuration?: string;

  constructor(model: SalePoolsItemModel) {
    Object.assign(this, model);
  }
}
