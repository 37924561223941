import { observable } from 'mobx-angular';

export class SettingsUserRegistrationsConfigModel {
  @observable apartment: boolean = null;
  @observable birthday: boolean = null;
  @observable city: boolean = null;
  @observable country: boolean = null;
  @observable docCountry: boolean = null;
  @observable docType: boolean = null;
  @observable documentScanBack: boolean = null;
  @observable documentScanBackFile: boolean = null;
  @observable documentScanFront: boolean = null;
  @observable documentScanFrontFile: boolean = null;
  @observable firstName: boolean = null;
  @observable fullName: boolean = null;
  @observable gender: boolean = null;
  @observable lastName: boolean = null;
  @observable middleName: boolean = null;
  @observable phone: boolean = null;
  @observable state: boolean = null;
  @observable street: boolean = null;
  @observable zipCode: boolean = null;
  @observable referralToken: boolean = null;

  constructor(params: SettingsUserRegistrationsConfigModel) {
    Object.assign(this, params);
  }
}
