import { Injectable } from '@angular/core';
import { CanLoad, Route } from '@angular/router';

import { SettingsResponseModel, SettingsService } from '../../../settings';
import { NavigationService } from '../../services/navigation.service';

@Injectable()
export abstract class PermissionNavigateBaseGuard<ConfigModelType> implements CanLoad {
  constructor(
    protected readonly settingsService: SettingsService,
    private readonly navigationService: NavigationService,
  ) {}

  canLoad(route: Route): boolean {
    const key = route.data.path;

    return this.checkPermission(this.settingConfig, key);
  }

  private checkPermission(sidebarSettings: SettingsResponseModel<ConfigModelType>, key: string): boolean {
    if (this.hasPagePermission(sidebarSettings.settings, key)) {
      return true;
    }
    this.navigationService.navigateToDefaultTenantRoute();

    return false;
  }

  protected abstract hasPagePermission(settings: ConfigModelType, key: string): boolean;

  protected abstract get settingConfig(): SettingsResponseModel<ConfigModelType>;
}
