import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-switch-btn',
  templateUrl: './switch-btn.component.html',
  styleUrls: ['./switch-btn.component.scss'],
})
export class SwitchBtnComponent {
  @Input() leftName: string;
  @Input() rightName: string;
  @Input() active: string;

  @Output() readonly changeActive = new EventEmitter();

  public changeActiveBtn(active: string): void {
    this.changeActive.emit(active);
  }
}
