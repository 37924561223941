import { observable } from 'mobx-angular';

export class SettingsOptionsModel {
  @observable currencies: string[] = null;
  @observable timezones: string[] = null;
  @observable locales: string[] = null;

  constructor(params: SettingsOptionsModel) {
    Object.assign(this, params);
  }
}
