import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Output() readonly buttonClick: EventEmitter<string> = new EventEmitter();
  @Input() disabled = false;
  @Input() svgIcon: string;
  @Input() text: string;
  @Input() showSpinner: boolean;
  @Input() themeBackground = false;

  constructor() {}

  ngOnInit(): void {}

  onClick(): void {
    this.buttonClick.emit();
  }
}
