import { observable } from 'mobx-angular';

export class SettingsFooterFieldsModel {
  @observable socialMedia: SettingsFooterSocialMediaModel[] = null;
  @observable text: string[] = null;
  constructor(data: SettingsFooterFieldsModel) {
    Object.assign(this, data);
  }
}

export interface SettingsFooterSocialMediaModel {
  image: string;
  link: string;
}
