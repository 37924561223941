import { observable } from 'mobx-angular';

export class SettingsAboutProjectVersionModel {
  @observable id: string = null;
  @observable body: string = null;
  @observable version: number = null;
  @observable createdAt: Date = null;
  @observable createdByUserId: string = null;

  constructor(params: SettingsAboutProjectVersionModel) {
    Object.assign(this, params);
  }
}
