import { observable } from 'mobx-angular';

import { CountryModel } from './country.model';

export class CountriesDataModel {
  @observable items: CountryModel[];

  constructor(params: CountryModel[]) {
    this.items = params.map((item) => new CountryModel(item));
  }
}
