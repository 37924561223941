export function putSettingsAboutProject() {
  return {
    serviceId: 'about.project.service',
    settings: {
      body: '',
      updatedAt: '2018-10-12T07:46:37.653Z',
      isPublic: false,
      versions: [],
    },
    type: 'about.project',
  };
}

export function getSettingsAboutProject() {
  return {
    serviceId: 'about.project.service',
    settings: {
      body: '',
      updatedAt: '2018-10-12T07:46:37.653Z',
      isPublic: false,
      versions: [],
    },
    type: 'about.project',
  };
}
