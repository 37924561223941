<div id="atl-wrapper" *ngIf="configLoaded && configuration.enabled">
  <div id="container-wrapper" *ngIf="created">
    <div
      id="atlwdg-container"
      [ngClass]="{ 'd-none': loader }"
      [ngStyle]="{ height: configuration.customHeight ? configuration.customHeight : 'auto' }"
      class="atlwdg-popup"
    >
      <iframe
        id="atlwdg-frame"
        scrolling="no"
        frameborder="0"
        [src]="iframeSrc"
        #iframeEl
        (load)="emitMessage()"
      ></iframe>
    </div>
  </div>
  <div id="atlwdg-blanket" class="atlwdg-blanket" *ngIf="!hidden || loader"></div>

  <div id="atl-loader" class="spinner-bg lds-ring" *ngIf="created && loader">
    <mat-spinner></mat-spinner>
  </div>
  <a id="atlwdg-trigger" (click)="showDialog()" [ngClass]="getTriggerClasses()">{{ configuration.triggerText }}</a>
</div>
