import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { action, computed, observable } from 'mobx-angular';
import { from, lastValueFrom, Observable, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { UserMembershipStore } from '@tokengear-common/pages/user-membership/stores/user-membership.store';

import { PoolsStore } from '@pages/user/components/expanded-header/stores/pools-store.store';
import { UserModel } from '@users/models/user/user.model';
import { UsersService } from '@users/services/users/users.service';

import { isProviderExists, NotifyEvent, NotifyHelper } from '../../../helpers';
import { ApiUrlsDictionary } from '../../api';
import { NavigationService } from '../../navigation';
import { NetworkService, SettingsService } from '../../settings';
import { AuthResponse } from '../models/login/auth-response.model';
import { LoginModel } from '../models/login/login.model';
import { SignTypeDataModel } from '../models/wallet-connect/sign-type-data.model';

// TODO: clean the imports, should not be imports of the third party modules
/**
 * @dynamic
 */
@Injectable()
export class AuthService {
  @observable public token: string;
  @observable public ssoToken: string;
  @observable public authorization2FA: string;

  public readonly helper = new JwtHelperService();

  constructor(
    private http: HttpClient,
    private navigationService: NavigationService,
    private usersService: UsersService,
    private settingsService: SettingsService,
    private userMembershipStore: UserMembershipStore,
    private poolsStore: PoolsStore,
    private networkService: NetworkService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  public init(): void {
    const token = this.window.localStorage['token'];
    if (token) {
      this.setToken(token);
    }
  }

  private get window(): Window {
    return this.document.defaultView;
  }

  public login(credentials: LoginModel): Observable<unknown> {
    return this.http.post<AuthResponse>(ApiUrlsDictionary.auth(), credentials).pipe(
      switchMap(({ googleTwoFactorEnabled, authorization }) => {
        if (googleTwoFactorEnabled) {
          this.authorization2FA = authorization;

          return from(this.navigationService.navigateToConfirmLogin());
        }

        this.saveToken(authorization);

        return this.usersService.getMe().pipe(
          switchMap(() => {
            NotifyHelper.emit(NotifyEvent.LogIn);

            return from(this.navigationService.navigateToDefaultTenantRoute());
          }),
        );
      }),
    );
  }

  public verify2FA(data: { secret: string }): Observable<UserModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.authorization2FA,
      }),
    };

    return this.http.post(ApiUrlsDictionary.verify2FA(), data, httpOptions).pipe(
      tap((response: { authorization: string }) => {
        this.saveToken(response.authorization);
      }),
      switchMap(() => this.usersService.getMe()),
      tap(() => {
        NotifyHelper.emit(NotifyEvent.LogIn);
        this.navigationService.navigateToDefaultTenantRoute();
      }),
    );
  }

  public async logout(notRemoveWalletConnect?: boolean): Promise<void> {
    this.settingsService.setIsLoadingGlobalData(true);
    await lastValueFrom(this.http.post(ApiUrlsDictionary.logout(), null));
    this.removeToken();
    this.usersService.removeMeUser();
    this.resetHeaderData();
    await lastValueFrom(this.settingsService.getAllSettings());
    if (this.isEnabledNetworks) {
      if (this.networkService.isWalletConnectCurrentProvider && !notRemoveWalletConnect) {
        await this.networkService.currentProvider.disconnect();
        this.window.localStorage.removeItem('walletconnect');
      }
      await this.networkService.initProvider();
      if (!isProviderExists()) {
        this.networkService.setDefaultRPCProvider();
      }
    }
    await this.navigationService.navigateToDefaultTenantRoute();
    NotifyHelper.emit(NotifyEvent.LogOut);
    this.settingsService.setIsLoadingGlobalData(false);
    if (this.settingsService.isMobile) {
      this.window.location.reload();
    }
  }

  public handleAuthError(shouldNavigate = true): void {
    this.removeToken();
    this.usersService.removeMeUser();

    if (shouldNavigate) {
      this.navigationService.navigateToDefaultTenantRoute();
    }
  }

  @action public setToken(token: string): void {
    this.token = token;
  }

  @action public setSSOToken(token: string): void {
    this.ssoToken = token;
  }

  public saveToken(token: string): void {
    this.window.localStorage['token'] = token;
    this.setToken(token);
  }

  public removeToken(): void {
    this.window.localStorage.removeItem('token');
    this.setToken(null);
  }

  @computed public get isTokenExpired() {
    return this.helper.isTokenExpired(this.token);
  }

  @computed public get isTokenEmpty() {
    return this.token == null;
  }

  @computed public get isLogged(): boolean {
    return this.token != null;
  }
  @computed public get ifAuthorization2FA(): boolean {
    return !!this.authorization2FA;
  }

  @computed public get isEnabledNetworks(): boolean {
    return this.settingsService.networksConfig?.settings.enabled;
  }

  public getSignature(): Observable<SignTypeDataModel> {
    return this.http
      .get(ApiUrlsDictionary.signature())
      .pipe(map((response: SignTypeDataModel) => new SignTypeDataModel(response)));
  }

  public authWallet(loginData: { signature: string; address: string; message: string }): Observable<UserModel> {
    return this.http.post(ApiUrlsDictionary.authWallet(), loginData).pipe(
      switchMap((response: { authorization: string }) => {
        if (response) {
          this.saveToken(response.authorization);
          this.navigationService.navigateToDefaultTenantRoute();

          return this.usersService.getMe();
        }
      }),
    );
  }

  public ssoAuth(): Subscription {
    return this.http
      .get(ApiUrlsDictionary.ssoAuth(this.ssoToken))
      .pipe(
        tap((response: { authorization: string }) => {
          if (response) {
            this.saveToken(response.authorization);
            this.navigationService.navigateToDefaultTenantRoute();

            return this.usersService.getMe();
          }
        }),
      )
      .subscribe();
  }

  /**
   * TODO: move to separate place and reset on logout event
   *
   * @private
   */
  private resetHeaderData(): void {
    this.userMembershipStore.setUserMembership(null);
    this.poolsStore.setPoolsList([]);
    this.poolsStore.setStackingPoolsList([]);
  }
}
