import { TransactionsFilterModel } from '@domain-modules/transactions/models/transactions-filter/transactions-filter.model';

export function getTransactionsMockData(filter: TransactionsFilterModel) {
  return {
    total: 100,
    data: [
      {
        id: '8c2fd7c0-9af9-11e8-b8f7-a731e9ac72b3',
        ref: {
          blockchain: 'ETHEREUM',
          symbol: 'ETH',
          txHash: '0x3bd60714df22e195a5406c68ea79c428b22c4f01a8d56d88792eb27d7e3679dc',
          amount: '306603773584905660',
          formattedAmount: '0.30660377358490566',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1.2999999e+24',
            value: '306603773584905660',
            formattedTokens: '1299999.9000',
          },
          userAddress: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-08-08T10:55:22.428Z',
      },
      {
        id: '8a730be0-94bf-11e8-9636-e1a2cc899186',
        ref: {
          blockchain: 'INVOICE',
          symbol: 'USD',
          txHash: '0x40d65d4845cf2b12ab325110b53740e9b82d8e854eb5331bbfdfbe8f65fd612c',
          amount: '111',
          formattedAmount: '111.00',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1.11e+24',
            value: '245575221238938080',
            formattedTokens: '1110000.0000',
          },
          userAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
          userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-07-31T12:45:01.726Z',
      },
      {
        id: '0e1403e0-9499-11e8-89e9-fb90d1e4a719',
        ref: {
          blockchain: 'ETHEREUM',
          symbol: 'ETH',
          txHash: '0xdf959f58afc8608c42882b59601fc39726d34d4ec8a1be29de8cc073e0ef9744',
          amount: '221238938053097346',
          formattedAmount: '0.221238938053097346',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1e+24',
            value: '221238938053097346',
            formattedTokens: '1000000.0000',
          },
          userAddress: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-07-31T08:09:32.190Z',
      },
      {
        id: 'a13567e0-9426-11e8-89e9-fb90d1e4a719',
        ref: {
          blockchain: 'ETHEREUM',
          symbol: 'ETH',
          txHash: '0xac602fdfa6917279654170ccfb5fda54546d666deeee0b1bfbf74dfca4c47f11',
          amount: '221238938053097346',
          formattedAmount: '0.221238938053097346',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1e+24',
            value: '221238938053097346',
            formattedTokens: '1000000.0000',
          },
          userAddress: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-07-30T18:30:26.910Z',
      },
      {
        id: '59d697e0-9415-11e8-89e9-fb90d1e4a719',
        ref: {
          blockchain: 'INVOICE',
          symbol: 'USD',
          txHash: '0x65e021ced2f7b4dbc33285facfb1faf922263e91cb3a09eda9e138c9169a3ae2',
          amount: '100',
          formattedAmount: '100.00',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1e+24',
            value: '210084033613445400',
            formattedTokens: '1000000.0000',
          },
          userAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
          userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-07-30T16:26:45.726Z',
      },
      {
        id: 'f6322ba0-9414-11e8-89e9-fb90d1e4a719',
        ref: {
          blockchain: 'INVOICE',
          symbol: 'USD',
          txHash: '0x47eef7120819efbe3e9e0fe1a855f200c078473e6460d4726b6409a68cb8b33e',
          amount: '100',
          formattedAmount: '100.00',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1e+24',
            value: '210084033613445400',
            formattedTokens: '1000000.0000',
          },
          userAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
          userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-07-30T16:23:58.554Z',
      },
      {
        id: '14f745d0-940f-11e8-89e9-fb90d1e4a719',
        ref: {
          blockchain: 'INVOICE',
          symbol: 'USD',
          txHash: '0x8a0cb928cfd65c5cc3e0d7ba3a0b5154c081374b5d821fc8bc94854116f74ae6',
          amount: '101',
          formattedAmount: '101.00',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1.01e+24',
            value: '212184873949579840',
            formattedTokens: '1010000.0000',
          },
          userAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
          userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-07-30T15:41:53.197Z',
      },
      {
        id: '0a9a8510-940b-11e8-99c7-13bd02d2c601',
        ref: {
          blockchain: 'INVOICE',
          symbol: 'USD',
          txHash: '0x9eceaba0c06d33dd920958db5666ae0bf3d0d81eefaf8bc0ecc762fc99e2d061',
          amount: '100',
          formattedAmount: '100.00',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1e+24',
            value: '210084033613445400',
            formattedTokens: '1000000.0000',
          },
          userAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
          userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-07-30T15:12:57.825Z',
      },
      {
        id: '513016d0-940a-11e8-99c7-13bd02d2c601',
        ref: {
          blockchain: 'INVOICE',
          symbol: 'USD',
          txHash: '0xfd46bd31256934c1b455bc16f42e6759c8153f93940e8322ae0d6ef4fa5afc73',
          amount: '100',
          formattedAmount: '100.00',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1e+24',
            value: '210084033613445400',
            formattedTokens: '1000000.0000',
          },
          userAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
          userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-07-30T15:07:46.749Z',
      },
      {
        id: 'f973ea20-9409-11e8-99c7-13bd02d2c601',
        ref: {
          blockchain: 'BITCOIN',
          symbol: 'BTC',
          txHash: '0xd112cab8433d67ccb69879fe3447b33ab924adc1478a04d9cd4bdbc2e4c3c974',
          amount: '1900000',
          formattedAmount: '0.019',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1.6044971e+24',
            value: '337079229763315400',
            formattedTokens: '1604497.1000',
          },
          userAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
          userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-07-30T15:05:19.554Z',
      },
      {
        id: '94dc2490-9406-11e8-99c7-13bd02d2c601',
        ref: {
          blockchain: 'INVOICE',
          symbol: 'USD',
          txHash: '0x4de21b23387f3c383f74cf1d861e4fbc0ea17b4ef63d0da272ecf430ef0f10de',
          amount: '100',
          formattedAmount: '100.00',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1e+24',
            value: '210084033613445400',
            formattedTokens: '1000000.0000',
          },
          userAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
          userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-07-30T14:41:02.297Z',
      },
      {
        id: '1ccc8440-9406-11e8-99c7-13bd02d2c601',
        ref: {
          blockchain: 'INVOICE',
          symbol: 'USD',
          txHash: '0x9d1131760fb5e3c15e44248c657400b4d6cdebb844a638e0285dd990eb65f86e',
          amount: '100',
          formattedAmount: '100.00',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1e+24',
            value: '210084033613445400',
            formattedTokens: '1000000.0000',
          },
          userAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
          userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-07-30T14:37:40.868Z',
      },
      {
        id: 'cb118000-9401-11e8-99c7-13bd02d2c601',
        ref: {
          blockchain: 'INVOICE',
          symbol: 'USD',
          txHash: '0x1af1e74dab571554bd3506ef15320931d8526605d5db800f24a7fd8645d8c932',
          amount: '101',
          formattedAmount: '101.00',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1.01e+24',
            value: '212184873949579840',
            formattedTokens: '1010000.0000',
          },
          userAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
          userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-07-30T14:06:45.760Z',
      },
      {
        id: '516eb200-9400-11e8-99c7-13bd02d2c601',
        ref: {
          blockchain: 'INVOICE',
          symbol: 'USD',
          txHash: '0xc7412b54e4c93e8d11c6d91651f4f92fb585c2579a6865dfc0550758423cb874',
          amount: '100',
          formattedAmount: '100.00',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1e+24',
            value: '210084033613445400',
            formattedTokens: '1000000.0000',
          },
          userAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
          userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-07-30T13:56:12.192Z',
      },
      {
        id: 'af865c20-93fc-11e8-99c7-13bd02d2c601',
        ref: {
          blockchain: 'INVOICE',
          symbol: 'USD',
          txHash: '0x0b27e484fc28349a9c474269c4dea7078780dc34a71a03b7adca5b64d640b8d6',
          amount: '100',
          formattedAmount: '100.00',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1e+24',
            value: '210084033613445400',
            formattedTokens: '1000000.0000',
          },
          userAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
          userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-07-30T13:30:12.067Z',
      },
      {
        id: '39b31410-93f8-11e8-99c7-13bd02d2c601',
        ref: {
          blockchain: 'INVOICE',
          symbol: 'USD',
          txHash: '0x43220df2c8ca5e17c46006d5b668eda01b4e201d8fa32115f9ff348ea02c917e',
          amount: '100',
          formattedAmount: '100.00',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1e+24',
            value: '210084033613445400',
            formattedTokens: '1000000.0000',
          },
          userAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
          userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-07-30T12:58:16.401Z',
      },
      {
        id: 'ecbc9560-93f6-11e8-99c7-13bd02d2c601',
        ref: {
          blockchain: 'INVOICE',
          symbol: 'USD',
          txHash: '0xbaeff3844def1fef13bb70a500c6ae928ab4c468a0fbeab41dd2887b0c2f75da',
          amount: '100',
          formattedAmount: '100.00',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1e+24',
            value: '210084033613445400',
            formattedTokens: '1000000.0000',
          },
          userAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
          userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-07-30T12:48:57.782Z',
      },
      {
        id: '48ff2a00-93f6-11e8-99c7-13bd02d2c601',
        ref: {
          blockchain: 'INVOICE',
          symbol: 'USD',
          txHash: '0x8521befa28e0a34ebbbe92ca23e9dc55f0e965a93ae70180e1e6ad1a1be9a373',
          amount: '101',
          formattedAmount: '101.00',
          params: {
            holder: '0x6dff9c7c1a821190c9f3b34a835a01dd58c90af0',
            tokens: '1.01e+24',
            value: '212184873949579840',
            formattedTokens: '1010000.0000',
          },
          userAddress: '0x6DFF9C7c1a821190c9f3b34A835A01Dd58C90AF0',
          userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        },
        status: 'PROCESSED',
        userId: 'e72c4fe7-3cd8-48df-a05b-066dbda1ffc4',
        date: '2018-07-30T12:44:23.072Z',
      },
    ],
  };
}
