<ng-container *mobxAutorun>
  <div
    class="status"
    [ngClass]="{
      status_active: active,
      status_confirmed:
        name === 'APPROVED' ||
        name === 'ACCEPTED' ||
        name === 'CONFIRMED' ||
        name === 'SUCCESSFUL' ||
        name === 'PROCESSED' ||
        name === 'COMPLETED' ||
        name === 'PAID_BACK',
      status_pending:
        name === 'PENDING' ||
        name === 'MANUAL_CHECKING' ||
        name === 'PROCESSING' ||
        name === 'RESCIND_IN_PROGRESS' ||
        name === 'CANCEL_PROCESSING' ||
        name === 'WAITING_PAID_BACK' ||
        name === 'IN_PROGRESS',
      status_declined:
        name === 'DECLINED' ||
        name === 'REFUSED' ||
        name === 'BLOCKED' ||
        name === 'EXPIRED' ||
        name === 'CANCELED' ||
        name === 'FAILED',
      status_canceled: name === 'CANCEL_PROCESSED' || name === 'RESCINDED' || name === 'CAN_BE_RESCINDED',
      'status_pending-outdated': name === 'PENDING_OUTDATED',
      'status_not-verify': name === 'NOT_VERIFIED' || name === 'CREATED',
      status_rescind: name === 'RESCIND',
      status_reschedule: name === 'RESCHEDULE'
    }"
  >
    <ng-container *ngIf="isIcon">
      <i
        *ngIf="
          name === 'APPROVED' ||
          name === 'ACCEPTED' ||
          name === 'CONFIRMED' ||
          name === 'SUCCESSFUL' ||
          name === 'COMPLETED' ||
          name === 'PROCESSED'
        "
        class="material-icons status__icon"
      >
        check
      </i>
      <i
        *ngIf="
          name === 'PENDING' ||
          name === 'PROCESSING' ||
          name === 'CANCEL_PROCESSING' ||
          name === 'CREATED' ||
          name === 'IN_PROGRESS'
        "
        class="material-icons status__icon"
      >
        access_time
      </i>
      <i
        *ngIf="
          name === 'DECLINED' ||
          name === 'REFUSED' ||
          name === 'BLOCKED' ||
          name === 'CANCELED' ||
          name === 'EXPIRED' ||
          name === 'FAILED'
        "
        class="material-icons status__icon"
      >
        close
      </i>
      <i
        *ngIf="name === 'RESCIND' || name === 'RESCIND_IN_PROGRESS' || name === 'RESCINDED'"
        class="material-icons status__icon"
      >
        info
      </i>
    </ng-container>
    <span>{{ status | translate }}</span>
  </div>
</ng-container>
