import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { action } from 'mobx-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiUrlsDictionary } from '@tokengear-common/modules/api';

import { StakingFarmingPoolsType } from '@domain-modules/pools/dectionaries/staking-farming-pools.dictionary';
import { StakingPoolsFilterModel } from '@domain-modules/pools/models/staking-pools-filter.model';
import { StakingPoolsStatsModel } from '@domain-modules/pools/models/staking-pools-stats.model';
import { StakingPoolsModel } from '@domain-modules/pools/models/staking-pools.model';
import { UniswapTokenModel } from '@domain-modules/uniswap/models/uniswap-token.model';
import { UniswapTokensModel } from '@domain-modules/uniswap/models/uniswap-tokens.model';

@Injectable({
  providedIn: 'root',
})
export class StakingPoolsService {
  constructor(private http: HttpClient) {}

  @action stakingPoolsList(filter: StakingPoolsFilterModel): Observable<StakingPoolsModel> {
    return this.http
      .get(ApiUrlsDictionary.stakingFarmingPoolsList(), { params: <any>filter })
      .pipe(map((response: StakingPoolsModel) => new StakingPoolsModel(response)));
  }

  @action getAllStakedContracts(tokenAddress: string): Observable<string[]> {
    tokenAddress = tokenAddress ? tokenAddress.toLowerCase() : tokenAddress;

    return this.http
      .get(ApiUrlsDictionary.allStakedContracts(), { params: { token: tokenAddress } })
      .pipe(map((response: string[]) => response));
  }

  @action getStakedAll(): Observable<string> {
    return this.http
      .get(ApiUrlsDictionary.stakedAll())
      .pipe(map((response: { totalStacked: string }) => response.totalStacked));
  }

  @action getUserStaked(tokenAddress: string): Observable<string> {
    tokenAddress = tokenAddress ? tokenAddress.toLowerCase() : tokenAddress;

    return this.http
      .get(ApiUrlsDictionary.userStaked(), { params: { token: tokenAddress } })
      .pipe(map((response: { total: string }) => response.total));
  }

  @action getStakingPoolsStats(): Observable<StakingPoolsStatsModel> {
    return this.http
      .get(ApiUrlsDictionary.stakingFarmingPoolStats(StakingFarmingPoolsType.GENESIS_STAKING))
      .pipe(map((response: StakingPoolsStatsModel) => new StakingPoolsStatsModel(response)));
  }

  @action getTokens(token: string): Observable<UniswapTokensModel> {
    return this.http
      .get(ApiUrlsDictionary.tokens(token))
      .pipe(map((response: UniswapTokenModel[]) => new UniswapTokensModel({ data: response })));
  }

  @action getUserStakingPoolsList(userId: string, params: { offset: number; limit: number }): Observable<any> {
    return this.http.get(ApiUrlsDictionary.userStakingPoolsList(userId), { params });
  }
}
