import { observable } from 'mobx-angular';

import { VestingInfoCardListModel } from './vesting-info-card-list.model';

export enum VestingPoolType {
  FIXED_SWAP = 'fixed_swap',
  DEFAULT = 'default',
}

export class SettingsVestingPoolsModel {
  @observable enabled: boolean;
  @observable showBalanceSection: boolean;
  @observable needKycToGetSignature: boolean;
  @observable needKycToGetSignatureInPrivatePool: boolean;
  @observable infoCards: VestingInfoCardListModel;
  @observable type: VestingPoolType;
  @observable addressesToFilter: string[];
  @observable isPaginationEnabled: boolean;
  @observable itemsPerPage: number;
  constructor(data: SettingsVestingPoolsModel) {
    Object.assign(this, data);
  }
}
