import { StatsFilterModel } from '@stats/models/stats-filter/stats-filter.model';

export function updateSettingsContributionsLtc() {
  return {
    enabled: true,
    address: 'moBX9SBfLpsMHg5oj2CHhyMoRVDnkqDENW',
  };
}

export function getSettingsContributionsLtc() {
  return {
    serviceId: 'litecoin-contributions',
    type: 'contributions',
    settings: {
      enabled: true,
      address: 'moBX9SBfLpsMHg5oj2CHhyMoRVDnkqDENW',
    },
  };
}
