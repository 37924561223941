import { observable } from 'mobx-angular';

import { BalanceModel } from '@domain-modules/balance/models/balance/balance.model';

import { UserParamsModel } from '../user-params/user-params.models';
import { UserTenantModel } from '../user-tenant/user-tenant.model';

export class UserModel {
  @observable id: string;
  @observable referrerUserId: string;
  @observable referrerUserFullName: string;
  @observable referrerUserCustomId: {
    customUserId: string;
    _id: string;
  };
  @observable customUserId: string;
  @observable balance: BalanceModel[];
  @observable email: string;
  @observable newEmail?: string;
  @observable isEmailConfirmed: boolean;
  @observable googleTwoFactorEnabled: boolean;
  @observable userAddress: string;
  @observable slug: string;
  @observable params: UserParamsModel;
  @observable tenant: UserTenantModel;

  constructor(user: UserModel) {
    Object.assign(this, user);

    if (this.params != null) {
      this.params = new UserParamsModel(this.params);
    }

    // if (this.tenant && this.tenant.address) {
    //   alert(this.tenant.address);
    // }

    if (this.tenant != null) {
      this.tenant = new UserTenantModel(this.tenant);
    }
  }
}
