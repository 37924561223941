import { Injectable } from '@angular/core';

import { PermissionNavigateBaseGuard } from './permission-navigate.base.guard';

import { SettingsAdminSideBarModel, SettingsResponseModel } from '../../../settings';

@Injectable()
export class PermissionNavigateAdminGuard extends PermissionNavigateBaseGuard<SettingsAdminSideBarModel> {
  hasPagePermission({ navigationGroup, enabled }: SettingsAdminSideBarModel, key: string): boolean {
    if (!enabled) {
      return false;
    }

    for (const { navigation } of navigationGroup) {
      const navigationItem = navigation.find(({ path }) => path === key);

      if (navigationItem) {
        return !navigationItem.isDisabled;
      }
    }

    return false;
  }

  protected get settingConfig(): SettingsResponseModel<SettingsAdminSideBarModel> {
    return this.settingsService.adminSideBarConfig;
  }
}
