import { UniswapTokenModel } from './uniswap-token.model';

export class UniswapTokensModel {
  data: UniswapTokenModel[];

  constructor(params: UniswapTokensModel) {
    Object.assign(this, params);
    if (this.data != null) {
      this.data = params.data.map((item) => new UniswapTokenModel(item));
    }
  }
}
