import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ApiUrlsDictionary } from '@tokengear-common/modules/api';

import { environment } from '@environments/environment';
import { UsersFilterModel } from '@users/models/users-filter/users-filter.model';

import { getUsersMockData } from './users.mock-data';

@Injectable({
  providedIn: 'root',
})
export class UsersInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      environment.mock &&
      environment.interceptors.UsersInterceptor &&
      request.url.endsWith(ApiUrlsDictionary.users()) &&
      request.method === 'GET' &&
      request.params.get('id') == null
    ) {
      const params = request.params;
      const search = params.get('search');
      const statusFilter = params.get('statusFilter');
      const proofOfIncomeFilter = params.get('proofOfIncomeFilter');
      const sortField = params.get('sortField');
      const sortOrder = params.get('sortOrder');
      const limit = params.get('limit');
      const offset = params.get('offset');
      const response = new HttpResponse({
        status: 200,
        body: getUsersMockData(
          new UsersFilterModel(<UsersFilterModel>{
            search,
            statusFilter,
            proofOfIncomeFilter,
            sortField,
            sortOrder,
            limit: Number(limit),
            offset: Number(offset),
          }),
        ),
      });

      return of(response);
    }

    return next.handle(request);
  }
}
