import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TransactionStatus } from '@app/integrations/dictionaries/web3.dictionary';
import { TransactionResponseModel } from '@app/integrations/models/transaction-response.model';
import { ModalTransactionStatusComponent } from '@shared/components/modal-transaction-status/modal-transaction-status.component';

import { NetworkService, SettingsService, SettingsTransactionsExplorerLinkModel } from '../modules/settings';

@Injectable()
export class TransactionStatusService {
  constructor(private dialog: MatDialog, private settings: SettingsService, private networkService: NetworkService) {}

  public openTransactionStatusModal(
    status: TransactionStatus,
    response: TransactionResponseModel,
    options?: {
      title?: string;
      description?: string;
    },
  ): void {
    this.dialog.open(ModalTransactionStatusComponent, {
      width: '480px',
      autoFocus: false,
      backdropClass: 'backdrop-bg-dark',
      panelClass: 'modal-dark',
      disableClose: true,
      data: { status, response, ...(options || {}) },
    });
  }

  public isRejected(error: any): boolean {
    return error.code === 4001;
  }

  public getTxUrl(hash: string): string {
    const network = this.getNetwork();

    return network && `${network.txLink}${hash}`;
  }

  private getNetwork(): SettingsTransactionsExplorerLinkModel {
    return this.settings.transactionsExplorerLinks.settings.links.find(
      (network) => network.networkId === this.networkService.currentNetwork.networkId,
    );
  }
}
