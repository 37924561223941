import { observable } from 'mobx-angular';

import { UserIdHistoryModel } from '../user-id-history/user-id-history.model';

export class UserIdHistoryResponseModel {
  @observable data: UserIdHistoryModel[] = null;
  @observable total: number = null;

  constructor(user: UserIdHistoryResponseModel) {
    Object.assign(this, user);

    if (this.data) {
      this.data = this.data.map((el) => new UserIdHistoryModel(el));
    }
  }
}
