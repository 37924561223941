import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { observable, computed } from 'mobx-angular';

import { Destroyable } from '@tokengear-common/base';
import { NotifyEvent, NotifyHelper } from '@tokengear-common/helpers';
import { SettingsService, NetworkModel, NetworkService } from '@tokengear-common/modules/settings';

import { METAMASK_LINK } from '@app/integrations/dictionaries/web3.dictionary';
import { MetaMaskService } from '@app/integrations/services/meta-mask/meta-mask.service';
import { Web3Service } from '@app/integrations/services/web3/web3.service';
import { UsersService } from '@users/services/users/users.service';

@Component({
  selector: 'app-network-error',
  templateUrl: './network-error.component.html',
  styleUrls: ['./network-error.component.scss'],
})
export class NetworkErrorComponent extends Destroyable implements OnInit {
  public readonly METAMASK_LINK = METAMASK_LINK;
  public networkId: number;
  @observable public showNoProviderMessage: boolean;
  @observable public showErrorNetworkMessage: boolean;

  constructor(
    private web3Service: Web3Service,
    private networkService: NetworkService,
    private metaMaskService: MetaMaskService,
    private changeDetectorRef: ChangeDetectorRef,
    private settingsService: SettingsService,
    private usersService: UsersService,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.isEnabledNetworks) {
      this.init();
      this.subscriptions.push(
        this.networkService.currentNetworkChange.pipe().subscribe(() => {
          this.init();
        }),
        NotifyHelper.on(NotifyEvent.LogIn, () => {
          this.init();
        }),
      );
    }
  }

  private init(): void {
    if ((this.isAuth1FA && this.usersService.isClient) || this.isAuthCryptoWallet || this.showWithoutLogin) {
      this.web3Service
        .getNetworkId()
        .then(async (networkId) => {
          this.networkId = networkId;
          this.showErrorNetworkMessage =
            !this.hesProjectNetwork(this.networkId) ||
            (this.currentNetwork &&
              this.networkService.isMetaMastCurrentProvider &&
              this.currentNetwork.networkId !== this.networkId);
          this.changeDetectorRef.detectChanges();
        })
        .catch(() => {
          this.showErrorNetworkMessage = true;
        });
    }
  }

  public switchNetworkByParams(networkId: number): void {
    this.metaMaskService.switchNetworkByParams(networkId);
  }

  public get currentNetwork(): NetworkModel {
    return this.networkService.currentNetwork;
  }

  @computed get showNotice(): boolean {
    return this.showErrorNetworkMessage || this.showNoProviderMessage;
  }

  public hesProjectNetwork(networkId): NetworkModel {
    return this.settingsService.allowedNetworks.find((network) => network.networkId === networkId);
  }

  @computed get isAuth1FA(): boolean {
    return this.settingsService.isAuth1FA;
  }

  @computed get isAuthCryptoWallet(): boolean {
    return this.settingsService.isAuthCryptoWallet;
  }

  @computed get projectName(): string {
    return this.settingsService.settingsGeneral.settings.projectName;
  }

  @computed get isEnabledNetworks(): boolean {
    return this.settingsService.networksConfig?.settings.enabled;
  }
  @computed get showWithoutLogin(): boolean {
    return this.settingsService.networksConfig?.settings.showWithoutLogin;
  }
}
