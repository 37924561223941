import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@tokengear-common/modules/auth';
import { RoutingPaths } from '@tokengear-common/modules/navigation';

import { UserRolesDictionary } from '@domain-modules/users/dictionaries/user-roles.dictionary';
import { DummyRouteComponent } from '@shared/components/dummy-route/dummy-route.component';

export const routes: Routes = [
  {
    path: '',
    component: DummyRouteComponent,
    pathMatch: 'full',
  },
  {
    path: RoutingPaths.user,
    loadChildren: async () => import('./pages/user/user.module').then((m) => m.UserModule),
    runGuardsAndResolvers: 'always',
    canLoad: [AuthGuard],
    data: {
      permission: [UserRolesDictionary.Guest, UserRolesDictionary.Client],
    },
  },
  {
    path: RoutingPaths.admin,
    loadChildren: async () => import('./pages/admin/admin.module').then((m) => m.AdminModule),
    canLoad: [AuthGuard],
    data: {
      permission: [UserRolesDictionary.Manager],
    },
  },
  {
    path: RoutingPaths.payment,
    loadChildren: async () => import('./pages/payment/payment.module').then((m) => m.PaymentModule),
    data: {
      permission: [UserRolesDictionary.Guest],
    },
  },
  {
    path: '**',
    redirectTo: `/`,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
