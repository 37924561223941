export enum LookAndFeelMenuLayout {
  left = 'LEFT_SIDE_BAR',
  top = 'HORIZONTAL',
  right = 'RIGHT_SIDE_BAR',
}
export enum LookAndFeelColorStyle {
  light = 'LIGHT',
  dark = 'DARK',
}
export enum LookAndFeelLabelsColorTon {
  normal = 'NORMAL',
  light = 'LIGHT',
  dark = 'DARK',
}
export enum LookAndFeelTextColor {
  light = 'LIGHT',
  dark = 'DARK',
}
export interface LookAndFeelLabelsMileStoneColor {
  colorTop: string;
  colorBottom: string;
}
export interface LookAndFeelStepItem {
  title: string;
  description: string;
  step: string;
}

export enum LookAndFeelContentType {
  one = 'CONTENT_TYPE_1',
  two = 'CONTENT_TYPE_2',
  three = 'CONTENT_TYPE_3',
  for = 'CONTENT_TYPE_4',
}

export enum NumberFormatNone {
  NONE = 'NONE',
}

export interface LookAndFeelNumberFormat {
  afterDot: string | NumberFormatNone;
}
