<div class="slide-pagination">
  <div class="pagination">
    <div class="left">
      <img *ngIf="!isLoading && ableToGoLeft" (click)="goLeft()" src="../assets/img/theme/kaishi/left-arrow.svg" />
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
    <div class="right">
      <img *ngIf="!isLoading && ableToGoRight" (click)="goRight()" src="../assets/img/theme/kaishi/right-arrow.svg" />
    </div>
  </div>
  <div class="info" *ngIf="!isLoading">
    <span>{{ total === 0 ? 0 : current }}/{{ total }}</span>
  </div>
</div>
