import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AuthGuard } from './guards/auth.guard';
import { NoAuthGuard } from './guards/no-auth.guard';
import { TwoFactorAuthenticationGuard } from './guards/two-factor-authentication.guard';
import { AuthErrorInterceptor } from './interceptors/auth-error.interceptor';
import { SetTokenInterceptor } from './interceptors/set-token.interceptor';
import { AuthService } from './services/auth.service';

@NgModule({
  providers: [
    AuthService,

    AuthGuard,
    NoAuthGuard,
    TwoFactorAuthenticationGuard,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthErrorInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {}
