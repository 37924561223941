import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, from, lastValueFrom, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { JsonRpcResponse } from 'web3-core-helpers';

import { SettingsService, PlanType } from '@tokengear-common/modules/settings';

import {
  DEFAULT_GAS_LIMIT,
  ETHEREUM,
  EthUnits,
  METAMASK_ERRORS,
  METAMASK_LINK,
  WEB3,
} from '@app/integrations/dictionaries/web3.dictionary';
import { NetworkParamsModel } from '@app/integrations/models/network-params.model';

@Injectable({
  providedIn: 'root',
})
export class MetaMaskService {
  constructor(private settingsService: SettingsService, private translateService: TranslateService) {}

  isMetaMaskInstalled(): boolean {
    return !!window[ETHEREUM] || !!this.walletConnectObject;
  }

  public get walletConnectObject() {
    const isWalletConnect = window.localStorage.getItem('walletconnect');
    if (isWalletConnect) {
      return JSON.parse(isWalletConnect);
    }

    return null;
  }

  /*
    @to - address of recipient
    @value - amount of ETH to transfer (number as ETH  or string as Wei)
    @data - transaction data (if any)
    @gasLimit - max amount of gas to spent on the transaction (using default value if not specified)
   */
  sendMetaMaskTransaction(
    to: string | PlanType[],
    value: string | number,
    data?: string,
    gasLimit?: number,
  ): Observable<string> {
    if (!this.isMetaMaskInstalled()) {
      window.open(METAMASK_LINK, '_blank');

      return throwError(METAMASK_ERRORS.notInstalled);
    }

    value = typeof value === 'string' ? value : window[WEB3].toWei(value, EthUnits.ether);

    const account$ = from(window[WEB3].currentProvider.enable()).pipe(
      map((accounts) => accounts[0]),
      catchError(() => throwError(METAMASK_ERRORS.needUserPermission)),
    );

    const gasPrice$ = this.getGasPrice().pipe(catchError(() => throwError(METAMASK_ERRORS.failedToRetrieveGasPrice)));

    return combineLatest(account$, gasPrice$).pipe(
      switchMap(([account, gasPrice]: [string, string]) =>
        this.getTransactionHash(to, account, value, gasPrice, gasLimit, data),
      ),
    );
  }

  private getTransactionHash(to, fromAccount, value, gasPrice, gas = DEFAULT_GAS_LIMIT, data = ''): Observable<string> {
    return Observable.create((observer) => {
      window[WEB3].eth.sendTransaction({ to, from: fromAccount, value, gasPrice, gas, data }, (err, hash) => {
        if (err) {
          observer.error(err);
        } else {
          observer.next(hash);
          observer.complete();
        }
      });
    });
  }

  public getGasPrice(): Observable<string> {
    return Observable.create((observer) => {
      window[WEB3].eth.getGasPrice((err, result) => {
        if (err) {
          observer.error(err);
        } else {
          observer.next(result);
          observer.complete();
        }
      });
    });
  }

  public switchNetworkByParams(networkId): void {
    if (!this.isMetaMaskInstalled()) {
      window.open(METAMASK_LINK, '_blank');

      return console.error(this.translateService.instant(METAMASK_ERRORS.notInstalled));
    }
    lastValueFrom(this.settingsService.getNetworkParams()).then((result: NetworkParamsModel[]) => {
      const chainId = `0x${Number(networkId).toString(16)}`;
      const networkParams = result?.length
        ? result.find((item) => item.chainId.toLowerCase() === chainId.toLowerCase())
        : null;
      this.sendAddNetworkByParams(networkParams).then(() => {
        window[WEB3].currentProvider.sendAsync({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId,
            },
          ],
        });
      });
    });
  }

  public async sendAddNetworkByParams(networkParams: NetworkParamsModel): Promise<JsonRpcResponse> {
    return new Promise(async (resolve, reject) => {
      if (!networkParams) {
        return resolve(null);
      }
      window[WEB3].currentProvider.sendAsync(
        {
          jsonrpc: '',
          method: 'wallet_addEthereumChain',
          params: [networkParams],
        },
        (error: any, result: any) => {
          if (error) {
            return reject(error);
          }
          if (result.error) {
            return reject(result.error);
          }
          resolve(result.result);
        },
      );
    });
  }
}
