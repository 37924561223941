import { Injectable, NgZone } from '@angular/core';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { from, Observable, of } from 'rxjs';

import { isProviderExists } from '@tokengear-common/helpers';

import { ETHEREUM, METAMASK_LINK, WEB3 } from '@app/integrations/dictionaries/web3.dictionary';
import { MetaMaskService } from '@app/integrations/services/meta-mask/meta-mask.service';

declare let window: any;

@Injectable({
  providedIn: 'root',
})
export class Web3Service {
  constructor(private ngZone: NgZone, private metaMaskService: MetaMaskService) {}
  public requestAccount(): Observable<string> {
    return new Observable<string>((observer) => {
      if (!this.metaMaskService.isMetaMaskInstalled()) {
        window.open(METAMASK_LINK, '_blank');
        observer.error('ERROR_METAMASK_LINK');

        return;
      }
      window[WEB3].currentProvider
        .request({ method: 'eth_requestAccounts' })
        .then((accounts) => {
          if (accounts.length === 0) {
            // MetaMask is locked or the user has not connected any accounts
            console.log('Please connect to MetaMask.');
          } else if (accounts[0]) {
            observer.next(accounts[0]);
            observer.complete();
          }
        })
        .catch((err) => {
          if (err.code === 4001) {
            console.log('Please connect to MetaMask.');
          } else {
            observer.error(err);
          }
        });
    });
  }

  public async requestAccountWalletConnect(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const accounts = await window[WEB3].eth.getAccounts();
      if (accounts) {
        resolve(accounts[0]);
      } else {
        reject('error get account');
      }
    });
  }

  public async getBalance(address: string): Promise<string> {
    if (!isProviderExists()) {
      return null;
    }

    return window[WEB3].eth.getBalance(address);
  }
  public async getNetworkId(): Promise<number> {
    if (!isProviderExists()) {
      return null;
    }

    return window[WEB3].eth.net.getId();
  }

  public async getChainId(): Promise<string> {
    if (!isProviderExists()) {
      return null;
    }

    return window[WEB3].eth.getChainId();
  }

  public async getBlockNumber(): Promise<number> {
    if (!isProviderExists()) {
      return null;
    }

    return window[WEB3].eth.getBlockNumber();
  }

  public async signDataV4(account: any, data: any): Promise<string> {
    return new Promise(async (resolve) => {
      window[WEB3].currentProvider.sendAsync(
        {
          method: 'eth_signTypedData_v4',
          params: [account, data],
          from: account,
        },
        (error: any, result: any) => {
          if (result.error) {
            return console.log(result.error?.message);
          }
          resolve(result.result);
        },
      );
    });
  }

  public async initProvider(infuraProjectId: string): Promise<WalletConnectProvider> {
    if (this.isWalletConnectObject && infuraProjectId) {
      return new Promise(async (resolve) => {
        const provider = new WalletConnectProvider({
          infuraId: infuraProjectId,
          qrcode: false,
        });
        provider
          .enable()
          .then(() => {
            resolve(provider);
          })
          .catch(() => {
            resolve(null);
          });
      });
    }

    return new Promise(async (resolve) => {
      const provider = window && window[ETHEREUM] ? window[ETHEREUM] : null;
      if (provider) {
        resolve(provider);
      } else {
        resolve(null);
      }
    });
  }

  public connectToMetaMask(): Observable<string> {
    if (this.isWalletConnectObject) {
      const account = this.isWalletConnectObject.accounts[0];

      return of(account);
    }

    return from(this.requestAccount());
  }

  public async connectToWalletConnect(infuraProjectId: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const provider = new WalletConnectProvider({
        infuraId: infuraProjectId,
        qrcodeModalOptions: {
          mobileLinks: ['metamask', 'trust'],
          desktopLinks: [],
        },
      });
      provider
        .enable()
        .then(() => {
          resolve(provider);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  public get isWalletConnectObject() {
    return this.metaMaskService.walletConnectObject;
  }
}
