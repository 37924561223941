import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { observable, computed } from 'mobx-angular';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @observable @Input() public status: string;

  @observable @Input() public isIcon = true;

  @observable @Input() public active: boolean;

  @computed get name(): string {
    if (this.status) {
      const arr = this.status.split('.');

      return arr[arr.length - 1];
    }

    return this.status;
  }
}
