import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TenantCoinsState } from '@tokengear-common/models';
import { NavigationService } from '@tokengear-common/modules/navigation';

import { ReferralsModel } from '@domain-modules/referrals/models/referrals/referrals.model';

@Component({
  selector: 'app-statistic-token-sale-balance',
  templateUrl: './statistic-token-sale-balance.component.html',
  styleUrls: ['./statistic-token-sale-balance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticTokenSaleBalanceComponent {
  @Input() userAffiliate: ReferralsModel;
  @Input() showBtn: boolean;
  @Input() showBorder: boolean;

  constructor(private navigationService: NavigationService) {}

  public buyTokens(): void {
    this.navigationService.navigateToUserDashboard({ coins_state: TenantCoinsState.Buy, coins_balance: 0 });
  }
}
