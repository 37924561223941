import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { AppSettings } from '@app/app.setting';

@Component({
  selector: 'app-crypto-currency-list',
  templateUrl: './crypto-currency-list.component.html',
  styleUrls: ['./crypto-currency-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CryptoCurrencyListComponent implements OnInit {
  constructor() {}

  public cryptoCurrencyUrl = AppSettings.CRYPTO_CURRENCY_URL;

  ngOnInit() {}
}
