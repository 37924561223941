import { observable } from 'mobx-angular';

export class ProviderModel {
  @observable enabled: boolean;
  @observable order: number;
  @observable networkIds: number[];
  @observable name: string;
  @observable logo: string;

  constructor(value: ProviderModel) {
    Object.assign(this, value);
  }
}
