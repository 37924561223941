import { observable } from 'mobx-angular';

export class DashboardPageConfigModel {
  @observable buyCoins: boolean;
  @observable KYCStatus: boolean;
  @observable vestingPools: boolean;
  @observable fixedSwapPools: boolean;
  @observable affiliate: boolean;
  @observable howItWorks: boolean;
  @observable fiatPayments: boolean;
  @observable purchaseCoins: boolean;
  @observable referralLink: boolean;
  @observable yourRecentTransactions: boolean;
  @observable tokenBalance: boolean;

  constructor(data: DashboardPageConfigModel) {
    Object.assign(this, data);
  }
}
