import { observable } from 'mobx-angular';

import { SettingsContributionsCancelModel } from '../settings-contributions-cancel/settings-contributions-cancel.model';

export class SettingsContributionsInvoiceSettingModel {
  @observable
  public accountName: string = null;
  @observable
  public accountNumber: string = null;
  @observable
  public allowedCurrencies: string[] = null;
  @observable
  public bankAddress: string = null;
  @observable
  public bankName: string = null;
  @observable
  public cancel: SettingsContributionsCancelModel;
  @observable
  public companyAddress: string = null;
  @observable
  public companyName: string = null;
  @observable
  public enabled: boolean = null;
  @observable
  public federalId: string = null;
  @observable
  public paymentReminders: string[] = null;
  @observable
  public swiftCode: string = null;
  @observable
  public wireRoutingNumber: string = null;

  constructor(data: any) {
    Object.assign(this, data);
  }
}
