import { observable } from 'mobx-angular';

import { UserParamsAvatarModels } from '@users/models/user-params-avatar/user-params-avatar.models';

import { UserParamsFileModel } from '../user-params-file/user-params-file.models';

export class UserParamsModel {
  @observable avatar: UserParamsAvatarModels = null;
  @observable apartment: string;
  @observable email: string;
  @observable birthday: string;
  @observable city: string;
  @observable country: string;
  @observable address: string;
  @observable cryptoAddress: string;
  @observable docType: string;
  @observable docCountry: string;
  @observable gender: string;
  @observable firstName: string;
  @observable lastName: string;
  @observable fullName: string;
  @observable middleName: string;
  @observable phone: string;
  @observable state: string;
  @observable street: string;
  @observable isTermsOfUseAccepted: boolean;
  @observable zipCode: string;
  @observable documentScanFrontFile: UserParamsFileModel;
  @observable documentScanBackFile: UserParamsFileModel;
  @observable documentScanFaceFile: UserParamsFileModel;
  @observable fundsProofsFiles: any[];
  @observable amount?: number;

  constructor(value: UserParamsModel) {
    Object.assign(this, value);
  }

  public getFullAddress(country?: string, state?: string): string {
    return [this.apartment, this.street, this.city, state, this.zipCode, country]
      .filter((segment) => segment)
      .join(', ');
  }

  public get fileNameDocumentScanFront(): string {
    return this.documentScanFrontFile ? /[^/]*$/.exec(this.documentScanFrontFile.key)[0] : null;
  }

  public get fileNameDocumentScanBack(): string {
    return this.documentScanBackFile ? /[^/]*$/.exec(this.documentScanBackFile.key)[0] : null;
  }

  public get fileNameDocumentScanFace(): string {
    return this.documentScanFaceFile ? /[^/]*$/.exec(this.documentScanFaceFile.key)[0] : null;
  }

  public fileNameFundsProofs(index: number): string {
    return this.fundsProofsFiles[index] ? /[^/]*$/.exec(this.fundsProofsFiles[index].key)[0] : null;
  }
}
