import { observable } from 'mobx-angular';

export class TimeModel {
  @observable year?: number;
  @observable month?: number;
  @observable days: number;
  @observable hours: number;
  @observable minutes: number;
  @observable seconds: number;

  constructor(params: TimeModel) {
    Object.assign(this, params);
  }
}
