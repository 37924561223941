import { AbiItem } from 'web3-utils';

import { ContractStorage } from '@app/integrations/contracts/contract-storage';

export interface ContractData {
  address: string;
  abiJson: AbiItem[];
}

export const LIST_CONTRACTS: ContractStorage = {
  uniswapContract: { address: null, abiJson: null },
  uniswapFactoryContract: { address: null, abiJson: null },
  uniswapSwapContract: { address: null, abiJson: null },
  planForwarderContract: { address: null, abiJson: null },
  exchangeContract: { address: null, abiJson: null },
  fundForwarderContract: { address: null, abiJson: null },
  aprStakeContract: { address: null, abiJson: null },
  contractMembership: { address: null, abiJson: null },
  contractToken: { address: null, abiJson: null },
  wrappedEthContract: { address: null, abiJson: null },
  paymentContract: { address: null, abiJson: null },
  shopsManagerContract: { address: null, abiJson: null },
  shopsPoolContract: { address: null, abiJson: null },
  managementContract: { address: null, abiJson: null },
  coinExchangeToNative: { address: null, abiJson: null },
  coinDiscountExchange: { address: null, abiJson: null },
};

export const LIST_ABI: ContractStorage = {
  nftTokenAbi: null,
  vestingPoolAbi: null,
  vestingPoolNewAbi: null,
  bridgeAbi: null,
  erc20Abi: null,
  privateVestingPoolAbi: null,
  privatePoolAbi: null,
  publicPoolAbi: null,
  genesisAbi: null,
  uniswapPairAbi: null,
  exchangeForwarder: null,
};
