import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { computed } from 'mobx-angular';

import { SettingsService } from '@tokengear-common/modules/settings';

import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(private appService: AppService, private titleService: Title, private settingsService: SettingsService) {}

  public ngOnInit(): void {
    this.appService.init();
  }

  public ngAfterViewInit(): void {
    this.setTitle(this.settingsService.settingsGeneral.settings.projectName);
    this.setFavicon(this.settingsService.settingsLookAndFeel.settings.favicon.location);
  }

  @computed get isLoading(): boolean {
    return this.settingsService.isLoadingGlobalData;
  }

  @computed get menuStatus(): boolean | number {
    return this.settingsService.menuStatus;
  }

  private setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }
  public setFavicon(favicon: string): void {
    const temp = document.createElement('link');
    temp.innerHTML = `<link rel="icon" type="image/png" href="${favicon}">`;
    const head = document.head;
    while (temp.firstChild) {
      head.appendChild(temp.firstChild);
    }
  }
}
