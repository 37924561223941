import { SignTypeDataModel } from '@tokengear-common/modules/auth';

export class ContributionSignatureModel {
  public r: string;
  public s: string;
  public v: number;
  public typedData: SignTypeDataModel;
  public data: string;
  constructor(params: ContributionSignatureModel) {
    Object.assign(this as any, params);
  }
}
