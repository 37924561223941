import { observable } from 'mobx-angular';

import { PaymentAppStatus } from '@domain-modules/payment/dictionaries/payment-status.dictionary';

export class PaymentAppModel {
  @observable id: string;
  @observable img: string;
  @observable tenantId: string;
  @observable title: string;
  @observable status: PaymentAppStatus;
  @observable shopEthAddress: string;
  @observable defaultCurrency: string;
  @observable webhookUrl: string;
  @observable successUrl: string;
  @observable cancelUrl: string;
  @observable liveMode: boolean;
  @observable liveApiKey: string;
  @observable testApiKey: string;
  @observable liveSecretKey: string;
  @observable testSecretKey: string;
  @observable isDeleted: boolean;
  @observable userId: string;
  @observable isBlackListed: boolean;
  @observable isFreezeListed: boolean;

  constructor(model: PaymentAppModel) {
    Object.assign(this, model);
  }
}
