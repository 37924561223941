import { observable } from 'mobx-angular';

export enum BuyTokenTypeDictionary {
  btc = 'btc',
  web3 = 'web3',
  fiat = 'fiat',
}

export class BuyTokensMethodModel {
  @observable address: string;
  @observable currency: string;
  @observable icon: string;
  @observable name: string;
  @observable plan: string;
  @observable type: BuyTokenTypeDictionary;
  @observable networkId?: number;
  @observable token?: string;
  @observable discountValue?: number;
  @observable withDiscount?: boolean;
  @observable minPurchaseAmountInBaseCurrency?: string;
  @observable minPurchaseForDiscount?: string;
  @observable children?: BuyTokensMethodModel[];
  @observable networkName?: string;
  @observable decimals?: number;

  constructor(model: BuyTokensMethodModel) {
    Object.assign(this, model);
  }
}
